.suggestions {
    &__collaboration {
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            &__item {
                margin-bottom: 8px;
                box-sizing: border-box;
                padding: 16px;
                @extend %border-box-shadow;
                @include transitionEase(#{background});
                display: flex;
                background: $white;
                border-radius: 10px;
                position: relative;
                text-decoration: none;
                &:hover, &:focus, &:active {
                    background: $grayBackground;
                    .suggestions__collaboration__list__arrow {
                        opacity: 1;
                    }
                }
            }
            &__icon {
                display: block;
                margin-right: 12px;
                border-radius: 5px;
                border: 0.5px solid $superLightBlack;
                overflow: hidden;
                background: $white;
                height: 100%;
                img {
                    width: 72px;
                    height: 72px;
                    object-fit: cover;
                    border: 0;
                    border-radius: 5px;
                    display: block;
                }
            }
            &__info {
                width: calc(100% - 100px);
            }
            &__title {
                @include get-font($f16, $fw-m, $black, $lh);
                margin-bottom: 6px;
                display: block;
                text-decoration: none;
                word-break: break-word;
            }
            &__helper {
                @include get-font($f14, $fw-r, $mediumBlack, $lh);
                display: inline-block;
                text-decoration: none;
                &--verified {
                    display: inline-flex;
                    align-items: center;
                }
            }
            &__arrow {
                opacity: 0;
                @include transitionEase(#{opacity});
                position: absolute;
                right: 0;
                margin-right: 16px;
                top: 50%;
                transform: translateY(-50%) rotate(-90deg);
            }
        }
    }
}