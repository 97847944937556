.certification-list {
    margin: 32px 0px 24px 0px;
    display: flex;
    gap: 24px;
    align-items: center;
    overflow: hidden;
    overflow-x: scroll;
    padding-bottom: 8px;
    @include getDefaultScrollbar();

    &__item {
        border-radius: 16px;
        // border: 1px solid $imageWrapperBackgroundGray;
        background: #FFF;
        text-decoration: none;
        min-width: 350px;
        width: 350px;
        box-sizing: border-box;


        &.item-height__container {
            min-height: 395px;

            .media__container {
                min-height: 214px;
            }

            .info__conatiner {
                min-height: 130px;
            }
        }

        @media screen and (max-width: $onlyMobile) {
            min-width: 100%;
            width: 100%;
        }

        .need-help__link {
            display: flex;
            gap: 8px;
            text-decoration: none;
            align-items: center;
            justify-content: center;
            flex: 1;
            margin-top: 24px;
            @include get-font($f14, $fw-b, $blue, $lh);

        }

        .image {
            height: auto;
            width: 100%;
            aspect-ratio: 2/1;
            min-height: 214px;
            border-radius: 16px 16px 0px 0px;
            border-bottom: 1px solid #ededed;
            object-fit: contain;
        }

        .react-player__video {
            margin-top: 0px;

            video {
                min-height: 232px;
                border-bottom: 1px solid #ededed;
                border-radius: 16px 16px 0px 0px;
            }
        }

        .slick-slider {
            .survey__details--slider__arrow {
                opacity: 1;
            }

            .slick-list {
                .survey__details--slider__image {
                    min-height: 214px;
                    border-radius: 16px 16px 0px 0px;
                    aspect-ratio: unset;
                    border: none;
                }
            }
        }


        .no-image {
            width: 100%;
            box-sizing: border-box;
            min-height: 232px;
            background-color: #ededed;
            border-radius: 16px 16px 0px 0px;
        }

        &.details-view {
            position: absolute;
            right: 0;
            top: 8%;
            z-index: 1;

            @media screen and (max-width: $onlyMobile) {
                position: unset;
            }

            .note-title {
                margin: 0;
                @include get-font($f14, $fw-m, #F47816, $lh);
                margin-top: 16px
            }
        }

        .model-name {
            @include get-font($f12, $fw-r, $black, $lh);
            margin: 0;
            padding: 0;
        }

        .title-info {
            @include get-font($f18, $fw-sb, $darkBlack, $lh);
            margin: 0;
            padding: 0;
            margin-top: 8px;
            @include limit-text-to-line(1);
        }

        .media__container {
            border-radius: 16px 16px 0px 0px;
            overflow: hidden;
        }

        .info__conatiner {
            padding: 16px 24px 16px 24px;

            .photo-modal {
                &__close {
                    top: 44px;
                }
            }

            .info-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;

                &__item {
                    @include get-font($f16, $fw-m, $copiedBackground, $lh);
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    min-width: fit-content;

                    >img {
                        height: 16px;
                        width: 16px;
                    }

                    span {
                        &:last-child {
                            .symbol-text {

                                display: none
                            }
                        }
                    }


                }

                &__discount {
                    @include get-font($f18, $fw-sb, $darkBlack, $lh);

                    .offer_price {
                        @include get-font($f16, $fw-r, $copiedBackground, $lh);
                        text-decoration-line: line-through;
                        margin-left: 4px;
                    }

                    .symbol {
                        color: #747474;
                    }
                }
            }
        }

        // add-to-cal styling
        add-to-calendar-button::part(atcb-button) {
            font-size: $f14;
        }

        .payment-container {
            padding: 0px 24px 24px 24px;
            display: flex;
            flex-direction: column;

            .coupon-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 8px;
                border: 1px dashed #92BCEE;
                background: #DBE9F9;
                padding: 8px 16px;

                .coupon-details {
                    .coupon-title {
                        @include get-font($f14, $fw-b, $black, $lh);

                        >span {
                            @include get-font($f14, $fw-r, $copiedBackground, $lh);

                        }
                    }

                    .coupon-name {
                        @include get-font($f12, $fw-r, $copiedBackground, $lh);
                    }
                }

                .cancel-coupon {
                    outline: none;
                    background: transparent;
                    border: none;

                    .cross-img {
                        height: auto;
                        width: 12px;
                        filter: brightness(0) saturate(100%) invert(50%) sepia(91%) saturate(840%) hue-rotate(189deg) brightness(90%) contrast(97%);
                    }
                }
            }

            .coupon-input__container {
                display: flex;
                align-items: center;
                gap: 16px;
                // margin-top: 16px;
                margin-bottom: 16px;

                .coupon_input {
                    border-radius: 8px;
                    border: 1px solid $imageWrapperBackgroundGray;
                    align-items: center;
                    width: 100%;
                    padding: 12px 16px;
                    outline: none;
                    @include get-font($f14, $fw-r, $copiedBackground, $lh);
                }

                .apply_btn {
                    border-radius: 8px;
                    border: 1px solid $blue;
                    padding: 12px 16px;
                    background: $white;
                    outline: none;
                    @include get-font($f14, $fw-m, $blue, $lh);

                    &.disabled {
                        opacity: 0.7;
                        pointer-events: none;
                        cursor: not-allowed;
                    }
                }
            }

            .buy-now__btn {
                padding: 12px 16px;
                border-radius: 8px;
                background: $blue;
                margin-top: 16px;
                outline: none;
                border: transparent;
                @include get-font($f14, $fw-b, $white, $lh);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                flex: 1;

                >img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7485%) hue-rotate(308deg) brightness(103%) contrast(104%);
                }

                &.disabled {
                    opacity: 0.6;
                }
                .timer-container{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // gap: 4px;
                    color: $white;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    .min_div{
                      display: flex;
                    }
                    span:first-child{
                        margin-right: 4px;
                    }
                    .dot-text{
                        margin: 0px 4px;
                    }
                  }
            }

            .payment-info {
                // padding: 16px 0px 0px 0px;
                border-top: 1px dashed $copiedBackground;

                &.gray {
                    border-top-color: $lightGrey;
                }

                .info-text {
                    @include get-font($f14, $fw-m, $darkBlack, $lh);

                    &.green {
                        color: $green;
                        font-weight: $fw-r;
                    }

                    &.gray {
                        color: $copiedBackground;
                        font-weight: $fw-r;
                        // margin-bottom: 16px;

                    }
                }

                &__container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 12px;
                    flex-wrap: wrap;
                    gap: 6px;

                    &:first-child {
                        margin-top: 0px;
                    }


                    .schedule__add-to-calendar-wrapper {
                        max-width: fit-content !important;
                        width: fit-content !important;
                        // height: 20px;

                        .atcb-button {
                            font-size: 14px !important
                        }
                    }

                    .info-text {
                        @include get-font($f14, $fw-m, $darkBlack, $lh);

                        &.green {
                            color: $green;
                            font-weight: $fw-r;
                        }

                        &.gray {
                            color: $copiedBackground;
                            font-weight: $fw-m;
                            // margin-bottom: 16px;
                        }
                    }

                    .info-price {
                        @include get-font($f14, $fw-m, $darkBlack, $lh);

                        &.green {
                            color: $green;

                        }

                        &.gray {
                            color: $copiedBackground;
                            // margin-bottom: 16px;

                        }
                    }

                    .reschedule-container {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .reschedule-btn {
                            text-decoration-line: underline;
                            background: transparent;
                            border: none;
                            outline: none;
                            @include get-font($f14, $fw-r, #4990E2, $lh);
                            margin: 0;
                            padding: 0;
                        }

                        >img {
                            filter: brightness(0) saturate(100%) invert(58%) sepia(22%) saturate(3236%) hue-rotate(188deg) brightness(89%) contrast(98%);
                            height: auto;
                            width: 16px;
                        }
                    }

                }
            }

            .payment-status__strip {
                border-radius: 8px;
                background: $green;
                display: flex;
                padding: 8px 16px;
                align-items: center;
                gap: 8px;
                margin-bottom: 16px;
                @include get-font($f14, $fw-r, $white, $lh);


                &.red {
                    background-color: $red;
                }

                >img {
                    height: auto;
                    width: 16px;
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7485%) hue-rotate(308deg) brightness(103%) contrast(104%);
                }
            }

            .completed-container {
                .score-text {
                    display: flex;

                    @include get-font($f22, $fw-sb, $green, $lh);

                    >img {
                        margin-left: 8px;
                        height: auto;
                        width: 16px;
                        padding-top: 5px;
                        filter: brightness(0) saturate(100%) invert(33%) sepia(99%) saturate(1810%) hue-rotate(130deg) brightness(94%) contrast(104%);
                    }
                }

                .score-subtext {
                    margin-top: 4px;
                    @include get-font($f16, $fw-r, $black, $lh);

                }
            }

            .certification_slot-timer {
                border-radius: 8px;
                border: 1px solid $imageWrapperBackgroundGray;
                border: 1px solid #F60E3A;
                background: #FFECF0;
                padding: 16px 24px;
                margin-top: 16px;
                margin-bottom: 4px;

                .title {
                    margin-bottom: 12px;
                    text-align: center;
                    @include get-font($f14, $fw-m, $black, $lh);
                }

                .dot-text {
                    color: $black;
                    font-size: 28px;
                    margin-right: 4px;
                    margin-left: 4px;
                    margin-top: 6px;
                    font-weight: 600;


                    &.details-view {
                        color: $lightGrey;
                    }
                    &.hide {
                        display: none;
                    }
                }

                .countdown {
                    display: flex;
                    gap: 4px;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 0px;

                }

                .min_div {
                    display: flex;
                    gap: 4px;
                }

                .timer-conatiner {
                    display: flex;
                    gap: 4px;

                    &:last-child {
                        .dot-text {
                            display: none;
                        }
                    }
                }

                .countdown-label {
                    padding: 4px 8px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    background: $red;
                    border-radius: 4px;
                    @include get-font($f28, $fw-sb, $white, $lh);
                }

                .time-label {
                    margin-top: 4px;
                    text-align: center;
                    @include get-font($f14, $fw-b, $black, $lh);
                }
            }

        }


        //responsive 
        @media screen and (max-width:$smallMobile) {
            .invitation-timer-container {
                &.details-view {
                    margin-top: 16px;
                    justify-content: flex-start;
                }

            }
        }

        .certification__media__thumb {
            object-fit: contain !important;
            width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;
            min-height: 232px;
            border-bottom: 1px solid #ededed;
        }

        .play {
            position: absolute;
            z-index: 2;
            top: 50%;
            right: 50%;
            height: auto;
            width: 8%;
            left: 50%;
        }

        .post-action-bar__action__popup-container {
            left: 10px;

            >button {
                border-radius: 100%;
                height: 38px;
                width: 38px;
                position: absolute;
                right: 0;
                top: -15px;
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
            }
        }

        .post-share-popup-up {
            right: 0;
        }
    }
}