.workshop{
    &__overlap-modal{
        .default-modal{
            &__container{
                @include centerItem();
                width: 470px;
                max-width: 470px;
                @media screen and (max-width:$onlyMobile) {
                    width: 100%;
                    @include centerItem();
                }
            }
            &__header__title{
                color:$removeRed;
            }
        }
       &__content{
        .info{
            @include get-font($f16,$fw-r,$black,$lh24);
        }
       }
       &__action{
        margin-top: 16px;
        .workshop__action{
            width: 100%;
        }
       }
    }
};