.career-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $mediumBlack;
    padding-bottom: 80px;
    z-index: 25;
    .close-btn-container {
        position: absolute;
        right: 30px;
        top: 20px;
        width: 32px;
        height: 32px;
        line-height: 34px;
        background: $black;
        border-radius: 32px;
        text-align: center;
        z-index: 10;
    }
    .career-modal {
        background: $white;
        border-radius: 5px;
        overflow: hidden;
        overflow-y: auto;
        padding: 30px;
        width: 100%;
        max-width: 1048px;
        height: calc(100vh - 153px);
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        position: relative;
        .career-header-container {
            margin: 40px auto 50px auto;
            .career-heading {
                text-align: center;
                margin: 0 0 12px 0;
            }
            .career-mini-description {
                text-align: center;
                @include get-font($f16, $fw-r, $lightBlack, $lh);
                margin: 0;
            }
        }
        .career-modal-content {
            display: flex;
            .career-description-container {
                flex: 3;
                .career-head {
                    @include get-font($f18, $fw-b, $black, $lh);
                }
            }
            .career-form-container {
                flex: 2;
            }
        }
        .career-application-form {
            padding: 25px 15px 15px 15px;
            background-color: $grayBackground;
            margin-left: 50px;
            .career-head {
                margin: 0;
                @include get-font($f18, $fw-b, $black, $lh);
                margin: 0 0 12px 0;
            }
            .career-description {
                @include get-font($f16, $fw-r, $black, $lh);
                margin: 0;
            }
            .application-form-block {
                margin-bottom: 25px;
                .application-form-label {
                    @include get-font($f14, $fw-sb, $black, $lh);
                    margin-bottom: 8px;
                    display: block;
                }
                .application-form-input {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 9px 12px;
                    //border: 0;
                    border: $border;
                    border-radius: 5px;
                }
                .application-form-textarea {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 9px 12px;
                    border: 0;
                    border-radius: 5px;
                    min-height: 76px;
                    resize: none;
                    border: $border;
                }

            }
            .application-form-resume {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .choose-file-button {
                    padding: 9px 16px;
                    background: $white;
                    border-radius: 5px;
                    text-decoration: none;
                    @extend %appearance-none;
                    cursor: pointer;
                    .choose-file-text {
                        @include get-font($f14, $fw-r, $black, $lh);
                    }
                }
                .input-file-button {
                    display: none;
                }
            }
            .error {
                @include get-font($f12, $fw-r, $red, $lh);
            }

            .send-application {
                margin-top: 16px;
                height: 38px;
                background: $yellow;
                text-align: center;
                width: 100%;
                border-radius: 5px;
                border: $border;
                @include get-font($f12, $fw-b, $black, $lh);
            }

        }
    }
}
@media only screen and (max-width: 786px) {
    .career-modal {
        width: 100% !important;
        box-sizing: border-box;
        height: 100vh!important;
        border-radius: 0!important;
        .career-modal-content {
            display: block!important;
            width: 100%!important;
            box-sizing: border-box;
        }
        .career-application-form {
            margin-left: 0!important;
        }
    }
}
