.discard-post {
    z-index: 110;
    position: fixed;
    .default-modal__container  {
        @include centerItem();
        margin-bottom: 0;
    }
    .default-modal__content p {
        margin-bottom: 24px;
    }
}


@media only screen and (max-width: $onlyMobile) {
    .discard-post {
        .default-modal__footer {
         padding: 20px 24px;
        }
    }
}