.schedule__container{
  .collaboration-manage__search--container{
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin: 24px 0 16px 0;
  }
  .invitation-schedule {
    .header-title {
      @include get-font($f22, $fw-sb, $black, $lhl)
    }
    .details-view {
      padding: 24px;
      border-radius: 8px;
      border-radius: 8px;
      border: 1px solid #f8ae73;
      background-color: #FDE4D033;
      margin-top: 16px;
      margin-bottom: 16px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 8px;
        
        .title {
        @include get-font($f18, $fw-b, $orange, $lh)

        }
        &__actions{
          display: flex;
          gap: 16px;

          button{
            &.disabled{
              pointer-events: none;
              img{
                filter:brightness(0) saturate(100%) invert(89%) sepia(1%) saturate(0%) hue-rotate(156deg) brightness(94%) contrast(95%);
              }
            }
          }
        }
      }
      &__group{
        margin-top: 16px;
      }
      &__text-group{
        display: grid;
        gap: 4px;
      }
      &__info {
       display: grid;
       grid-template-columns: repeat(5,1fr);
      }
      .info-label{
        @include get-font($f14,$fw-m,$copiedBackground);
      }
      .info-value {
        @include get-font($f16,$fw-sb,$black);
        
        &__link{
          @include get-font($f16,$fw-sb,$blue);
          text-decoration: none;
          word-break: break-word;
        }
      }
    }
  }
  .filter-container{
    margin-bottom: 16px;
  }
}
  //responsive 
  @media screen and (max-width:$onlyMobile) {
  .schedule__container{
    .collaboration-manage__search--container{
      flex-direction: column;
    }
    .invitation-schedule{
      .details-view{
        .header{
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 8px;
          &__actions{
            flex-wrap: wrap;
            gap: 15px;
            }
        }
        &__info{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 12px;
        }
      }
    }
  }
}

@media screen and (max-width:$smallMobile) {
  .schedule__container{
    .invitation-schedule{
      .details-view{
        &__info{
          grid-template-columns: repeat(2, 1fr);
        }
        .header{
         flex-direction: column;
        }
      }
    }
  }
}