.not-found {
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        max-width: $containerMaxWidth;
        margin-left: auto;
        margin-right: auto;
        min-height: calc(100vh - 65px);
        > div {
            flex: 0.5;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &__left {
        img {
            width: auto;
            height: auto;
            max-width: 100%;
        }
    }
    &__right {
        flex-direction: column;
        a {
            display: inline-block;
            margin-top: 24px;
            background: $removeRed;
            text-decoration: none;
            padding: 8px 16px;
            border-radius: 8px;
            @include get-font($f16, $fw-m, $white, $lh);
        }
        img {
            margin-top: 50px;
        }
    }
    &__title {
        @include get-font(60px, $fw-sb, $black);
    }
    &__subtitle {
        @include get-font(34px, $fw-r, $mediumBlack);
        margin-top: 48px;
        letter-spacing: 4px;
    }
}

.no-data{
    &__container{
        @include centerItem();
    }
}

@media only screen and (max-width: $onlyMobile) {
    .not-found {
        &__container {
            flex-direction: column;
            margin-top: 56px;
            > div {
                flex: 1;
            }
        }
        &__left {
            img {
                max-width: 80%;
            }
        }
        &__title {
            font-size: 40px;
        }
        &__subtitle {
            font-size: $f18;
            margin-top: 12px;
        }
        &__right {
            justify-content: flex-start !important;
            img {
                margin-top: 12px;
                width: 50%;
            }
            a {
                margin-top: 12px;
            }
        }
    }
}