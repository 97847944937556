.pr-manage {
  &__applicants {
    &__container {
      margin-top: 24px;
    }
    &__details,
    &__action {
      display: flex;
      align-items: center;
    }
    &__action {
      justify-content: flex-end;
      flex-shrink: 0;
      &__btn {
        @extend %appearance-none;
        @include get-font($f12, $fw-sb, $blue, $lh);
        border: 0;
        padding: 0;
        background: $white;
        display: flex;
        align-items: center;
        justify-items: center;
        flex-shrink: 0;
        cursor: pointer;
        > img {
          display: block;
          margin-right: 8px;
        }
        margin-right: 24px;
        &--verified {
          color: $green;
        }

        @media only screen and (max-width: $onlyMobile) {
          margin-right: 4px;
        }
      }
    }
    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
      &__item {
        padding: 24px 0;
        display: flex;
        align-items: flex-start;
        text-decoration: none;
        &--multi {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
        }
        &--centered {
          align-items: center;
        }
        position: relative;
        &:after {
          content: ' ';
          position: absolute;
          width: calc(100% + 48px);
          bottom: 0;
          left: -24px;
          height: 1px;
          background: $grayBackground;
        }
      }
    }
    &__icon {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      border: 0.5px solid $superLightBlack;
      margin-right: 12px;
      object-fit: cover;
      flex-shrink: 0;
    }
    &__message {
      margin-left: 12px;
      width: 16px;
      height: 16px;
    }
    &__title {
      @include get-font($f14, $fw-m, $black, $lh);
      text-decoration: none;
      &__container {
        display: flex;
        align-items: center;
      }
    }
    &__btn {
      @extend %appearance-none;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: $white;
      background: transparent;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include transitionEase(#{background});
      &:hover,
      &:focus {
        background: $grayBackground;
      }
      &--disabled {
        opacity: 0.65;
        &:hover,
        &:focus {
          background: transparent;
        }
        cursor: not-allowed;
      }
      @include get-font($f14, $fw-m, $lighterBlack);
      &__container {
        position: relative;
      }
      &__options {
        position: absolute;
        width: 218px;
        @include form-input-border-shadow();
        background-color: $white;
        margin: 0;
        padding: 8px 0;
        list-style: none;
        border-radius: 10px;
        margin-left: -200px;
        z-index: 1;
        &__item {
          padding: 8px 16px;
          @include get-font($f16, $fw-r, $black, $lh);
          cursor: pointer;
          display: flex;
          align-items: center;
          &:hover,
          &:focus {
            background-color: $grayBackground;
          }
          @include transitionEase(#{background-color});
          &.active {
            background-color: $grayBackground;
          }
          &--danger {
            color: $removeRed;
          }
          &--primary {
            color: $blue;
          }
          &__link {
            text-decoration: none;
            @include get-font($f16, $fw-r, $black, $lh);
            display: block;
          }
          &__img {
            width: 16px;
            display: inline-block;
            margin-right: 20px;
            vertical-align: text-top;
          }
        }
      }
    }
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }
  }
  &__product-applicants {
    &__list {
      list-style: none;
      margin: 24px 0 0;
      padding: 0;
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;
        position: relative;
        &:hover &__hover {
          opacity: 1;
        }
        &__hover {
          position: absolute;
          left: -24px;
          width: calc(100% + 48px);
          height: 100%;
          top: 0;
          background-color: $grayBackground;
          @include transitionEase(#{opacity});
          opacity: 0;
          pointer-events: none;
        }
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: -24px;
          width: calc(100% + 48px);
          height: 1px;
          background-color: $grayBackground;
        }
        &--title {
          > div {
            @include get-font($f12, $fw-b, $black, $lh);
            letter-spacing: 0.6px;
            text-transform: uppercase;
            flex-shrink: 0;
          }
        }
      }
    }
    &__info {
      width: 90px;
      &--big {
        width: 236px;
      }
      &--medium {
        width: 210px;
      }
      margin-right: 24px;
      > span {
        font-weight: $fw-r;
        margin-left: 6px;
        text-transform: capitalize;
      }
    }
    &__user {
      display: flex;
      align-items: flex-start;
      flex-shrink: 0;
      > label {
        cursor: pointer;
        input[type='checkbox'] {
          position: absolute;
          opacity: 0;
          z-index: -1;
        }
        > img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          margin-right: 12px;
          flex-shrink: 0;
          cursor: inherit;
        }
      }
      > a {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-left: 4px;
        > p {
          @include get-font($f14, $fw-m, $black, $lh);
          text-decoration: none;
        }
      }
    }
    &__action {
      width: 160px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > p {
        @include get-font($f14, $fw-m, $black, $lh);
        margin-right: 16px;
      }
    }
    &__text {
      @include get-font($f14, $fw-r, $black, $lh);
      flex-shrink: 0;
      word-break: break-word;
    }
  }
  &__unassigned-applicants {
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      height: calc(100% - 48px);
      overflow: hidden;
      overflow-y: auto;
      &__header {
        @include get-font($f12, $fw-b, $black, $lh);
        letter-spacing: 0.6px;
        text-transform: uppercase;
        flex-shrink: 0;
      }
      &__item {
        padding: 12px 0 24px;
        display: flex;
        align-items: flex-start;
        text-decoration: none;
        position: relative;
        &:after {
          content: ' ';
          position: absolute;
          width: calc(100% + 48px);
          bottom: 0;
          left: -24px;
          height: 1px;
          background: $grayBackground;
        }
        > label {
          position: relative;
          margin-right: 12px;
          cursor: pointer;
          > img {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
          > input[type='checkbox'] {
            opacity: 0;
            position: absolute;
            pointer-events: none;
          }
        }
      }
      &__content {
        display: flex;
      }
      &__icon {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        border: 0.5px solid $superLightBlack;
        margin-right: 16px;
        object-fit: cover;
        flex-shrink: 0;
      }
      &__info {
        @include get-font($f14, $fw-m, $black, $lh);
        text-decoration: none;
      }
    }
  }
}
