.top-bar {
  width: 100%;
  height: 64px;
  border-bottom: solid 0.5px $superLightBlack;
  box-shadow: 0 1px 14px 0 $superLightBlack;
  background: $white;
  // background-image: linear-gradient(to right, #f1f1f1, #ffffff 51%, #f1f1f1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 24; //check z-index affects anywhere

  @media only screen and (max-width: $onlyMobile) {
    height: 56px;
  }

  &--secondary {
    border-bottom: 0;
    box-shadow: none;
  }

  &__hamburger {
    @extend %appearance-none;
    border: 0;
    background: transparent;
    cursor: pointer;
    left: 0;
    padding: 0;

    @media only screen and (max-width: $onlyMobile) {
      margin-right: 16px;
      display: flex;
    }
  }

  &__content {
    max-width: $maxWidthContainer;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;

    &__icon {
      >img {
        width: 84px;
        display: block;
        height: auto;
      }
    }

    &__btn {
      margin-left: 8px;
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__search {
      margin-left: 42px;
      width: 340px;
      border-radius: 20px;
      height: 40px;
      box-sizing: border-box;
      padding: 10px 12px 10px 44px;
      border: 0;
      outline: 0;
      background: url('assets/images/bar/magnifying-glass-browser.svg');
      background-repeat: no-repeat;
      background-position: 16px;
      background-color: $grayBackground;
      @include get-font($f14, $fw-r, $black);
    }

    &__left {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    &__right {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__quick-links {
      position: relative;
      height: 100%;
      box-sizing: border-box;
      padding: 4px 12px;
      @include get-font($f16, $fw-m, $lightBlack);
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
      // @include transitionEase(#{background});

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 3px;
        width: 0;
        transition: width .2s;
        background-color: $lightestBlack;
      }

      &:hover {
        background: $grayBackground;
        color: $black;

        &::after {
          width: 100%;
        }
      }

      &__overlay {
        color: $lightestGray !important;
        border-bottom: 3px solid $lightestBlack !important;
      }

      &.active {
        color: $black;
        font-weight: $fw-sb;

        &::after {
          width: 100%;
          background-color: $red ;
        }
      }

      &--business {
        &::after {
          background-color: $teal;
        }

        &:hover {
          color: $black;
          background-color: $superLightTeal;

          &::after {
            width: 100%;
          }
        }

        &.active {
          &::after {
            background-color: $teal;
          }
        }
      }

      &__activeitem {
        border-bottom: 3px solid $red !important;
      }

      @media only screen and (max-width: $onlyMobile) {
        font-size: 14px;
        margin: 0;
        margin-left: 8px;
      }

      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &__chaticon {
          margin: auto 0;
          margin-top: calc(50% + 2px);

          path {
            fill: $lightestGray;
          }
        }

        &__notificationicon {
          margin: auto 0;

          path {
            fill: $lightestGray;
          }

          &-open {
            margin: auto 0;

            path {
              fill: $black;
            }
          }
        }
      }

      &--black {
        background: rgba($black, 0.9);
        color: $white;

        &:hover,
        &:active,
        &:focus,
        &.active {
          background: $black;
          color: $white;
        }
      }
    }

    &__user-links {
      width: 24px;
      height: 83%;
      display: flex;
      padding: 4px 12px;
      margin: 0px 12px 0px 8px;

      &:hover {
        padding-top: 4px;
        background: $grayBackground;
        border-bottom: 3px solid $lightestBlack;

        .top-bar__content__quick-links__container__chaticon {
          margin: auto 0;
          margin-top: calc(50% + 3.5px);
        }
      }

      &.active {
        color: $black;
        font-weight: $fw-sb;
        height: 83%;
        padding-top: 4px;
        border-bottom: 3px solid $red;

        .top-bar__content__quick-links__container__chaticon {
          margin-top: calc(50% + 3.5px);
        }

        path {
          fill: $black;
        }
      }

      &__overlay {
        border-bottom: 3px solid $lightestBlack !important;

        path {
          fill: $lightestGray !important;
        }
      }

      img {
        @include transitionEase();
      }

      &--unread {
        img {
          transform: rotate(20deg);
        }
      }

      &--link {
        margin-right: 0;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        overflow: hidden;
        @extend %border-box-shadow;
        object-fit: cover;
        cursor: pointer;
        background: $white;
        box-sizing: border-box;

        &__profile {
          display: flex;

          &__chevron {
            margin-left: 6px;
          }
        }
      }

      &--center {
        margin-left: 0;
      }

      &__chat {
        &:hover {
          .top-bar__content__quick-links__container__chaticon {
            path {
              fill: $black;
            }
          }
        }
      }

      &__notifications {
        &:hover {
          padding-top: 4px;

          .top-bar__content__quick-links__container__notificationicon {
            path {
              fill: $black;
            }
          }
        }
      }

      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;

        @media only screen and (max-width: $onlyMobile) {
          height: auto;
        }

        &__list {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          list-style: none;
          padding: 0 15px;
          margin: 0;
          height: 100%;

          &--item {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            width: fit-content;

            .item-content {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 30px;
              width: 30px;
              padding: 0 10px;

              @media screen and (max-width:375px) {
                padding: 0 8px;
              }

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }

              .unread-count {
                display: inline-block;
                height: 22px;
                width: 22px;
                position: absolute;
                top: -5px;
                right: 5px;
                border-radius: 100px;
                text-align: center;
                @include get-font($f14, $fw-m, $white, $lh);
                background-color: #F60E3A;

                &.large {
                  width: 34px;
                  right: -10px;
                  padding-left: 3px;
                }
              }
            }

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              height: 3px;
              width: 0;
              transition: width .2s;
              background-color: $lightestBlack;
            }

            &:hover {
              background: $grayBackground;

              &::after {
                width: 100%;
              }
            }

            &.active {
              &::after {
                width: 100%;
                background-color: $red ;
              }
            }

            @media screen and (max-width:819px) {
              padding: 10px 0;
            }
          }

          @media screen and (max-width:819px) {
            gap: 0;
            padding: 0;
          }
        }
      }

      &__sup {
        position: absolute;
        top: 0;
        min-width: 20px;
        height: 20px;
        border-radius: 20px;
        background: $red;
        padding: 0 2px;
        @include get-font($f12, $fw-m, $white);
        text-align: center;
        line-height: 20px;
        top: 12px;
        left: 12px;
        margin-left: 10px;

        @media only screen and (max-width: $onlyMobile) {
          top: -4px;
        }

        &--message {
          top: 12px;
          left: 20px;
        }
      }
    }
  }

  &__popup {
    position: absolute;
    width: 400px;
    top: 100%;
    right: 20px;
    overflow: hidden;
    margin-top: 8px;
    z-index: 11;
    border-radius: 10px;
    @extend %border-box-shadow;
    background: $white;
    max-height: calc(100vh - 64px);
    overflow: hidden;
    overflow-y: auto;

    &__navmodal {
      margin-top: 8px;
      padding-bottom: 10px;
      border-bottom: 1.5px solid $superLightBlack;

      &__link {
        display: block;
        width: 100%;
        height: 54px;
        border: 0.5px solid $grayBackground;
        margin-bottom: 16px;
        @include transitionEase();
        text-decoration: none;
        @include get-font($f18, $fw-sb, $black, $lh);
        line-height: 54px;
        text-align: center;
        border-radius: 54px;

        &:hover {
          background: $grayBackground;
        }
      }
    }

    &__navfooter {
      text-align: center;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      &__link {
        text-decoration: none;
        margin-top: 24px;
        @include get-font($f14, $fw-m, $lightestGray, $lh);

        &:first-child {
          margin-top: 0px;
        }
      }

      &__copyright {
        margin-top: 24px;
        @include get-font($f14, $fw-r, $lightestGray, $lh);
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 26px 16px 6px;

      &__title {
        @include get-font($f16, $fw-sb, $black, $lh);
      }

      &__link {
        @include get-font($f12, $fw-b, $blue, $lh);
        letter-spacing: 0.6px;
        text-decoration: none;
      }
    }

    .notification {
      padding: 0;

      &__list__container {
        padding-bottom: 12px;
        padding-top: 0;
        border-radius: 0;
        box-shadow: none;
        border: 0;
        margin-bottom: 0;
      }

      &__list__item {
        padding: 10px 14px;
      }
    }

    &__container {
      position: relative;
      height: 100%;

      .top-bar__content__user-links {
        &.hover {
          padding-top: 0px;
        }
      }
    }
  }
}

.profile-menu-popup {
  @extend %border-box-shadow;
  width: 300px;
  background: $white;
  border-radius: 16px;
  z-index: 12;
  position: absolute;
  margin-top: 4px;
  right: 4px;
  max-height: calc(100vh - 64px);
  overflow: hidden;
  overflow-y: auto;
  @include getDefaultScrollbar();
  box-shadow: 0px 12px 60px 3px rgba(23, 23, 23, 0.1);

  &__reward_preference {
    background-color: unset !important;

    &-flex {
      display: flex;
      align-items: center;
      border-radius: 16px;

      &.profile-reward {
        border: 1px solid $blue;
        padding: 4px 8px;

        &.donate {
          border: 1px solid $orange;
        }
      }
    }

    &-text {
      @include get-font($f14, $fw-m, $blue, $lhOnboarding);
      font-weight: 500;

      &-donate {
        color: #F47816;
      }

      &-circle {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        margin-right: 8px;
      }
    }

    &-div {
      justify-content: space-between !important;
    }
  }

  @media only screen and (max-width: $onlyMobile) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    position: fixed;
  }

  &__close {
    position: absolute;
    top: 27px;
    right: 16px;
    margin-left: 12px;
  }

  .profile-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .slider-div {
    position: relative;
    width: 100%;
    max-height: 547px;
  }

  &__head {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    height: 74px;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    .red-triangle{
      margin-right: 38px;
    }

    &__img {
      width: 42px;
      height: 42px;
      border-radius: 42px;
      object-fit: cover;
      margin-right: 16px;
    }

    &__details {
      width: calc(100% - 74px);
      text-align: left;

      >p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__name {
      @include get-font($f14, $fw-m, $black, $lh);
    }

    &__handle {
      @include get-font($f14, $fw-r, $lightBlack, $lh);
    }
  }

  &__list {
    &--fixed {
      max-height: 192px;
      overflow: hidden;
      overflow-y: auto;
    }

    &__container {

      .toggle-list-title {
        text-align: left;
        padding: 4px 24px;
        @include get-font($f12, $fw-b, $copiedBackground, $lh);
      }
    }

    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
      width: 100%;
      padding: 12px 24px;
      box-sizing: border-box;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include transitionEase(background);
      text-decoration: none;

      &:focus,
      &:hover,
      &:active {
        background: $grayBackground;
      }
    }

    &__title {
      @extend %appearance-none;
      @include get-font($f14, $fw-m, $black, $lh);
      text-decoration: none;
      background: none;
      border: 0;
      cursor: pointer;
      padding: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      padding: 12px 18px 12px 18px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include transitionEase(background);
      gap: 12px;

      &:focus,
      &:hover,
      &:active {
        background: $grayBackground;
        outline: $grayBackground;
      }

      &.company {
        align-items: flex-start;
      }

      &--primary {
        color: $blue;
        padding: 0px;
        height: unset;
      }

      .with-image {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .username {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        word-break: break-word;

        .subtitle {
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
          color: #747474;
        }
      }

      .company-icon {
        border-radius: 20px;
        background-color: #DBE9F9;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 8px;
        padding: 4px 24px;
        @include get-font($f14, $fw-m, $black, $lh);

        >img {
          width: auto;
          height: 10px;
        }
      }

      &.nocompany {
        justify-content: center;
        margin-top: 20px;
        color: $lightGrey;

        &:focus,
        &:hover,
        &:active {
          background: $white;
          outline: $white;
        }
      }

      .useravatar {
        .profile-menu-popup__list__img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
      &.passbook {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__img {
      width: 24px;
      height: 24px;
      object-fit: cover;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
    }

    .popup__title {
      padding: 12px 24px 0px;
      @include get-font($f12, $fw-b, $copiedBackground, $lh);
    }

    .popup-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f2f2f2;
      padding: 12px 20px;

      .back-button {
        text-align: center;
        border: 1px solid $lightGrey;
        border-radius: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        width: fit-content;
        padding: 4px 12px;
        @include get-font($f14, $fw-m, $black, $lh);

        >img {
          width: auto;
          height: 10px;
        }
      }
    }

    &.activity {
      position: relative;
      width: 100%;
    }

    &.main {
      position: relative;
      width: 100%;
    }
  }

  &__paidprogram {
    padding: 12px 0px 12px 24px;
    background-color: rgba(239, 185, 32, 0.6);

    &__header {
      display: flex;
      width: 100%;

      &__icon {
        width: 28px;
        height: 28px;
        margin-top: 3px;
        margin-right: 12px;
      }

      &__text {
        @include get-font($f16, $fw-m, $black, $lh);
      }

      &__chevron {
        width: 16px;
        height: 16px;
        margin-top: 8px;
        margin-right: 12px;
        margin-left: auto;
      }

      &__subtitle {
        @include get-font($f12, $fw-b, $black, $lh);
      }
    }

  }

  &__mypassbook {
    width: 100%;
    height: 20px;
    margin-top: 16px;
    margin-bottom: 14px;

    &__header {
      padding: 0px 24px 0px 24px;
      display: flex;

      &__icon {
        width: 22px;
        height: 17px;
        margin-top: 3px;
        margin-right: 16px;
      }

      &__profileicon {
        width: 22px;
        height: 19px;
        margin-top: 1px;
        margin-right: 8px;
      }

      &__textmy {
        @include get-font($f14, $fw-m, $black, $lh);
      }

      &__textpassbook {
        @include get-font($f14, $fw-m, $black, $lh);
        margin-left: 4px;
      }
    }
  }

  &__passbook {
    background-color: #FFECF0;
    padding: 8px 0px;
    text-align: center;
    border-radius: 24px;
    width: 100%;

    &__earningdiv {
      width: 100%;
    }

    &__earning {
      display: flex;
      text-decoration: none;

      &__block {
        display: flex;
      }

      &__text {
        @include get-font($f12, $fw-sb, $darkBlack, $lh);
      }

      &__amount {
        margin-left: auto;
        @include get-font($f14, $fw-m, $darkBlack, $lh);
      }

      &__arrow {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        margin-right: 12px;
      }

      &__profilearrow {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        margin-left: 12px;
      }
    }

    &__nopaidtester {
      display: flex;
      width: 100%;
      height: 36px;
      cursor: pointer;
      margin-top: 12px;
      padding: 12px 12px 12px 26px;
      background-color: rgba(246, 14, 58, 0.04);

      &__text {
        width: 210px;
        @include get-font($f12, $fw-sb, $red, $lh);
      }
    }
  }
}

.top-bar__content__logo__container {
  display: flex;
  align-items: center;
  padding-top: 4px;
}