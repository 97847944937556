.biz {
  &__members {
    &__div {
      background-color: $white;
      border-radius: 10px;
    }
    &__search {
      padding: 24px 24px 16px 24px;
      &__title {
        @include get-font($f18, $fw-sb, $black, $lh);
      }
      &__div {
        margin-top: 16px !important;
        position: relative;
        &__box {
          background-color: $grayBackground !important;
          border-radius: 10px !important;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
          &__clear {
            position: absolute;
            right: 15px;
            top: 20px;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }
      }
    }

    &__table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border-radius: 0px;
      // overflow: hidden;
      margin: 24px auto 32px;

      &__column__name {
        display: flex;
        &__title {
          @include get-font($f14, $fw-m, $black, $lh);
          margin-left: 2px;
          margin-top: 10px;
        }
      }
      &__column__role {
        text-align: left;
        &__admintitle {
          @include get-font($f14, $fw-m, $teal, $lh);
        }
        &__title {
          @include get-font($f14, $fw-m, $black, $lh);
        }
      }
    }
  }
}
