$border: 0.5px solid $lightestBlack;
.career-page {
    background: $backgroundGray;
    padding-bottom: 80px;
    padding-top: 64px;
    .career-image-container {
        max-width: $containerMaxWidth;
        height: 562px;
        background-repeat: no-repeat;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0,0,0,0.2)), url('assets/images/misc/teamPic.jpg');
        background-position: center;
        background-size: cover;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        .career-image-text {
            position: absolute;
            left: 50%;
            bottom: 60px;
            color: $white!important;
            transform: translate(-50%,0);
            margin: 0;
            @include get-font($f40, $fw-b, $black, $lh);
        }
    }
    .career-content {
        max-width: 788px;
        margin: 0 auto;
        .career-title {
            margin: 60px 0 15px 0;
        }
        .career-description {
            @include get-font($f18, $fw-r, $black, $lh);
            &--with-margin {
                margin-bottom: 16px;
            }
            //margin: 0 0 50px 0;
        }
        .career-description-block {
            .career-description {
                @include get-font($f18, $fw-r, $black, $lh);
                //margin: 0 0 50px 0;
            }
        }
        .career-subhead {
            @include get-font($f14, $fw-b, $black, $lh);
            text-transform: uppercase;
            margin: 0 0 25px 0;
            &.second-head {
                margin-top: 60px;
            }
        }
        .career-list {
            list-style: none;
            margin: 0;
            padding: 0;
            background: $white;
            &-item {
                text-decoration: none;
                background: $white;
                border: 0.5px solid transparent;
                margin-bottom: 6px;
                cursor: pointer;
                .career-designation {
                    padding: 16px 25px;
                    background-color: $white;
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                    .designation-name {
                        @include get-font($f18, $fw-b, $black, $lh);
                        text-decoration: none;
                        margin: 0 0 6px 0;
                    }
                    .designation-description {
                        @include get-font($f14, $fw-r, $lightBlack, $lh);
                        text-decoration: none;
                        margin: 0;
                        &-divider {
                            margin: 0 8px;
                            display: inline-block;
                            vertical-align: top;
                        }
                    }
                    .apply-button {
                        display: none;
                        padding: 10px 25px;
                        background-color: $yellow;
                        border-radius: 5px;
                        border: none;
                        @include get-font($f16, $fw-b, $black, $lh);
                        cursor: pointer;
                    }
                }
                &:hover {
                    border: $border;
                    box-shadow: 0 2px 4px 0 $lightestBlack;
                    .apply-button {
                        display: block;
                        outline: none;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 786px) {
    .career-page {
        width: 100% !important;
        box-sizing: border-box;
        .career-content {
            padding: 0 15px;
            margin: 0 auto;
            width: 100%!important;
            box-sizing: border-box;
        }
    }
}
