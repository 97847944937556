.about {
    &__gray {
        padding-top: 40px;
        padding-bottom: 40px;
        background: $grayBackground;
    }
    &__meta {
        height: calc(100vh - 65px);
        width: 100%;
        position: relative;
        background: $lightBlack;
        margin-bottom: 40px;
        &__scroll-arrow {
            position: absolute;
            bottom: 0;
            margin-bottom: 30px;
            width: 44px;
            height: 44px;
            left: 50%;
            transform: translateX(-50%);
            background: $red;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 44px;
            border: 4px solid rgba($red, 0.4);
            z-index: 10;
            &:before {
                position: absolute;
                content: '';
                background: $red;
                width: calc(100% + 8px);
                height: calc(100% + 8px);
                z-index: 0;
                border-radius: calc(100% + 8px);
                opacity: 0.2;
            }
            img {
                transform: rotate(90deg);
            }
        }
    }
    &__heading {
        @include get-font(45px, $fw-r, $black);
        position: relative;
        padding-bottom: 12px;
        &:after {
            content: '';
            width: 64px;
            height: 4px;
            background: $red;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    &__section {
        box-sizing: border-box;
        &--centered {
            width: 100%;
            max-width: 1076px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 16px;
            box-sizing: border-box;
        }
        &--full-width {
            flex: 1;
            display: flex;
        }
        &__separator {
            margin-bottom: 40px;
        }
    }
    &__text {
        margin-top: 24px;
        @include get-font($f18, $fw-r, $black, $lh);
    }
    &__sub-heading {
        margin-bottom: 24px;
        @include get-font($f22, $fw-sb, $lightBlack, $lh);
        &--secondary {
            font-size: $f28;
            color: $black;
        }
    }
    &__sub-section {
        display: flex;
        align-items: center;
        flex: 1;
        margin-bottom: 40px;
        > div {
            flex: 0.5;
            &:first-child {
                margin-right: 40px;
            }
        }
        &__title {
            @include get-font($f22, $fw-sb, $black, $lh);
        }
        &__description {
            @include get-font($f16, $fw-r, $mediumBlack, $lh);
            margin-top: 9px;
        }
        &__image {
            &__container {
                width: 100%;
                height: 295px;
            }
        }
    }
    &__full-section {
        flex: 0.5;
        height: 273px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &--yellow {
            background: $yellow;
        }
        &--mustard {
            background: #daa100;
        }
        &--dark-blue {
            background: $blue;
        }
        &--blue {
            background: #6fb2ff;
        }
        &__title {
            @include get-font($f24, $fw-sb, $white, $lh);
        }
        &__action {
            @extend %appearance-none;
            cursor: pointer;
            height: 52px;
            border-radius: 10px;
            border: 1px solid $white;
            box-shadow: 0 2px 4px 0 $superLightBlack;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            padding: 0 16px;
            @include get-font($f24, $fw-sb, $white, $lh);
            box-sizing: border-box;
            text-decoration: none;
            margin-top: 16px;
        }
    }
    &__people-section {
        display: flex;
        flex: 1;
        > div {
            flex: 0.5;
            &:first-child {
                margin-right: 40px;
            }
        }
        &__title {
            @include get-font($f22, $fw-sb, $black, $lh);
            margin-top: 24px;
        }
        &__image {
            height: 295px;
            width: 100%;
        }
        &__helper {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
            margin-top: 8px;
        }
        &__description {
            @include get-font($f16, $fw-r, $mediumBlack, $lh);
            margin-top: 8px;
        }
    }
}

@media only screen and (max-width: $onlyMobile) {
    .about {
        &__meta {
            height: 210px;
            &__scroll-arrow {
                display: none;
            }
        }
        &__heading {
            font-size: $f24;
        }
        &__text {
            font-size: $f18;
        }
        &__section {
            &__separator {
                margin-bottom: 32px;
            }
            &--full-width {
                display: block;
            }
        }
        &__sub-heading {
            font-size: $f14;
            margin-bottom: 16px;
            &--secondaru {
                font-size: $f24;
            }
        }
        &__sub-section {
            display: block;
            margin-bottom: 32px;
            > div {
                &:first-child {
                    margin-right: 0;
                }
            }
            &__image {
                &__container {
                    height: 188px;
                }
            }
            &__description {
                margin-top: 16px;
            }
        }
        &__full-section {
            height: 101px;
            margin-bottom: 4px;
            &__title {
                font-size: $f14;
                font-weight: $fw-m;
            }
            &__action {
                height: 35px;
                margin-top: 12px;
                font-size: $f14;
                font-weight: $fw-r;
            }
        }
        &__people-section {
            display: block;
            > div {
                &:first-child {
                    margin-right: 0;
                }
            }
            &__image {
                height: 188px;
            }
            &__title {
                margin-top: 16px;
            }
        }
        &__gray {
            padding-top: 32px;
            padding-bottom: 32px;
        }
    }
}