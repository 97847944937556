.passbook {
  &__subtitle {
    @include get-font($f16, $fw-r, $lightestGray, $lh);
  }
  &__totalearning {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    &__title {
      margin: 9px auto;
      width: 120px;
      text-align: center;
      @include get-font($f14, $fw-sb, $lightestGray, $lh);
    }

    &__amount {
      display: flex;
      align-content: center;
      justify-content: center;
      border-radius: 10px;
      background-color: white;
      width: 288px;
      height: 36px;
      margin: 0px auto;
      padding: 6px 12px;
      @media only screen and (max-width: $onlyMobile) {
        padding:6px 0px ;
      }
      @include get-font($f24, $fw-b, $red, $lh);
    }
  }
  &__stats {
    display: flex;
    margin-bottom: 24px;
    overflow-x: auto;
    align-items: center;
    justify-content: center;
    padding: 4px 0;

    > * + * {
      margin: 0 0 0 25px;
    }

    @media screen and (max-width: $onlyMobile) {
      align-items: left;
      justify-content: left;
    }

    &__block {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      width: 126px;
      min-width: 126px;
      height: 84px;
      padding: 12px;
      border-radius: 10px;
      box-shadow: 0 1px 2px 0 #0000001a;

      &__icon {
        width: 32px;
        height: 32px;
        min-height: 32px;
        margin: 12px auto 4px auto;
        background-color: $white;
        border-radius: 20px;

        &__img {
          width: 32px;
          height: 32px;
        }
      }
      &__title {
        text-align: center;
        @include get-font($f14, $fw-m, $black, $lh);
      }
      &__subtitle {
        margin-top: 4px;
        margin-bottom: 12px;
        text-align: center;
        @include get-font($f16, $fw-sb, $black, $lh);
      }
    }
  }

  &__transactions {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background-color: $white;
    min-height: 100px;

    &__typefilter {
      width: 100%;
      border-radius: 10px;
      background-color: $white;
      &__sticky {
        position: sticky;
        top: 50px;
        z-index: 9;
      }
      &__div {
        margin: 24px auto 0 auto;
        border-radius: 20px;
        width: 176px;
        height: 20px;
        border: 1px solid $superLightBlack;
        padding: 6px 12px 6px 12px;

        &__select {
          width: 176px;
          border: 0;
          background: $white;
          outline: 0;
          margin-bottom: 8px;
          @include get-font($f14, $fw-m, $black);
        }
      }
    }

    &__list {
      &__item {
        display: flex;
        padding: 0 24px;

        &__idblock {
          width: 234px;
        }
        &__amountblock {
        }
        &__statusblock {
          margin-left: auto;
        }
        &__header {
          @include get-font($f12, $fw-b, $lightestGray);
          text-transform: uppercase;
        }
      }
    }
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 24px auto 32px;

    &__head {
      position: sticky;
      top: 108px;
      z-index: 9;
      background: $white;
      border-bottom: 1px solid $black;
      box-shadow: 0px 0.5px 2px -2px $focusedGray;

      &__row {
        border-bottom: 1px solid $lightGray;

        &__col {
          @include get-font($f12, $fw-b, $lightestGray, $lh);
          text-transform: uppercase;

          &:first-child {
            padding: 24px 0 24px 3%;
          }
          &:last-child {
            padding: 24px 0% 24px 14%;

            @media only screen and (max-width: $onlyMobile) {
              padding: 24px 0% 24px 0;
            }
          }
        }
      }
    }

    &__body {
      &__row {
        &:hover {
          background-color: $lightGray;
        }
        &:active {
          background-color: $darkLightGray;
        }

        &__col {
          text-align: center;
          &:first-child {
            padding: 24px 0 24px 3%;
          }
          &:last-child {
            padding: 24px 3% 24px 0;
          }

          &__transaction {
            &__id {
              @include get-font($f14, $fw-m, $black, $lh);

              @media only screen and (max-width: $onlyMobile) {
                @include get-font($f12, $fw-m, $black, $lh);
              }
            }
            &__date {
              margin-top: 2px;
              @include get-font($f14, $fw-r, $lightestGray, $lh);

              @media only screen and (max-width: $onlyMobile) {
                @include get-font($f12, $fw-r, $lightestGray, $lh);
              }
            }
          }
          &__amount {
            @include get-font($f14, $fw-b, $black, $lh);

            @media only screen and (max-width: $onlyMobile) {
              @include get-font($f12, $fw-b, $black, $lh);
            }
          }

          &__status {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            &__chevron {
              width: 16px;
              height: 16px;

              &__marginbottom {
                margin-bottom: 8px;

                @media only screen and (max-width: $onlyMobile) {
                  margin-bottom: 4px;
                }
              }
            }
            &__text {
              margin-right: 50px;
              @include get-font($f14, $fw-m, $black, $lh);

              @media only screen and (max-width: $onlyMobile) {
                margin-right: 10px;
                @include get-font($f12, $fw-m, $black, $lh);
              }
            }
            &__redeem {
              @include get-font($f14, $fw-m, $white, $lh);
              background-color: $orange;
              padding: 6px 12px;
              margin-left: auto;
              margin-right: 40px;
              border-radius: 16px;

              @media only screen and (max-width: $onlyMobile) {
                margin-right: 10px;
                padding: 3px 6px;
                @include get-font($f12, $fw-m, $white, $lh);
              }
            }
          }
        }
      }
    }
  }

  &__myprofile {
    display: flex;
    height: 22px;
    margin: 16px 0px 16px 0;
    padding: 8px 12px;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 $tastingCardShadow;
    border: solid 0.5px $superLightBlack;
    background-color: $white;

    &__paidwidth {
      width: 342px;
      @media only screen and (max-width: $onlyMobile) {
        width: inherit;
      }
    }
    &__nopaidwidth {
      width: 342px;
    }

    &__title {
      display: flex;
    }

    &__profilearrow {
      width: 16px;
      height: 16px;
      margin-top: 3px;
      margin-left: 16px;
    }

    &__earning {
      display: flex;
      margin-left: auto;
      text-decoration: none;
      cursor: pointer;

      &__text {
        margin-right: 6px;
        margin-top: 1px;
        @include get-font($f12, $fw-r, $lightestGray, $lh);
      }
      &__amount {
        margin-left: auto;
        @include get-font($f14, $fw-b, $red, $lh);
      }
    }
    &__nopaidtester {
      display: flex;
      height: 36px;
      cursor: pointer;
      margin-left: auto;

      &__text {
        margin-top: 1px;
        @include get-font($f12, $fw-sb, $red, $lh);
      }
    }
  }
}
