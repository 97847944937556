.donation{
    &__header{
        text-align: center;
        h4{
            margin: 12px 0;
            @include get-font($f18,$fw-b,$black);
        }
        p{
            @include get-font($f14,$fw-r,$black);
        }
    }
    &__horizontal {
        background: #ededed;
        // height: 1px
        margin-top: 20px;
        margin-bottom: 0px;
        opacity: 0.2;
    }
    &__content{
        .title{
            @include get-font($f16,$fw-sb,$black);
        }
        .menu{
            & + .menu{
                margin-top: 32px;
            }
            &__item{
                margin-bottom: 18px;

                &--input{
                    &__wrapper{
                        display: flex;
                        align-items: center;
                    }
                    appearance: none;
                    margin: 0;
                    &::after{
                        content: "";
                        display: inline-block;
                        height: 20px;
                        width: 20px;
                        margin-right: 10px;
                        background: url("assets/images/donation/radio-off.svg") no-repeat center center;
                    }
                    &.checked::after{
                        background-image: url("assets/images/donation/radio-on.svg");
                    }
                    &[type="checkbox"]{
                        &::after{
                            background-image: url("assets/images/donation/check-off.svg");
                        }
                        &.checked::after{
                            background: url("assets/images/donation/check-on.svg");
                        }
                    }

                }
                &--label{
                    @include get-font($f14,$fw-m,$black);
                }

                .placeholder{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    &::after{
                        content: "";
                        display: inline-block;
                        height: 40px;
                        width: 40px;
                        min-width: 40px;
                        border-radius: 4px;
                        border: 1px solid #f2f2f2;
                        box-sizing: border-box;
                        overflow: hidden;
                        background-color: $imageWrapperBackgroundGray;
                    }
                    >span{
                        order: 2;
                        @include get-font($f14,$fw-m,$copiedBackground);
                    }
                }
                .value{
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    .image{
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                        &__wrapper{
                            display: block;
                            height: 50px;
                            width: 50px;
                            min-width: 50px;
                            border-radius: 4px ;
                            box-sizing: border-box;
                            overflow: hidden;
                            border: 1px solid #f2f2f2;
                        }
                    }
                    .title{
                        margin: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @include get-font($f14,$fw-sb,$black);
                    }
                }
            }
        }
    }
    &__organisations{
        &--list{
            &__item{
                display: flex;
                align-items: center;
                gap: 16px;
                padding:16px;
                border-bottom: 1px solid #f2f2f2;
                
                .image{
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    &__wrapper{
                        display: block;
                        height: 50px;
                        width: 50px;
                        min-width: 50px;
                        box-sizing: border-box;
                        overflow: hidden;
                        border-radius: 4px ;
                        border: 1px solid #f2f2f2;
                    }

                }
                .title{
                    margin: 0;
                    @include get-font($f14,$fw-sb,$black);
                }
            }
        }
    }
    &__footer{
        margin-top: 24px;
        .btn{
            display: block;
            background-color: $yellow;
            @include get-font($f14,$fw-b,$black);
            border: none;
            outline: none;
            cursor: pointer;
            width: 100%;
            padding: 12px 0;

            &:disabled{
                opacity: .2;
                pointer-events: none;
                cursor: no-drop;
            }
            &.survey{
                background-color: $teal;
                color: white;
            }
            &.public {
                background-color: #00a146;
            }
        }
    }
    &__modal{
        &--container{
            max-width: 442px;

            .default-modal__header{
                margin-bottom: 0;
            }
        }
    }
}



@media only screen and (max-width: $smallMobile) {
  .donation__modal--container{
    &.default-modal__container{
        width: 100%;
        top: 8%;
        max-width: none;
    }
  }
    
  }