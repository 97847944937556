.pill-filters {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  display: inline-block;
  vertical-align: top;
  height: 40px;
  line-height: 40px;
  background: $grayBackground;
  @include transitionEase(#{background});
  @include get-font($f16, $fw-m, $lightBlack);
  cursor: pointer;
  padding: 0 16px;
  border-left: .5px solid $lightGray;
  border-right: .5px solid $lightGray;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: none;
  }
  &:last-child {
    border-right: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:hover {
    background: rgba($blue, 0.2);
  }
  &--selected {
    background: $blue;
    color: $white;
    &:hover {
      background: $blue;
    }
  }
  &--survey {
    &:hover {
      background: $lightestGreen;
    }
    &--selected {
      background: $green;
      color: $white;
      border-color: $green;
      &:hover {
        background: $green;
      }
    }
  }
  &--quiz {
    &:hover {
      background: $lightestOrange;
    }
    &--selected {
      background: $orange;
      color: $white;
      border-color: $orange;
      &:hover {
        background: $orange;
      }
    }
  }
  &--dark {
    &:hover {
      background: rgba(0, 0, 0, .1);
    }
    &--selected {
      background: $black;
      color: $white;
      border-color: $black;
      &:hover {
        background: $black;
      }
    }
   
  }
  &.disabled {
    pointer-events: none;
  }
}

.filter-chips {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > li {
    background: $white;
    padding: 6px 8px;
    box-sizing: border-box;
    @include get-font($f14, $fw-m, $black, $lh);
    margin: 0 8px 8px 0;
    @include form-input-border-shadow();
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      margin-left: 8px;
      width: 10px;
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .pill-filters {
    height: 32px;
    padding: 0 8px;
    font-size: $f14;
    line-height: 32px;
    &__list {
      margin-bottom: 16px;
    }
  }
}
