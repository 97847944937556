.product {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 24px;
    &__item {
      margin-bottom: 16px;
      padding: 16px;
      box-sizing: border-box;
      display: block;
      text-decoration: none;
      display: flex;
      border: 1px solid $superLightBlack;
      border-radius: 10px;
      background: $white;
    }
    &__videoicon {
      position: absolute;
      z-index: 2;
      width: 15%;
      top: 41%;
      left: 43%;
      margin-right: -50%;
    }
    &__icon {
      width: 156px;
      height: 104px;
      border-radius: 10px;
      object-fit: contain;
      overflow: hidden;
      border: 1px solid $grayBackground;
      margin-right: 16px;
      background: $grayBackground;
      flex-shrink: 0;
      position: relative;
      > img {
        width: 156px;
        height: 104px;
        object-fit: contain;
        display: block;
      }
    }
    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__details {
      width: 100%;
    }
    &__title {
      @include get-font($f16, $fw-sb, $black, $lh);
      margin-bottom: 14px;
      margin-top: 8px;
    }
    &__inline-info {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-right: 8px;
        border: 1px solid $superLightBlack;
        display: block;
        border-radius: 4px;
      }
      p {
        @include get-font($f14, $fw-r, $black, $lh);
      }
    }
    &__review-container {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      flex-wrap: wrap;  
      justify-content: space-between;
      .product__ratings__default {
        margin-right: 12px;
      }
      p {
        @include get-font($f12, $fw-b, $black, $lh);
        letter-spacing: 0.6px;
      }
      &__text{
        @include get-font($f14, $fw-m, $copiedBackground, $lh);
        padding: 8px 16px;
        border-radius: 8px;
        &.in-progress{
          background-color: #FCF1D2;
          color: $black;
        }
        &.completed{  
          opacity: .6;
          background-color: #F2F2F2;
        } 
      }
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .product {
    &__list {
      &__item {
        padding: 8px 16px;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
      }
      &__videoicon {
        position: absolute;
        z-index: 2;
        width: 15%;
        top: 41%;
        left: 43%;
        margin-right: -50%;
        img {
          position: absolute;
          z-index: 2;
          width: 15%;
          top: 41%;
          left: 43%;
          margin-right: -50%;
        }
      }
      &__icon {
        width: 100%;
        height: 74px;
        >img {
          width: 100%;
        }
      }
      &__title,
      &__inline-info {
        margin-bottom: 8px;
      }
    }
  }
}
@media only screen and (max-width: $smallMobile) {
  .product {
    &__list {
      &__review-container{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        &__text {
          margin-top: 16px;
          text-align: center;
        }
      }
    }
  }
}
