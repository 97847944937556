.explore {
    &__hashtag {
        margin: 16px 0 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        &__link {
            background: $white;
            padding: 6px 12px;
            display: inline-block;
            @include form-input-border-shadow();
            text-decoration: none;
            border-radius: 10px;
            @include get-font($f16, $fw-sb, $black, $lh);
        }
        &__item {
            margin: 0 16px 16px 0;
        }
    }
    &__specialization {
        &__container {
            text-decoration: none;
            width: 184px;
            height: 212px;
            border-radius: 10px;
            @include form-input-border-shadow();
            background: $white;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__img {
            width: 136px;
            height: 136px;
            object-fit: contain;
        }
        &__text {
            @include get-font($f16, $fw-sb, $black, $lh);
            margin-top: 16px;
        }
    }
    &__profile {
        &__container {
            width: 184px;
            min-height: 256px;
            border-radius: 10px;
            padding-bottom: 12px;
            @include form-input-border-shadow();
            background: $white;
            overflow: hidden;
            margin-right: 8px;
            .following-btn, .follow-btn {
                height: 21px !important;
                padding: 4px 2px !important;
                line-height: 0 !important;
            }
            .following-btn {
                font-size: $f14;
                background: transparent;
                font-weight: normal;
                letter-spacing: normal;
            }
            .follow-btn {
                @include transitionEase(#{background});
                border-radius: 3px;
                &:hover {
                    background: $lightestPink;
                }
            }
        }
        &__img {
            width: 184px;
            height: 184px;
            object-fit: contain;
        }
        &__badge {
            position: absolute;
            left: 8px;
            top: 8px
        }
        &__link {
            text-decoration: none;
            display: block;
            position: relative;
        }
        &__text {
            width: 100%;
            padding: 0 12px;
            margin-top: 12px;
            box-sizing: border-box;
            @include get-font($f14, $fw-m, $black, $lh);
            display: block;
            > span {
                > span {
                    max-width: calc(100% - 24px);
                    display: inline-block;
                    word-break: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: top;
                }
            }
        }
        &__action {
            margin-top: 4px;
            padding: 0 12px;
            box-sizing: border-box;
        }
    }
    &__search {
        &__container {
            margin-top: 112px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        &__input {
            height: 30px;
            flex: 1;
            margin-left: 12px;
            @include get-font($f20, $fw-sb, $black, $lh);
            background: transparent;
            border: 0;
            outline: none;
            max-width: calc(100% - 42px);
            &__container {
                height: 54px;
                border: 3px solid $grayBackground;
                border-radius: 32px;
                padding: 16px;
                box-sizing: border-box;
                width: 530px;
                display: flex;
                align-items: center;
                max-width: 100%;
                position: relative;
                .hashtag__suggestions {
                    left: 0;
                    top: 50px;
                }
            }
            &__remove {
                border: 0;
                cursor: pointer;
                background: transparent;
                @include get-font($f24, $fw-l, $mediumBlack, $lh);
                position: absolute;
                right: 6px;
                outline: 0;
                > span {
                    margin-top: -1px;
                    display: block;
                }
            }
        }
        &__list {
            margin: 0 0 0 16px;
            padding: 0;
            list-style: none;
            display: flex;
            &__item {
                @include get-font($f14, 900, $lightBlack);
                padding: 12px 16px;
                background: $grayBackground;
                margin-left: 16px;
                cursor: pointer;
                border-radius: 10px;
                @include transitionEase(#{background});
                &:hover {
                    background: $lightestBlack;
                }
                &--active {
                    background: $yellow;
                    color: $black;
                    pointer-events: none;
                    &:hover {
                        background: $yellow;
                    }
                }
            }
        }
    }
    &__collaboration {
        &__footer {
            margin-top: 64px;
            &__title {
                @include get-font($f18, 900, $black, $lh);
            }
            &__description {
                margin-top: 8px;
                @include get-font($f18, $fw-r, $black, $lh);
            }
            .see-all-container__action-btn {
                margin-top: 24px;
            }
        }
    }
}

@media only screen and (max-width: $onlyMobile) {
    .explore {
        &__hashtag {
            margin-left: 16px;
        }
        &__search {
            &__container {
                margin-top: 70px;
                flex-direction: column;
                padding-left: 16px;
                box-sizing: border-box;
            }
            &__input {
                font-size: $f16;
                &__container {
                    height: 40px;
                    margin-right: 16px;
                    .hashtag__suggestions {
                        left: 50%;
                        top: 30px;
                        width: 100%;
                    }
                }
            }
            &__list {
                width: 100%;
                overflow: hidden;
                overflow-x: auto;
                margin-top: 16px;
                margin-left: 0;
                &__item {
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        &__profile {
            &__container {
                width: 150px;
                min-height: auto;
                padding-bottom: 0;
                box-shadow: none;
                border-color: transparent;
                background: transparent;
                margin-right: 8px;
            }
            &__img {
                width: 150px;
                height: 150px;
                border-radius: 10px;
                background: $white;
                border-radius: 10px;
            }
            &__text {
                padding: 0;
                margin-top: 8px;
            }
            &__action {
                padding: 0;
            }
        }
        &__specialization {
            &__container {
                width: 110px;
                margin-right: 8px;
                height: auto;
                border-radius: 10px;
                @include form-input-border-shadow();
                background: transparent;
                border: 0;
                box-shadow: none;
            }
            &__img {
                width: 110px;
                max-height: 110px;
                object-fit: contain;
                background: $white;
                border-radius: 10px;
            }
            &__text {
                font-size: $f12;;
                margin-top: 8px;
                word-break: break-word;
                white-space: normal;
                text-align: center;
            }
        }
        &__collaboration {
            &__footer {
                margin-left: 16px;
                margin-top: 32px;
                &__title {
                    font-size: $f14;;
                }
                &__description {
                    font-size: $f16;
                    margin-top: 8px;
                    width: calc(100% - 32px);
                }
            }
        }
    }
}