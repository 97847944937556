.biz {
  &__overview {
    &__container {
      min-height: 84px;
      width: 100%;
      border-radius: 10px;
      background-color: $white;
      @include form-input-border-shadow();
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 16px;
      margin-top: 24px;
    }
    &__helper {
      display: inline-block;
      height: 100%;
      border-right: 1px solid $grayBackground;
      @include get-font($f16, $fw-b, $lighterBlack);
      padding-right: 24px;
      line-height: 50px;
      margin-right: 8px;
    }
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    > li {
      margin: 0 16px 6px;
    }
    &__title {
      @include get-font($f18, $fw-b, $black, $lh);
      text-align: right;
      &--secondary {
        margin-bottom: 4px;
        font-size: $f14;
        font-weight: $fw-m;
        text-transform: capitalize;
      }
    }
    &__subtitle {
      @include get-font($f16, 900, $teal);
    }
    &__row {
      display: flex;
      margin-top: 24px;
      &__block {
        width: 200px;
        margin-right: 32px;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 10px;
        border: 0.5px solid $superLightBlack;
        background-image: url('assets/images/business/consistency/overview-block-bg.png');
        text-decoration: none;
        box-shadow: 0 2px 4px 0 $superLightBlack;
        &__number {
          @include get-font($f32, $fw-ub, $black, $lh);
        }
        &__title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &__text {
            @include get-font($f14, $fw-sb, $black, $lh);
          }
        }
      }
    }
  }
  &__details {
    &__product {
      display: flex;
      &__icon {
        width: 172px;
        height: 112px;
        flex-shrink: 0;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: $white;
        > img {
          width: 172px;
          height: 112px;
          object-fit: contain;
          display: block;
          border-radius: 10px;
          border: 1px solid $grayBackground;
        }
      }
      &__info {
        margin-left: 12px;
        text-decoration: none;
      }
      &__title {
        @include get-font($f16, $fw-m, $black, $lh);
      }
      &__subtitle {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-top: 6px;
      }
      &__rating {
        margin-top: 6px;
        display: flex;
        align-items: center;
        .product__ratings__default {
          margin-right: 12px;
        }
        &__text {
          @include get-font($f12, $fw-b, $black, $lh);
          letter-spacing: 0.6px;
          text-transform: uppercase;
        }
      }
    }
  }
}
