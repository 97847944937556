.poll-create-container {
  padding: 16px;
  box-sizing: border-box;
  max-height: 65.2vh;
   overflow-x: hidden;
   overflow-y: auto;
  @include getDefaultScrollbar();
  .post-bar__post__cancel {
    top: 20px;
  }
  &__type {
    &__container {
      display: flex;
       margin-bottom: 16px;
    }
    @extend %appearance-none;
    padding: 4px 16px;
    box-sizing: border-box;
    background: transparent;
    @include get-font($f14, $fw-r, $lightestGray);
    border: 0;
    cursor: pointer;
    border-radius: 20px;
    @include transitionEase(#{color, background});
    &--active {
      font-weight: $fw-sb;
      border: 1px solid $lightGreen;
      color: $mediumBlack;
      background: $grayBackground;
      cursor: default;
    }
  }
  &__preview {
    &__helper {
      margin-top: -12px;
      @include get-font($f16, $fw-r, $black, $lh);
      margin-bottom: 16px;
    }
    &__container {
      position: relative;
      margin-bottom: 24px;
      margin-top: 0;
      .feed-card-preview--small {
        margin-top: -12px;
      }
      .post-bar__post__preview-container__close {
        margin-top: -10px;
      }
    }
    &__remove {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .user-create-block__container {
    margin-bottom: 12px;
  }
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &__title {
    background: transparent;
    border: 0;
    @include get-font($f16, $fw-sb, $black, $lh);
    width: 100%;
    outline: 0;
    margin-bottom: 24px;
    height: 24px;
    resize: none;
  }
  &__options {
    list-style: none;
    margin: 0;
    padding: 0 8px;
    &__item {
      margin-bottom: 16px;
      position: relative;
      &__input {
        @include get-font($f14, $fw-m, $black, $lh);
        height: 34px;
        padding: 0;
        background: $grayBackground;
        border-radius: 34px;
        border: 0;
        width: 100%;
        padding-left: 16px;
        outline: 0;
        box-sizing: border-box;
      }
      &__remove-option {
        cursor: pointer;
        position: absolute;
        margin-right: 8px;
        right: 0;
        top: 5px;
        @extend %appearance-none;
        border: transparent;
        background: transparent;
        @include transitionEase();
        height: 24px;
        width: 24px;
        border-radius: 24px;
        &:focus,
        &:hover,
        &:active {
          background: $grayBackground;
        }
        &__text {
          @include get-font($f24, $fw-m, $lighterBlack);
          margin-top: -8px;
          margin-left: -2px;
          display: inline-block;
        }
      }
    }
  }
  &__add-btn {
    @extend %appearance-none;
    background: transparent;
    padding: 0;
    border: 0;
    cursor: pointer;
    margin-left: 8px;
    &__text {
      @include get-font($f14, $fw-m, $blue, $lh);
      letter-spacing: -0.2px;
    }
  }
}
.post-bar__post__poll-helper {
  @include get-font($f14, $fw-r, $lh);
  color: #dd2e1f;
  margin-bottom: 4px;
}

.poll-edit-modal-style {
  top: 0px !important;
}

.poll-create {
  &__image {
    &__template {
      position: relative;
      &__title {
        padding: 24px 0;
        box-sizing: border-box;
        @include get-font($f14, $fw-sb, $mediumBlack, $lh);
        text-align: center;
      }
      &__container {
        width: 100%;
        display: flex;
        margin-bottom: 40px;
        @media screen and (max-width: $onlyMobile) {
          margin-bottom: 16px !important;
        }
        > div {
          // width: 264px;
          border: 1px solid $grayBackground;
          height: 230px;
          border-radius: 10px;
          flex: 1;
          position: relative;
          cursor: pointer;
          @include transitionEase(#{border});
          &:first-child {
            margin-right: 10px;
          }
          &:hover,
          &:focus {
            border-color: $green;
            .poll-create__image__template__check {
              display: block;
            }
          }
        }
      }
      &__check {
        @include centerItem();
        background: $white;
        border-radius: 100%;
        padding: 4px;
        display: none;
      }
    }
    &__question {
      &__image {
        max-width: 538px;
        max-height: 200px;
        position: relative;
        max-width: 100%;
        background: $grayBackground;
        margin-bottom: 8px;
        border-radius: 10px;
        overflow: hidden;
        > img {
          width: inherit;
          height: inherit;
          object-fit: cover;
          object-position: center;
          border-radius: 10px;
          max-width: 100%;
        }

        &.video{
          max-height: unset;
          min-height:250px;
          background-color: $black;
          overflow: unset;
        }
        
        &:has(.gif_image){
          max-height: unset
        }
        .react-player__video{
          margin-top: unset;
        }  
      }
      &__icon {
        @include centerItem();
        &.video{
          inset: unset;
          top: 10px;
          right: 10px;
          transform: unset;
        }
        cursor: pointer;
        @media only screen and (max-width: 400px) {
          width: 80% !important;
        }
        text-align: center;
        > input {
          position: absolute;
          opacity: 0;
          width: 0;
        }
        > p {
          text-align: center;
        }
        &__text {
          @include get-font($f14, $fw-r, $lighterBlack, $lh);
          margin-top: 6px;
        }
        &__helper {
          @include get-font($f12, $fw-r, $lighterBlack, $lh);
        }
      }
    }
    &__option {
      margin-bottom: 16px;
      margin-left: 4px;
      position: relative;
      &__container {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        list-style: none;
      }
      &__image {
        width: inherit;
        min-height: inherit;
        background: $grayBackground;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        &__img {
          width: inherit;
          height: inherit;
          object-fit: cover;
          object-position: center;
        }
      }
      &__helper {
        @include get-font($f14, $fw-r, $lighterBlack, $lh);
        margin-top: 6px;
        &--secondary {
          font-size: $f12;
          margin-top: 0;
        }
      }
      &__icon {
        @include centerItem();
        text-align: center;
        width: 100%;
        cursor: pointer;
        > input {
          position: absolute;
          opacity: 0;
          width: 0;
        }
      }
      &__title {
        background: $white;
        border: 0;
        @include get-font($f14, $fw-m, $black, $lh);
        margin-top: 6px;
        width: 100%;
        outline: none;
        
      }
      &__remove {
        @extend %appearance-none;
        border: 0;
        width: 28px;
        height: 28px;
        background: $lightBlack;
        border-radius: 28px;
        @include get-font($f28, $fw-r, $white, 0);
        text-align: center;
        position: absolute;
        padding-left: 6px;
        top: 12px;
        right: 12px;
        cursor: pointer;
        > span {
          display: block;
          margin-top: -2px;
          margin-left: -1px;
        }
      }
    }
  }
  &__video{
    margin-top: 16px;
      & *{
        border-radius: 10px;
      }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .poll-create-container {
    &__type {
      &__container {
        margin-bottom: 16px;
      }
    }
  }
  .poll-create {
    &__image {
      &__template {
        &__title {
          padding: 0;
          margin: 16px 0;
        }
        &__container {
          flex-wrap: wrap;
          > div {
            overflow: hidden;
            min-width:99%;
            &:first-child {
              margin-right: 0;
              margin-bottom: 16px;
            }
          }
        }
        &__image {
          width: 100%;
        }
      }
    }
  }
}
.multiple-image-poll-create-container{
  padding: 16px;
  box-sizing: border-box ;
  max-height: 65.2vh;
  overflow-y: hidden;
  overflow-x: hidden;
  @media only screen and (max-width: $onlyMobile) {
    max-height: none;
    overflow-y: auto;
  }
}

.poll-type-scroll{
  height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1px;
  
}
.poll-type-scroll::-webkit-scrollbar {
  display: none;
}
.text-poll-type{
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.text-poll-type::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: $onlyMobile) {
  .poll-create__image__template__container{
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}


