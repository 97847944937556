.product {
  &__container {
    padding-top: 60px;
    padding-bottom: 32px;
  }
  &__sample {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    width: 100%;
    @media only screen and (max-width: $tab) {
      margin-bottom: 0;
    }
    &__text {
      @include get-font($f15, $fw-r, $black, $lh);
      padding: 10px 0 10px 24px;
      background: $aquaBlue;
      width: 100%;
      border-radius: 8px 0 0 8px;
      @media only screen and (max-width: $tab) {
        border-radius: 0;
        @include get-font($f12, $fw-r, $black, $lh);
      }
    }
    &__button {
      @include get-font($f14, $fw-b, $white, $lh);
      width: 188px;
      border: none;
      border-radius: 0px 8px 8px 0px;
      outline: none;
      @media only screen and (max-width: $tab) {
        border-radius: 0;
        @include get-font($f12, $fw-b, $white, $lh);
      }
      &--requested {
        img {
          margin-right: 5px;
        }
        background: $aquaBlue;
        color: $blue;
      }
      &--available {
        cursor: pointer;
        background: $darkSkyBlue;
        color: $white;
      }
    }
  }
  &__deprecated {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    width: 100%;
    border-radius: 10px;
    background: $removeRed;
    @media only screen and (max-width: $tab) {
      margin-bottom: 0;
    }
    &__text {
      @include get-font($f14, $fw-m, $white, $lh);
      padding: 10px 0 10px 24px;
      width: 100%;
      @media only screen and (max-width: $tab) {
        @include get-font($f12, $fw-m, $white, $lh);
      }
    }
  }

  &__title {
    @include get-font($f20, $fw-b, $black, $lh);
    margin-top: 8px;
  }
  &__description {
    @include get-font($f15, $fw-r, $black, $lh);
  }
  &__section {
    margin-top: 32px;
  }
  &__keywords {
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 12px;
      &__item {
        min-height: 30px;
        border-radius: 15px;
        padding: 6px 8px;
        box-sizing: border-box;
        background: $grayBackground;
        margin-right: 9px;
        display: inline-block;
        vertical-align: top;
        @include get-font($f12, $fw-r, $black, $lh);
        margin-bottom: 10px;
      }
    }
  }
  &__meta {
    display: flex;
    align-items: flex-start;
    flex: 1;
    &__details {
      flex: 1;
      padding-top: 16px;
      &__type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          display: flex;
          margin-right: 8px;
        }
        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          display: inline-block;
          vertical-align: middle;
          border: 1px solid $grayBackground;
          box-sizing: border-box;
          border-radius: 4px;
        }
        &__title {
          display: inline-block;
          vertical-align: middle;
          @include get-font($f14, $fw-r, $black, $lh);
          &--margin {
            margin-left: 6px;
          }
        }
      }
    }
    &__rating {
      display: flex;
      align-items: center;
      margin-top: 16px;
      &__title {
        @include get-font($f12, $fw-b, $black, $lh);
        letter-spacing: 0.6px;
        margin-left: 12px;
        text-transform: uppercase;
      }
    }
  }
  &__action {
    &__container {
      margin-top: 20px;
      display: flex;
      position: relative;
      .post-share-popup-up {
        position: absolute;
        top: 44px;
        right: 0;
      }
    }
    &__btn {
      @extend %appearance-none;
      width: 100%;
      max-width: 292px;
      height: 38px;
      margin-right: 12px;
      border-radius: 20px;
      border: 0;
      background: $productBtn;
      @include get-font($f14, $fw-b, $black, $lh);
      letter-spacing: 0.7px;
      text-transform: uppercase;
      cursor: pointer;
      &--disabled {
        cursor: default;
        opacity: 0.6;
      }
    }
    &__share {
      @extend %appearance-none;
      height: 38px;
      width: 38px;
      border-radius: 19px;
      background: $white;
      box-shadow: 0 2px 8px 0 $superLightBlack;
      border: 0;
      cursor: pointer;
      @include transitionEase(#{background});
      &:hover,
      &:active {
        background: $grayBackground;
      }
    }
  }
  &__radar-chart {
    border: 1px solid $grayBackground;
    border-radius: 20px;
    &--empty {
      border: 0;
    }
    &__container {
      width: calc(100% - 8px);
      margin: 32px auto;
    }
    img {
      width: auto;
      max-width: 100%;
    }
    button {
      width: 100%;
      height: 46px;
      background: rgba($blue, 0.2);
      @include get-font($f14, $fw-sb, $blue);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border: 0;
    }
  }
  &__get-sample {
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 12px;
    background: $lighterBlack;
    height: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    p {
      @include get-font($f12, $fw-b, $white, $lh);
      letter-spacing: 0.5px;
    }
    img {
      margin-left: 2px;
      width: 10px !important;
      height: auto !important;
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .product {
    &__container {
      padding-top: 0;
    }
    &__meta {
      flex-direction: column;
      &__details {
        width: 100%;
        padding: 16px 16px 0;
        box-sizing: border-box;
      }
    }
    &__section {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 24px;
    }
    &__description {
      margin-top: 16px;
    }
    &__action__btn{
      max-width: 100%;
    }
  }
}
