.admin-modal {
    &__footer {
        border-top-width: 0;
        padding: 0px 16px;
        .profile-edit-modal__btn--secondary{
            padding: 0;
        }
    }

    &__section {
        &__header {
            @include get-font($f16, $fw-m, $black, $lh);
            margin-bottom: 16px;
        }

        &__loader {
            @include get-font($f12, $fw-sb, $black, $lh);
            margin-bottom: 24px;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 24px;

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 6px;
            @include transitionEase(#{background});
            box-sizing: border-box;

            &:hover,
            &:focus,
            &:active {
                background: $grayBackground;

                .admin-modal__list__action-btn {
                    opacity: 1;
                }
            }
        }

        &__info {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover,
            &:focus,
            &:active {
                & + .admin-modal__list__action-btn {
                    opacity: 1;
                }
            }
        }

        &__avatar {
            width: 36px;
            height: 36px;
            border-radius: 36px;
            object-fit: cover;
            border: solid 0.5px $superLightBlack;
            margin-right: 8px;
        }

        &__title {
            @include get-font($f14, $fw-m, $black, $lh);

            .verified__text {
                display: inline;
            }
        }

        &__action-btn {
            @extend %appearance-none;
            border: 0;
            background: transparent;
            @include get-font($f14, $fw-r, $blue, $lh);
            padding: 0;
            opacity: 0;
            cursor: pointer;
            @include transitionEase(#{opacity, color});

            @media only screen and (max-width: $onlyMobile) {
                opacity: 1;
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 1;
                color: $darkBlue;
            }

            &--disabled {
                opacity: 0.6;
                color: $blue;

                &:hover,
                &:focus,
                &:active {
                    color: $blue;
                }
            }
        }
    }

    &__form {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__input {
            width: 100%;
            flex: 1;
            margin-right: 24px;
        }

        &__btn {
            font-weight: $fw-sb;
            opacity: 1;

            &--disabled {
                opacity: 0.6 !important;
                cursor: pointer;
                pointer-events: none;
            }
        }
    }

    &__remove {
        &__title {
            margin-bottom: 24px;
            @include get-font($f16, $fw-r, $black, $lh);
        }
    }
}

.company-view {
    &__admin {
        &__container {
            margin: 48px auto;
            width: 100%;
            max-width: $centeredContainer;

            @media only screen and (max-width: $onlyMobile) {
                padding: 0 12px;
                box-sizing: border-box;
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 12px;
        }

        &__title {
            @include get-font($f18, $fw-b, $black, $lh);
            margin-right: 16px;
        }

        &_block-grey-icon {
            display: flex;
            justify-content: center;
            margin-bottom: 17px
          }

        &_block-text {
            text-align: center;
            color: #171717;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;

        }
        &_block-text1 {
            text-align: center;
            color: #747474;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            // opacity: 0.4;
            margin-bottom: 53px
        }

        &__btn {
            @extend %appearance-none;
            border: 0;
            background: transparent;
            padding: 0;
            @include get-font($f12, $fw-b, $blue, $lh);
            letter-spacing: 0.6px;
            cursor: pointer;

            @media only screen and (max-width: $onlyMobile) {
                display: block;
            }
        }

        &__list {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            list-style: none;

            @media only screen and (max-width: $onlyMobile) {
                justify-content: space-evenly;
            }

            &__container {
                @extend %border-box-shadow;
                background: $white;
                padding: 24px 16px;
                border-radius: 10px;
                box-sizing: border-box;
            }

            &__item {
                text-align: center;
                margin: 24px 0;
                min-width: 142px;

                @media only screen and (max-width: $onlyMobile) {
                    min-width: 120px;
                }

                &__link {
                    text-decoration: none;
                    display: block;
                }

                &__icon {
                    width: 64px;
                    height: 64px;
                    border-radius: 64px;
                    object-fit: cover;
                    margin-bottom: 8px;
                    border: 0.5px solid $grayBackground;
                    box-sizing: border-box;
                }

                &__title {
                    @include get-font($f14, $fw-m, $black, $lh);
                    max-width: 142px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &__sub-title {
                    @include get-font($f14, $fw-sb, $lightestGray, $lh);
                    max-width: 142px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

}