.quiz-share-popup-up {
  @extend %border-box-shadow;
  width: 175px;
  background: $white;
  position: absolute;
  border-radius: 10px;
  border-top-right-radius: 0;
  right: 1.7rem;
  @media only screen and (max-width: $onlyMobile) {
    border-top-left-radius: 10px;
  }
  z-index: 12;
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 16px;
      text-decoration: none;
      @include transitionEase();
      cursor: pointer;
      user-select: none;
      &__icon,
      img {
        margin-right: 16px;
      }
      &__text,
      p {
        @include get-font($f16, $fw-r, $black, $lh);
        &--danger {
          color: $removeRed !important;
        }
      }
      &:hover,
      &:active {
        background: $grayBackground;
      }
      &__btn {
        @extend %appearance-none;
        border: 0;
        background: transparent;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        width: 100%;
        cursor: pointer;
      }
    }
    &--secondary {
      margin-top: 16px;
      border-top: 1px solid $grayBackground;
      padding-top: 16px;
    }
  }
}

.postss-bar {
  display: flex;
  max-width: 100%;
  width: $centerColumnWidth;
  margin: 0 auto 16px;
  padding: 0 8px;

  &__cropmodal {
    .default-modal__container {
      padding: 14px 20px 0px 20px;
    }

    .default-modal__header {
      margin: 4px -20px 24px -20px;
      padding: 0px 20px 0px 20px;
    }

    .default-modal__header__title {
      font-weight: $fw-sb;
    }

    .default-modal__content {
      margin: 0px -20px 0px -20px;
      padding: 0px;
    }

    &__footer {
      height: 36px;
      display: flex;
      padding: 12px 16px;
      align-items: center;

      &__rotatebtn {
        margin-left: 24px;
        width: 24px;
        height: 24px;
      }
    }
  }
}
