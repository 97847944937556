
.prapplicant__table {
  border: 0px !important;
  width: 100%;
  height: 100%;
  min-height: 290px;
  @include getDefaultScrollbar();
  &::-webkit-scrollbar {
    width: 12px;
    background-color: black;
  }
  &__header {
    padding: 8px !important;
    text-align: center;
    @include get-font($f12, $fw-b, $black, $lh);
  }
  &__eye-image{
    margin-top: 8px;
  }

  &__row {
    min-height: 68px;
  }
  .strip-text{
    padding: 16px 10px;
    text-align: center;
    background: #FCF1D2;
  }

  &__userdetails {
    padding: 10px 0;
    align-items: flex-start;
    >label:first-child{
      margin-top: 4px;
    }
  }

  &__usertype {
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
    @include get-font($f14, $fw-r, $black, $lh);
  }

  &__userstatus {
    margin-top: 10px;
    margin-left: 46px;

    &__btn {
      &:focus,
      &:hover {
        outline: 0px;
      }
    }
  }

  &__useraction {
    display: flex;
    margin-top: 5px;
    position: relative;

    &__statusicon {
      margin-left: 4px;
      vertical-align: text-top;

      &__text .multi-line {
        text-align: left !important;
      }
      &__summarytable {
        width: 300px;
      }
    }

    p {
      margin-top: 6px;
      margin-left: auto;
      @include get-font($f14, $fw-m, $black, $lh);
    }

    .pr-manage__applicants__btn__container {
      position: relative;
      margin-left: auto;
    }

    &__button {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: $white;
      background: transparent;
      border: 0;
      margin-left: auto;
      @include get-font($f14, $fw-m, $lightestGray, $lh);

      &:focus,
      &:hover {
        outline: 0px;
        background: $grayBackground;
      }

      &__options {
        position: absolute;
        width: 218px;
        border: solid 1px $superLightBlack;
        box-shadow: 0 1px 4px 0 $superLightBlack;
        background-color: $white;
        margin: 0;
        padding: 8px 0;
        list-style: none;
        border-radius: 10px;
        z-index: 24;
        right: 32px;
      }
    }
  }

  .pr-manage__product-applicants__user > label > img {
    width: 24px;
    height: 24px;
  }
}

.prproductapplicant__table {
  border: 0px !important;
  width: 100%;
  height: 100%;
  @include getDefaultScrollbar();
  min-height: 250px;
  &::-webkit-scrollbar {
    width: 16px;
    color: #f2f2f2;
  }

  &--left_header{
    text-align: left !important;
  }
  &__header {
    padding: 8px !important;
    text-align: center;
    @include get-font($f12, $fw-b, $black, $lh);

    &__applicant {
      &__title {
        @include get-font($f12, $fw-b, $black, $lh);
        font-weight: $fw-b !important;
      }
      &__subtitle {
        @include get-font($f12, $fw-r, $black, $lh);
        font-weight: $fw-r !important;
      }
    }
  }

  &__row {
    min-height: 58px;

  }

  &__column{
    border-bottom: 1px solid white !important;
    background-color: #F0F6FD !important;
    &.duration-slug-reason{
      background-color: #FFECF0 !important;
    }
  }

  &__usertype {
    text-align: left;
    padding: 8px 8px 8px 0px;
    box-sizing: border-box;
    @include get-font($f14, $fw-r, $black, $lh);
  }

  &__userstatus {
    &:focus,
    &:hover {
      outline: 0px;
    }
  }

  &__useraction {
    display: flex;
    position: relative;
    align-items: center;
    gap: 6px;
    margin-top: 4px;

    p {
      margin-left: auto;
      @include get-font($f14, $fw-m, $black, $lh);
      display: flex;
      align-items: center;
    }

    .pr-manage__applicants__btn__container {
      position: relative;
      margin-left: auto;
    }

    &__button {
      display: flex;
      min-width: 32px;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 32px;
      border-radius: 50%;
      background-color: $white;
      background: transparent;
      border: 0;
      @include get-font($f14, $fw-m, $lightestGray, $lh);

      &:focus,
      &:hover {
        outline: 0px;
        background: $grayBackground;
      }

      &__options {
        position: absolute;
        width: 218px;
        border: solid 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        margin: 0;
        padding: 8px 0;
        list-style: none;
        border-radius: 10px;
        z-index: 24;
        right: 6px;
        top: 36px;
        @media screen and (max-width: $onlyMobile) {
          // left: -174px;
          -webkit-backface-visibility: hidden;
        }
      }
    }
  }
}



@media only screen and (max-width: $onlyMobile) {
  .prproductapplicant__table{
    overflow-y: scroll;
  }
}