//settings css
.settings-container {
  width: $centeredContainer;
  border-radius: $f10;
  box-shadow: 0 2px 4px 0 $superLightBlack;
  border: solid 0.5px $superLightBlack;
  background-color: $white;
  margin-bottom: $f16;
  padding-bottom: $f32;
  box-sizing: border-box;
  @media only screen and (max-width: $onlyMobile) {
    width: 100%;
  }
  &__heading {
    @include get-font($f18, $fw-sb, $black, $lh);
    padding: 32px 0px 12px 24px;
    border-bottom: 1px solid $lightGray;
    @media only screen and (max-width: $onlyMobile) {
      padding: 16px;
    }
  }
  &__remove-btn {
    @extend %appearance-none;
    background: transparent;
    border: 0;
    @include get-font($f16, $fw-m, $removeRed, $lh);
    cursor: pointer;
  }
}

.profile-settings {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
      margin: 24px;
      @media only screen and (max-width: $onlyMobile) {
        margin: 0;
        padding: 16px 12px 0;
      }
      &--disabled {
        opacity: 0.65;
        cursor: not-allowed;
        label {
          cursor: inherit !important;
        }
      }
      &__label {
        margin-right: $f16;
        display: flex;
        &__input {
          margin-right: $f14;
          height: $f20;
        }
        &__text {
          @include get-font($f16, $fw-sb, $black, $lh);
        }
        &__subtext {
          @include get-font($f14, $fw-r, $lightestGray, $lh);
        }
      }
      &__flex__wrapper__space-between {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &__checkbox-input {
    position: relative;
    input[type="checkbox"] {
      display: none;
      & + label {
        background-image: url("assets/images/icons-checkbox-off.png");
        display: inline-block;
        padding-left: 45px;
        background-repeat: no-repeat;
        background-size: 24px;
        cursor: pointer;
      }
      &:checked + label {
        background-image: url("assets/images/checkbox-on-icon.png");
      }
    }
  }
  &__anchor-link {
    text-decoration: none;
    label {
      cursor: pointer;
    }
  }
  &__user-select {
    margin-bottom: $f32;
  }
  &__group-name {
    margin-top: $f10;
    padding: $f10;
    padding-left: $f26;
    background: $lightGray;
    text-transform: capitalize;
    @include get-font($f16, $fw-sb, $lightestGray, $lh);
  }
}

.settings-accountmanagement-container {
  @media only screen and (max-width: $onlyMobile) {
    ul{
        padding-left: 4px;
    }
  }
}
