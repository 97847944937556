.workshop{
    &__action {
        @include get-font($f14, $fw-m, $black, $lhOnboarding);
        background-color: $white;
        outline: none;
        padding: 0;
        &:focus,
        &:active{outline: none;}
        min-height: 40px;
        border-radius: 8px;
        padding: 8px 16px;
        @extend %border-box-shadow;
        overflow: hidden;
        position: relative;

        &.dark{
          color: $white;
          background-color: $black;
          font-weight: $fw-b;
        }
        &.primary{
          color: $white;
          background-color: $teal;
          font-weight: $fw-b;
          .arrow{
            &-wrapper{
              margin-left: 8px;
            }
            width: 20px;
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(106%) contrast(104%);
          }
         @include slideLines($white);
        }
        &.disabled{
          color: $copiedBackground;
          background-color: #f2f2f2;
          font-weight: $fw-b;
          box-shadow: none;
          border: none;
        }
        &.secondary{
          box-shadow: none;
        }
        &--container{
          display: flex;
          width: 100%;
          gap: 15px;
          margin: 12px 0;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        &__info{
            background-color: #F2F2F2;
            padding: 8px 16px;
            display: flex;
            align-items: flex-start;
            gap: 12px;
            border-radius: 8px;
            min-height: 40px;
            box-sizing: border-box;
            .icon{
              img{
                display: block;
                height: 20px;
                width: 20px;
              }
            }
            .text{
              @include get-font($f14,$fw-r,$black);
            }
            &.error{
              gap: 8px;
              align-items:center;
              .icon{
                img{
                  filter: brightness(0) saturate(100%) invert(20%) sepia(41%) saturate(6451%) hue-rotate(354deg) brightness(96%) contrast(87%);
                }
              }
              .text{
                color: $removeRed;
              }
            }
        }
        
    }
}