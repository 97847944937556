.product {
    &__media {
        width: 100%;
        max-width: 394px;
        border-radius: 20px;
        height: 262px;
        overflow: hidden;
        margin-right: 24px;
        background: $grayBackground;
        position: relative;
        overflow: hidden;
        border: 1px solid $grayBackground;
        box-sizing: border-box;
        &__item {
            img {
                width: 394px;
                height: 262px;
                object-fit: contain;
                display: block;
            }
        }
        &__arrow {
            @extend %appearance-none;
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            background-color: $white;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;
            border: 0;
            &--disabled {
                cursor: default;
                opacity: 0.8;
            }
            &__icon {
                width: 8px;
                &--prev {
                    transform: rotate(180deg);
                }
            }
            &--prev {
                margin-left: 8px;
            }
            &--next {
                right: 0;
                margin-right: 8px;
            }
        }
        .react-player__video{
            height: 262px !important;
            background-color: $black;
            margin-top: 0;
            @media only screen and (max-width: 1023px) {
                height: 238px !important;
            }
        }
    }
}

@media only screen and (max-width: $tab) {
    .product {
        &__media {
            max-width: 100%;
            border-radius: 0;
            height: 240px;
            &__item {
                img {
                    width: auto;
                    max-width: 100%;
                    height: 240px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}