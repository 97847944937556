.profile-completion-card {
    padding: 24px 16px;
    box-sizing: border-box;
    position: relative;
    &__image {
        width: 104px;
        height: auto;
        &__container {
            margin-bottom: 16px;
            text-align: center;
        }
    }
    &__details {
        text-align: center;
        margin-bottom: 16px;
        &__title {
            @include get-font($f18, $fw-b, $black, $lh);
            margin-bottom: 8px;
        }
        &__helper {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
        }
    }
    &__action {
        text-align: center;
        &__btn {
            @extend %appearance-none;
            border: 1px solid $removeRed;
            border-radius: 30px;
            height: 40px;
            padding: 8px 12px;
            box-sizing: border-box;
            background: $white;
            @include transitionEase(#{background});
            &:hover {
                background: #FFE6EE;
                cursor: pointer;
            }
            &__text {
                @include get-font($f14, $fw-m, $removeRed, $lh);
            }
        }
    }
}