.like-modal {
    // @include centerItem();
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    .default-modal__container {
        width: 448px;
        max-width: 100%;
        padding-bottom: 48px;
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        overflow-y: auto;
        max-height: 450px;
        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            &__following-btn {
                height: 30px;
                background: $grayBackground;
                @include get-font($f12, $fw-b, $lightBlack, $lh);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                letter-spacing: 0.6px;
                padding: 6px 12px;
                box-sizing: border-box;
                text-transform: uppercase;
                border-radius: 5px;
                &__icon {
                    width: 12px;
                    margin-right: 6px;
                }
            }
            &__details {
                display: flex;
                align-items: center;
                text-decoration: none;
                max-width: calc(100% - 132px); // follow button margin
                margin-right: 8px;
                &--full-width {
                    width: 100%;
                }
                &__icon {
                    width: 36px;
                    height: 36px;
                    border-radius: 36px;
                    overflow: hidden;
                    object-fit: cover;
                    margin-right: 8px;
                }
                &__name {
                    display: block;
                    @include get-font($f14, $fw-m, $black, $lh);
                    letter-spacing: -0.2px;
                    max-width: calc(100% - 44px);
                    &--verified {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            &__action-btn {
                @extend %appearance-none;
                height: 30px;
                padding: 6px 12px;
                box-sizing: border-box;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                &__icon {
                    margin-right: 6px;
                }
                &__text {
                    @include get-font($f12, $fw-b, $lightBlack, $lh);
                }
            }
        }
    }
}