.common-otp-modal{
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 511px !important ;
    margin-bottom: 0px !important;
  .default-modal__content {
   
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .not-center-content{
          display: unset;
          &.isPreview{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            .default-modal__header__close{
              position: absolute;
              right: 8px;
              top: 0px;
            }
            .title{
              @include get-font($f22,$fw-sb, $black, $lh);
              margin: 4px;
            }
            .sub-title{
              @include get-font($f14,$fw-r, $lightestGray, $lh);
              margin: 8px 4px 36px 4px;
              text-align: center
            }
          }
        }
      .success-gif-title{
        @include get-font($f18, $fw-sb,#001223, $lh);
        margin-bottom: 24px;
        text-align: center;
      }
      .success-gif-subtitle{
        text-align: center;
        @include get-font($f12, $fw-r,rgba(25, 25, 25, 0.4), $lh);
        width: 50%;
        margin: auto;
      }
      
      .login-form__header{
        margin-bottom: 12px
      }
      .login-form__otp-form--continue_button{
        margin-top: 20px;
        background-color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        &.disabled{
          background-color: rgba(23,23,23,0.3);
        }
        &.isPreview{
          width: 75%;
        }
        .generic-loader--centered{
          line-height: 0;
        }
      }
      .login-form__otp-form--confirm_title{
        text-align: left;
        padding: 0px;
        margin-bottom: 20px;
         font-weight: 400;
         font-size: 16px;
        .color{
          color: #171717;
          font-weight: 500;
        }
      }
      .login-form__otp-form--time{
          margin-top: 20px;
      }
      .login-form__form__group{
        position: unset;
        margin-bottom: unset;
        &.isPreview{
         width: 75%;
        }
        .login-form__form__input1{
          text-align: center;
          color: black;
          &.red{
            border-color: $red;
          }
        }
        .error-text{
          text-align: center;
          position: unset;
          margin: 0px;
          margin-top: 10px;
        }
      }
      .hr{
        color: #EDEDED;
        margin-top: 24px;
      }
  }
  }
  }

  @media only screen and (max-width: $onlyMobile) {
    .common-otp-modal{
      &.default-modal__container{
        width: 100% !important;
        @include centerItem();
      }
    }
  }