.paidtaster {
  &__title {
    margin-bottom: 32px;
    &__text {
      @include get-font($f32, $fw-sb, $black, $lh);
    }
  }
  &__block {
    width: 100%;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 $superLightBlack;
    border: solid 0.5px $superLightBlack;
    background-color: $white;

    &__subtitle {
      @include get-font($f16, $fw-m, $black, $lh);
    }

    &__details {
      margin-top: 16px;

      &__block {
        margin-top: 16px;
      }
      &__title {
        @include get-font($f12, $fw-b, $blue, $lh);
      }
      &__bulletheader {
        margin-top: 8px;
        padding-left: 24px;
        &__text {
          @include get-font($f16, $fw-r, $black, $lh);
        }
      }

      &__textheader {
        margin-top: 8px;
        &__text {
          @include get-font($f16, $fw-r, $black, $lh);
          margin-bottom: 16px;
        }
        &__buttondiv {
          display: flex;
          margin: 24px 0;
          align-items: center;
          justify-content: center;

          &__button {
            position: relative;
            padding: 12px 12px;
            border-radius: 8px;
            border: 0px;
            width: 360px;
            max-width: 360px;

            @include get-font($f14, $fw-sb, $black, $lh);
            text-align: center;

            &__loader {
              &:after {
                content: '';
                position: absolute;
                right: 6px;
                top: 50%;
                width: 0;
                height: 0;
                border-radius: 50%;
                animation: rotate360 0.5s infinite linear, exist 0.1s forwards ease;
              }

              &:before {
                content: '';
                width: 0px;
                height: 0px;
                border-radius: 50%;
                right: 6px;
                top: 50%;
                position: absolute;
                border: 2px solid darken($black, 40%);
                border-right: 3px solid $white;
                animation: rotate360 0.5s infinite linear, exist 0.1s forwards ease;
              }

              @keyframes rotate360 {
                100% {
                  transform: rotate(360deg);
                }
              }

              @keyframes exist {
                100% {
                  width: 15px;
                  height: 15px;
                  margin: -8px 5px 0 0;
                }
              }
            }

            &:disabled {
              opacity: 0.65;
              cursor: not-allowed !important;
            }
          }
        }
        &__buttonsuccessdiv {
          display: flex;
          margin: 24px 0;
          align-items: center;
          justify-content: center;

          &__text {
            padding: 8px 16px;
            border-radius: 10px;
            background-color: $lightestGreen;
            @include get-font($f14, $fw-m, $green, $lh);
          }
        }
      }
      &__conditions {
        margin-top: 40px;
      }
    }
  }
}

.termsconditions {
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    @include get-font($f12, $fw-b, $blue, $lh);
  }
  &__modaltext {
    @include get-font($f14, $fw-m, $blue, $lh);
  }
}
