// Common variables
$btnBg: #f6f9fd;
.collaboration-list {
  margin: 0;
  padding: 0;
  list-style: none;
  @media only screen and (max-width: $onlyMobile) {
    margin-bottom: 16px;
  }
  > li {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.collaboration-item {
  @extend %border-box-shadow;
  background: $white;
  border-radius: 10px;
  padding: 24px;
  box-sizing: border-box;
  position: relative;
  &--disabled {
    opacity: 0.65;
  }
  @media only screen and (max-width: $onlyMobile) {
    padding: 16px;
  }
  &__details {
    margin-bottom: 8px;
    text-decoration: none;
    &__type {
      @include get-font($f12, $fw-b, $lightBlack, $lh);
      margin-bottom: 8px;
      letter-spacing: 0.6px;
      &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__warning {
        display: flex;
        align-items: center;
        background: $lightestPink;
        margin-right: -24px;
        padding: 8px 16px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        > img {
          margin-right: 6px;
        }
        @include get-font($f14, $fw-m, $red);

        @media only screen and (max-width: $onlyMobile) {
          @include get-font($f12, $fw-m, $red);
          margin-right: -16px;
        }
      }
    }
    &__status{
      @include get-font($f14, $fw-b, $white, $lh);
      padding: 4px 12px;
      border-radius: 4px;
      background-color: $copiedBackground;
      &__container{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }
      &.closed{
        background-color: $black;
      }
      &.expired{
        background-color: $red;
      }
    }
    &__title {
      @include get-font($f18, $fw-sb, $black, $lh);
      margin-bottom: 8px;
      word-break: break-word;
      .post-details__link {
        font-size: inherit;
        font-weight: inherit;
      }
    }
    &__description {
      @include get-font($f16, $fw-r, $lightBlack, $lh);
      margin-bottom: 8px;
      word-break: break-word;
      @media only screen and (max-width: $onlyMobile) {
        font-size: $f14;
      }
    }
    &__media {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 16px 0;
      &__thumb {
        width: 64px;
        height: 64px;
        border-radius: 10px;
        margin-right: 8px;
        border: solid 1px $superLightBlack;
        object-fit: cover;
        object-position: center;
        margin-bottom: 6px;
      }
      &__playIcon {
        width: 26%;
        top: 34%;
        left: 34%;
      }
    }
    .collaboration__view__details__types{
      margin-bottom: 12px;
    }
  }
  &__admin {
    &__action {
      &__container {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      &__right {
        display: flex;
        align-items: center;
      }
    }
  }
  &__interested-btn {
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #F2F2F2;
    text-decoration: none;
    @include get-font($f14, $fw-b, $black, $lh);
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    img {
      width: 12px;
      margin-right: 8px;
      display: block;
      vertical-align: middle;
    }
    span{
      opacity: .6;
    }
  }
  &__manage-btn {
    background-color: #F2F2F2;
    padding: 8px 16px;
    border-radius: 8px;
    text-decoration: none;
    @include get-font($f14, $fw-m, $black, $lh);
    display: block;
    // min-width: 145px;
    flex-shrink: 0;
    text-align: right;
  }
  &__text {
    &--disabled {
      @include get-font($f12, $fw-r, $removeRed, $lh);
      opacity: 0.6;
      text-transform: uppercase;
    }
  }
  &__show-interest-btn {
    height: auto;
    text-decoration: none;
    padding: 8px 16px;
    box-sizing: border-box;
    border: 1.5px solid $blue;
    border-radius: 7px;
    background: $blue;
    @include get-font($f12, $fw-b, $white, $lh);
    letter-spacing: 0.6px;
    display: block;
    text-align: center;
    flex-shrink: 0;
    min-width: 148px;
    @media only screen and (max-width: $onlyMobile) {
      min-width: 100px;
      padding: 8px 8px;
    }
    cursor: pointer;
    &--yellow {
      border-color: $yellow;
      color: $black;
      background:$yellow;
    }
    &--green {
      border-color: $green;
      color: $white;
      background: $green;
      min-width: unset;
    }
    &.disabled{
      opacity: .6;
    }
  }
  &__review-count-btn{
    background-color: #FBB51F1A;
    border-radius: 8px;
    padding: 8px 16px;
    text-decoration: none;
    @include get-font($f14, $fw-m, $black, $lh);
    display: flex;
    justify-content: center;
  }
  &__action {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: $smallMobile) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
    }
    &__edit,
    &__setup-roles {
      @extend %border-box-shadow;
      display: inline-block;
      height: 40px;
      box-sizing: border-box;
      padding: 10px 16px;
      border-radius: 10px;
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      margin-right: 8px;
      min-width: fit-content;
      &__icon {
        margin-right: 12px;
        vertical-align: text-top;
        display: inline-block;
      }
      &__text {
        @include get-font($f14, $fw-b, $black, $lh);
        letter-spacing: 0.7px;
        text-decoration: none;
        display: inline-block;
        vertical-align: top;
      }
      &--no-margin {
        margin-left: 0;
      }
    }
    &__setup-roles {
      background: $lightestBlue;
      border-color: transparent;
      &__text {
        color: $blue;
      }
    }
    &__edit {
      background: $white;
    }
    &__link {
      text-decoration: none;
      &--manage {
        color: $black;
      }
      &--interest {
        color: $blue;
      }
      &__text {
        &--blue {
          color: $blue !important;
        }
        &--green {
          color: $green !important;
        }
        &--orange {
          color: $orange !important;
        }
        &--black {
          color: $black !important;
        }
        &--strong {
          @include get-font($f18, $fw-sb, $black, $lh);
          margin-right: 4px;
        }
        &--primary {
          @include get-font($f14, $fw-r, $black, $lh);
        }
      }
      &--interested {
        @include get-font($f12, $fw-b, $blue, $lh);
        letter-spacing: 0.6px;
        display: inline-block;
        img {
          margin-right: 8px;
          width: 12px;
          vertical-align: middle;
        }
      }
      &--show-interest {
        height: 36px;
        border: 1.5px solid $blue;
        border-radius: 7px;
        background: $btnBg;
        display: inline-block;
        line-height: 36px;
        padding: 8px 16px;
        box-sizing: border-box;
        @include get-font($f12, $fw-b, $blue, $lh);
        letter-spacing: 0.6px;
      }
    }
    &__user__block{
      .user-block{
        .user-block__details{
          align-items: flex-start;
        }
      }
    }
  }

  .paidribbon__rectangle{
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

@media only screen and (max-width: $onlyMobile) {
  .collaboration-item__admin__action__container {
    overflow: hidden;
    overflow-x: auto;
    // white-space: nowrap;
    padding-bottom: 12px;
  }
  
}
@media only screen and (max-width: $smallMobile) {
  .collaboration-item__manage-btn {
   text-align: center;
  }
  .collaboration-item__interested-btn{
    justify-content: center;
  }
}
