.document-upload-modal {
    &__title {
        @include get-font($f16, $fw-r, $onboardingBlack, $lh);
        margin-bottom: 16px;
    }
    &__sub-title {
        @include get-font($f14, $fw-r, $black, $lh);
        margin-bottom: 6px;
    }
    &__list {
        @include get-font($f14, $fw-sb, $black, $lh);
        padding-left: 24px;
        margin-bottom: 16px;
        &__item {
            margin-bottom: 6px;
        }
    }
    &__documents {
        &__container {
            display: flex;
            justify-content: space-between;
        }
        &__title {
            @include get-font($f16, $fw-sb, $black, $lh);
            margin-bottom: 18px;
        }
    }
    &__checkbox-container {
        margin: 16px 0;
        display: flex;
        align-items: flex-start;
        &__text {
            @include get-font($f14, $fw-r, $onboardingBlack, $lh);
        }
        &__checkbox {
            margin-right: 14px;
        }
        &__link {
            @include get-font($f14, $fw-m, $blue, $lh);
            text-decoration: none;
        }
    }
    &__footer {
        justify-content: space-between;
        margin-top: 66px;
    }
}

@media only screen and (max-width: $onlyMobile) {
    .document-upload-modal {
        &__documents {
            &__container {
                display: block;
                .image-placeholder__image--centered {
                    margin-bottom: 16px;
                }
            }
        }
    }
}