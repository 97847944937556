.badge-details {

    &__container{
        .scrollable-modal__container{
            
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 500px;

            @media screen and (max-width:881px) {
                width: 100%;
            }
        }
        .scrollable-modal__header{
            position: absolute;
            top: 24px;
            right: 24px;
        }
    }
    &__image {
        margin: auto;
        width: 134px;
        height: 96px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        margin: 20px 0 10px 0;
    }

    &__description {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        max-height: 30vh;
        overflow-y: auto;
        @include getDefaultScrollbar();
    }
}

.profile-badge-tooltip {
    background-color: $black !important;
    font-weight: 500;
    font-size: 14px !important;
    color: $white;
    opacity: 1 !important;
    &::after {
        bottom: -5px !important;
        border-top-color: $black !important;
    }
    @media screen and (max-width:881px) {
        z-index: auto !important;
    }
}