.product {
    &__reviews {
        padding-top: 32px;
        border-top: 1px solid $grayBackground;
        &__loader {
             margin: 24px 0;
        }
        &__header {
            margin-bottom: 24px;
            @include get-font($f18, 900, $black);
        }
        &__helper {
            @include get-font($f15, $fw-r, $black, $lh);
            margin-top: 16px;
        }
        &__comment {
            @include get-font($f14, $fw-r, $black, $lh);
            letter-spacing: 0.25px;
            &-btn {
                cursor: pointer;
                @extend %appearance-none;
                border: 0;
                background: transparent;
                @include get-font($f14, $fw-r, $lightBlack, $lh);
                margin-top: 18px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                img {
                    margin-right: 6px;
                }
            }
        }
        &__sort {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 8px;
            border-bottom: 2px solid $grayBackground;
            margin-top: 9px;
            &__btn {
                margin-right: 12px;
                @extend %appearance-none;
                background: transparent;
                border: 0;
                @include get-font($f12, $fw-b, $black);
                letter-spacing: 0.9px;
                padding: 0 12px;
                opacity: 0.6;
                position: relative;
                cursor: pointer;
                img {
                    vertical-align: text-top;
                    margin-right: 6px;
                }
                &--active {
                    opacity: 1;
                    &:after {
                        content: '';
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: $black;
                        margin-bottom: -10px;
                    }
                }
            }
        }
        &__load-more {
            @extend %appearance-none;
            border: 0;
            width: 100%;
            height: 45px;
            line-height: 45px;
            border-radius: 10px;
            background: rgba(73, 144, 226, 0.1);
            @include get-font($f14, $fw-sb, $blue);
            cursor: pointer;
            @include transitionEase(#{background});
            &:hover, &:focus {
                background: rgba(73, 144, 226, 0.2);
            }
        }
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 32px;
                &__left {
                    flex: 1;
                    border-right: 1px solid $grayBackground;
                }
                &__right {
                    min-width: 132px;
                    text-align: center;
                    .product__ratings__default {
                        border: 3px solid $white;
                        position: relative;
                        box-sizing: unset;
                        padding-top: 0;
                        padding-bottom: 0;
                        z-index: 1;
                    }
                }
            }
        }
    }
    &__ratings {
        &__default {
            height: 32px;
            padding: 8px;
            box-sizing: border-box;
            color: $white;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            &--medium {
                height: 48px;
                .product__ratings__start {
                    display: none;
                }
            }
            &--small {
                height: 28px;
                padding: 6px;
            }
            .product__ratings__start {
                font-size: 13.5px;
                font-weight: $fw-sb;
                margin-right: 2px;
            }
            .product__ratings__to {
                @include get-font($f12, 900, $white);
            }
            .product__ratings__from {
                @include get-font($f12, $fw-m, $white);
            }
            &--empty {
                background: #ababab;
                .product__ratings__to {
                    line-height: 0;
                }
            }
        }
        &__img {
            width: 48px;
            height: 48px;
            object-fit: cover;
            border-radius: 48px;
            margin-left: -14px;
            margin-top: -5px;
            position: absolute
        }
    }
}

@media only screen and (max-width: $tab) {
    .product {
        &__reviews {
            padding-top: 24px;
            &__header {
                font-size: $f16;
                margin-bottom: 8px;
            }
            &__list {
                &__item {
                    margin-top: 16px;
                    position: relative;
                    border-bottom: 1px solid $grayBackground;
                    &:last-child {
                        border-bottom: 0;
                    }
                    &__left {
                        border-right: 0;
                        .user-block {
                            max-width: calc(100% - 115px);
                        }
                    }
                    &__right {
                        position: absolute;
                        right: 0;
                        top: 0;
                        min-width: auto;
                        &--with-image {
                            margin-right: 33px;
                            margin-top: 5px;
                        }
                    }
                }
            }
            &__comment {
                &-btn {
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
            }
            .product__reviews__sort {
                margin-top: 12px;
                align-items: baseline;
                > div {
                    flex: 1;
                    &:last-child {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                }
                &__btn {
                    margin-right: 0;
                    margin-bottom: 12px;
                    &::after {
                        margin-bottom: -6px;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: $onlyMobile) {
    .product {
        &__reviews {
            .product__reviews__sort {
                margin-top: 12px;
                display: flex;
                flex-direction: column;
                align-items: unset;
                width: 100%;
                gap: 8px;
                > div {
                    flex: 1;
                    &:last-child {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}