.followers {
    margin: 114px auto 66px;
    max-width: $centeredContainer;
    width: 100%;
    @media only screen and (max-width: $onlyMobile) {
        margin-top: 88px;
        padding: 0 16px;
        box-sizing: border-box;
    }
    &__blank {
        padding: 16px;
        box-sizing: border-box;
        @include get-font($f14, $fw-m, $black, $lh);
    }
    .user-create-block {
        margin-bottom: 24px;
    }
    &__header {
        display: inline-block;
        vertical-align: top;
        margin-right: 64px;
        color: $lighterBlack;
        text-decoration: none;
        margin-bottom: 24px;
        @media only screen and (max-width: $onlyMobile) {
            margin-right: 48px;
        }
        &:hover, &:active, &:focus {
            color: $lightBlack
        }
        &--active {
            color: $black;
            &:hover, &:active, &:focus {
                color: $black !important;
            }
        }
        &:last-child {
            margin-right: 0;
        }
        &__title {
            @include get-font($f32, $fw-sb, inherit);
            margin-bottom: 2px;
            @media only screen and (max-width: $onlyMobile) {
                font-size: $f18;
            }
        }
        &__count {
            @include get-font($f16, $fw-r, inherit, $lh);
        }
    }
    &__container {
        background: $white;
        @extend %border-box-shadow;
        border-radius: 10px;
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        &__item {
            padding: 16px 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            @include transitionEase(#{background});
            @media only screen and (max-width: $onlyMobile) {
                padding: 8px 12px;
            }
            &:hover, &:active, &:focus {
                background: $grayBackground;
            }
            &__meta {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: calc(100% - 86px);
                text-decoration: none;
        }
        }
        &__icon {
            width: 44px;
            height: 44px;
            border-radius: 44px;
            border: 1px solid $grayBackground;
            margin-right: 8px;
            object-fit: cover;
            display: block;
            box-sizing: border-box;
            @media only screen and (max-width: $onlyMobile) {
                width: 32px;
                height: 32px;
                border-radius: 32px;
            }
        }
        &__details {
            flex: 1;
            &__title {
                @include get-font($f14, $fw-sb, $black, $lh);
                &--secondary {
                    margin-left: 6px;
                    font-weight: $fw-r;
                    color: $lightBlack;
                    @media only screen and (max-width: $onlyMobile) {
                        display: none;
                    }
                }
            }
            &__secondary {
                margin-top:2px;
                @include get-font($f14, $fw-r, $lightBlack, $lh);
                word-wrap: break-word;
                @media only screen and (max-width: $onlyMobile) {
                    display: none;
                }
            }
        }
        &__action {
            margin-left: 26px;
            width: 76px;
            text-align: right;
            @media only screen and (max-width: $onlyMobile) {
                width: auto;
                margin-left: 16px;
            }
            &__btn {
                @extend %appearance-none;
                @include get-font($f14, $fw-r, $lightBlack, $lh);
                cursor: pointer;
                background: transparent;
                border: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0;
                margin: 0;
                &--action {
                    @include get-font($f12, $fw-b, $red, $lh);
                    letter-spacing: 0.6px;
                    width: 100%;
                }
                &__icon {
                    width: 12px;
                    margin-right: 6px;
                }
            }
        }
    }
}