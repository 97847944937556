.show-flag-modal {
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    >div:first-child{
        padding: 0px 0px 16px 0px !important;
    }
    .scrollable-modal__modal-overflow{
        .default-modal__content {
            .user-block .user-block__details{
                > div:last-child {
                > a{
                    font-size: 16px;
                }
                }
            }
        }
    }
    &__content {
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;
    }

    &__disclaimer {
        margin: 40px 0 16px;
        @include get-font($f14, $fw-r, $lightBlack, $lh);
    }

    .post-text {
        margin-top: 12px;
        margin-bottom: 12px;
        border-radius: 10px;
        resize: none;
        @include get-font($f16, $fw-r, $black, $lh);
        @extend %appearance-none;
        outline: none;
        height: 120px;
        box-sizing: border-box;
        width: 100%;
        background: $backgroundGray;
        border: none;
        padding: 16px;
        @include getDefaultScrollbar();
    }

    &__footer {
        padding: 12px 0 0px 0;

        &__action-btn {
            @extend %appearance-none;
            padding: 6px 12px;
            border-radius: 7px;
            outline: 0;
            border: 0;
            background-color: #DD2E1F;
            cursor: pointer;

            &--disabled-new {
                opacity: 0.65;
                ;
                cursor: not-allowed;
            }

            &--yellow {
                background: $yellow;
            }

            &.disabled {
                background: $lightGrey !important;
                color: white;
                cursor: default !important;
            }

            &__text {
                @include get-font($f14, $fw-sb, $white, $lh);
            }

            &.unflag {
                background-color: #00A146;
            }
        }
    }

    &__checkbox {
        margin-top: 12px;
        @include get-font($f12, $fw-r, $black, $lh);
    }
}