.survey {
  &__container {
    width: 100%;
    margin-left: auto;
    padding: 32px 0;
    margin-right: auto;
  }
  &__acceptingResponseTag {
    display: flex;
    align-items: center;
    justify-items: center;
    padding-left: 24px;
    margin-left: 24px;
    border-left: 1px solid #979797;
    &--text {
      @include get-font($f14, $fw-m, $black);
      margin-right: 16px;
    }
  }
  &__editOptions {
    width: 177px !important;
    margin-left: -131px !important;
    .post-share-popup-up__list{
      .user-block__action__container{
        &__list{
          &__item{
            &__btn{
             img{
                filter: brightness(0) saturate(100%) invert(0%) sepia(12%) saturate(133%) hue-rotate(320deg) brightness(107%) contrast(82%);
              }
              &__text{
                font-weight: 500;
              }
            }
        }}
      }
    }
  }
  &__details {
    max-width: $centeredContainer;
    margin: 0 auto;
    width: 100%;
    background: $white;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 $superLightBlack;
    border: solid 0.5px $superLightBlack;
    box-sizing: border-box;
    &__content {
      padding: 32px 24px;
    }
    .post-comments-list {
      margin-bottom: 0;
    }
    .post-comments-containers {
      max-width: 100%;
    }
    &__multipleAllowedTag {
      background: $green;
      padding: 4px 16px;
      border-radius: 100px;
      display: flex;
      width: fit-content;
      align-items: center;
      justify-content: center;
      @include get-font($f14, $fw-m, $white);
      margin-top: 20px;
      margin-bottom: 20px;
      &--img {
        margin-right: 8px;
      }
    }
    &__submissionCount {
      width: 100%;
      padding: 12px 24px;
      background: #ccecda;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 16px;
      left: -24px;
      &--cta {
        &--img {
          margin-left: 8px;
        }
        border-left: 1px solid #66c790;
        padding-left: 24px;
      }
      &__text {
        @include get-font($f14, $fw-m, $black);
      }
      &--addSaperator {
        &::after {
          content: " ";
          position: absolute;
          height: 1.5px;
          background: rgba(0, 0, 0, 0.05);
          width: calc(100% + -48px);
          bottom: -16px;
        }
      }
    }
    &__title {
      @include get-font($f22, $fw-sb, $green, $lh);
      word-break: break-word;
      box-sizing: border-box;
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .survey__preview{
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between; 
          &--options{
            margin-top: 8px;
            position: relative;
            .user-block__action__container{
              margin-left: auto;
              right: 16px;
              &__list{
                width: max-content;
                &__item{
                  &__btn{
                   img{
                      filter: brightness(0) saturate(100%) invert(0%) sepia(12%) saturate(133%) hue-rotate(320deg) brightness(107%) contrast(82%);
                    }
                    &__text{
                      font-weight: 500;
                    }
                  }
              }}
            }
          }
        }
      }
    }
    &__posted {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .collaboration-item__admin__action__container {
      .collaboration__view__action__btn {
        margin-left: 16px;
      }
    }
    &__description {
      margin-top: 24px;
      @include get-font($f16, $fw-r, $black, $lh);
      word-break: break-word;
      white-space: pre-line;
      .linkified {
        font-size: inherit;
        color: $blue;
        text-decoration: none;
      }
    }
    &__creator {
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      margin-top: 24px;
      padding-bottom: 8px;
      position: relative;
      a {
        color: inherit;
        font-size: inherit;
        text-decoration: none;
      }
      &::after {
        content: " ";
        position: absolute;
        height: 1px;
        background: #ededed;
        width: calc(100% + 50px);
        left: -26px;
        top: 41px;
      }
    }
    &__video-container {
      margin-top: 24px;
      border-radius: 10px;
      background: $black;
      overflow: hidden;
    }
    &--slider{
      margin-top: 24px;
      position: relative;
      &__image{
        width: 100%;
        aspect-ratio: 16/9;
        border-radius: 8px;
        border: 1px solid rgba($color: $black, $alpha: .1);
        object-fit: contain;
        cursor: pointer;
        box-sizing: border-box;
      }
      .react-player__video,
      .survey__details__video-container{
        margin: 0;
      }
      &__arrow{
        opacity: .2;
        top: 50%;
        transform: translateY(-50%);
        @include transitionEase(#{opacity});
        &.suggestions__arrow--prev{
          margin:0;
          left: 16px;
        }
        &.suggestions__arrow--next{
          margin:0;
          right:16px;
        }
      }
      .pagination{
        position: absolute;
        top: 16px;
        right: 14px;
        display: block;
        width: fit-content;
        padding: 4px 12px;
        background-color: $white;
        border-radius: 8px;
        border: 1px solid $copiedBackground;
        @include get-font($f14,$fw-m,$copiedBackground);
        z-index: 1;
      }

      // hover

      &:hover{
        .survey__details--slider__arrow{
          opacity: .9;
        }
      }
    }
  }
  &__complete {
    &__container {
      background: $white;
      @include form-input-border-shadow();
      padding: 32px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 80px;
      border-radius: 10px;
    }
    &__img {
      margin-bottom: 16px;
    }
    &__title {
      @include get-font($f32, $fw-sb, $black, $lh);
      margin-bottom: 16px;
    }
    &__subtitle {
      @include get-font($f14, $fw-m, $black, $lh);
    }
  }
  &__view__button {
    &__showinterest {
      &.collaboration-item__show-interest-btn {
        cursor: pointer;
        background: $teal;
        border: 0px;
        margin-left: 16px;
        @include get-font($f14, $fw-b, $white, $lh);
        min-width: unset;
       
      }
      &.disabled{
        cursor: pointer;
        pointer-events: none;
        opacity: 0.5;
      }
      margin-left: 0px;
      .loading{
          display: flex;
          gap: 8px;
          align-items: center;
          .generic-loader{
            line-height: 0;
            >img{
              width: 30px;
            }
          }
      }
    }
    &__interested {
      &.collaboration-item__show-interest-btn {
        cursor: default;
        background: #F2F2F2;
        border: none;
        margin-left: 16px;
        @include get-font($f14, $fw-m, $black,$lh);
        min-width: unset;
      }

      &__icon {
        vertical-align: middle;
      }
      &__text {
        margin-left: 8px;
        opacity: .6;
      }
    }
    &__modal-interested {
      &.show-interest-modal__footer__action-btn {
        background: $surveyTeal;
      }
    }
    &__submitted {
      &.collaboration-item__show-interest-btn {
        opacity: .6;
        cursor: default;
        margin-left: 16px;
        line-height: 0;
        @include get-font($f14, $fw-m, $black);
        min-width: unset;
        height: 40px;
        border-radius: 10px;
        border: 0.5px solid rgba(0, 0, 0, 0.10);
        background: #F7F7F7;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
        img{
          width: 12px;
          display: inline-block;
        }
      }

    }
  }
  &__view{
    &__details{
      &__images {
        width: 100%;
        margin-top: 32px;
        display: flex;
        overflow-x: auto;
        gap: 12px;
        @include getDefaultScrollbar();
      }
      &__image {
        height: 108px;
        border-radius: 10px;
        border: solid 1px $superLightBlack;
        object-fit: contain;
        object-position: top;
        aspect-ratio: 16/9;
        @media only screen and (max-width: $onlyMobile) {
          margin-bottom: 8px;
          height: 60px;
        }
      }
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .survey {
    &__container {
      padding-top: 0;
    }
    &__details {
      border-radius: 0;
      box-shadow: unset;
      &__title__container {
        .user-block{
          &__action{
              &__container{
                  margin-left: -164px;
                  &__list{
                      width: 182px;
                  }
              }
          }
      }
      }
      &__posted {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
      }
      &__description {
        margin-top: 16px;
        font-size: $f14;
      }
      .collaboration__view__action__btn--green {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (max-width: $smallMobile) {
  .survey {
    &__details {
      &__submissionCount{
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        &--cta{
          border-left: unset;
          padding-left: 0px;
        }
      }
    }
  }
}
