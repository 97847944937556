
.createbar {
  &__mobile {
    margin-top: -16px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: $white;
    padding: 12px 0 12px 0;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px $superLightBlack;

    &__text {
      margin-left: 8px;
      @include get-font($f16, $fw-sb, $black, 1.5);
    }
  }
  &__modal {
    &.top-bar__content__right {
      position: relative;
    }

    &.top-bar__content__user-links__container {
      position: absolute;
      z-index: 100;
    }

    &__header {
      display: flex;

      &__text {
        @include get-font($f16, $fw-sb, $black, 1.5);
      }
      &__close {
        margin-left: auto;
        background: transparent;
        border: 0;
        cursor: pointer;
      }
    }
    &__content {
      margin-top: 36px;

      &__item {
        display: flex;
        padding: 18px 0;
        border-bottom: 0.5px solid $superLightBlack;

        &__icon {
          width: 24px;
          height: 24px;
        }
        &__text {
          margin-top: 2px;
          margin-left: 12px;
          @include get-font($f14, $fw-m, $black, 1.5);
        }
      }
    }
  }
  
  &__toast {
    &.green{
      background-color: $green !important;
    }
    &.black{
      background-color: $black !important;
    }
    &.Toastify__toast {
      padding: 8px;
      min-height: 0px;
      border-radius: 8px;
      cursor: default;
      width: fit-content;
    }
    &__container {
      width: 200px;
    }

    &__content {
      display: flex;
      &.isNotLink {
        padding: 0px 28px;
      }
      &__text {
        @include get-font(14px, $fw-sb, $white, 1.5);
      }
      &__link {
        margin-left: auto;
        margin-right: 4px;
        text-decoration: none;
        border-left: 1px solid rgba(255, 255, 255, 0.7);
        padding-left: 12px;
        text-transform: uppercase;
        @include get-font($f14, $fw-sb, $white, 1.5);
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
}

.home {
  &__loader {
    @media only screen and (max-width: $onlyMobile) {
      margin-top: 24px;
    }
  }
  &__card {
    margin: 24px 0;
    .workshop__card{
      border-radius: 10px 10px 0 0;
      &__status{
        margin-right: 0;
      }
    }

    @media only screen and (max-width: $onlyMobile) {
      .review-page__scroll {
        padding-left: 0px;
        @include getDefaultScrollbar();
        scrollbar-color: #ededed #ffffff;
        padding-bottom: 4px;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      &__text {
        &__title {
          @include get-font($f18, $fw-sb, $black, 1.5);
        }
        &__subtitle {
          margin-top: 5px;
          @include get-font($f15, $fw-r, $lightestGray, 1.5);
        }
      }
    }
    &__content {
      .suggestions__arrow--prev {
        margin-left: 12px;
      }
      .suggestions__arrow--next {
        margin-right: 12px;
      }
    }
    
  }

  &__imagecarousel {
    &__slideritem {
      position: relative;
      width: 95% !important;
      height: 354px;
      text-decoration: none;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px $superLightBlack;
      margin-bottom: 4px;

      @media only screen and (max-width: $onlyMobile) {
        width: 69% !important;
        margin-right: 12px;
      }

      &__sliderimg {
        width: 100%;
        aspect-ratio: 0.8;
        border-radius: 8px;
        height: 100%;
        object-fit: cover;
      }
      &__viewicon {
        position: absolute;
        padding: 4px 8px;
        border-radius: 20px;
        background-color: $black;
        left: 12px;
        bottom: 12px;
        min-width: 40px;

        &__img {
          width: 16px;
          height: 12px;
          vertical-align: middle;
          path {
            fill: $white;
          }
        }
        &__text {
          margin-left: 4px;
          @include get-font($f14, $fw-m, $white, 1.5);
        }
      }
    }
  }

  &__carousel {
    &__slideritem {
      width: 95% !important;
      height: 280px;
      background-color: $white;
      border-radius: 8px;
      text-decoration: none;
      box-shadow: 0px 2px 4px 0px $superLightBlack;
      margin-bottom: 4px;

      @media only screen and (max-width: $onlyMobile) {
        width: 69% !important;
        margin-right: 12px;
      }

      &.workshop{
        height:313px;
        background-color: $workshop;

      }
    }
    &__survey {
      display: flex;
      flex-direction: column;
      position: relative;

      &__title {
        margin-bottom: 24px;
        padding: 12px;
        height: 40px;
        white-space: normal;
        word-break: break-word;
        @include get-font($f15, $fw-sb, $black, 1.5);
        @include limit-text-to-line(2);
      }
      &__image {
        width: 100%;
        height: 146px;
        object-fit: cover;
        border-top: 1px solid #d1d1d1;
        + .play{
          height: 33px;
          width: 33px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

        }

        &__overlay {
          position: absolute;
          bottom: 44px;
          width: 100%;
          height: 140px;
          z-index: 1;
          background-image: linear-gradient($lightestBlackOverlay, $lightestBlackOverlay, $black);
        }
      }
      &__info {
        position: absolute;
        display: flex;
        bottom: 50px;
        padding: 0px 8px;
        width: 100%;
        z-index: 2;
        .useravatar{
          .pr-manage__applicants__icon{
            margin-right: 8px;
          }
        }
        &__name {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          &__link {
            display: flex;
            text-decoration: none;

            &__text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 100px;
              text-decoration: none;
              @include get-font($f14, $fw-m, $white, 1.5);
            }
            &__verifiedicon {
              margin-top: 3px;
              margin-left: 8px;
              flex-shrink: 0;
              vertical-align: text-bottom;
              width: 16px;
              height: 16px;
            }
          }

          &__viewicon {
            &__img {
              vertical-align: middle;

              path {
                fill: $white;
              }
            }
            &__text {
              margin-left: 4px;
              @include get-font($f12, $fw-sb, $greylight, 1.5);
            }
          }
          &__paidribbon {
            margin: 8px 8px 0px auto;

            .paidribbon__rectangle {
              border-radius: 4px 0 0 4px;
            }
          }
        }
        .pr-manage__applicants__icon {
          width: 38px;
          height: 38px;
        }
      }
      &__button {
        text-align: center;
        background-color: $green;
        padding: 13px;
        border-radius: 0px 0px 8px 8px;
        text-transform: uppercase;
        @include get-font($f14, $fw-b, $white, 1.5);
      }
    }
    &__collaborate {
      display: flex;
      flex-direction: column;
      position: relative;

      .home__carousel__survey__button {
        background-color: $blue;
      }

      &__playIcon {
        width: "13%";
        top: "32%";
        left: "47%";
      }
    }
    &__productreview {
      display: flex;
      flex-direction: column;
      position: relative;

      .home__carousel__survey__button {
        color: $black;
        background-color: $yellow;
      }
    }
    &__polling {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 280px;

      &__paidribbon {
        position: absolute;
        right: 0px;
        top: 8px;

        .paidribbon__rectangle {
          border-radius: 4px 0 0 4px;
        }
      }

      &__info {
        display: flex;
        padding: 0px 12px 0px 12px;
        margin-bottom: 12px;
        margin-top: auto;
        .useravatar{
          .pr-manage__applicants__icon{
            margin-right: 8px;
          }
        }

        &__name {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          &__link {
            display: flex;
            text-decoration: none;

            &__text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 84px;
              text-decoration: none;
              @include get-font($f14, $fw-m, $black, 1.5);
            }
            &__verifiedicon {
              margin-top: 3px;
              margin-left: 4px;
              flex-shrink: 0;
              vertical-align: text-bottom;
              width: 16px;
              height: 16px;
            }
          }

          &__viewicon {
            &__img {
              vertical-align: middle;

              path {
                fill: $black;
              }
            }
            &__text {
              margin-left: 4px;
              @include get-font($f12, $fw-sb, $black, 1.5);
            }
          }
        }

        &__pollbtn {
          display: flex;
          margin-top: 4px;
          margin-left: auto;
          height: 22px;
          padding: 4px;
          width: 52px;
          background: $green;
          border-radius: 4px;

          &__icon {
            width: 16px;
            height: 16px;
            margin: 2px 4px 4px 4px;

            path {
              fill: $white;
            }
          }
          &__text {
            @include get-font($f14, $fw-m, $white, 1.5);
          }
        }
        .pr-manage__applicants__icon {
          width: 38px;
          height: 38px;
        }
      }

      .home__carousel__survey__image {
        margin-top: 0px;
        border-radius: 8px 8px 0 0;
        aspect-ratio: 2;
        object-fit: cover;
        border-bottom: 1px solid #d1d1d1;
      }
    }

    &__product {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 280px;

      &__paidribbon {
        position: absolute;
        right: 0px;
        top: 8px;
      }

      &__title {
        padding: 0 12px;

        &.home__carousel__survey__title {
          margin-bottom: 0px;
          padding: 12px 12px 0 12px;
        }
      }

      &__brand {
        padding: 0 12px;
        margin-top: 4px;
        @include get-font($f14, $fw-r, $black, 1.5);
      }

      &__review {
        padding: 0 12px;
        margin-top: auto;
        margin-bottom: 14px;

        &__empty {
          display: flex;

          &__icon {
            border-radius: 4px;
            background-color: $lightGray;
            padding: 6px;
            color: $focusedGray;
          }
          &__text {
            margin-left: 8px;
            margin-top: 4px;
            @include get-font($f16, $fw-r, $superLightGrey, 1.5);
          }
        }

        &__nonempty {
          display: flex;

          &__rating {
            border-radius: 4px;
            background-color: $overallPrefernceGreen;
            padding: 6px;
            color: $white;

            &__text {
              margin-left: 6px;
              @include get-font($f14, $fw-sb, $white, 1.5);
            }
          }
        }
      }

      .home__carousel__survey__image {
        margin-top: 0px;
        border-radius: 8px 8px 0 0;
        aspect-ratio: 1.8;
        object-fit: cover;
        border-bottom: 1px solid #d1d1d1;
      }
    }
    &__workshop{
      position: relative;
      .workshop__card__status{
        position: absolute;
        top: 12px;
        left:12px;
      }
      .home__carousel__survey__title{
        padding-top: 45px;
        height: 38px;
      }
      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        text-align: center;
        background-color: $teal;
        padding: 13px;
        border-radius: 0px 0px 8px 8px;
        text-transform: uppercase;
        @include get-font($f14, $fw-b, $white, 1.5);
        .arrow{
          width: 20px;
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(106%) contrast(104%);
        }
        position: relative;
        overflow: hidden;
        @include slideLines($white);
      }
    }
  }

  &__suggested {
    margin: 12px 16px 0 16px;
    border-bottom: 1px solid $lightGray;
    padding-bottom: 8px;

    &__title {
      @include get-font($f14, $fw-m, $lightestGray, 1.5);
    }
    &__subtitle {
      margin-top: 12px;

      &__userlink {
        text-decoration: none;
        @include get-font($f14, $fw-sb, $black, 1.5);
      }
      &__text {
        @include get-font($f14, $fw-r, $lightestGray, 1.5);
      }
    }
  }

  &__hashtag {
    width: 100%;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 $superLightBlack;

    &__item {
      display: flex;
      border: 0.5px solid $superLightBlack;
      padding: 12px;
      text-decoration: none;

      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }

      &__tag {
        max-width: 76%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include get-font($f16, $fw-m, $blue, 1.5);
      }
      &__count {
        margin-left: auto;
        padding: 3px 0px 3px 0px;
        @include get-font($f12, $fw-sb, $lightestGray, 1.5);
      }
    }
  }

  &__productmodal {
    .default-modal__header {
      margin: 0px -20px 0px -20px;
      padding: 0px 20px 10px 20px;
    }
    .default-modal__container {
      @media only screen and (max-width: $onlyMobile) {
        top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
      }
    }

    &__content {
      max-height: 400px;
      overflow: auto;
      margin: 0px -20px;
      padding: 4px 20px 0px 20px;
      @media only screen and (max-width: $onlyMobile) {
        max-height: 523px;
      }
      &__desc {
        @include get-font($f14, $fw-r, $lightestGray, 1.5);
      }

      &__products {
        margin-top: 16px;
        &__title {
          margin-bottom: 12px;
          @include get-font($f16, $fw-sb, $black, 1.5);
        }

        &__item {
          &__title {
            display: flex;
            text-decoration: none;
            background-color: $grayBackground;
            margin: 0px -20px;
            padding: 8px 20px;
            cursor: pointer;

            &__text {
              max-width: 86%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @include get-font($f14, $fw-m, $black, 1.5);
            }
            &__icon {
              margin: 2px 8px 0px auto;
              width: 16px;
            }
          }
          &__content {
            display: flex;
            padding: 20px 0px;
            border-bottom: 1px solid $lightestBlack;

            &:last-child {
              border-bottom: 0px;
            }

            &__batch {
              display: flex;
              flex-direction: column;
              margin-left: 4px;

              &__title {
                @include get-font($f14, $fw-m, $black, 1.5);
              }
              &__date {
                @include get-font($f12, $fw-r, $lightBlack, 1.5);
              }

              &__button {
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }
}

.product-tour {
  &__first {
    &__image {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 80px;
    }
    &__title {
      margin-top: 12px;
      @include get-font($f28, $fw-ub, $black, 1.5);
    }
    &__subtitle {
      margin-top: 12px;
      @include get-font($f18, $fw-sb, $lightestGray, 1.5);
    }
    &__startbutton {
      margin-top: 32px;
      border: 0px;
      border-radius: 8px;
      padding: 12px 0;
      width: 75%;
      text-transform: uppercase;
      background-color: $black;
      @include get-font($f14, $fw-sb, $white, 1.5);
    }
    &__skipbutton {
      margin-top: 24px;
      margin-bottom: 12px;
      border: 0px;
      text-decoration: underline;
      text-transform: uppercase;
      background: transparent;
      @include get-font($f14, $fw-sb, $black, 1.5);
    }
  }
  &__second {
    margin: -16px -8px;

    &__header {
      display: flex;

      &__new {
        padding: 4px 12px;
        margin-right: 12px;
        background-color: $red;
        border-radius: 4px;
        text-transform: uppercase;
        @include get-font($f12, $fw-sb, $white, 1.5);
      }

      &__title {
        @include get-font($f18, $fw-sb, $black, 1.5);
      }
      &__close {
        margin-left: auto;
        width: 16px;
      }
    }
    &__text {
      margin-top: 12px;
      text-align: left;
      @include get-font($f16, $fw-r, $black, 1.5);
    }
    &__footer {
      margin-top: 24px;
      display: flex;

      &__next {
        margin-left: auto;
        padding: 4px 12px;
        background-color: $black;
        border: 0px;
        border-radius: 4px;
        text-transform: uppercase;
        @include get-font($f14, $fw-sb, $white, 1.5);
      }
    }
  }
}

.landingmobile {
  &__footer {
    width: 100%;
    margin-top: 24px;

    &__button {
      display: flex;
      text-decoration: none;
      padding: 12px;
      background-color: $superLightBlack;
      @include get-font($f14, $fw-m, $black, 1.5);
      border: 0px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      &__icon {
        margin-left: 12px;
      }
    }

    &__copy {
      margin-top: 24px;
      margin-bottom: 24px;
      text-align: center;
      @include get-font($f14, $fw-r, $lightestGray, 1.5);
    }
  }
}
