.create-video-container {
    width: 100%;
    background: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    video {
        max-width: 100%;
        width: auto;
        height: auto;
    }
    &__remove-btn {
        @extend %appearance-none;
        width: 24px;
        height: 24px;
        border-radius: 14px;
        background-color: $lightBlack;
        border: 0;
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &__icon {
            width: 12px;
        }
    }
}