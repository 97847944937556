.pr-manage {
    &__roles {
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            &__item {
                margin-top: 24px;
                padding-bottom: 24px;
                border-bottom: 1px solid $grayBackground;
            }
            &__title {
                @include get-font($f12, $fw-b, $black, $lh);
                letter-spacing: 0.6px;
                text-transform: uppercase;
            }
            &__info {
                margin-top: 8px;
                @include get-font($f14, $fw-r, $lightBlack, $lh);
            }
        }
        &__profiles {
            &__list {
                margin: 0;
                padding: 0;
                list-style: none;
                &__item {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 16px;
                }
                &__icon {
                    width: 36px;
                    height: 36px;
                    border-radius: 36px;
                    display: block;
                    border: 0.5px solid $grayBackground;
                    margin-right: 8px;
                }
                &__link {
                    @include get-font($f14, $fw-m, $black, $lh);
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                }
                &__remove-btn {
                    @extend %appearance-none;
                    border: 0;
                    background: transparent;
                    @include get-font($f12, $fw-b, $removeRed, $lh);
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    cursor: pointer;
                    &--disabled {
                        opacity: 0.6;
                        pointer-events: none;
                    }
                }
            }
            &__blank {
                @include get-font($f14, $fw-r, $lightBlack, $lh);
                margin-top: 8px;
            }
        }
        &__search-input {
            margin-top: 16px;
            &__control {
                height: 36px !important;
                border-radius: 10px !important;
                background-color: $grayBackground !important;
                border: 0 !important;
                &--is-focused {
                    border: 0 !important;
                    box-shadow: none !important;
                }
            }
            &__indicator {
                display: none !important;
            }
            &__placeholder {
                @include get-font($f14, $fw-r, $lighterBlack, $lh);
            }
        }
    }
}

@media only screen and (max-width: $onlyMobile) {
    .pr-manage {
        &__roles {
            &__modal {
                .default-modal__container{
                    width: 100%;
                    top: 15%;
                }
            }
        }
    }
    
  }