.helper-message-modal {
    .default-modal__header {
        margin-bottom: 8px;
        @include get-font($f22, $fw-b, $mediumBlack, $lh);
    }
    &__content {
        padding-bottom: 20px;
        &__primary {
            white-space: pre-line;
            @include get-font($f16, $fw-r, $black, $lh);
            margin-top: 30px;
            &--secondary {
                font-size: $f14;
            }
        }
        .review-page__suggestions__arrow {
            top: 50%;
        }
        .slick-slider{
            .slick-dots{
              bottom: 32px;
              width: fit-content;
              left: 50%;
              transform: translateX(-50%);
              li{
                button{
                  &::before{
                    content: none;
                  }
                  &::before{
                    content: "";
                    opacity: 1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 4px;
                    width: 12px;
                    border-radius: 100px;
                    padding: 0;
                    background-color: $copiedBackground;
                    border: 0.5px solid #747474;
                  }
                }
                &.slick-active{
                  button{
                    &::before{
                      background-color: $white;
                    }
                  }
                }
              }
            }
            .suggestions__arrow{
              top: 50%;
              translate: 0,-50%;
              &--prev{
                margin-left: 16px;
              }
              &--next{
                margin-right: 16px;
              }
            }
            .slick-list{
              border-radius: 8px;
            }
          }
        .react-player__video{
          margin-top: 0px;
        }
    }
    &__image {
        width: auto;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    &__footer {
        display: flex;
        justify-content: flex-end;
        border-top: solid 0.5px $superLightBlack;
        width: calc(100% + 48px);
        margin-left: -24px;
        padding: 12px 24px;
        box-sizing: border-box;
        margin-bottom: -24px;
        &--multi {
            justify-content: space-between;
        }
        &__checkbox {
            @include get-font($f14, $fw-r, $lightBlack);
            cursor: pointer;
            display: flex;
            align-items: center;
            &--disabled {
                cursor: wait;
                opacity: 0.65;
            }
            > img {
                width: 20px;
                height: 20px;
                margin-right: 12px;
                vertical-align: top;
            }
            > input[type="checkbox"] {
                position: absolute;
                opacity: 0;
            }
        }
        &__action-btn {
            border-radius: 7px;
            padding: 5px 12px;
            @include get-font($f14, $fw-b, $black, $lh);
            @include transitionEase(#{background});
            background: $darkYellow;
            border:0;
            cursor: pointer;
        }
    }
}