.passbook {
  &__modal {
    &__width {
      .default-modal__container {
        width: 500px;
        @media only screen and (max-width: $onlyMobile) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        @media only screen and (max-width: $centerColumnWidth) {
          width: 90%;
      }
      }

      .default-modal__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: -20px;
      }
    }
    &__row1 {
      text-align: center;

      &__icon {
        width: 134px;
        height: 96px;
      }
      &__image {
        width: auto;
      }
      &__amount {
        margin-top: 12px;
        @include get-font($f18, $fw-b, $black, $lh);
      }
      &__title {
        @include get-font($f18, $fw-b, $black, $lh);
      }
      &__subtitle {
        margin-top: 8px;
        @include get-font($f14, $fw-m, $lightestGray, $lh);
      }
      &__hr {
        margin-top: 16px;
        background-color: $lightGray;
      }
    }
    &__row2 {
      text-align: left;
      margin-top: 24px;
      &__datetype {
        @include get-font($f12, $fw-b, $lightestGray, $lh);
      }
      &__titlediv {
        display: flex;
        width: 100%;
        &__text {
          margin-top: 8px;
          text-decoration: none;
          @include get-font($f16, $fw-m, $blue, $lh);
        }
        &__icon {
          width: 20px;
          height: 20px;
          margin-top: 8px;
          margin-left: 16px;
          padding-top: 2px;
        }
      }
      &__transactionid {
        margin-top: 12px;
        @include get-font($f14, $fw-b, $black, $lh);
        text-transform: uppercase;
      }
      &__status {
        margin-top: 8px;

        &__text {
          @include get-font($f14, $fw-m, $lightestGray, $lh);
        }
        &__type {
          @include get-font($f14, $fw-m, $green, $lh);
        }
        &__charity_name {
          @include get-font($f14, $fw-sb, $black, $lh);
        }

        &__redeem {
          color: $white;
          background-color: $orange;
          padding: 2px 3px;
          border-radius: 4px;
        }
      }
    }

    &__row3 {
      margin-top: 24px;

      &__button {
        width: 100%;
        padding: 12px 0;
        background-color: $white;
        border: solid 0.5px $superLightBlack;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 $superLightBlack;
        @include get-font($f14, $fw-sb, $black, $lh);

        &__text {
          @include get-font($f14, $fw-m, $black, $lh);
        }
      }

      &__knowmorebutton {
        width: 100%;
        padding: 12px 0;
        background-color: $white;
        border: solid 0.5px $blue;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 $superLightBlack;
        @include get-font($f14, $fw-sb, $blue, $lh);
      }
    }
    &__footer {
      margin-top: 24px;

      &__redeembutton {
        background-color: $orange;
        @include get-font($f14, $fw-m, $white, $lh);
        border-radius: 8px;
        border: 0px;
        padding: 6px 12px 5px;
        text-decoration: none;
      }
    }

    &__concern {
      &__title {
        @include get-font($f14, $fw-r, $lightestGray, $lh);
        margin-bottom: 24px;
        margin-top: 28px;
      }
      &__formtitle {
        background-color: $lightGray;
      }
      &__textarea {
        background-color: $lightGray;
        height: 176px;
      }
      &__submitbutton {
        background-color: $black;
        @include get-font($f14, $fw-m, $white, $lh);
        border-radius: 8px;
        border: 0px;
        padding: 6px 12px 5px;

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed !important;
        }
        &__disabled {
          opacity: 0.6;
          cursor: not-allowed !important;
        }
      }
    }
  }

  &__enrolusermodal {
    &__width {
      .default-modal__container {
        width: 400px;
      }
      .default-modal__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: -30px;
      }
    }

    &__row3 {
      margin-top: 16px;
      overflow-y: auto;
      max-height: 310px;

      &__block {
        margin-top: 16px;
      }
      &__title {
        @include get-font($f12, $fw-b, $blue, $lh);
      }
      &__header {
        margin-top: 8px;
        padding-left: 24px;
        &__text {
          @include get-font($f14, $fw-m, $black, $lh);
        }
      }
    }

    &__row4 {
      margin-top: 24px;

      &__button {
        width: 100%;
        padding: 12px 0;
        background-color: $blue;
        border: 0px;
        border-radius: 8px;
        @include get-font($f14, $fw-sb, $white, $lh);

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed !important;
        }
      }
    }
  }

  &__beforereviewmodal {
    &__width {
      .default-modal__container {
        width: 440px;
      }
      .default-modal__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: -30px;
      }
    }

    &__row2 {
      text-align: left;
      margin-top: 24px;

      &__title {
        margin-top: 16px;
        text-align: center;
        @include get-font($f16, $fw-m, $black, $lh);
      }
    }

    &__row3 {
      overflow-y: auto;
      max-height: 310px;

      &__block {
        margin-top: 16px;
      }
      &__survey__title {
        @include get-font($f12, $fw-b, $green, $lh);
      }
      &__collaboration__title {
        @include get-font($f12, $fw-b, $yellow, $lh);
      }

      &__header {
        margin-top: 8px;
        padding-left: 24px;
        &__text {
          @include get-font($f14, $fw-m, $black, $lh);
        }
      }
    }

    &__termscondition {
      margin-top: 24px;
    }
    &__row4 {
      margin-top: 16px;

      &__survey__button {
        width: 100%;
        padding: 12px 0;
        background-color: $green;
        border: 0px;
        border-radius: 8px;
        @include get-font($f14, $fw-sb, $white, $lh);

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed !important;
        }
      }
      &__collaboration__button {
        width: 100%;
        padding: 12px 0;
        background-color: $yellow;
        border: 0px;
        border-radius: 8px;
        @include get-font($f14, $fw-sb, $black, $lh);

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed !important;
        }
      }
    }
  }
  &__afterreviewmodal {
    &__row2 {
      text-align: left;
      margin-top: 24px;

      &__margintop {
        margin-top: 48px;
      }

      &__title {
        margin-top: 16px;
        text-align: center;
        @include get-font($f14, $fw-m, $black, $lh);
      }
    }
  }
}
