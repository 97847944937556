.feed-placeholder {
    padding: 16px;
    box-sizing: border-box;
    &__user {
        &__container {
            display: flex;
            align-items: center;
        }
        &__avatar {
            width: 40px;
            height: 40px;
            background: $grayBackground;
            border-radius: 40px;
        }
        &__info {
            width: 100px;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: 10px;
            .placeholder__block {
                &:last-child {
                    margin-top: 8px;
                }
            }
        }
    }
    &__content {
        margin-top: 16px;
        > div {
            margin-top: 8px;
            display: flex;
            margin-right: 8px;
            flex: 6;
            flex-grow: grow;
            width: 60%;
            .placeholder__block {
                flex: 1;
                margin-right: 10px;
                &:nth-child(even) {
                    flex: 3;
                }
            }
        }
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        > div {
            display: flex;
            margin-right: 10px;
            flex: 0.4;
            &:last-child {
                margin-right: 0;
                flex: 0.45;
            }
            .placeholder__block {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}