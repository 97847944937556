.hashtag {
    &__container {
        width: 100%;
        max-width: 888px;
        margin-left: auto;
        padding: 64px 0;
        margin-right: auto;
    }
    &__posts {
        display: flex;
        justify-content: space-between;
    }
    &__search {
        margin-bottom: 32px;
        position: relative;
        &__input {
            max-width: 572px;
            width: 100%;
            border: 3px solid $grayBackground;
            border-radius: 32px;
            padding: 12px 16px;
            max-height: 54px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            > input {
                @include get-font($f20, $fw-sb, $black, $lh);
                flex: 1;
                box-sizing: border-box;
                border: 0;
                background: transparent;
                outline: 0;
                height: 100%;
            }
            &__glass {
                margin-right: 12px;
            }
            &__cancel {
                margin-left: 12px;
                cursor: pointer;
                border: 0;
                padding: 0;
                background: transparent;
                cursor: pointer;
                outline: none;
                width: 20px;
            }
        }
    }
    &__main {
        width: 100%;
        max-width: 572px;
        margin-right: 16px;
    }
    &__secondary {
        width: 100%;
        max-width: 300px;
    }
}

@media only screen and (max-width: $onlyMobile) {
    .hashtag {
        &__container {
            padding: 32px 0;
        }
        &__search {
            margin-bottom: 24px;
            &__input {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                &__cancel {
                    margin-left: 4px;
                }
            }
        }
        &__main {
            margin-left: auto;
            margin-right: auto;
            padding-left: 16px;
            padding-right: 16px;
            box-sizing: border-box;
        }
    }
}