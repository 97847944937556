.notification {
  max-width: $centeredContainer;
  width: 100%;
  margin: 0 auto;
  padding-top: 98px;
  @media only screen and (max-width: $tab) {
    padding: 16px 16px 32px;
    box-sizing: border-box;
  }
  &__sub-title {
     @include get-font($f14, $fw-r, $black, $lh);
  }
  &__profile-icon {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    object-fit: cover;
    border: 0.5px solid $grayBackground;
    margin-right: 8px;
    box-sizing: border-box;
    flex-shrink: unset;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    &__loader {
      @include get-font($f14, $fw-m, $black, $lh);
      margin: 0 16px;
      @media only screen and (max-width: $onlyMobile) {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
    &__container {
      border-radius: 10px;
      @extend %border-box-shadow;
      background: $white;
      padding: 16px 0;
      overflow: hidden;
      margin-bottom: 128px;
      @media only screen and (max-width: $onlyMobile) {
        padding: 0;
      }
    }
    &__item {
      display: block;
      border-left: 4px solid transparent;
      min-height: 74px;
      padding: 10px 16px;
      box-sizing: border-box;
      text-decoration: none;
      &--unread {
        background: $grayBackground;
        border-left-color: $red;
      }
      &--invitation{
        background-color: #FFECF0;
      }
    }
  }
  &__action {
    &__container {
      margin-top: 10px;
    }
    &__btn {
      cursor: pointer;
      @extend %appearance-none;
      background: transparent;
      border: 0;
      padding: 0;
      @include get-font($f12, $fw-b, $red, $lh);
      letter-spacing: 0.6px;
      &__icon {
        margin-right: 6px;
      }
      &--following {
        font-size: $f14;
        font-weight: $fw-r;
        color: $black;
      }
      &--poll {
        color: $green;
      }
    }
  }
  &__image {
    min-width: 64px;
    height: 64px;
    border: 1px solid $superLightBlack;
    border-radius: 5px;
    object-fit: contain;
    flex-shrink: unset;
    margin-left: 10px;
  }
  &__details {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    &__container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  &__title {
    @include get-font($f14, $fw-r, $black, $lh);
    flex: 1;
    &--helper {
      @include get-font($f12, $fw-r, $lightBlack);
      display: inline-block;
      margin-left: 2px;
    }
    &--secondary {
      font-weight: $fw-sb;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    @media only screen and (max-width: $onlyMobile) {
      display: block;
    }
    &__title {
      @include get-font($f32, $fw-sb, $black);
      @media only screen and (max-width: $onlyMobile) {
        font-size: $f18;
        margin-bottom: 4px;
      }
    }
    &__action {
      &__btn {
        @extend %appearance-none;
        background: transparent;
        text-decoration: none;
        display: inline;
        padding: 0;
        border: 0;
        margin: 0 4px;
        @include get-font($f14, $fw-m, $blue, $lh);
        &:first-child {
          margin-left: 0;
        }
        &--disabled {
          opacity: 0.65;
          cursor: not-allowed;
        }
      }
    }
  }
}
