.close-collab-modal {
    .default-modal__header {
        margin-bottom: 8px;
    }
    &__helper {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        > li {
            margin-top: 16px;
        }
        input {
            margin-right: 10px;
        }
        span {
            @include get-font($f16, $fw-m, $black, $lh);
        }
    }
    textarea {
        margin-top: 16px;
        width: 100%;
        height: 80px;
        background: $grayBackground;
        border-radius: 10px;
        border: 0;
        @include get-font($f16, $fw-m, $black, $lh);
        resize: none;
        padding: 16px;
        box-sizing: border-box;
    }
    .default-modal__footer {
        margin-top: 48px;
        width: calc(100% + 48px);
        padding: 16px;
        margin-left: -24px;
        margin-bottom: -24px;
        box-sizing: border-box;
        border-top: 1px solid $grayBackground;
    }
}