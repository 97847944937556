.survey {
  &__form {
    .template-review-header{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 20px;
      .title {
        @include get-font($f22, $fw-sb, $black, $lh);

      }
      .subtitle {
        @include get-font($f16, $fw-r, $copiedBackground, $lh);
      }
      .btn {
        @include get-font($f14, $fw-m , $green, $lh);
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid #00A146;
        display: flex;
        align-items: center;
        gap: 12px;
        background-color: $white;
        cursor: pointer;
        min-width: fit-content;
        width: fit-content;
        margin-top: 12px;
        &.fill{
          background-color: #00A146;
        }
        &.text{
          color: $white;
        }
      }
    }
    &__surveyCopyComplete {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: 80vh;
      padding: 12px 16px;
      background: #171717;
      border-radius: 8px;
      width: auto;
      left: 33%;
      &__text {
        @include get-font($f14, $fw-m, $white);
      }
      &__btn {
        @extend %appearance-none;
        border: 0;
        background: transparent;
        cursor: pointer;
        @include get-font($f14, $fw-m, $white);
        margin-left: 24px;
        border-left: 1px solid white;
        padding-left: 12px;
        text-decoration: none;
      }
    }
    &__surveyTextarea {
      border-radius: 8px !important;
      &::placeholder {
        opacity: 1;
        @include get-font($f16, $fw-r, $lightGrey, $f24);
      }
      @include getDefaultScrollbar();
    }
    &__copySurveyModal {
      display: flex;
      height: 48px;
      align-items: center;
      padding: 12px 18px;
      position: relative;
      &--selectImg {
        margin-right: 18px;
      }
      &--profileImg {
        width: 40px !important;
        height: 40px !important;
        margin-right: 12px !important;
      }
      & + &::after {
        content: " ";
        position: absolute;
        height: 0.5px;
        background: #f0f0f0;
        width: calc(100% + 50px);
        left: -25px;
        top: 0;
      }
      &--containerClassName {
        width: 448px;
      }
      .user-create-block__name{
        & > span{
            display: flex;
            align-items: center;
            max-width: 100%;
            > span:first-child{
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 310px;
            }
            > span:last-child{
                display: flex;
            }
        }
      }
    }
    &__details {
      background: $white;
      padding: 24px 24px;
      box-sizing: border-box;
      @include form-input-border-shadow();
      margin-top: 24px;
      border-radius: 10px;
      .collaboration-form__group {
        margin-bottom: 24px;
      }
      .collaboration-form__input-container--media {
        border: 0;
        padding: 0;
        box-shadow: none;
      }
      .collaboration-form__input-container__media-group {
        margin-bottom: 24px;
      }
      .tox-edit-area__iframe {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .survey__details__description {
        margin-top: 12px;
        font-size: $f14;
        font-weight: $fw-m;
      }
      &__action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-top: 16px;
        &::before {
          content: " ";
          position: absolute;
          height: 1px;
          background: #ededed;
          width: calc(100% + 48px);
          left: -26px;
          top: 0;
        }
        &__btn {
          border: 0;
          height: 40px;
          padding: 0 16px;
          line-height: 40px;
          @include get-font($f16, $fw-sb, $white);
          @include transitionEase();
          cursor: pointer;
          border-radius: 10px;
          outline: none;
          &--primary {
            background: $green;
            &:hover {
              background: $darkGreen;
            }
          }
          &--secondary {
            padding: 0;
            background: transparent;
            color: $removeRed;
            &:hover {
              color: $red;
            }
          }
          &--disabled {
            cursor: not-allowed;
            opacity: 0.65;
          }
        }
      }
    }
    &__add-question {
      width: 100%;
      margin-top: 40px;
      height: 56px;
      line-height: 56px;
      border-radius: 10px;
      border: 1px solid $green;
      background: $white;
      text-align: center;
      @extend %appearance-none;
      @include get-font($f18, $fw-sb, $green);
      cursor: pointer;
      img {
        margin-right: 16px;
        display: inline-block;
        vertical-align: text-bottom;
      }
      &--inline {
        > span {
          background: rgb(241, 241, 241);
          position: relative;
          z-index: 1;
          padding: 0 8px;
          img {
            width: 20px;
            height: auto;
            margin-right: 8px;
          }
        }
        @include transitionEase();
        display: none;
        font-size: $f16;
        font-weight: $fw-m;
        height: auto;
        background: transparent;
        border-radius: unset;
        border: 0;
        padding: 0;
        margin-top: 24px;
        position: relative;
        &:after {
          content: "";
          width: 80%;
          height: 1px;
          background: $green;
          @include centerItem();
        }
      }
    }
    &__modal-overflow {
      max-height: 72vh;
      overflow: auto;
      overflow-x: hidden;
      padding-right: 16px;
      width: 100%;
      scrollbar-width: thin;
      scrollbar-color: #ffffff #ffffff;
      &:active,
      &:focus,
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: #d1d1d1;
          transition: 0.5s ease-in-out;
        }
        scrollbar-color: #d1d1d1 #ffffff;
      }
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

    }
    &__edit-btn {
      @extend %appearance-none;
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0;
      cursor: pointer;
      margin-left: 16px;
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
    &__toggle {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &__title {
        @include get-font($f14, $fw-m, $green);
        &.demography {
          color: $black;
        }
      }
      &__description {
        @include get-font($f14, $fw-r, $lightBlack);
        margin-top: 12px;
      }
      .switch__on {
        background: $lighterGreen;
      }
      .switch__input:checked + .switch__btn {
        background: $green;
      }
      &__container {
        .collaboration-form__image-container{
          width: unset;
          margin-top: 16px;
          height: 192px;
          aspect-ratio: 16/9;
        }
        .collaboration-form__video-container {
          margin-top: 16px;
        }
        .error-text {
          position: relative;
          margin-top: 0;
        }
      }
      &__image-helper {
        @include get-font($f14, $fw-r, $lightBlack);
        margin-top: 8px;
      }
      .collaboration-form__outlets__city__select__control {
        min-width: 100px;
        border: solid 1px $superLightBlack !important;
        box-shadow: 0 1px 4px 0 $superLightBlack !important;
        background-color: $white !important;
      }
    }
    &__copiedParent {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      justify-content: space-between;
    }
    &__copied {
      @include get-font($f12, $fw-r, $white, $lhxs);
      background-color: $copiedBackground;
      padding: 6px 12px;
      border-radius: 4px;
      height: fit-content;
      animation: hideAnimation 0s ease-in 5s;
      animation-fill-mode: forwards;
    }
    @keyframes hideAnimation {
      to {
        visibility: hidden;
        width: 0;
        height: 0;
      }
    }
    &__question-image {
      &__container {
        width: 120px;
        height: 120px;
        position: relative;
      }
    }
    &__question-details{
      .scrollable-modal__header{
        align-items: flex-start;
      }
    }
    &__rangequestion1 {
      display: flex;
      margin-top: 24px;
      align-items: center;
      justify-content: center;
      &__dropdown {
        display: flex;
        &__to {
          margin-top: 10px;
          margin-left: 12px;
          margin-right: 8px;
          @include get-font($f14, $fw-sb, $lightestGray);
          opacity: 0.7;
        }
      }
      &__option {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &__title {
          min-width: 70px;
          @include get-font($f16, $fw-r, $black, $lh);
        }
        &__dropdown {
          width: 15%;
          margin-left: 4px;
          &__control {
            border-radius: 10px !important;
            border: 0.5px solid rgba(0, 0, 0, 0.1);

            &--is-focused{
              border-color: rgb(204, 204, 204) !important;
              box-shadow: none !important;
            }

            .selected-value{
              img{
                width:20px;
              }
              display: flex;
              align-items: center;
              gap: 10px;
              @include get-font($f14, $fw-r, $black, $lh);
              b{
                font-weight: 500;
              }
            }
          }
          &__indicator {
            color: rgb(102, 102, 102);
            display: none;
            padding: 6px !important;
          }
          &__placeholder {
          top: 44% !important;
          }

          &__option {
            display: flex;
            padding: auto;
            padding: 6px 10px;
            text-align: center;
            &:hover{
              background-color: $backgroundGray;
            }

            &__radiobutton {
              margin-right: 8px;
              margin-left: 6px;
              width: 20px;
            }
            &__textdiv {
                @include get-font($f14, $fw-r, $black, $lh);
                text-align: center;
                b{
                  font-weight: 500;
                }
            }

            &:hover {
              cursor: pointer;
            }
          }
          &__menu{
            box-shadow: 0px 6px 10px 3px rgba(23, 23, 23, 0.1) !important;
            z-index: 13 !important;
            width: 13%;
            &-list{
              max-height: 300px !important;
              @include getDefaultScrollbar();
            }
          }
        }
      }
    }
    &__footer {
      .profile-edit-modal__btn--secondary {
        padding: 0;
      }
      .profile-edit-modal__btn--primary {
        background: $green;
        &:hover {
          background: $darkGreen;
        }
      }
    }
    &__choices {
      margin-top: 16px;
      &__empty {
        min-width: 24px;
      }
      &__remove {
        @extend %appearance-none;
        border: 0;
        padding: 0;
        background-color: $white;
        cursor: pointer;
        margin-left: 8px;
      }
      &__add-more {
        @extend %appearance-none;
        border: 0;
        padding: 0;
        background: $white;
        @include get-font($f16, $fw-sb, $blue);
        margin: 24px 0;
        cursor: pointer;
      }
      &__container {
        margin-top: 24px;
        border-top: 1px solid $superLightBlack;
      }
      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      &__title {
        @include get-font($f14, $fw-m, $black);
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--secondary {
          font-weight: $fw-r;
          color: $lightBlack;
        }
      }
      &__input {
        border: 0;
        @include get-font($f14, $fw-r, $black);
        flex: 1;
        outline: none;
        &__container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &__box {
          @include form-input-border-shadow();
          width: 100%;
          max-width: 494px;
          border-radius: 10px;
          padding: 12px 16px;
          box-sizing: border-box;
          display: flex;
          outline: none;
          &:focus,
          &:active{
            border-color: $black;

            + .drag-icon{
              opacity: 1;
            }
          }
          &:hover{
            + .drag-icon{
              opacity: 1;
            }
          }
          &--container{
            position: relative;
            width: 100%;
            > .drag-icon{
              position: absolute;
              top: 50%;
              left: 10px;
              transform: translateY(-50%);
              cursor: grab;
              opacity: .2;
              transition: opacity .4s;
              &:disabled,
              &.disabled{
                cursor: not-allowed;
              }
            }
            > input{
              padding-left: 45px;
            }
          }
        }
        &__img {
          position: relative;
          cursor: pointer;
          > img {
            width: 20px;
            height: auto;
          }
          > input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
          }
        }
      }
      .error-text {
        position: relative;
      }
      &__image {
        &__container {
          width: 156px;
          height: 120px;
          border: 1px solid $superLightBlack;
          background: $grayBackground;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        width: 156px;
        height: 120px;
        object-fit: contain;
        &__remove {
          @extend %appearance-none;
          width: 24px;
          height: 24px;
          padding: 6px;
          border-radius: 14px;
          background-color: $lightBlack;
          border: 0;
          z-index: 1;
          position: absolute;
          top: 8px;
          right: 8px;
          cursor: pointer;
          > img {
            width: 12px;
          }
        }
      }
    }
    &__save {
      &__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 32px;
        margin-top: 32px;
        border-top: 1px solid $superLightBlack;
        &.certification{
          justify-content: flex-end;
        }
      }
      &__date-picker {
        outline: none;
        &__container {
          display: flex;
          align-items: center;
          padding: 16px;
          @media(max-width:360px){
            padding: 12px;
          }
          background: transparent;
          border: 1px solid $superLightBlack;
          border-radius: 16px;
        }
        &__icon {
          margin-right: 16px;
        }
        &__title {
          @include get-font($f12, $fw-b, $lightBlack, $lh);
          margin-bottom: 4px;
        }
        background: transparent;
        border: 0;
        @include get-font($f16, $fw-sb, $black, $lh);
      }
      &__btn {
        @extend %appearance-none;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        background: $green;
        padding: 16px;
        @media(max-width:360px){
          padding: 12px;
        }
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          background: $darkGreen;
        }
        &__details {
          text-align: right;
        }
        &__helper {
          @include get-font($f12, $fw-b, rgba($white, 0.6));
          margin-bottom: 4px;
        }
        &__title {
          @include get-font($f16, $fw-sb, $white, $lh);
        }
        > img {
          margin-left: 16px;
          height: 24px;
          width: auto;
        }
      }
      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        > li {
          height: 60px;
          border: 1px solid $green;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px;
          box-sizing: border-box;
          border-radius: 10px;
          margin-top: 16px;
          cursor: pointer;
          &:hover {
            background: $grayBackground;
          }
          > div {
            display: flex;
            align-items: center;
            > img {
              margin-right: 40px;
            }
            > p {
              @include get-font($f18, $fw-b, $black, $lh);
            }
          }
        }
        &__collab_border {
          border: 1px solid #4990E2 !important; 
        }
        &__tasting_border {
          border: 1px solid #fbb51f !important;
        }
      }
      &__type {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 32px;

        &__li {
          height: 60px;
          border: 0.5px solid $superLightBlack;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px;
          box-sizing: border-box;
          border-radius: 10px;
          margin-top: 16px;
          cursor: pointer;
          &:hover {
            background: $grayBackground;
          }
          &__selected {
            border: 0.5px solid $green;
          }

          &__div {
            display: flex;
            > img {
              margin-right: 12px;
            }
            &__text {
              &__title {
                @include get-font($f16, $fw-m, $black, $lh);
              }
              &__subtitle {
                @include get-font($f14, $fw-r, $lightestGray, $lh);
              }
            }
          }
        }
        &__publish-btn {
          @extend %appearance-none;
          height: 32px;
          border: 0;
          @include get-font($f14, $fw-sb, $white);
          padding: 0 12px;
          box-sizing: border-box;
          border-radius: 7px;
          cursor: pointer;
          @include transitionEase(#{background});
          margin-left: auto;
          background: $green;
          &:focus,
          &:hover,
          &:active {
            background: $mediumGreen;
          }
          &--disabled {
            opacity: 0.6;
            pointer-events: none;
          }
        }
      }
    }
    &__question-list {
      &__item{
        width:100%;
        padding-right: 16px;
        .item-group{
          margin-bottom: 24px;
          &.with-border{
            position: relative;
            padding-top: 8px;
            &::before{
              content: "";
              position: absolute;
              top: -8px;
              left: 50%;
              transform: translateX(-50%);
              height: 1px;
              width: calc(100% + 48px);
              background-color: $imageWrapperBackgroundGray;
            }
          }
          .form-label{
            @include get-font($f14,$fw-m,$copiedBackground,$lh);
            &--secondary{
              @include get-font($f14,$fw-r,$copiedBackground,$lh); 
            }
          }
          .form-input,
          .form-textarea{
            font-size: 16px;
            &::placeholder{
              color: $lightGrey;
              font-size: 16px;
            }
          }
          &.multi{
            border-bottom: 2px dotted $imageWrapperBackgroundGray;
            &:nth-last-child(1 of .multi) {
              border-bottom: none;
            }
          }
        }
        .option{
          &__list{
            &--header{
              @include get-font($f14,$fw-m,$copiedBackground,$lh);
              margin-bottom: 4px;
              .mandatory{
                color: $red;
              }
            }
            &--subheader{
              @include get-font($f12,$fw-r,$copiedBackground,$lh);
              margin-bottom: 4px;
            }
            &--item{
              &:not(:first-child){
                margin-top: 16px;
              }
              h6{
                margin: 0;
                text-align: right;
                @include get-font($f16,$fw-m,$copiedBackground,$lh);
                min-width: 20px;
              }
            }
          }
        }
      }
      .premium-modal__list__item {
        display: flex;
        padding: 16px;
        align-items: center;
      }
      .premium-modal__list__icon__container {
        width: auto;
        height: auto;
        margin-right: 16px;
      }
      .premium-modal__list__icon {
        width: 56px;
        height: 56px;
        border-radius: 8px;
      }
      .premium-modal__list__info {
        padding: 0;
        height: auto;
        width: auto;
      }
    }
    &__customdropdown {
      display: flex;
      width: 60px;
      padding: 5px 12px 5px 12px;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 $superLightBlack;
      border: solid 0.5px $superLightBlack;
      background-color: $white;

      &__select {
        width: 100%;
        border-radius: 10px;
        border: 0px;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;

        background-color: $white;
        background-image: url("assets/images/products/modal/chevron-down.svg");
        background-repeat: no-repeat;
        background-position: calc(100% - 0px) center;

        @include get-font($f16, $fw-r, $black, $lh);
        &:focus-visible {
          outline: none;
        }
      }
      &__chevron {
        margin-left: auto;
      }
    }

    &__rangequestion {
      &__dropdown {
        display: flex;
        &__to {
          margin-top: 10px;
          margin-left: 12px;
          margin-right: 12px;
          @include get-font($f12, $fw-sb, $grayText);
        }
      }
      &__rangelabels {
        margin-top: 36px;

        &__min {
          display: flex;
          width: 100%;
        }
        &__max {
          display: flex;
          width: 100%;
          margin-top: 16px;
        }
        &__label {
          margin-top: 12px;
          margin-right: 12px;
          width: 12px;
          @include get-font($f16, $fw-sb, $grayText);
        }
        &__input {
          outline: none;
          width: 100%;
          height: 24px;
          padding: 12px 12px;
          border-radius: 10px;
          background-color: $white;
          border: solid 1px $superLightBlack;
          box-shadow: 0 1px 4px 0 $superLightBlack;
          @include get-font($f12, $fw-r, $black);
        }
      }
      .dropdown{
        &__container{
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;
          margin-left: 3px;
          &--title{
            @include get-font($f12,$fw-b,$copiedBackground,$lh);
          }
          &--item{
            min-width: 88px;
          }

        }
      }
    }
    &__rankquestion {
      display: flex;
      &__text {
        display: flex;
        flex-direction: column;
        &__title {
          @include get-font($f14, $fw-m, $black, $lh);
        }
        &__subtitle {
          margin-top: 8px;
          @include get-font($f14, $fw-r, $lightestGray, $lh);
        }
      }
      &__dropdown {
        margin-left: auto;
      }
    }
    &__multiselectradio {
      display: flex;
      justify-content: space-between;
    }

    &__reorderModal{ 
      overflow-y: hidden;
      .section{
        &__wrapper{
          padding: 0 16px;

          .questions__list{
            padding: 0;
          }
        }
        &__item{
          margin-bottom: 16px;
        }
        &__title{
          border-bottom: 1px solid #CCECDA;
          .text{
            display: block;
            border-radius: 8px 8px 0 0;
            padding:4px 12px;
            @include get-font($f14,$fw-m,$green);
            background-color:#CCECDA;
            width: fit-content;
          }
        }
        &__move{
          padding: 0 16px 16px 16px;
          .questions__list--item{
            display: flex;
            align-items: flex-start;
            gap: 10px;
            cursor: pointer;
            &:first-child{
              padding-top: 0;
            }
            img{
              margin-top: 2px;
            }
            .info{
                h6{
                  margin: 0;
                  @include get-font($f14,$fw-m,$black);
                }
                p{
                  margin-top: 3px;
                  @include get-font($f12,$fw-r,$copiedBackground);
                }
            }
          }
        }
      }
      .questions__list{
        counter-reset: section;
        padding: 0 16px;
        &--item{
          padding: 12px 0;
          border-bottom: 1px solid #F2F2F2;
          background-color: $white;
          &__content{
            display: flex;
            align-items: center;
            gap: 12px;

            .title{
              display: flex;
              gap: 5px;
              align-items: flex-start;
              flex: 1;
              @include get-font($f14,$fw-m,$black);
              span{
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
              }
              &::before{
                counter-increment: section;
                content: ""counter(section)".";
              }
            }
            .drag-image{
              &.disabled{
                opacity: .5;
              }
            }
            .checkbox-img{
              padding-top: 3px;
              cursor: pointer;
            }

            
          }
        }
        &:not(:has(:first-child)){
          padding: 20px 0;
        }
        .dragging{
          .questions__list--item__content{
            padding: 12px 16px;
            background-color: $white;
          }
        }
      }
      .profile-edit-modal__btn--green.down-icon{
        &::after{
          content: "";
          display: inline-block;
          height: 15px;
          width: 15px;
          background: url("assets/images/survey/create/chevron-right.svg") no-repeat center center;
          background-size: contain;
          rotate: 90deg;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
    &__select{
      &__control{
        border-radius: 10px !important;
        min-height: 50px !important;
        padding: 2px 16px;
        border: none !important;
        color: #757575;
        @extend %border-box-shadow;
        &--is-focused {
            box-shadow: none !important;
            border: 1px solid $mediumBlack !important;
        }
      }
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .survey {
    &__form {
      .collaboration__header__text {
        font-size: $f22;
        font-weight: $fw-sb;
      }
      &__details {
        padding: 16px 12px;
      }
      .survey__form__save__date-picker__container {
        max-width: 166px;
        box-sizing: border-box;
      }
      .survey__form__save__btn {
        max-width: 166px;
        box-sizing: border-box;
        > img {
          display: none;
        }
      }
      &__question-list {
        .default-modal__backdrop{
          .default-modal__container{
            width: 100%;
          }
          .premium-modal__list__item{
            width: auto;
          }
        }
      }
      &__save__container{
        .default-modal {
          .default-modal__backdrop{
            .default-modal__container{
              width: 100%;
            }
          }
        }
      }
    }
  }
  .survey__details__content{
    padding: 16px;
  }
  .survey-form-mob{
    gap: 20px;
  }
}
@media(max-width:360px){
  .survey__form__save__container{
     gap: 20px;
  }
  .survey__form__save__date-picker__title{
    font-size: 10px;
  }
 .survey__form__save__date-picker{
font-size: 14px;
 }
 .survey__form__save__btn__title{
  font-size: 14px;
 }
 
}

@media(max-width:$smallMobile){
  .survey-form-mob{
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
  }
}


