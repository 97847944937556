@import "view.scss";
@import "detailsItem.scss";
@import "list.scss";

.all-certifications {
    background-color: $white;
    margin-top: 40px;

    .container {
        max-width: $maxWidthContainer;
        margin: 0 auto;

        &.relative {
            position: relative;
            z-index: 1;
        }
        &.all-certification{
            max-width: 1256px;
        }
    }

    .section {
        padding: 40px;

        @media screen and (max-width:$onlyMobile) {
            padding: 24px;
        }

        &.filled {
            background-color: #F5F8FA;

            &.rounded {
                border-radius: 0 0 100% 100%;
                height: 280px;

                @media only screen and (max-width: $onlyMobile) {
                    height: fit-content;
                    border-radius: unset;
                }
            }
        }

        .text-center {
            text-align: center;
        }

        .title {
            @include get-font($f48, $fw-b, $black, $lh);

            @media screen and (max-width:$onlyMobile) {
                font-size: $f28;
            }

            &.left {
                text-align: left;
            }
        }

        .subtitle {
            @include get-font($f16, $fw-r, $black, $lh);
            margin-top: 24px;

            @media screen and (max-width:$onlyMobile) {
                font-size: $f14;
            }
            ul{
                padding: 0;
                width: 17%;
                text-align: left;
                margin-left: 24px;
                width: fit-content;
            }
        }

        &-title {
            text-align: center;
            @include get-font($f32, $fw-sb, #1F2937, $lh);
            margin-bottom: 12px;

            @media screen and (max-width:$onlyMobile) {
                font-size: $f24;
            }
            &.left {
                text-align: left;
            }
        }

        &-subtitle {
            text-align: center;
            @include get-font($f18, $fw-r, $copiedBackground, $lh);

            @media screen and (max-width:$onlyMobile) {
                font-size: $f14;
            }
            &.left {
                text-align: left;
            }
        }

        .list-container {

            margin: 0;
            padding: 0;
            margin-top: 8px;
            margin-left: 16px;

            &__item {
                @include get-font($f18, $fw-r, $black, $lh);
                margin-top: 24px;
                margin-bottom: 24px;

                >span {
                    &:first-child {
                        font-weight: 600;
                    }
                }

                >span {
                    &:last-child {
                        font-weight: 400;
                    }
                }
            }

        }

        .steps-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            margin-top: 32px;
            justify-content: space-between;
            @media screen and (max-width: $onlyMobile) {
                flex-direction: column;
            }
            &__item {
                padding: 24px;
                border-radius: 24px;
                border: 1px solid #EDEDED;
                min-height: 228px;
                box-sizing: border-box;
                flex: 1;
                // text-align: center;

                .image {
                    text-align: center;
                    margin-bottom: 12px;

                    >img {
                        height: auto;
                        width: 32px;
                    }

                }

                .step-text {
                    @include get-font($f14, $fw-b, $copiedBackground, $lh);
                    text-align: center
                }

                .title {
                    @include get-font($f18, $fw-b, #1F2937, $lh);
                    margin-top: 8px;
                    @include limit-text-to-line(1);
                    text-align: center;
                }

                .list-item {
                    margin-top: 16px;
                    @include get-font($f16, $fw-r, #767676, $lh);
                }

            }
        }

        &__item {
            .section__item--title {
                color: #171717;
                font-size: 32px;
                font-weight: 600;
                line-height: 48px;
                margin: 0;
            }
        }
    }

    .benefits__description{
        display: inline;
        a{
            font-size: inherit;
            color: #4990e2;
            font-weight: inherit;
            line-height: 1.5;
            text-decoration: none;
            cursor: pointer; 
        }

    }

    .faq-list {
        margin: 24px 0;

        &__item {
            padding: 16px;
            border-radius: 8px;
            border-bottom: 1px solid rgba(245, 248, 250, 1);

            &:hover {
                background-color: rgba(245, 248, 250, .8);
            }


            &--action {
                display: flex;
                align-items: flex-start;
                width: 100%;
                justify-content: space-between;
                gap: 4px;
                @include get-font($f16, $fw-sb, $black, $lh);

                @media screen and (max-width:$onlyMobile) {
                    font-size: $f14;
                }
            }

            &--info {
                margin-top: 16px;
                @include get-font($f16, $fw-r, $black, $lh);

                @media screen and (max-width:$onlyMobile) {
                    font-size: $f14;
                }

                li {
                    margin-top: 12px;
                }

                .semi-bold-link {
                    color: $black;
                }
                a{
                    font-size: inherit;
                    color: #4990e2;
                    font-weight: inherit;
                    line-height: 1.5;
                    text-decoration: none;
                    cursor: pointer; 
                }

            }

            &.active {
                background-color: rgba(245, 248, 250, 1);

                .faq-list__item--action__icon {
                    rotate: 180deg;
                }
            }
        }
    }

    .skeleton__explore-loader {
        max-width: 1188px;
        margin: 0 auto;

        .skeleton-list{
            grid-template-columns: repeat(3, 1fr);
            .item {
                width: 372px;
                min-width: 372px;
    
                div {
                    &:first-child {
                        min-height: 232px;
                        height: 232px;
                    }
                }
            }
        } 
    }
}