.faqs {
    &__categories {
        &__container {
            width: 100%;
            max-width: 304px;
            flex-shrink: 0;
        }
        &__list {
            margin: 0;
            padding: 0;
            width: 100%;
            list-style: none;
            &__header {
                width: 100%;
                height: 40px;
                border-radius: 10px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border: 1px solid $blue;
                background: rgba($blue, 0.1);
                padding: 0 16px;
                box-sizing: border-box;
                position: relative;
                cursor: pointer;
                @include get-font($f14, $fw-m, $black);
                line-height: 40px;
                &--active {
                    background: $white;
                }
                img {
                    width: 12px;
                    height: auto;
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &__item {
                height: 40px;
                width: 100%;
                @include get-font($f14, $fw-r, $black, $lh);
                line-height: 40px;
                padding-left: 14px;
                border-left: 2px solid transparent;
                cursor: pointer;
                box-sizing: border-box;
                &--active, &:hover {
                    border-left-color: $blue;
                    background: rgba($blue, 0.1);
                    font-weight: $fw-m;
                }
            }
        }
    }
    &__questions {
        &__container {
            flex: 1;
            margin-left: 16px;
        }
        &__list {
            margin: 0;
            padding: 0;
            width: 100%;
            list-style: none;
            &__item {
                min-height: 40px;
                line-height: 40px;
                padding: 10px 16px;
                &:first-child {
                    padding-top: 0;
                }
                box-sizing: border-box;
                border-bottom: 1px solid $grayBackground;
                position: relative;
                cursor: pointer;
                &--active {
                    padding-bottom: 32px;
                    cursor: default;
                }
                &__title {
                    @include get-font($f16, $fw-sb, $black, $lh);
                    width: calc(100% - 32px);
                }
                &__answer {
                    @include get-font($f14, $fw-r, $black, $lh);
                    padding: 0 16px;
                    margin-top: 12px;
                    h1, h2, h3, h4, h5 {
                        font-size: $f16;
                        font-weight: $fw-sb;
                        margin: 0;
                    }
                    b, strong {
                        font-weight: $fw-sb;
                    }
                    a {
                        color: $blue;
                        text-decoration: none;
                    }
                }
                &__chevron {
                    width: 20px;
                    height: auto;
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    &__container {
        display: flex;
        width: 100%;
    }
    max-width: 1024px;
    margin: 66px auto 32px;
    width: 100%;
    min-height: calc(100vh - 190px);
    box-sizing: border-box;
    @media only screen and (max-width: $onlyMobile) {
        margin-top: 56px;
        padding: 0 16px;
    }
    &__header {
        padding: 24px 0;
        margin: 0;
        @include get-font($f24, $fw-sb, $black, $lh);
    }
}

@media only screen and (max-width: $onlyMobile) {
    .faqs {
        &__container {
            display: block;
        }
        &__categories {
            &__container {
                max-width: 100%;
                position: relative;
            }
            &__list {
                position: absolute;
                margin-top: 2px;
                background: $white;
                border: 1px solid $blue;
                box-sizing: border-box;
                z-index: 1;
                &__item {
                    &--active {
                        border-left: 0;
                    }
                }
            }
        }
        &__questions {
            &__container {
                margin-left: 0;
                margin-top: 12px;
            }
            &__list {
                &__item {
                    padding: 10px 8px;
                    &--active {
                        padding-bottom: 12px;
                    }
                    &__answer {
                        padding: 0;
                    }
                }
            }
        }
    }
}