.workshop {
  &__container {
    width: 100%;
    margin-left: auto;
    padding: 32px 0;
    margin-right: auto;
  }
  &__seeAll {
    background: $orange !important;
  }
  &__details {
    max-width: $centeredContainer;
    margin: 0 auto;
    width: 100%;
    background: $white;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 $superLightBlack;
    border: solid 0.5px $superLightBlack;
    box-sizing: border-box;
    &__content {
      padding: 32px 24px;
    }
    &--viewPage {
      margin-top: 24px !important;
    }
    .post-comments-list {
      margin-bottom: 0;
    }
    .post-comments-containers {
      max-width: 100%;
    }
    
    &__title {
      @include get-font($f22, $fw-sb, $black, $lh);
      word-break: break-word;
      box-sizing: border-box;
      &__container {
        margin-bottom: 32px;
      }
    }
    &__posted {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: $onlyMobile) {
        gap: 15px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__description {
      margin: 8px 0px;
      @include get-font($f14, $fw-m, $black, $lhOnboarding);
      word-break: break-word;
      white-space: pre-line;
      .linkified {
        font-size: inherit;
        color: $blue;
        text-decoration: none;
      }
      &--playable {
        @include get-font($f16, $fw-r, $black, $f24);
        margin: 8px 0px 0px 0px;
      }
    }
    &__video-container {
      margin-top: 24px;
      border-radius: 10px;
      background: $black;
      overflow: hidden;
    }
    &__date{
      @include get-font($f14,$fw-r,$lightBlack);
      margin: 4px 0;
    }
    &__link{
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      width: fit-content;
      border: 1px solid $teal;
      text-decoration: none;
      @include get-font($f14,$fw-m,$teal);
      border-radius: 8px;
      margin-top: 16px;
      &:visited{
        color: $teal;
      }
    }
  }
}
