.schedule{
    &__list{
        &-table{

            &--item{
                &__text{
                    &--header{
                        @include get-font($f12,$fw-b,$black);
                        text-transform: uppercase;
                        white-space:nowrap;
                        text-align: left;
                    }
                    @include get-font($f14,$fw-r,$black);
                    &.centered{
                        text-align: center;
                    }
                    &--row{
                        &.disabled{
                            .schedule__list-table--item__text,
                            .schedule__list-table--item__link{
                                pointer-events: none;
                                color: $copiedBackground;
                            }
                        }
                    }
                    &.city {
                            display: block;
                            text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                    }
                }
                &__link{
                    cursor: pointer;
                    text-decoration: none;
                    @include get-font($f14,$fw-r,$blue);
                    word-break: break-word;
                }
                &__image{
                    &-wrapper{
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img{
                            object-fit: cover;
                        }
                    }
                }
                &__action{
                    &--wrapper{
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;
                        padding: 0 4px;
                    }
                    cursor: pointer;
                }
            }
        }
    }
}