.profile-completion-onboarding {
    &__content {
        background: $white;
    }
    &__header {
        text-align: center;
        padding-top: 25px;
        margin-bottom: 16px;
        @media only screen and (max-width: $onlyMobile) {
            padding-top: 12px;
        }
        &__title {
            @include get-font($f28, $fw-sb, $black, $lh);
            margin-bottom: 8px;
            @media only screen and (max-width: $onlyMobile) {
                font-size: $f18;
            }
        }
        &__helper {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
        }
    }
    &__body {
        margin-bottom: 78px;
    }
    &__error {
        margin: 0 auto 32px;
        border: 1px solid rgba(238,49,57,0.2);
        background-color: rgba(239,51,59,0.05);
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 $superLightBlack;
        width: 100%;
        max-width: 436px;
        text-align: center;
        padding: 16px;
        box-sizing: border-box;
        @include get-font($f14, $fw-m, $red, $lh);
        @media only screen and (max-width: $onlyMobile) {
            width: 90%;
        }
    }
    &__list {
        width: 100%;
        max-width: 890px;
        margin: 0;
        padding: 0;
        margin: 0 auto 16px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-items: flex-start;
        &--job {
            max-width: 820px;
        }
        @media only screen and (max-width: $onlyMobile) {
            padding: 0 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &__item {
            width: 100%;
            //max-width: 300px;
            max-width: 438px;
            margin-right: 12px;
            margin-bottom: 12px;
            list-style: none;
            min-height: 120px;
            display: flex;
            //align-items: center;
            justify-content: flex-start;
            &--job {
                max-width: 400px;
            }
            @media only screen and (max-width: $onlyMobile) {
                margin-right: 0;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        &__label {
            display: flex;
            flex-wrap: wrap;
            //padding: 8px;
            padding: 8px 8px 8px 16px;
            box-sizing: border-box;
            width: 100%;
            border: 1px solid $superLightBlack;
            //box-shadow: 0 1px 4px 0 $superLightBlack;
            //box-shadow: 0 0 8px 0 $superLightBlack;
            border-radius: 5px;
            align-items: center;
            cursor: pointer;
        }
        &__title {
            @include get-font($f16, $fw-sb, $black, $lh);
        }
        &__description {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
            letter-spacing: -0.15px;
            margin-top: 4px;
        }
        &__info {
            display: flex;
            align-items: center;
            justify-content: center;
            &--specialization {
                align-items: flex-start;
                flex: 1;
                flex-direction: column;
            }
        }
        &__input {
            opacity: 0;
            position: absolute;
            &:checked + label {
                background: $activeYellowBg;
                border: 2px solid $activeYellow;
                border-radius: 10px;
                //border-color: $activeYellow;
            }
        }
        &__icon {
            width: 80px;
            height: 80px;
            object-fit: contain;
            border: 1px solid $grayBackground;
            margin-right: 20px;
            border-radius: 5px;
        }
    }
}

.onboarding-top-container {
    width: 100%;
    height: 64px;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 1;
    text-align: center;
    .tagtaste-logo {
        margin: 16px 0;
    }
}

.onboarding-footer-container {
    width: 100%;
    height: 68px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: $white;
    z-index: 1;
    box-shadow: 0 2px 8px 0 $superLightBlack;
    border-top: 1px solid $superLightBlack;
    @media only screen and (max-width: $onlyMobile) {
        position: relative;
        margin-top: 32px;
    }
    .onboarding-footer {
        max-width: 590px;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        display: flex;
        align-items: center;
        height: 100%;
        .arrow-container {
            border: 0;
            padding: 10px;
            box-sizing: border-box;
            background: $white;
            margin-right: 10px;
            cursor: pointer;
            outline: 0;
            .getting-started-next-btn {
                font-weight: 600;
                color: #FF1654;
                font-size: 14px;
                &.disabled {
                    color: #BDBDBD;
                }
            }
            .arrow {
                width: 12px;
                height: auto;
                margin-left: 5px;
                &.next {
                    margin-left: 5px;
                }
            }
        }

    }
}