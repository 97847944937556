.report-email-form {
    z-index: 1;
    &__subtitle {
        @include get-font($f14, $fw-sb, $lightBlack, $lh);
        margin-top: 16px;
    }
    &__title {
        margin-top: 6px;
        @include get-font($f16, $fw-sb, $black, $lh);
        text-align: center;
        > span, &--secondary {
            font-weight: $fw-r;
        }
        a {
            color: $blue;
            text-decoration: none;
        }
        &--small {
            font-size: $f14;
        }
    }
    .login-form__form__group {
        margin-bottom: 46px;
    }
    .login-form__container--secondary {
        padding: 48px !important;
    }
}

@media only screen and (max-width: $onlyMobile) {
    .report-email-form {
        position: relative;
        margin: 84px auto 64px;
        max-width: 440px;
        width: calc(100% - 32px);
        height: auto;
        .login-form__title {
            font-size: $f18;
        }
        .login-form__container {
            max-height: unset;
        }
        &__subtitle, .login-form__link {
            font-size: $f14;
        }
    }
}