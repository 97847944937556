$modalHelperBg: #333333;

.photo-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    min-height: -webkit-fill-available;
    &__container {
        width: 100%;
        height: 100%;
        position: relative;
        background: rgba(23,23,23,1);
        overflow: hidden;
        overflow-y: auto;
    }
    &__content {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
    &__img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        border-radius: 10px;
        display: block;
        padding: 16px;
        box-sizing: border-box;
        object-fit: contain;
        &__container {
            width: 80vw;
            height: 80vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border-radius: 10px;
        }
        &__btn {
            @extend %appearance-none;
            @extend %border-box-shadow;
            background: $white;
            padding: 0;
            width: 48px;
            height: 48px;
            border-radius: 24px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            z-index: 101;
            cursor: pointer;
            opacity: 0.95;
            @include transitionEase(#{opacity});
            display: flex;
            align-items: center;
            justify-content: center;
            &__img {
                width: auto;
                height: auto;
                max-height: 24px;
                max-width: 24px;
                display: block;
            }
            @media only screen and (max-width: $onlyMobile) {
                width: 32px;
                height: 32px;
                line-height: 36px;
                &__img {
                    width: auto;
                    height: auto;
                    max-height: 16px;
                    max-width: 16px;
                }
            }
            &:hover, &:focus, &:active {
                opacity: 1;
            }
            &--disabled {
                opacity: 0.65;
                pointer-events: none;
                &:hover, &:focus, &:active {
                    opacity: 0.65 !important;
                }
            }
            &--right {
                right: 32px;
                @media only screen and (max-width: $onlyMobile) {
                    right: 24px;
                }
            }
            &--left {
                left: 32px;
                @media only screen and (max-width: $onlyMobile) {
                    left: 24px;
                }
            }
        }
    }
    &__close {
        @extend %appearance-none;
        border: 0;
        background: transparent;
        position: fixed;
        right: 0;
        top: 0;
        margin-right: 40px;
        margin-top: 40px;
        cursor: pointer;
        &__icon {
            @media only screen and (max-width: $onlyMobile) {
                width: 16px;
                height: 16px;
            }
        }
        @media only screen and (max-width: $onlyMobile) {
            margin-top: 16px;
            margin-right: 16px;
        }
    }
    &__container {
        position: relative;
    }
    &__helper {
        @include get-font($f12, $fw-b, $lightBg, $lh);
        letter-spacing: 0.6px;
        background: $modalHelperBg;
        border-radius: 5px;
        padding: 4px 8px;
        display: inline-block;
        position: absolute;
        right: 0;
        margin-top: 16px;
        margin-right: 16px;
        box-sizing: border-box;
    }
    &__download {
        @include get-font($f16, $fw-sb, $white, $lh);
        background: $black;
        padding: 8px 24px;
        text-decoration: none;
        border-radius: 5px;
        &__container {
            margin-top: 22px;
            @media only screen and (max-width: $onlyMobile) {
                margin-top: 16px;
            }
            text-align: center;
            .remove{
                cursor: pointer;
                background-color: $removeRed;
                padding: 8px 16px;
                border-radius: 8px;
                @include get-font($f16, $fw-sb, $white, $lh);
            }
        }
    }
}