.show-interest-modal {
    >div:first-child{
      padding: 0px 0px 16px 0px !important;
    }
    &__content {
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;
    }
    &__disclaimer {
        margin: 40px 0 16px;
        @include get-font($f14, $fw-r, $lightBlack, $lh);
    }
    .post-text {
        margin-top: 25px;
        border-radius: 10px;
        resize: none;
        @include get-font($f16, $fw-r, $black, $lh);
        @extend %appearance-none;
        outline: none;
        height: 96px;
        box-sizing: border-box;
        width: 100%;
        background: $backgroundGray;
        border: none;
        padding: 16px;
    }
    &__footer {
        padding: 13px 0 0px 0;
        &__action-btn {
            @extend %appearance-none;
            background: $blue;
            padding: 6px 12px;
            border-radius: 7px;
            outline: 0;
            border: 0;
            cursor: pointer;
            &--disabled-new {
                opacity: 0.65;;
                cursor: not-allowed;
            }
            &--yellow {
                background: $yellow;
            }
            &--disabled {
                background: $lightestYellow!important;
                cursor: default!important;

            }
            &__text {
                @include get-font($f14, $fw-sb, $white, $lh);
            }
        }
    }
    &__checkbox {
        margin-top: 12px;
        @include get-font($f12, $fw-r, $black, $lh);
    }
}


.collab-contest-document-modal{
    &__content {
        padding-bottom: 24px;
    }
    &__uploaded-files {
        margin-top: 24px;
        &__title {
            @include get-font($f14, $fw-m, $black, $lh);
        }
        &__list {
            margin: 0;
            &__item {
                > a {
                    @include get-font($f14, $fw-m, $black, $lh);
                    text-decoration: none;
                    word-break: break-word;
                    > img {
                        margin-left: 12px;
                        vertical-align: middle;
                    }
                }
                margin-top: 8px;
                &--error {
                    color: $removeRed;
                    > a {
                        color: $removeRed;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: $smallMobile) {
    .show-interest-modal {
        .default-modal__container {
            width: 100%;
            top: 8%;
        }
    }
  }