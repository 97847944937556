.polls-seeall {
  &.detail-page {
    padding-top: 0px;
  }

  @media screen and (max-width: $onlyMobile) {
    .see-all-container {
      padding: 32px 0px;
    }
  }

  .see-all-container__action-btn {
    background: $green;

    @media screen and (max-width: $onlyMobile) {
    

      .see-all-container__action-btn__text {
        font-size: 14px;
      }
    }
  }

  &__switch {
    display: flex;

    &__text {
      margin-right: 16px;
      @include get-font($f14, $fw-m, $black, $lh);

      @media screen and (max-width: $onlyMobile) {
        font-size: 14px;
      }
    }
    .switch__on {
      background: $tastingGreen;
    }

    .switch__input:checked + .switch__btn {
      background: $green;
    }
  }

  &.see-all-container__search-input__container,
  &.see-all-container__search-input {
    width: 100%;
  }
}
