.hashtag {
  &__suggestions {
    position: absolute;
    width: 100%;
    max-width: 572px;
    @include form-input-border-shadow();
    background: $white;
    margin: 8px 0 0;
    border-radius: 32px;
    padding: 24px;
    box-sizing: border-box;
    z-index: 11;
    list-style: none;
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__link {
      @include get-font($f18, $fw-sb, $black, $lh);
      text-decoration: none;
      display: block;
    }
  }
  &__trending {
    @include form-input-border-shadow();
    background: $white;
    border-radius: 10px;
    width: 100%;
    max-width: 572px;
    &__list {
      list-style: none;
      margin: 0;
      padding: 0 16px;
      box-sizing: border-box;
      &__item {
        padding: 16px 0;
        border-bottom: 1px solid $grayBackground;
        &:last-child {
          border-bottom: 0;
        }
      }
      &__link {
        text-decoration: none;
        display: block;
        &--see-more {
          @include get-font($f12, $fw-b, $blue, $lh);
          letter-spacing: 0.6px;
          text-align: center;
        }
      }
      &__title {
        @include get-font($f16, $fw-sb, $black, $lh);
        margin-bottom: 2px;
      }
      &__helper {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
      }
    }
    &__title {
      @include get-font($f12, $fw-b, $lightBlack, $lh);
      margin-bottom: 12px;
      &--large {
        @include get-font($f18, 900, $black, $lh);
        margin-bottom: 24px;
      }
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .hashtag {
    &__suggestions {
      width: calc(100% - 32px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.landingpage-hashtag {
  .hashtag__trending__title {
    text-transform: uppercase;
    @include get-font($f12, $fw-b, $lightestGray, $lh);
  }
  .hashtag__trending__list__title {
    color: $blue;
  }
}
