.biz {
  &__sidemenu {
    width: 265px;
    box-sizing: border-box;
    &__logo {
      width: 84px;
      height: auto;
      margin: 24px 0 12px;
    }
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      &__container {
        background-color: $white;
        border-radius: 10px;
        overflow: hidden;
      }
      &__main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 16px 16px;
        cursor: pointer;
        > div {
          display: flex;
          align-items: center;
        }
        &__icon {
          margin-right: 8px;
          display: inline-block;
        }
        &__text {
          text-transform: uppercase;
          @include get-font($f14, $fw-b, $teal, $lh);
        }
        &__chevron {
          transform: rotate(90deg);
          &--active {
            transform: rotate(270deg);
          }
        }
      }
      &__parent {
        @include get-font($f12, $fw-sb, $lightBlack, $lh);
        padding: 8px 16px 8px;
        border-bottom: 1px solid $superLightBlack;
      }
      &__child {
        height: 46px;
        @include get-font($f14, $fw-r, $black, $lh);
        letter-spacing: 0.7px;
        padding: 12px 16px;
        box-sizing: border-box;
        text-decoration: none;
        display: block;
        margin-top: 12px;
        width: 100%;
        @include transitionEase(#{background-color});
        &--active,
        &:hover,
        &:active,
        &:focus {
          background-color: $lighterTeal;
        }
        &--active {
          font-weight: $fw-sb;
          color: $black;
        }
      }
    }
  }

  &__group-dropdown {
    margin-top: 36px;
    margin-bottom: 72px;
    &:nth-child(2) {
      margin-top: 24px !important;
      margin-bottom: 0px !important;
    }

    &__title {
      display: flex;
      @include get-font($f14, $fw-b, $black, $lh);
      text-transform: uppercase;
      cursor: pointer;

      &__group {
        width: 20px;
        height: 20px;
        margin-left: 5px;
        margin-right: 16px;
      }

      %__chevron {
        width: 16px;
        margin-left: auto;
        margin-right: 16px;
      }
      &__chevron-close {
        @extend %__chevron;
        transform: rotate(90deg);
      }
      &__chevron-open {
        @extend %__chevron;
        transform: rotate(-90deg);
      }

      &__features {
        @include get-font($f12, $fw-m, $grayText, $lh);
        margin-top: 24px;
        padding-bottom: 8px;
        border-bottom: 0.5px solid $grayText;
      }
    }
  }
}
