$followPink: #ff1654;
$followingGray: rgba(0, 0, 0, 0.02);

.default-follow-btn {
    @extend %appearance-none;
    border: 1.2px solid $followPink;
    background: $white;
    cursor: pointer;
    &__text {
        color: $followPink;
    }
}

.follow-btn {
    cursor: pointer;
    @extend %appearance-none;
    background: transparent;
    border: 0;
    padding: 0;
    @include get-font($f12, $fw-b, $red, $lh);
    letter-spacing: 0.6px;
    img {
        margin-right: 6px;
    }
    &--disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.following-btn {
    @extend %appearance-none;
    border: 0;
    height: 30px;
    background: $grayBackground;
    @include get-font($f12, $fw-b, $lightBlack, $lh);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    letter-spacing: 0.6px;
    padding: 0 12px;
    box-sizing: border-box;
    border-radius: 5px;
    img {
        width: 12px;
        margin-right: 6px;
    }
}


.default-following-btn {
    @extend %appearance-none;
    border: 1.2px solid $followingGray;
    background: $followingGray;
    &__text {
        color: $lightBlack;
    }
}