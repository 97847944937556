.terms {
    &__container {
        width: 100%;
        max-width: 820px;
        margin: 66px auto 0;
        padding-top: 32px;
        @media only screen and (max-width: $onlyMobile) {
            margin-top: 56px;
            box-sizing: border-box;
            padding-top: 16px;
        }
    }
    &__header {
          @include get-font($f28, $fw-sb, $black, $lh);
          @media only screen and (max-width: $onlyMobile) {
              font-size: $f24;
              padding: 0 16px;
          }
    }
    &__content {
        width: 100%;
        background: $white;
        @extend %border-box-shadow;
        padding: 40px;
        box-sizing: border-box;
        margin-top: 24px;
        border-radius: 10px;
        margin-bottom: 40px;
        @media only screen and (max-width: $onlyMobile) {
            border-radius: 0;
            padding-left: 16px;
            padding-right: 16px;
            margin-top: 16px;
            box-shadow: none;
        }
    }
    &__date {
        @include get-font($f16, $fw-sb, $mediumBlack, $lh);
        padding-bottom: 24px;
        border-bottom: 0.5px solid $lightestBlack;
        margin-bottom: 16px;
    }
    &__title {
        @include get-font($f18, $fw-sb, $black, $lh);
        padding-bottom: 10px;
        position: relative;
        margin-bottom: 16px;
        &:after {
            content: '';
            width: 64px;
            height: 4px;
            background: $black;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        &--bigger {
            font-size: $f22;
        }
    }
    &__description {
        @include get-font($f16, $fw-r, $black, $lh);
        margin-bottom: 16px;
    }
    &__list {
        margin: 0;
        padding-left: 20px;
        &__item {
            margin-bottom: 16px;
            > ol {
                margin-top: 12px;
                > li {
                    padding-left: 10px;
                    margin-bottom: 0;
                }
            }
            &--no-margin {
                margin-bottom: 0;
                padding-left: 10px;
            }
        }
        &__title {
            @include get-font($f16, $fw-sb, $black, $lh);
            margin-bottom: 12px;
        }
        &__description {
            @include get-font($f16, $fw-r, $mediumBlack, $lh);
        }
    }
    &__link {
        text-decoration: none;
        font-size: inherit;
        font-weight: inherit;
        color: $blue;
    }
}