.form-input, .form-select, .form-textarea  {
    @include get-font($f16, $fw-r, $black, $lh);
    @include form-input-border-shadow();
    outline: none;
    width: 100%;
    padding: 12px 16px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: $white;
    resize: none !important;
    &:focus, &:active {
        border: 1px solid $mediumBlack;
        box-shadow: none;
    }
    &--secondary {
        font-size: 14px;
        height: 36px;
    }
    &--disabled {
        background: $grayBackground;
        box-shadow: none !important;
        border: 0 !important;
        line-height: 1;
        &:focus, &:active {
            border: 0;
        }
    }
    &--email {
        position: relative;
        padding-right: 90px;
        text-overflow: ellipsis;
        overflow: hidden;
        .profile-edit-modal__form__phone__verified {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 16px;
        }
    }
    &__helper {
        @include get-font($f12, $fw-r, $lightBlack, $lh);
        margin-top: 6px;
        &__btn {
            @extend %appearance-none;
            background: transparent;
            border: 0;
            padding: 0;
            @include get-font($f12, $fw-r, $blue, $lh);
            cursor: pointer;
            &--disabled {
                cursor: not-allowed;
                opacity: 0.65;
            }
        }
    }
}

.form-select {
    @extend %appearance-none;
    padding: 0;
    text-indent: 16px;
    background-image: url('assets/images/chevrons/chevron_down_grey.png');
    background-repeat: no-repeat;
    background-position: 96%;
    background-size: 20px;
    &__control {
        @include form-input-border-shadow();
        border-radius: 10px !important;
        border-color: $superLightBlack !important;
        &--is-focused {
            border-width: 1px !important;
            border-color: $mediumBlack !important;
            box-shadow: none !important;
        }
    }
    &__placeholder {
        font-size: $f14;
    }
    &__dropdown-indicator {
        display: none !important;
    }
    &__indicator-separator {
        display: none !important;
    }
}


.form-textarea {
    resize: none;
    &--secondary {
        max-height: 56px;
    }
    &--expandable {
        max-height: 172px;
        font-size: $f14;
    }
    height: 86px;
}

.form-label {
    @include get-font($f14, $fw-m, $black, $lh);
    &--multi {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    &__inline-input {
        margin-right: 8px;
    }
    &--secondary {
        color: $lightBlack;
        font-weight: $fw-r;
    }

    .required{
        color: $removeRed;
    }
}

.form-inline-select {
    @include get-font($f16, $fw-r, $black, $lh);
    @extend %border-box-shadow;
}

.text-input{
    width: 100%;
    outline: none;
    border: none;
    min-height: 48px;
    box-sizing: border-box;
    padding-right: 10px;
    @include get-font($f16,$fw-r,$black);

    &::-webkit-inner-spin-button{
        display: none;
    }
    
    &__container{
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: 100%;
        border: 1px solid $lightGrey;
        border-radius: 8px;
        transition: padding .4s;
        box-sizing: border-box;
        background-color: $white;

        &.disabled{
            border-color: $lightGrey;
            opacity: .5;
            padding: 0 16px;
        }


        &:focus-within,
        &:active{
            &.disabled{
                border-color: $lightGrey;
                opacity: .5;
            }
            border-color: $black;
            .text-input--action{
                right: 20px;
                width: auto;
                margin-left: 20px;
            }
        }


        &.media-visible{
            .text-input{
                min-height: 90px;
            }
            .text-input--action{
                right: 20px;
                width: auto;
                margin-left: 20px;
            }
            .media-preview{
                height: 64px;
                width: 64px;
                border: 1px solid $lightGrey;
                border-radius: 8px;
                position: relative;
                overflow: hidden;

                 .remove{
                    position: absolute;
                    top: 4px;
                    right: 4px;
                    display: block;
                    height: 16px;
                    width: 16px;
                }

            }
        }
        .loader__wrapper{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 30px;
            }
        }

        .drag-icon{
            margin: 0 10px;
            opacity: .2;
            transition: opacity .4s;
            cursor: grab;
        }

        &:hover{
            .text-input--action{
                right: 20px;
                width: auto;
                margin-left: 20px;
            }
            .drag-icon:not(.disabled){
                opacity: 1;
            }
        }
    }
    &--action{
        position: relative;
        right: -100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 24px;
        width: 0;

        &__button{
            cursor: pointer;
            height: 24px;
            width: 24px;
            label{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                height: 100%;
                width: 100%;
                margin: auto;
            }
            > img{
                object-fit: contain;
                height: inherit;
                width: inherit;
            }
            &.disabled,
            &:disabled{
                opacity: .2;
                cursor: not-allowed;
            }
        }
    }
    &::placeholder{
        color: $lightGrey;
        font-size: 16px;
    }
}

.button{
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    &:focus,
    &:active{outline: none;}
}