.company-info-edit {
    position: absolute;
    right: 24px;
    border: 0;
    box-sizing: border-box;
    background: #FFFFFF;
    padding: 0;
    cursor: pointer;
    outline: 0;
}

.company-profile-image {
    border-radius: 10px !important;
    .profile-view__details__image {
        border-radius: 10px !important;
    }
    .profile-view__details__image__placeholder {
        border-radius: 10px !important;
    }
}
.company-view-add-icon{
    position: absolute;
    left: 96px;
    top: 96px;
    @media only screen and (max-width: $onlyMobile) {
        left: 43px;
        top: 42px;
    }
    cursor: pointer;
}

.company-view-edit-list{
  
    position: absolute;
    left: 20px;
}
.back-ground-image{
    cursor: pointer;
}