.survey {
  &__userSubmission {
    max-width: $centeredContainer;
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    &__header {
      max-width: 100%;
      display: flex;
      margin-bottom: 24px;
      &--image {
        width: 64px;
        height: 42px;
        object-fit: cover;
        border-radius: 7px;
        margin-right: 8px;
      }
      &--upperBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__container {
          background: $white;
          @include form-input-border-shadow();
          padding: 16px;
          border-radius: 10px;
        }
      }
    }
    &__text-container {
      &--primary {
        margin-bottom: 2px;
        @include get-font($f12, $fw-r, $copiedBackground, $f18);
      }
      &--secondary {
        @include get-font($f14, $fw-m, $black, $lhOnboarding);
        margin: 0px;
      }
    }
  }
}
