.certification-details {
    .section {
        padding: 40px;

        &.filled {
            background-color: #091420;
        }

        .drawer {
            width: 425px !important;
            box-sizing: border-box;
            @media screen and (max-width: $onlyMobile) {
                width: 100% !important;
            }

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                border-bottom: 1px solid #EDEDED;

                .header-title {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    @include get-font($f16, $fw-sb, $black, $lh);


                    .close-container {
                        background: transparent;
                        outline: none;
                        border: none;

                        >img {
                            height: auto;
                            width: 12px;
                        }
                    }

                }

                .action-btn {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    display: flex;
                    padding: 8px 16px;
                    border-radius: 4px;
                    background: $blue;
                    outline: none;
                    border: none;
                    @include get-font($f14, $fw-b, $white, $lh);


                    >img {
                        height: auto;
                        width: 16px;
                        margin-right: 8px;
                        filter: brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(40%) hue-rotate(282deg) brightness(119%) contrast(101%);
                    }
                }
            }


            .action-list_container {
                padding: 16px 24px 24px 24px;

                .note-title {
                    margin: 0;
                    @include get-font($f14, $fw-r, #F47816, $lh);

                }

                .picker-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 16px;

                    .date-picker__container {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        border-radius: 4px;
                        border: 1px solid #EDEDED;
                        background: #FFF;
                        padding: 8px 16px;
                        // padding-right: 0px;

                        .icon-img {
                            height: auto;
                            width: 16px;
                        }

                        .react-datepicker__input-container {
                            border: none;
                            outline: none;

                            >input {
                                border: none;
                                outline: none;
                                width: 116px;
                            }
                        }
                    }
                }

                .prev-next__container {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .button {
                        padding: 8px 16px;
                        border-radius: 4px;
                        border: 1px solid #EDEDED;
                        background: #FFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.disabled {
                            opacity: 0.4;
                        }

                        &.icon-img {
                            height: auto;
                            width: 16px;

                            &.left {
                                transform: rotate(-180deg);

                            }
                        }
                    }
                }

                .slot-list__container {
                    margin: 24px 0px;
                    max-height: 64vh;
                    overflow: hidden;
                    overflow-y: scroll;
                    @include getDefaultScrollbar();


                    .slot-list {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 16px;

                        &__item {
                            padding: 8px;
                            border: 1px solid transparent;
                            text-Align: center;
                            font-Size: 16px;
                            font-weight: 600;
                            border-radius: 8px;
                            cursor: pointer;
                            position: relative;

                            &.disabled {
                                cursor: no-drop;
                                color: #747474;
                                border-color: transparent;
                                background-color: #d1d1d1;
                            }

                            .selected-slot {
                                position: absolute;
                                top: 4px;
                                right: 4px;
                                height: auto;
                                width: 12px;
                            }
                        }
                    }
                }

                .color-list {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 20px;
                    margin-top: 24px;
                    padding-top: 24px;
                    border-top: 1px dashed $copiedBackground;

                    &__item {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .color-container {
                            width: 20px;
                            height: 20px;
                            border-radius: 2px;
                            border: 1px solid #00A146;
                            background: #FFF;
                            box-sizing: border-box;

                            &.fill {
                                background-color: rgb(116, 116, 116);
                            }
                        }

                        .text {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                        }
                    }
                }

                .no-slot__text {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    color: $copiedBackground;
                    text-align: center;
                    margin-top: 32px;
                }

            }

        }

        .container {
            max-width: $maxWidthContainer;
            margin: 0 auto;

            &.relative {
                position: relative;
                z-index: 1;
            }

            &.flex {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;
                @media screen and (max-width: $onlyMobile) {
                    flex-direction: column;
                    gap: 16px;
                }
            }
        }
    }

    .details-content {
        width: 65%;
        @media screen and (max-width: $onlyMobile) {
            width: 100%;
            z-index: 23;
        }

        .biz__breadcrumbs {
            margin-bottom: 16px;

            &__item {
                @include get-font($f14, $fw-m, $blue, $lh);
                margin-right: 12px;
                @include limit-text-to-line(1);

                &__icon {
                    filter: brightness(0) saturate(100%) invert(76%) sepia(46%) saturate(6784%) hue-rotate(189deg) brightness(91%) contrast(94%);
                    margin-right: 12px;
                    width: 12px;
                    margin-top: 2px;
                }
            }
        }


        &__id {
            @include get-font($f14, $fw-b, $white, $lh);
        }

        &__title {
            @include get-font($f28, $fw-sb, $white, $lh);
        }

        .subdetails-content {
            display: flex;
            align-items: center;
            gap: 12px;
            flex-wrap: wrap;

            &__item {
                @include get-font($f16, $fw-sb, #92BCEE, $lh);
                padding: 0;
                padding-left: 12px;
                border-left: 1px solid #92BCEE;
                margin: 0;
                min-width: fit-content;
                @media screen and (max-width: $onlyMobile) {
                   border: none;
                   padding: 0;
                }

                &:first-child {
                    border: unset;
                    padding-left: 0;
                }
            }
        }

        &__description {
            @include get-font($f16, $fw-r, $white, $lh);
            margin-top: 16px;
        }

    }

    .bg-img {
        height: auto;
        width: 300px;
        position: absolute;
        right: -5%;
        opacity: 0.5;
        @media screen and (max-width: $onlyMobile) {
            width: 100%;
            right: 0;
            opacity: 0.2;
            z-index: 0;
        }
    }

    .second-container {
        width: 67%;
        @media screen and (max-width: $onlyMobile) {
            width: 100%;
        }

        .title {
            margin-bottom: 16px;
            margin-top: 16px;
            @include get-font($f22, $fw-sb, $black, $lh);

        }

        .subtitle {
            @include get-font($f18, $fw-r, $black, $lh);

            >span {
                ul {
                    margin: 0;
                    padding: 0;
                    margin-left: 16px;
                }
            }

            span {
                display: block;
                color: #171717;
                font-size: 22px;
                font-weight: 600;
                line-height: 33px;
            }

        }
    }

    &__slot__info {
        .default-modal__container {
            top: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: $onlyMobile) {
                top: 8%;
                transform: none;
            }
        }

        .default-modal__header {
            margin-bottom: 12px;
            &__title {
                @include get-font($f16, $fw-sb, #DD2E1F, $lh);

            }
        }

        .info-title {
            @include get-font($f14, $fw-r, $copiedBackground, $lh);

        }

        .footer-container {
            .action-btn {
                float: right;
                padding: 8px 16px;
                border-radius: 8px;
                background: #171717;
                margin-top: 16px;
                @include get-font($f16, $fw-sb, $white, $lh);
                outline: none;
                border: none;
            }   
        }
    }

}

.certification-marksheet__modal {
    .scrollable-modal__container {
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0px 0px 16px 0px !important;
        .scrollable-modal__modal-overflow{
            padding: 0px 16px;
        }
        @media screen and (max-width: $onlyMobile) {
            top: 8%;
            transform: none;
        }
    }

    .table-data {
        display: flex;
        margin-bottom: 16px;
        // margin-top: 8px;

        .column {
            flex: 1;
            border-right: 1px solid $imageWrapperBackgroundGray;
            min-width: fit-content;

            &:first-child {
                min-width: 80%;

                .row {
                    border-bottom-left-radius: 8px;
                    border-left: 1px solid $imageWrapperBackgroundGray;

                    .cell {
                        &:last-child {
                            border-bottom-left-radius: 8px;
                        }

                        .text {
                            justify-content: flex-start;
                        }
                    }
                }

                .header {
                    border-top-left-radius: 8px;

                    .info-text {
                        justify-content: flex-start;
                    }
                }
            }

            &:last-child {
                border-right: none;

                .header {
                    border-top-right-radius: 8px;

                    .info-text {
                        justify-content: flex-end;
                    }
                }

                .row {
                    border-right: 1px solid $imageWrapperBackgroundGray;
                    border-bottom-right-radius: 8px;

                    .cell {
                        &:last-child {
                            border-bottom-right-radius: 8px;
                        }

                        .text {
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }

        .header {
            background-color: #f2f2f2;
            padding: 8px 16px 8px 16px;
            text-align: center;
            @include get-font($f16, $fw-sb, $black, $lh24);

            .info-text {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;

                >img {
                    filter: brightness(0) saturate(100%) invert(44%) sepia(11%) saturate(0%) hue-rotate(245deg) brightness(99%) contrast(89%);
                }

                .info-text-tooltip {
                    background-color: $black;
                    opacity: 1;
                    @include get-font($f14, $fw-m, $white, $lh);
                }
            }
        }

        .row {
            display: flex;
            flex-direction: column;
            text-align: center;

            .cell {
                padding: 8px 16px 8px 16px;
                border-bottom-color: $imageWrapperBackgroundGray;
                border-bottom: 1px solid $imageWrapperBackgroundGray;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;

                &:last-child {
                    font-weight: $fw-sb;
                }

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                }
            }
        }
    }
}