
.template-survey-modal{
    .scrollable-modal__container{
      padding-bottom: 16px !important;
    }
    .survey-create__container{
      padding: 16px;
      border-radius: 12px;
      border: 1px dotted rgba(204, 236, 218, 1);
      background-color: rgba(204, 236, 218, 0.3);
      margin-bottom: 8px;
      align-self: center;
      margin: 12px 20px;
      cursor: pointer;
        .title {
          @include get-font($f16, $fw-sb, $black, $lh);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          .icon{
            width: auto;
            height: 16px;
            filter: brightness(0) saturate(100%) invert(36%) sepia(97%) saturate(1935%) hue-rotate(126deg) brightness(92%) contrast(102%);
          }
        }
    
        .subtitle {
          @include get-font($f14, $fw-r, $copiedBackground, $lh);
          margin-top: 12px;
          text-align: center;
        }
    }
    
    .list-container{
      .temp-title {
        @include get-font($f16, $fw-sb, $black, $lh);
       margin: 12px 20px;
      }
      .all-list {
        margin: 0;
        padding: 0;
        .list-item {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 12px;
          border-radius: 16px;
          border: 1px solid #EDEDED;
          box-shadow: 0px 2px 4px 0px #1717171A;
          padding: 16px;
          margin: 16px 20px;
          &:hover{
           border: 1px solid $green;
          }
          .image {
            width: 100%;
            height: auto;
            max-width: 80px;
          }
          &__details {
            width: 100%;
            .title{
            @include get-font($f16, $fw-sb, $black, $lh);
            }
            .subtitle{
            @include get-font($f14, $fw-r, $copiedBackground, $lh);
            }
            .button-container{
              display: flex;
              gap: 16px;
              justify-content: flex-end;
              align-self: flex-end;
              margin-top: 12px;
              .btn {
                @include get-font($f14, $fw-m , $green, $lh);
                padding: 8px 16px;
                border-radius: 8px;
                border: 1px solid $green;
                display: flex;
                align-items: center;
                gap: 8px;
                background-color: $white;
                min-width: fit-content;
                width: fit-content;
                cursor: pointer;
                &.fill{
                  background-color: $green;
                }
                &.text{
                  color: $white;
                }
                .icon {
                  width: auto;
                  height: 16px;
                  margin-top: 2px;
                }
                &:disabled{
                  opacity: .5;
                  cursor:no-drop;
                }
              }
              @media only screen and (max-width: $smallMobile) {
                flex-direction: column;
                }
            }
          }
        }
      }
      .no-template{
        @include get-font($f16, $fw-sb, $black, $lh);
        text-align: center;
        margin: 20px 0;
      }
    }
  }