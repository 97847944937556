.premium-modal {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
      text-decoration: none;
      @extend %border-box-shadow;
      border-radius: 10px;
      background: $white;
      overflow: hidden;
      display: block;
      @include transitionEase(#{background});
      cursor: pointer;
      justify-content: space-between;
      &:hover {
        background: $grayBackground;
      }
      &__container {
        margin-top: 16px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    &__icon {
      width: 86px;
      height: 86px;
      object-fit: contain;
      display: block;
      &__container {
        display: inline-flex;
        vertical-align: top;
        width: 102px;
        height: 102px;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: $onlyMobile) {
          height: 124px;
        }
      }
    }
    &__info {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 102px);
      height: 102px;
      padding: 16px;
      box-sizing: border-box;
      &__title {
        @include get-font($f18, $fw-b, $black, $lh);
        text-align: left;
      }
      &__helper {
        @include get-font($f14, $fw-r, $black, $lh);
        margin-top: 6px;
        text-align: left;
      }
    }
    &__tag {
      width: 54px;
      height: 29px;
      border-radius: 4px;
      @include get-font($f14, $fw-b, $white, $lhOnboarding);
      background: $red;
      &__text {
        padding: 4px 12px;
      }
    }
  }
}

@media(max-width:$onlyMobile){
  .premium-modal{
    .default-modal__container{
      width: 100%;
      top: 10%;
    }
  }
}
