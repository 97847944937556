.quiz {
  &__reports {
    &__remove-content {
      background: transparent;
      box-shadow: none;
      border: 0;
      padding: 0;
    }
    &__header {
      padding-bottom: 16px;
      border-bottom: 1px solid $grayBackground;
      &--without-border {
        padding-bottom: 0;
        border-bottom: 0;
      }
      &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-bottom: 1em;
        &.no-bottom-margin{
          margin-bottom: 0;
        }
        &__title {
          @include get-font($f14, $fw-r, $orange);
        }
        &__image {
          margin-right: 8px;
        }
        &:hover {
          text-decoration: underline;
          color: $orange;
        }
      }
      &__action {
        display: flex;
        align-items: flex-end;
        margin-top: 24px;
        justify-content: space-between;
        &__title {
          @include get-font($f12, $fw-b, $black, $lh);
          letter-spacing: 0.6px;
        }
        &__btn {
          @extend %appearance-none;
          background: $white;
          border-radius: 10px;
          border: 1.5px solid $orange;
          cursor: pointer;
          @include get-font($f12, $fw-b, $orange, $lh);
          letter-spacing: 0.6px;
          padding: 8px 16px;
        }
      }
      &__container {
        background: $white;
        @include form-input-border-shadow();
        padding: 24px;
        border-radius: 10px;
      }
    }
    &__sectionTitle {
      width: auto;
      background: #00a146;
      border-radius: 10px 10px 0px 0px;
      padding: 12px 16px;
      @include get-font($f16, $fw-b, $white, $lh);
    }
    &__section {
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin-left: 16px;
      padding: 24px 24px 16px 24px;
      box-sizing: border-box;
      overflow: hidden;

      &__header {
        &--without-border {
          padding-bottom: 0;
          border-bottom: 0;
        }
        &__link {
          display: flex;
          align-items: center;
          text-decoration: none;
          &__title {
            @include get-font($f14, $fw-r, $orange);
          }
          &__image {
            margin-right: 8px;
          }
        }
        &__action {
          display: flex;
          align-items: flex-end;
          margin-top: 24px;
          justify-content: space-between;
          &__title {
            @include get-font($f12, $fw-b, $black, $lh);
            letter-spacing: 0.6px;
          }
          &__btn {
            @extend %appearance-none;
            background: $white;
            border-radius: 10px;
            border: 1.5px solid $orange;
            cursor: pointer;
            @include get-font($f12, $fw-b, $orange, $lh);
            letter-spacing: 0.6px;
            padding: 8px 16px;
          }
        }
        &__container {
          background: $white;
          @include form-input-border-shadow();
          padding: 24px;
          border-radius: 10px;
        }
      }
      &__responses {
        width: 100%;
        flex: 1 1;
      }
    }
    &__perSection {
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      box-sizing: border-box;
      overflow: hidden;
      margin-bottom: 16px;
    }
    &__semiSection {
      border-radius: 10px;
      box-sizing: border-box;
    }
    &__sectionQuestions {
      padding: 48px 24px 0px 24px;
    }
    &__summary {
      &__view-all {
        &__container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &__btn {
          @extend %appearance-none;
          background: $white;
          cursor: pointer;
          text-transform: uppercase;
          border: 0;
          padding: 0;
          @include get-font($f12, $fw-b, $blue, $lh);
          letter-spacing: 0.6px;
        }
      }
      &__question {
        list-style: none;
        margin: 0;
        padding: 24px;
        &__details {
          &__title {
            @include get-font($f16, $fw-m, $black, $lh);
            display: flex;
          }
          &__helper {
            margin-top: 6px;
            @include get-font($f14, $fw-r, $lightBlack, $lh);
          }
        }
        &__item {
          margin-bottom: 48px;
        }
        &__pill {
          display: flex;
          align-items: center;
          margin-top: 16px;
          justify-content: start;
          &__list {
            list-style: none;
            margin: 0;
            padding: 0;
          }
          &__data {
            min-height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            max-width: 740px;
            background-color: $grayBackground;
            border-radius: 22px;
            position: relative;
            overflow: hidden;
            padding: 0 16px;
            box-sizing: border-box;
            flex: 1;
          }
          &__filled {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            background-color: $lightTeal;
          }
          &__title {
            position: relative;
            margin-right: 8px;
            @include get-font($f16, $fw-m, $black);
          }
          &__helper {
            flex-shrink: 0;
            position: relative;
            @include get-font($f12, $fw-r, $lightBlack);
            > span {
              display: inline-flex;
              width: 24px;
              margin-left: 8px;
            }
          }
          &__percentage {
            margin-left: .5em;
            @include get-font($f14, $fw-m, $black, $lh);
            flex-shrink: unset;
            z-index: 1;
            text-align: right;
            min-width: 3.5em;
          }
          &__chevron {
            height: 24px;
            border-radius: 24px;
            background: $grayBackground;
            align-items: center;
            justify-content: center;
            > img {
              width: 12px;
              height: auto;
            }
          }
        }
      }
      &__answerpills {
        display: flex;
        margin-top: 24px;
        padding-bottom: 24px;
        overflow-x: auto;

        &__answer {
          display: flex;
          margin-right: 16px;
          flex-direction: column;
          align-items: center;
          vertical-align: middle;
          flex: 0 0 auto;

          &__title {
            @include get-font($f14, $fw-r, $black, $lh);
          }
          &__percent {
            padding: 4px 14px;
            margin-top: 6px;
            border-radius: 20px;
            border: solid 0.5px $tastingCardShadow;
            cursor: default;
            min-width: 48px;
            max-width: 48px;
            text-align: center;
            @include get-font($f14, $fw-m, $black, $lh);
          }
        }
      }
      &__multiselect {
        margin-top: 24px;
        &__row {
          background-color: $white;
          display: flex !important;
          align-items: center;
          vertical-align: middle;

          &__answerpill {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      &__rankquestion {
        margin-top: 24px;
      }
    }
    &__users {
      padding: 16px 0;
      box-sizing: border-box;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      &:after {
        position: absolute;
        height: 100%;
        top: 0;
        left: -24px;
        content: "";
        border-bottom: 1px solid $grayBackground;
        width: calc(100% + 48px);
      }
      &__details {
        display: flex;
        align-items: center;
      }
      &__avatar {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        margin-right: 8px;
      }
      &__title {
        @include get-font($f14, $fw-m, $black, $lh);
        text-decoration: none;
      }
      &__submissionDate{
        padding-top: 4px;
        opacity: .6;
        @include get-font($f12, $fw-r, $black, $lh);
      }
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      &__arrow {
        height: 16px;
        width: auto;
      }
      &__score{
        @include get-font($f14,$fw-m,$orange,21px);
        margin-right: 40px;
        text-transform: uppercase;
      }
    }
    &__text-answers {
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      margin-top: 16px;
      &__title {
        margin-top: 8px;
        @include get-font($f14, $fw-r, $black, $lh);
        word-break: break-word;
      }
    }
    &__media {
      &__pills {
        &__list {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 24px 0;
          padding: 0;
          list-style-type: none;
        }
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        box-sizing: border-box;
        background: $grayBackground;
        @include get-font($f14, $fw-m, $lightBlack);
        margin-right: 2px;
        cursor: pointer;
        @include transitionEase(#{background, color});
        &--active {
          background: $orange;
          color: $white;
          cursor: default;
        }
        &:hover {
          background: $orange;
          color: $white;
        }
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          margin-right: 0;
        }
        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
      &__links {
        &__list {
          list-style: none;
          margin: 0;
          padding: 0;
          margin-top: 8px;
          box-sizing: border-box;
          width: 100%;
        }
        &--blue {
          background: $superLightestBlue;
          .quiz__reports__media__links__title {
            color: $blue;
          }
        }
        height: 44px;
        border-radius: 22px;
        background-color: $grayBackground;
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        padding: 0 16px;
        box-sizing: border-box;
        &__title {
          @include get-font($f14, $fw-m, $black, $lh);
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 16px;
        }
        &__meta {
          flex-shrink: unset;
          > span {
            @include get-font($f14, $fw-m, $lightBlack);
          }
          > img {
            margin-left: 24px;
          }
        }
      }
    }

    &__list{
      &--item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $imageWrapperBackgroundGray;
        padding: 12px 16px 12px 16px;
        border-radius: 100px;
        width: 100%;
        color: $black;


        &.correct{
          &__answered{
            color: $white;
            background-color: $green;

            .quiz__reports__list--item__status{
              background-color: $green;
            }
          }
          &__not__answered{
            .quiz__reports__list--item__status{
              background-color: $quizGreen;
            }
          }
        }
        &.wrong__answered{
          color: $white;
          background-color: $removeRed;

          .quiz__reports__list--item__status{
            background-color: $removeRed;
          }
        }

        &__value{
          font-size: $f14;
          font-weight: $fw-m;
          line-height: $lh;
        }
        &__status{
          display: flex;
          align-items: center;
          gap: 10px;
          color: $white;
          padding: 0 8px;
          border-radius: 24px;
          height: 24px;
          
        }

        // image option
        &__image {
          position: relative;
          &__container{
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
          }
          &.correct__answered{
            &::before{
              content: "";
              position: absolute;
              height: calc(100% - 3px);
              width: calc(100% - 3px);
              z-index: 1;
              pointer-events: none;
              border-radius: 10px;
              border: 2px solid $green;
            }
          }
          &.wrong__answered{
            &::before{
              content: "";
              position: absolute;
              height: calc(100% - 3px);
              width: calc(100% - 3px);
              z-index: 1;
              pointer-events: none;
              border-radius: 10px;
              border: 2px solid $removeRed;
            }
          }
          &--img__container{
            position: relative;
            &--image{
              border-radius:10px 10px 0 0 ;
            }
            &--default--image{
              border-radius:10px 10px 0 0 ;
            }
          }
          &--check{

            &__container{
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px 15px 5px 10px;
              gap: 10px;
              position: absolute;
              bottom: 10%;
              left: 50%;
              transform: translateX(-50%);
              background-color: $removeRed;
              border-radius: 32px;

              span{
                @include get-font($f14,$fw-b,$white,21px)
              }

              &.correct__answered{
                background-color: $green;
              }
              &.correct__not__answered{
                background-color: $quizGreen;
              }
            }

          }
          &--value{
            margin-top: -5px;
            padding: 15px;
            border-radius: 0 0 10px 10px;
            box-shadow: 0px 2.66667px 5.33334px rgba(23, 23, 23, 0.1);

            @include get-font($f18,$fw-m,$black,28px);

            &.correct__answered{
              color: $white;
              background-color: $green;
            }
            &.wrong__answered{
              color: $white;
              background-color: $removeRed;
            }
          }
        }

      }
    }

    &__score{
      &__container{
        &--header{
          text-align: center;
          @include get-font($f18,$fw-sb,$copiedBackground,'27px');
        }
        &--score{
          margin: 15px auto;
          width: fit-content;
          padding: 8px 24px;
          text-align: center;
          @include get-font($f22,$fw-sb,$orange,$lhl);
          border: 1px solid #F2F2F2;
          border-radius: 100px;
        }

        &--analysis{
          &__title{
            text-align: center;
            text-transform: uppercase;
            @include get-font($f14,$fw-b,$copiedBackground,$lhOnboarding);
          }

          &__list{
            padding: 0;
            margin: 20px auto 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 450px;
            list-style: none;
            gap: 20px;

            li{
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 10px;

              span{
                text-align: center;
                width: 84px;
                border-radius: 100px;
                padding: 8px 12px;
                background-color: $black;
                @include get-font($f16,$fw-sb,$white,'24px');

                &.correct{
                  background-color: $green;
                }
                &.incorrect{
                  background-color: $removeRed;
                }
              }

              &:not(:first-child):before{
                content: "";
                position: absolute;
                top: 0;
                left: -25px;
                height: 100%;
                width:1px;
                background-color: $imageWrapperBackgroundGray;
              }
              label{
                @include get-font($f16,$fw-m,$black,24px);
              }
            }
          }
        }
      }
    }

    &__question--title{
      @include get-font($f16,$fw-m,$black,24px)
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .quiz {
    &__reports {
      padding-top: 24px !important;
      &__remove-content {
        padding: 0 !important;
      }
      .collaboration__header__text {
        font-size: $f22;
        font-weight: $fw-sb;
      }
      &__header {
        &__action {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 0px;
          align-items: center;
          &__btn {
            margin-top: 8px;
          }
        }
      }
      .pr-manage {
        &__sidebar {
          width: 100%;
          &__content-container {
            padding: 0;
            width: 100%;
          }
          &__list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__item {
              flex: 1;
            }
          }
        }
        &__content {
          margin-left: 0;
          padding: 24px 16px;
          &--max-width {
            max-width: 100%;
          }
        }
      }
      &__summary {
        &__question {
          padding: 16px 0px;
          &__item {
            margin-bottom: 24px;
          }
          &__pill {
            &__data {
              padding: 8px 12px;
            }
            &__percentage {
              margin-left: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              .quiz__reports__summary__question__pill__chevron {
                width: 24px;
                display: flex;
                align-items: center;
                margin-left: 8px;
              }
            }
          }
        }
      }
      &__score__container--analysis__list{
        flex-wrap: wrap;
      }
      &__users{
        flex-wrap: wrap;
        gap: 12px;
        &__score{
          margin-right: 24px;
        }
      }
    }
  }
}
