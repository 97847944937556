.biz {
  &__ttfbreport {
    &__productname {
      @include get-font($f18, $fw-sb, $lightestGray, $lh);
    }
    &__sidebar {
      padding-top: 0px;
      &__summaryreport {
        &__date {
          @include get-font($f12, $fw-sb, $lightestGray, $lh);
        }
        &--active,
        &--active:hover {
          background-color: $reportGreen;
          cursor: default;
        }
        &__borderradius {
          border-radius: 10px 10px 0 0;
        }
      }
      &__batchblock {
        overflow-y: auto;
        max-height: 320px;
      }
      &__batch {
        border-bottom: 1px solid $lightGray;
      }
    }
    &__summary {
      &__backtoproduct {
        &__title {
          @include get-font($f14, $fw-sb, $black, $lh);
        }
      }
    }
    &__chart {
      &__parentcontainer {
        margin-top: 50px;
      }
      &__downloadlink {
        margin-top: 72px;
        &__anchor {
          display: flex;
          cursor: pointer;
          text-decoration: none;
          align-items: center;
          justify-content: center;
          &__icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            vertical-align: middle;
          }
          &__text {
            @include get-font($f14, $fw-sb, $teal, $lh);
            text-decoration: none;
            margin-left: 8px;
          }
        }
      }
    }
    &__weightagemessage {
      display: flex;
      margin-top: 24px;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &__header {
        @include get-font($f14, $fw-m, $black, $lh);
      }
      &__text {
        margin-top: 12px;
        @include get-font($f14, $fw-r, $lightestGray, $lh);
        text-align: center;
      }
    }
  }
}
