.invitation-list {
  margin-top: 16px;
  .invitation-item {
    margin-top: 16px;
    &.no-margin {
      margin-top: 0px;
    }
    &--header {
      background-color: #f9eade;
      padding: 16px;
      gap: 10px;
      display: flex;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      justify-content: space-between;
      align-items: center;
      .right {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .user-block {
        margin-bottom: 0px;
        .user-block__details {
          > a {
            display: none;
          }
          .user-block__info {
            > a {
              color: $copiedBackground;
            }
          }
        }
      }
      .close-btn {
        background: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        margin: 0;
        padding: 0;
        > img {
          width: auto;
          height: 16px;
        }
      }
      .invitation-tasting {
        @include get-font($f18, $fw-sb, $black, $lh);
        text-decoration: none;
      }
      .status {
        padding: 8px 12px 8px 12px;
        border-radius: 8px;
        background-color: $red;
        @include get-font($f16, $fw-sb, $white, $lh);
        min-width: fit-content;
      }
    }
    &--content {
      padding: 16px;
      background-color: $white;
      .text {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        @include get-font($f16, $fw-m, #3e3e3e, $lh);
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .location {
          text-align: left;
          @include get-font($f16, $fw-r, #3e3e3e, $lh);
          width: 80%;
          padding-left: 24px;
          word-break: break-word;
          &.inactive {
              color: $copiedBackground;
          }
        }
        .venue {
          padding: 4px;
          border-radius: 4px;
          background-color: $imageWrapperBackgroundGray;
          display: flex;
          align-items: center;
          gap: 8px;
          text-decoration: none;
          @include get-font($f14, $fw-m, $copiedBackground, $lh);
        }
      }
      .description {
        margin-top: 16px;

        .heading {
          margin-top: 12px;
          text-align: center;
          @include get-font($f14, $fw-m, $black, $lh);
        }
        hr {
          border: 1px solid $imageWrapperBackgroundGray;
          color: #333;
          overflow: visible;
          text-align: center;
          height: 0px;
        }

        hr::after {
          background: $white;
          content: "Description";
          padding: 0 4px;
          position: relative;
          top: -13px;
          margin-top: 12px;
          text-align: center;
          @include get-font($f14, $fw-m, $black, $lh);
        }
        .more-text {
          padding: 16px;
          border-radius: 8px;
          background-color: #f6f6f6;
          margin-top: 16px;
          @include get-font($f14, $fw-sb, $black, $lh);
          max-height: 200px;
          @include getDefaultScrollbar();
          overflow-y: auto;
          &.inactive {
            color: $copiedBackground;
          }
        }
      }
      .timer-div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &.inactive {
          .text {
            color: $copiedBackground;
          }
        }
        .invitation-timer-container{
          .countdown{
            margin-bottom: 0px;
          }
        }
      }
    }
    &--footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 16px;
      padding-top: 0px;
      gap: 20px;
      background-color: $white;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      &.shadow {
        box-shadow: 0 -1px 4px 0 $grayBackground;
        padding-top: 16px;
      }
      .schedule__action {
        padding: 12px 16px 12px 16px !important;
      }
    }
  }
  .invitation-empty-view {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    margin-top: 8%;
    .title {
      text-align: center;
      margin-top: 16px;
      @include get-font($f16, $fw-sb, $copiedBackground, $lh);
    }
  }
}

.invitation-action {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  &.notification-btn {
    display: block;
    margin-top: 12px;
    .no-background {
      border: 1px solid $black;
      text-decoration: none;
      margin-right: 16px;
      @include get-font($f14, $fw-m, $black, $lh);
      img {
        filter: brightness(0) saturate(100%) invert(0%) sepia(87%)
          saturate(1624%) hue-rotate(139deg) brightness(94%) contrast(82%);
      }
    }
  }
  .add-to-calendar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $orange;
    border-radius: 8px;
    background-color: $white;
    *div{
      &.atcb-initialized {
        color: red;
      }
    }
    
    &.notification-btn {
      background-color: transparent;
      border: 1px solid $black;
      color: $black;
     
    }
  }
}


//responsive 
@media screen and (max-width:$smallMobile) {
  .invitation-action {
    flex-direction: column;
    align-items: normal;
  }
}