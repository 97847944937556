.schedule{
    &-modal{
        .scrollable-modal{
            &__header{
                display: block;

                &__close{
                    position: absolute;
                    top: 24px;
                    right: 16px;
                }
            }
            &__modal-overflow{
                padding-right: 0;
            }
            &__container {
                >div:first-child {
                    padding: 16px !important;
                    padding-bottom: 8px !important;
                }
            }
        }
        // add invitee modal
        &__add-invitee{
            .scrollable-modal{
                &__container{
                    @media screen and (max-width:$onlyMobile) {
                        width: 100%;
                    }
                }
                &__modal-overflow{
                    padding:0 16px;
                }
                
            }
            &--content{
                padding: 0 16px;
                max-height: 69vh;

                .collaboration-manage__search{
                    margin-bottom: 0px;
                }
                .pagination{
                    margin-top: 16px
                }
                @media screen and (max-width:$onlyMobile) {
                    padding-left: 16px;
                    padding-right: 16px !important;
                    max-height: 72vh;
                }
            }
        }
    }
    &__header{
        .title{
            @include get-font($f18,$fw-sb,$darkBlack,$lh);
            margin:0;
        }
        .subtitle{
            @include get-font($f16,$fw-r,$copiedBackground,$lh);
        }
     
    }
    &__content{
        padding: 0 16px;
       
        &-cityList{
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin: 8px 0;

            &-wrapper{
                display: flex;
                flex-direction: column;
                padding: 12px;
                background-color: rgba($color: #EDEDED, $alpha: .4);
                border-radius: 8px;
                margin-bottom: 16px;

                .title{
                    @include get-font($f14,$fw-b,$copiedBackground,$lh);
                    margin: 0;
                }
                .error{
                    padding: 10px;
                    background-color: #FFECF0;
                    @include get-font($f14,$fw-r,$removeRed);
                    border-radius: 8px;
                    margin: 8px 0;
                }
            }
            &__item{
                padding: 4px 12px;
                border-radius: 8px;
                background-color: #DBE9F9;
                display: flex;
                gap: 8px;
                .title,
                .button{
                    @include get-font($f14,$fw-m,$black);
                }
                .button{cursor: pointer;}
            }
        }
        .form-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            max-width: 600px;
            margin: auto;

            .form-label{
                display: inline-block;
                margin-bottom: 4px;
            }
            .form-input,
            .form-select{
                height: 46px;
                box-shadow: none;
                font-size: $f14;
            }
            .form-textarea{
                height: 100px;
                box-shadow: none;
                &::placeholder{
                    color: $lightGrey;
                }
            }
            .expand-width{
                grid-column: span 2;
            }
            .char-count{
                @include get-font($f14,$fw-r,$copiedBackground);
            }
            .timer-container{
                .text {
                    @include get-font($f18,$fw-r,#454545);
                    text-align: center;
                  }
                  .time {
                    @include get-font($f14,$fw-m,$black);
                    padding: 0;
                    margin: 0;

                    &.placeholder{
                        color: $lightGrey;
                    }
                  }
                  .time-box {
                    padding: 12px;
                    border: 1px solid #d8dde3;
                    border-radius: 8px;
                    margin-bottom: 16px;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  }
                  .timer-div {
                    position: absolute;
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(3,1fr);
                    padding:8px 24px;
                    border-radius: 8px;
                    box-sizing: border-box;
                    background-color: $white;
                    box-shadow: 0px 0px 20px 4px #bfbfbf40;
                    z-index: 99;

                    .timer-text{
                        display: grid;
                        place-items: center;
                        gap: 16px;
                        position: relative;
                        &:first-child::after{
                            content: ":";
                            position: absolute;
                            top:50%;
                            right: 0;
                            transform: translateY(-50%);
                            display: block;
                        }
                    }
                    .timer-btn{
                        background-color: transparent;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        height: 30px;
                        width: 50px;
                        img{
                            pointer-events: none;
                        }
                    }
                  }
            }
        }
        .info{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            gap: 16px;
            .info-box{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 5px;
                min-width: fit-content;
                &__title{
                    @include get-font($f14,$fw-sb,$orange);
                }
            }

            .pr-manage__content__title__container{
                margin: 0;
                @include limit-text-to-line(2)
            }
        }
    }
    &__form{
        &--date-picker{
            outline: none;
            border: none;
            @include get-font($f14,$fw-m,$black);
            width: 90%;
            cursor: pointer;
            text-transform: uppercase;
            &::placeholder{
                color: $lightGrey;
            }
            
            &__container{
              display: flex;
              align-items: center;
              border: 1px solid $superLightBlack;
              border-radius: 8px;
              padding: 12px 16px;
              position: relative;
              max-width: 256px;
              justify-content: space-between;

            }
            &__icon{
              cursor: pointer;
              &.clear{
                position: absolute;
                right: 18px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            
          }
    }
    &__action{
        background-color: $orange;
    }
}

// responsive 
.schedule{
    &__content{
        .form-grid{
            @media screen and(max-width:480px) {
                grid-template-columns: 1fr;

                .expand-width{
                    grid-column: 1;
                }
                .schedule__form--date-picker__container{
                    max-width: initial;
                }
            }
        }
        .timer-container{
            .timer-div{
                @media screen and (max-width:$onlyMobile) {
                    padding: 0;
                }
            }
        }
    }
    &-modal{
        .scrollable-modal{
            &__header{
                &__close{
                    position: absolute;
                    top: 18px;
                    right: 16px;
                }
            }
            &__container {
                >div:first-child {
                    padding: 16px !important;
                }
            }
        }
    }
}