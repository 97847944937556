.send-message-modal {
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  >div:first-child{
    padding: 0 !important;
  }
  .default-modal__header__title{
    line-height: 27px;
  }
  .default-modal__content {
    padding-bottom: 20px;
    .share-post-modal__content__helper {
      margin-bottom: 0;
    }
  }
  .scrollable-modal__modal-overflow{
    width: 100%;
    padding-right: 16px;
    max-height: 86vh;
  }
  &__title {
    @include get-font($f14, $fw-r, $lightBlack, $lh);
    margin-bottom: 24px;
  }
    .profile-list {
      padding: 16px;
      border-radius: 8px;
      background-color: white;
      border: 1px solid #EDEDED;
      margin-top: 16px;
  
      p {
        @include get-font($f14, $fw-m, $black, $lhOnboarding)
      }
    }
  &__textarea {
    background: $grayBackground;
    height: 126px;
    border-radius: 10px;
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    resize: none;
    @include get-font($f16, $fw-m, $black, $lh);
    outline: none;
  }
    .default-modal__footer {
    .disabled {
      pointer-events: none;
      background: #FCF1D2;
      color: #D1D1D1;

    }
    &__clear-btn {
      @include get-font($f14, $fw-r, $blue, $lh);
      @include transitionEase(#{color});
      &:hover,
      &:focus,
      &:active {
        color: $darkBlue;
      }
    }
    &__action-btn {
      border-radius: 7px;
      padding: 0 12px;
      @include get-font($f14, $fw-sb, $black, $lh);
      @include transitionEase(#{background});
      &:hover,
      &:focus,
      &:active {
        background: $darkYellow;
      }
    }
    &__btn--disabled {
      opacity: 0.65;
    }
  }
  &__profile-select {
    &__container {
      margin-top: 24px;
    }
    &__helper {
      @include get-font($f16, $fw-m, $copiedBackground, $lh);
      margin-right: 14px;
      margin-top: 5px;
      flex-shrink: 0;
    }
    &__select {
      &__container {
        flex: 1;
      }
    }
  }
  &__message {
    &__container {
      margin-top: 14px;
      display: flex;
      flex-direction: column;
      // max-height: 187px !important
    }
    &__textarea {
      @include get-font($f16, $fw-r, $black, $lh);
      // letter-spacing: -0.32px;
      resize: none;
      border: 0;
      outline: none;
      height: 96px !important;
      background: #f4f4f4;
      padding: 16px;
      border-radius: 10px;
      margin-top: 16px;
      margin-bottom: 16px;
      @include getDefaultScrollbar();
    }
  }
  &__preview {
    &__container {
      @extend %border-box-shadow;
      padding: 16px 24px 0;
      box-sizing: border-box;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      .feed-card-preview--small {
        width: calc(100% + 16px);
        margin-left: -24px;
      }
    }
    &__message {
      @include get-font($f14, $fw-r, $blue, $lh);
    }
  }
  .error-text {
    margin-top: 6px;
  }
  &__profile-container {
    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__image {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      object-fit: cover;
    }
    &__title {
      @include get-font($f14, $fw-m, $black, $lh);
      margin-left: 6px;
    }
  }
  &__checkbox-container {
    cursor: pointer;
    display: flex;
    gap: 8px;
    span {
      @include get-font($f14, $fw-r, $black, $lh);
    }
  }
}
@media only screen and (max-width: $onlyMobile) {
  .send-message-modal{
      left: 50%;
  }
}
