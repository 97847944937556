.post-share-popup-up {
    @extend %border-box-shadow;
    width: 256px;
    background: $white;
    padding: 16px 0;
    border-radius: 10px;
    border-top-left-radius: 0;
    @media only screen and (max-width: $onlyMobile) {
        border-top-left-radius: 10px;
    }
    z-index: 12;
    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        &__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 8px 16px;
            text-decoration: none;
            @include transitionEase();
            cursor: pointer;
            user-select: none;
            &__icon, img {
                margin-right: 16px;
            }
            &__text, p {
                @include get-font($f14, $fw-m, $black, $lh);
                &--danger {
                    color: $removeRed !important;
                }
            }
            &:hover, &:active {
                background: $grayBackground;
            }
            &__btn {
                @extend %appearance-none;
                border: 0;
                background: transparent;
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
                width: 100%;
                cursor: pointer;
            }
        }
        &--secondary {
            margin-top: 8px;
            border-top: 1px solid $grayBackground;
            padding-top: 8px;
        }
    }
}