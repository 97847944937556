// File variables
$reviewYellow: #fbb51f;

.product-cards {
  display: block;
  text-decoration: none;
  &__image-container {
    width: 100%;
    height: auto;
    max-height: 380px;
    min-height: 100px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    &__image {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 380px;
      object-fit: contain;
    }
    &__rating-container {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      margin-top: 34px;
      margin-right: 24px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      &__text {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
      }
      .product-rating {
        margin-bottom: 4px;
      }
    }
  }
  &__details {
    width: 100%;
    background: $lightBg;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: -5px;
    z-index: 10;
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    &__meta {
      margin-right: 8px;
      display: block;
      &__text {
        &--primary {
          @include get-font($f16, $fw-m, $black, 1.5);
          margin-bottom: 4px;
          word-break: break-word;
          @include limit-text-to-line(2);
        }
        &--secondary {
          @include get-font($f14, $fw-r, $lightBlack, 1.5);
        }
      }
    }
    &__action-btn {
      @extend %appearance-none;
      padding: 8px 12px;
      height: 36px;
      background: $reviewYellow;
      box-sizing: border-box;
      border: 0;
      border-radius: 5px;
      min-width: 125px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &__image {
        margin-right: 7px;
        width: 12px;
      }
      &__text {
        @include get-font($f14, $fw-b, $black, 1.5);
        letter-spacing: 0.7px;
      }
    }
  }
}


@media only screen and (max-width: $onlyMobile) {
  .product-cards{
    &__details{
      flex-direction: column;
      gap: 16px;
    }
    &__image-container {
      &__rating-container {
        margin-right: 0px;
      }
    }
  }
}