.poll-card {
  padding: 0 16px;
  &__expired-text {
    padding: 6px 10px;
    background: $lightestPink;
    box-sizing: border-box;
    height: 33px;
    @include get-font($f14, $fw-r, $removeRed, $lh);
    display: inline-block;
    border-radius: 5px;
    margin-bottom: 12px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    &__icon {
      margin-right: 6px;
      vertical-align: middle;
      width: 16px;
      height: 16px;
    }
    &__text {
      @include get-font($f12, $fw-m, $green, $lh);
      letter-spacing: 0.8px;
    }
    &__helper {
      @include get-font($f12, $fw-r, $lightBlack, $lh);
    }
  }
  &__details {
    position: relative;
    .feed-card-preview,
    .post-details__video-container {
      display: block;
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-top: -4px;
      margin-bottom: 24px;
    }
    &__image {
      width: 100%;
      object-fit: cover;
      background-color: $grayBackground;
      object-position: center;
      position: relative;
      aspect-ratio: 16 / 9;
      border-radius: 10px;
      border:1px solid rgba($black, 0.1);
      box-sizing: border-box;
      &__container {
        width: 100%;
        position: relative;
        margin-bottom: 24px;
        overflow: hidden;
        cursor: pointer;
      }
    }
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10;
    }
    &__title {
      @include get-font($f16, $fw-sb, $black, $lh);
      margin-bottom: 16px;
      margin-top: 16px;
      .post-details__link {
        font-size: inherit;
        font-weight: inherit;
      }
    }
    &__options {
      list-style: none;
      margin: 0;
      padding: 0;
      &__item {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        &__selected {
          border: 2px solid $green;
          border-radius: 34px;
          padding: 2px;
        }
        &__unselected {
          border: 2px solid transparent;
          border-radius: 34px;
          padding: 2px;
        }
      }
      &__option {
        height: 34px;
        border-radius: 34px;
        background: $grayBackground;
        display: flex;
        align-items: center;
        padding: 0 12px;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
        @include transitionEase();
        &:hover {
          background: $lightestBlack;
        }
        &--unplayable {
          cursor: default !important;
          &:hover {
            background: $grayBackground !important;
          }
        }
        &__play-icon {
          margin-right: 8px;
        }
        &__text {
          @include get-font($f14, $fw-m, $black, $lh);
          display: inline-block;
        }
        &__filled {
          top: 0;
          left: 0;
          background: rgba($green, 0.15);
          height: 100%;
          position: absolute;
        }
        &__details {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &__image {
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    &__zoom {
      position: absolute;
      top: 0;
      right: 0;
      background: $white;
      border: 0;
      padding: 6px;
      border-top-right-radius: 10px;
      display: block;
      margin-right: -1px;
      cursor: pointer;
      > img {
        display: block;
      }
    }
    &__options {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &__item {
        max-width: 253px;
        flex-shrink: unset;
        border: 1px solid $superLightBlack;
        border-radius: 10px;
        margin-bottom: 0px;
        overflow: hidden;
        position: relative;
        &:nth-child(n + 3) {
          margin-top: 16px;
        }
        &--selected {
          border-color: $green;
          box-shadow: 0 0 0 1px $green;
        }
        &__media{
          &.disabled{
            cursor: pointer;
          }
          position: relative;
          width: inherit;
          .media__action--wrapper{
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            gap: 30px;
            background-color: rgba($black, 0.2);
            .media__action{
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 30px;
              button{
                all: unset;
                background-color: transparent;
                cursor: pointer;
                height: 48px;
                width: 48px;
                img{
                  width: 100%;
                  height: 100%;
                }
              }
            }
           
          }
          &:hover{
            .media__action--wrapper{
              display: flex;
              &.disabled{
                display: none;
              }
            }
          }
        }
      }
    }
    &__title {
      @include get-font($f14, $fw-m, $black, $lh);
      padding: 8px;
      box-sizing: border-box;
      position: relative;
    }
    &__percentage {
      width: 100%;
      max-width: 184px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      height: 18px;
      background: $white;
      border-radius: 18px;
      overflow: hidden;
      &__filled {
        background: $green;
        height: 100%;
      }
      &__text {
        position: absolute;
        right: 12px;
        top: 0;
        @include get-font($f12, $fw-m, $black, $lh);
        &--centered {
          color: $white;
          right: 0;
          @include centerItem();
          text-align: center;
        }
      }
    }
    &__img {
      width: inherit;
      height: inherit;
      max-width: 253px;
      max-height: 193px;
      object-fit: cover;
      object-position: center;
    }
    &__details {
      height: auto;
    }
  }
  &__video{
    margin-bottom: 16px;
      & *{
        border-radius: 10px;
      }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .poll-card__image__options{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 16px;
    &__item{
      width: 100% !important;
      max-width: 100%;
      margin-top: 0px !important;
      &__media{
        width: 100%;
          .poll-card__image__img{
            width: 100%;
            max-width: 100%;
            border-bottom: 1px solid #ededed;
            object-fit: contain;
          }
      }
    }
  }
}

@media only screen and (max-width: $smallMobile) {
  .poll-card__image__options{
    gap: 16px;
    &__item{
      width: 100% !important;
      max-width: 100%;
      margin-top: 0px !important;
      &__media{
        width: 100%;
          .poll-card__image__img{
            width: 100%;
            max-width: 100%;
            border-bottom: 1px solid #ededed;
            object-fit: fill;
          }
      }
    }
  }
}


