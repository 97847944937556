$activeYellow: #fcba02;
$activeYellowBg: hsla(44, 98%, 50%, 0.15);

.profile-pill-label {
    display : block;
    &__list-text {
        @include get-font($f14, $fw-b, $black , $lh);
        &--centered-hidden {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            width: 100%;
        }
        &__helper {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
        }
    }
    &__eating-list {
        padding: 16px 24px 16px 24px;
        width: 100%;
        max-width: 335px;
        min-height: 95px;
        margin-bottom: 14px;
        border-radius: 5px;
        @include form-input-border-shadow();
        box-sizing: border-box;
        cursor: pointer;
        &--specialisation {
            padding: 8px;
            display: flex;
            align-items: center;
            > div {
                display: flex;
                align-items: center;
            }
        }
        &:hover {
            background-color: $grayBackground;
        }
    }
    &__allergens-list {
        @include form-input-border-shadow();
        width: 156px;
        height: 78px;
        @media only screen and (max-width: $onlyMobile) {
            width: 140px;
            height: 80px;
            margin: 0 6px 8px 6px;
        }
        text-align: center;
        background: $white;
        margin: 0 12px 16px 12px;
        padding: 10px 12px;
        border-radius: 10px;
        box-sizing: border-box;
        @include get-font($f14, $fw-m, $black, $lh);
        letter-spacing: -0.2px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
        border: solid 0.8px $superLightBlack;
        &:hover {
            background: $grayBackground;
        }
    }
    &__job-profile-list {
        @include form-input-border-shadow();
        width: 120px;
        height: 120px;
        @media only screen and (max-width: $onlyMobile) {
            width: 105px;
            height: 105px;
        }
        background: $white;
        margin: 4px;
        border-radius: 10px;
        @include get-font($f14, $fw-m, $black, $lh);
        letter-spacing: -0.2px;
        display: inline-block;
        padding: 0 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        &:hover {
            background: $grayBackground;
        }
    }
    &__input {
        visibility: hidden;
        position: absolute;
        &:checked + div {
            background: $activeYellowBg;
            border-color: $activeYellow;
        }
    }
    &__city-input {
        width: 100%;
        height: 36px;
        border-radius: 10px;
        background: $grayBackground;
        border: 0;
        @include get-font($f14, $fw-r, $black, $lh);
        outline: none;
        padding-left: 48px;
        background-image: url('assets/images/collaborations/form/pin-2.svg');
        background-repeat: no-repeat;
        background-position: 16px;
        background-size: 20px;
        box-sizing: border-box;
    }
    &__suggestion {
        padding: 8px;
        @include get-font($f16, $fw-r, $black, $lh);
        text-align: left;
        &__container {
            background: $white;
            @extend %border-box-shadow;
            border-radius: 5px;
            position: absolute;
            width: 260px;
            margin-top: 10px;
            border: 1px solid $superLightBlack !important;
            overflow: hidden;
            z-index: 10;
        }
    }
    &__specialisation-image {
        width: 60px;
        height: 60px;
        object-fit: contain;
        vertical-align: middle;
        &__container {
            width: 77px;
            height: 77px;
            border: 1px solid $grayBackground;
            margin-right: 8px;
            border-radius: 5px;
            line-height: 77px;
        }
        &--with-margin {
            margin-right: 8px;
            margin-top: 10px;
        }
        &--allergens {
            margin-right: 8px;
            @media only screen and (max-width: $onlyMobile) {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.profile-completion-btn {
    &__btn {
        margin-top: 20px;
        @media only screen and (max-width: $onlyMobile) {
            margin-bottom: 20px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__submit-btn {
            width: 70px;
            height: 32px;
            outline: none;
            border-radius: 7px;
            background-color: $activeYellow;
            cursor: pointer;
            border: 0;
            @include transitionEase();
            @include get-font($f14, $fw-b, $black);
            &--disabled {
                opacity: 0.5;
            }
            &:hover {
                background-color: #EDA200;
            }
            &.disabled{
                opacity: 0.3;
            }
        }
        &__cancel-btn {
            @include get-font($f14, $fw-r, $blue);
            background: none;
            border: none;
            outline: none;
            margin: 0;
            width: auto;
            margin-top: 10;
            @include transitionEase();
            &:hover {
                @include get-font($f14, $fw-r, #0c628a);
                cursor: pointer;
            }
        }
    }
}

.profile-updation-data {
    &__completed {
        background: rgba($green, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }
    &__container {
        padding: 24px 16px;
        box-sizing: border-box;
        position: relative;
        overflow: unset;
    }
    &__image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
    }
    &__image {
        border: 0;
        width: 104px;
        height: auto;
        object-fit: contain;
    }
    &__details {
        text-align: center;
        margin-bottom: 16px;
    }
    &__title {
        @include get-font($f18, $fw-b, $black, 1.5);
        margin-bottom: 8px;
    }
    &__meta {
        @include get-font($f14, $fw-r, $lightBlack);
    }
    &__textarea {
        width: 100%;
        box-sizing: border-box;
        height: 175px;
        resize: none;
        outline: 0;
        border-radius: 10px;
        background: $grayBackground;
        padding: 16px;
        border: 0;
        @include get-font($f16, $fw-r, $black, $lh);
    }
    &__btn-container {
        text-align: center;
    }
    &__btn {
        @extend %appearance-none;
        padding: 8px 12px;
        height: 36px;
        border-radius: 22px;
        background: $white;
        border: solid 1px $red;
        box-sizing: border-box;
        @include transitionEase(#{background});
        @include get-font($f14, $fw-r, $red);
        &:hover {
            background: #FFE6EE;
            cursor: pointer;
        }
        &--disabled {
            opacity: 0.6;
            cursor: wait;
        }
    }
    &__error {
        @include get-font($f12, $fw-sb, $red, $lh);
        margin-bottom: 8px;
    }
    &__date {
        &__container {
            width: 100%;
            height: 48px;
            padding: 12px 16px;
            box-sizing: border-box;
            background: $grayBackground;
            border-radius: 10px;
            text-align: left;
        }
        &__select {
            @extend %appearance-none;
            @include get-font($f16, $fw-r, $mediumBlack, $lh);
            vertical-align: top;
            border: 0;
            background: transparent;
        }
        &__separator {
            @include get-font($f16, $fw-r, $mediumBlack, $lh);
            margin-right: 5px;
            margin-left: 5px;
            vertical-align: top;
        }
    }
}

.profile-completion-modal {
    box-sizing: border-box;
    .default-modal__header{
        @include get-font($f18,$fw-sb,$black,$lh);
    }
    .default-modal__container {
        width: 490px;
        @media only screen and (max-width: $onlyMobile) {
            width: 100%;
            padding-bottom: 0;
            max-height: 99vh;
        }
    }
    &__label {
        @include get-font($f14, $fw-sb, $black, $lh);
        margin-bottom: 8px;
    }
    &__header {
        text-align: center;
        margin-top: -24px;
        margin-bottom: 16px;
        &__icon {
            margin-bottom: 8px;
            height: 104px;
        }
        &__title {
            @include get-font($f18, $fw-b, $black, $lh);
            margin-bottom: 8px;
        }
        &__helper {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
        }
    }
    &__input-default {
        @include get-font($f14, $fw-r, $black, $lh);
        padding-left: 16px;
        margin-top: 8px;
        outline: none;
        width: 420px;
        height: 37px;
        border-radius: 10px;
        margin-bottom: 20px;
        box-shadow: 0 1px 2px 0 $superLightBlack;
        border: solid 1.3px $superLightBlack;;
        &:focus {
            border-radius: 10px;
            border: solid 1.5px $grayBackground;
        }
    }
    &__body {
        max-height: 300px;
        overflow: hidden;
        overflow-y: auto;
        @media only screen and (max-width: $onlyMobile) {
            height: auto;
            max-height: none;
        }
    }
    &__pill-input-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        &__pill {
            @include form-input-border-shadow();
            background: $white;
            padding: 10px 12px;
            margin: 4px;
            border-radius: 10px;
            @include get-font($f14, $fw-m, $black, $lh);
            letter-spacing: -0.2px;
            display: inline-block;
            cursor: pointer;
            @include transitionEase(#{background});
            &:hover {
                background: $grayBackground;
            }
        }
        &__input {
            position: absolute;
            opacity: 0;
            &:checked + span {
                background: $activeYellowBg;
                border-color: $activeYellow;
            }
        }
    }
}

.follow-people {
    display: flex;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 12px;
    margin-top: 12px;
    border-bottom: 1px solid $superLightBlack;
    &__image-container {
        width: 40px;
        height: 40px;
        border: solid 0.5px $superLightBlack;
        border-radius: 50px;
        margin-right: 8px;
    }
    &__image {
        width: 40px;
        height: 40px;
    }
    &__details {
        width: 150px;
    }
    &__profile-name {
        @include get-font($f14, $fw-m, $black, $lh);
    }
    &__profile-desc {
        @include get-font($f12, $fw-r, $lightBlack, $lh);
    }
    &__btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30px;
    }
    &__btn {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
    &__see-more-btn {
        width: 100%;
        height: 55px;
        @include get-font($f12, $fw-b, $blue);
        letter-spacing: 0.6px;
        text-align: center;
        border: none;
    }
}

.profile-prompt{
    &-modal{
        .default-modal__container{
            width: 490px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .default-modal__header{
            @include get-font($f18,$fw-sb,$black,$lh);
        }
    }
    &__container{
        text-align: center;
    }
    &__content{
        padding:0 0 24px 0;

        &--label{
            @include get-font($f14,$fw-m,$black,$lh);
            &.secondary{
                @include get-font($f14,$fw-r,$copiedBackground,$lh);
            }
        }
        .form-textarea--expandable{
            min-height: 250px;
            max-height: 250px;
        }
        .input{
            &__container{
                display: flex;
                align-items: center;
                gap:5px;
                margin-top: 16px;
            }
            &__radio{
                margin: 0;
                appearance: none;
                &::before{
                    content: "";
                    display: block;
                    background: white;
                    height: 20px;
                    width: 20px;
                    border-radius: 100%;
                    border: 1px solid #747474;
                    box-sizing: border-box;
                }
                &:checked::before{
                    background:url("assets/images/donation/radio-on.svg") center center no-repeat;
                    background-size: 100%;
                    border: none;
                }
            }
            &__select{
                border: none;
                outline: none;
                &:focus,
                &:active{
                    border: none;
                    outline: none;
                }

                &.select-country{
                    width: 50px;
                    appearance: none;
                    border: 1px solid rgba(0, 0, 0, .1);
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
                    font-size: 14px;
                    color: #171717;
                    font-weight: 500;
                    line-height: 1.5;
                    border-radius: 0;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    text-align: center;

                    + .form-input{
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        padding: 6px 8px;
                        max-width: 170px;
                        font-size: 14px;

                        &::-webkit-inner-spin-button {
                            display: none;
                        }

                    }
                }
            }
            &__date{
                border: none;
                outline: none;
                &:focus,
                &:active{
                    border: none;
                    outline: none;
                }
                &--container{
                    border: 1px solid $lightGrey;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    border-radius: 8px;
                    gap: 16px;
                }
            }
            &__otp{
                width: 90px;
                padding: 6px 8px;
                font-size: 14px;
                text-align: center;

                &::-webkit-inner-spin-button {
                    display: none;
                }

                &--timer{
                    font-size: 14px;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .button{
            &-link{
                color:$blue;
                text-decoration: underline;
                cursor: pointer;
                &.disabled,
                &:disabled{
                    opacity: .4;
                    cursor: no-drop;
                }
            }
        }
        .phone-number{
            display: flex;
             margin-top: 8px;
             gap: 16px
        }
    }
    &__placeholder{
        &__container{
            height: 120px;
            width: 120px;
            border-radius: 100%;
            margin: auto;
            background-color: #DEDFE0;
            position: relative;
            border: 1px solid $lightGrey;
            
            > img{
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 100%;
            }
        }
    }
    &__title{
        margin: 16px 0 ;
        @include get-font($f18,$fw-ub,$black,$lh);

        &.success{
            color: $green;
            margin: 48px 0 24px 0;
        }
        &.error{
            color:$removeRed;
        }
    }
    &__subtitle{
        margin: 16px 0 24px 0 ;
        @include get-font($f16,$fw-m,$black,$lh);
    }
    &__action{
        cursor: pointer;
        display: block;
        padding: 16px 0;
        background-color: $black;
        border-radius: 8px;
        color:$white;
        margin-bottom: 24px;
        width: 100%;
        &.disabled{
            background-color: $lightGrey;
            cursor: no-drop;
        }
    }
    &__link{
        text-transform: uppercase;
        @include get-font($f14,$fw-b,$copiedBackground);
        &.disabled{
            opacity: .4;
            cursor: no-drop;
        }
    }
    &__error{
        margin-top: 8px;
        @include get-font($f14,$fw-m,$removeRed,$lh);
    }
    &__footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-top: 16px;
        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height:2px;
            width: calc(100% + 48px); // doing this bcoz common modal has padding outside
            box-shadow: 0 -2px 4px 0 $lightGrayText;
        }
        .profile-prompt__action{
            margin: 0;
            width: fit-content;
            font-weight: $fw-b;
            padding: 8px 16px;
            @include get-font($f16,$fw-b,$white,$lh);

            &.secondary{
                background-color: transparent;
                color: $removeRed;
                padding-left: 0;
            }
        }
    }
}

@media only screen and (max-width: $onlyMobile) {
    .profile-prompt{
        &-modal{
            .default-modal__container{
                overflow: unset;
            }
        }
    }
  }

  @media only screen and (max-width: $smallMobile) {
    .profile-prompt{
        &-modal{
            .default-modal__container{
                width: 100%;
            }
            .phone-number{
                flex-direction: column;
                align-items: baseline;
                gap: 8px;
            }
        }
    }
  }