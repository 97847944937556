.feed-common-cards {
  width: 100%;
  background: $white;
  border: 0.5px solid $superLightBlack;
  box-shadow: 0 2px 4px 0 $superLightBlack;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;
  text-decoration: none;
  display: block;
}

.feed-blog {
  .hashtag__trending {
    margin-bottom: 24px;

    &__title {
      letter-spacing: 0.6px;
    }
  }

  &--helper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    &__text {
      @include get-font($f12, $fw-b, $lightBlack, $lh);
      letter-spacing: 0.6px;
      text-decoration: none;

      &--link {
        color: $blue;
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    border: 0;
    margin-bottom: 8px;
  }

  &__details {
    padding: 8px 16px 24px;
  }

  &__title {
    @include get-font($f16, $fw-sb, $black, 1.5);
  }

  &__meta {
    @include get-font($f12, $fw-sb, $lightBlack, $lh);
    margin: 0 0 4px 0;
  }
}

.feed-user-info {
  &__media {
    position: relative;
    display: block;
  }

  &__cover {
    width: 293px;
    height: 125px;
    object-fit: cover;
  }

  &__image-container {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    border: 5px solid $white;
    left: 50%;
    bottom: -50px;
    transform: translateX(-50%);
    background-color: $white;
  }

  &__image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100px;
  }

  &__details {
    padding: 58px 16px 16px;
    text-align: center;

    &__meta {
      display: block;
      text-decoration: none;

      &__badge {
        margin: 8px 0;
      }

      &__text {
        @include get-font($f18, $fw-b, $black, 1.5);
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--secondary {
          @include get-font($f14, $fw-m, $black, 1.5);
          margin-bottom: 8px;
        }

        &--handle {
          @include get-font($f14, $fw-r, $black, 1.5);
          margin-bottom: 16px;
        }
      }
    }
  }

  &__followers_info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    &__block {
      flex: 1;
      text-align: center;
      display: block;
      text-decoration: none;

      &:first-child {
        border-right: 1px solid $grayBackground;
      }
    }

    &__text {
      &--header {
        @include get-font($f12, $fw-b, $red, 1.5);
        margin-bottom: 6px;
      }

      &--primary {
        @include get-font($f18, $fw-b, $black, 1.5);
      }
    }
  }
}

.feed-quick-link {
  width: calc(100% - 32px);
  padding: 16px;
  margin-bottom: 40px;

  &__title {
    border-bottom: 1px solid $superLightBlack;
    padding-bottom: 12px;
    @include get-font($f14, $fw-sb, $lightestGray, 1.5);
  }

  &__item {
    display: flex;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    margin: 0px -8px 8px -8px;
    position: relative;

    &:first-child {
      margin-top: 16px;
    }

    &__status {
      position: absolute;
      top: -1px;
      left: 20px;
      width: 37px;
      text-align: center;
      border: 1px solid $red;
      border-radius: 4px;
      @include get-font($f12, $fw-b, $white, $lh);
      background-color: $red;
      &.blink{
        @include blink($red, $white);
      }

      &.mobile {
        top: -10px;
        left: 7px;
      }
    }

    &__image {
      width: 48px;
      height: 48px;
    }

    &__text {
      margin-left: 12px;
      margin-top: 14px;
      @include get-font($f14, $fw-m, $lightestGray, 1.5);
    }

    &:hover {
      background-color: $grayerBackground;

      .feed-quick-link__item__text {
        color: $black;
      }
    }
  }
}

.feed-quick-link-mobile {
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  overflow: auto;
  // padding-bottom: 12px;
  padding: 20px 0px 20px 8px;
  background: $white;
  border-radius: 8px;

  &__item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    width: 50px;
    position: relative;
    // margin-right: 24px;
    margin-right: 20px;

    @media only screen and (max-width:$onlyMobile) {
      min-width: fit-content;
    }

    &__image {
      width: 40px;
      height: 40px;
    }

    &__text {
      margin-top: 8px;
      @include get-font($f14, $fw-m, $lightestGray, 1.5);
    }

    &.quick-links {
      margin-left: 9px;

      @media only screen and (max-width:360px) {
        margin-left: 6px;
      }
    }
  }
}