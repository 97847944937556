.explore {
    &__container {
        width: 100%;
        max-width: $centeredBigContentContainer;
        margin: 0 auto 52px;
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        &__item {
            margin-top: 64px;
            .review-page__collaboration {
                margin-top: 24px;
                &__item {
                    background: $white;
                }
            }
        }
    }
    &__empty {
        &__container {
            width: 100%;
            padding: 64px 0;
            text-align: center;
            > img {
                max-width: 100%;
            }
        }
        &__text {
            @include get-font($f18, $fw-sb, $lightBlack, $lh);
            margin-top: 32px;
            &--secondary {
                font-size: $f16;
                margin-top: 4px;
            }
        }
    }
}

@media only screen and (max-width: $onlyMobile) {
    .explore {
        &__container {
            margin-bottom: 48px;
        }
        &__empty {
            &__container {
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
            &__text {
                font-size: $f16;
                margin-top: 16px;;
                &--secondary {
                    font-size: $f14;
                }
            }
        }
        &__list {
            &__item {
                margin-top: 48px;
                .review-page__list__title {
                    font-size: $f14;
                }
                .review-page__list__sub-title {
                    font-size: $f12;
                    margin-top: 6px;
                }
                .review-page__list__title-container--secondary {
                    margin-right: 16px;
                }
                .review-page__list__sub-title-container__see-more {
                    width: 16px;
                    height: auto;
                }
                .review-page__collaboration {
                    margin-top: 16px;
                }
                .review-page__collaboration__item {
                    background: transparent;
                    &:last-child {
                        .review-page__collaboration__item--primary {
                            border-bottom: 0;
                        }
                    }
                    &-link {
                        background: transparent;
                    }
                }
            }
        }
    }
}