.onboarding-profile-detail {
    &__logout {
        position: absolute;
        top: 16px;
        right: 16px;
        @extend %appearance-none;
        background: transparent;
        @include get-font($f12, $fw-sb, $mediumBlack);
        padding: 0;
        border: 0;
        cursor: pointer;
        z-index: 10;
        @include transitionEase(#{color});
        &:hover, &:active, &focus {
            color: $black;
        }
    }
    &__container {
        width: 100%;
        max-width: 560px;
        margin: 0 auto;
    }
    &__header {
        margin: 0 auto;
        @include get-font($f28, $fw-sb, $black, $lhl);
        text-align: center;
        margin: 24px 0 36px 0;
        @media only screen and (max-width: $onlyMobile) {
            margin: 12px 0 18px 0;
            font-size: $f18;
        }
    }
    &__meta {
        width: 100%;
        text-align: center;
    }
    &__form {
        display: flex;
        margin-bottom: 78px;
        @media only screen and (max-width: $onlyMobile) {
            margin-bottom: auto;
            display: block;
        }
        &__image-container {
            cursor: pointer;
            display: block;
            width: 100%;
        }
        &__image {
           margin-top: 16px;
           width: 142px;
           height: 142px;
           border-radius: 50%;
           border: 1px solid $superLightBlack;
           object-fit: cover;
           @media only screen and (max-width: $onlyMobile) {
               margin-top: 0;
           }
        }
        &__input-block {
            display: flex;
            margin-top: 16px;
            @media only screen and (max-width: $onlyMobile) {
                margin-top: 32px;
            }
        }
        &__input-container {
            // border: solid 1px $superLightBlack;
            border-bottom: 1px solid $lightGray;
            padding-bottom: 16px;
            width: 100%;
            position: relative;
        }
        &__label {
            @include get-font($f16, $fw-sb, $black, $lh);
            width: 88px;
            margin-right: 10px;
        }
        &__error {
            @include get-font($f12, $fw-r, $red, $lhxs);
            margin-top: 6px;
        }
        &__input {
            max-width: 300px;
            width: 100%;
            border: none;
            display: block;
            outline: none;
            @include get-font($f16, $fw-r, $black, $lh);
        }
        &__genders {
            list-style: none;
            padding: 0;
            display: flex;
            margin: 0;
            @media only screen and (max-width: $onlyMobile) {
                display: flex;
                gap: 8px;
                flex-wrap: wrap;
            }
            &__label {
                position: relative;
                display: flex;
                @media only screen and (max-width: $onlyMobile) {
                    margin-bottom: 8px;
                }
            }
            &__title {
                padding-right: 16px;
            }
            &__input {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                & + .onboarding-profile-detail__form__genders__label__image {
                    width: 20px !important;
                    height: 20px !important;
                    margin-right: 8px;
                    background-image: url('assets/images/onboarding/radio_off.png');
                    background-size: contain;
                    display: block;
                }
                &:checked + .onboarding-profile-detail__form__genders__label__image {
                    width: 20px !important;
                    height: 20px !important;
                    margin-right: 8px;
                    background-image: url('assets/images/onboarding/radio_on.png');
                    background-size: contain;
                    display: block;
                }
            }

        }
        &__textarea {
            max-width: 300px;
            width: 100%;
            height: 84px;
            resize: none;
            outline: 0;
            border: 0;
            @include get-font($f16, $fw-r, $black, $lhOnboarding);
            @media only screen and (max-width: $onlyMobile) {
                 height: 48px;
            }
        }
        .add-photo-header {
            @include get-font($f14, $fw-r, $blue, $lhs);
            text-align: center;
        }

    }
    &__right{
        margin-left: 30px;
        @media only screen and (max-width: $onlyMobile) {
            margin-left: 0;
            width: 100%;
            padding: 0 16px;
            box-sizing: border-box;
        }
    }
    &__helper {
        @include get-font($f12, $fw-r, $lightBlack, $lhxs);
        text-align: center;
        @media only screen and (max-width: $onlyMobile) {
            padding: 0 16px;
            margin: 16px 0;
        }
        &__head {
            @include get-font($f12, $fw-sb, $lightBlack, $lhxs);
            //text-decoration: none;
        }
    }
}
