.services {
    background-color: $white;
    margin-top: 40px;

    //style={{ position: 'sticky', width: '140px', float: 'left', top: '80px', display: 'inline-block', marginRight: '20px' }}
    &-list {
        position: sticky;
        width: 140px;
        float: left;
        top: 80px;
        display: inline-block;
        margin-right: 20px;

        @media only screen and (max-width: $tab) {
            display: none;
        }

        &__item {
            position: relative;
            background-color: $white;
            border: 2px solid #F5F8FA;
            border-radius: 40px;
            margin-bottom: 32px !important;
            cursor: pointer;

            &--content {
                display: block;
                background-color: #F5F8FA;
                margin: 8px;
                padding: 8px 16px;
                border-radius: 40px;
                @include get-font($f16, $fw-m, $copiedBackground, $lh);
                text-align: center;
            }

            &.filled {
                border: 2px solid $red;

                .services-list__item--content {
                    background-color: $red;
                    color: $white;
                }

                // &::after {
                //     background-color: $red;
                // }
            }

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 2px;
                background-color: #F5F8FA;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                margin-top: 2px;
                // &.filled{
                //     background-color: $red;
                // }
            }

            &:last-child::after {
                content: none;
            }
        }

        &__content {
            display: inline-block;
            width: calc(100% - 160px);

            @media only screen and (max-width: $tab) {
                display: block;
                width: 100%;
            }

        }

    }

    .container {
        max-width: $maxWidthContainer;
        margin: 0 auto;
        &.relative{
            position: relative;
            z-index: 1;
        }
    }

    .section {
        padding: 40px;

        &.filled {
            background-color: #F5F8FA;

            &.rounded {
                border-radius: 0 0 100% 100%;
                height: 280px;

                @media only screen and (max-width: $onlyMobile) {
                    height: fit-content;
                    border-radius: unset;
                }
            }
        }

        @media only screen and (max-width: $onlyMobile) {
            padding: 30px 16px 16px 16px;
        }
    }

    .list-flexed {
        display: flex;
        align-items: center;
        gap: 16px;

        &.centered {
            justify-content: center;
        }

        &.wrapped {
            flex-wrap: wrap;
            gap: 8px;

            &.no-gap {
                gap: 0px;
            }

        }

        &.grid-items {
            @media only screen and (max-width: $onlyMobile) {
                // justify-content: center;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            @media only screen and (min-width: $onlyMobile) and (max-width: $tab) {
                // justify-content: center;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }

            li,
            div {
                @media only screen and (max-width: $tab) {
                    width: 100%;
                }
            }
        }

        &.client-list {
            @media only screen and (max-width: $onlyMobile) {
                // justify-content: center;
                display: grid;
                grid-template-columns: repeat(3, 1fr);

                .customer-list__item {
                    width: 100%;

                    img {
                        max-width: 100%;
                    }
                }
            }

            @media only screen and (min-width: $onlyMobile) and (max-width: $tab) {
                // justify-content: center;
                display: grid;
                grid-template-columns: repeat(4, 1fr);

                .customer-list__item {
                    width: 100%;

                    img {
                        max-width: 100%;
                    }
                }
            }
        }

        &.domain-list__item {
            gap: 4px;
            display: grid;
            grid-template-columns: repeat(6, 1fr);

            @media only screen and (max-width: $onlyMobile) {
                grid-template-columns: repeat(2, 1fr);
            }

            li {
                &:nth-child(6) {
                    div {
                        border-top-right-radius: 16px;
                        @media only screen and (max-width: $onlyMobile) {
                            border-radius: unset;
                        }
                    }

                }
                @media only screen and (max-width: $onlyMobile) {
                    &:nth-child(2) {
                        div {
                            border-top-right-radius: 16px;
                        }
                    }
                    &:nth-last-child(2) {
                        div {
                            border-bottom-right-radius: 16px;
                        }
                    }
                }

                &:nth-child(18) {
                    div {
                        border-bottom-right-radius: 16px;
                        @media only screen and (max-width: $onlyMobile) {
                            border-radius: unset;
                        }
                    }

                }

                &:nth-child(19) {
                    div {
                        border-bottom-left-radius: 16px;
                        @media only screen and (max-width: $onlyMobile) {
                            border-radius: unset;
                        }
                    }

                }

                &:first-child {
                    div {
                        border-radius: 16px 0 0 0;
                    }
                }

                &:last-child {
                    div {
                        border-radius: 0 0 16px 0;
                        @media only screen and (max-width: $onlyMobile) {
                            border-bottom-left-radius: 16px;
                        }
                    }
                }
            }
        }

        &__item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;

            &--image {
                height: auto;
                width: 20px;
            }

            &--title {
                @include get-font($f16, $fw-m, $black);

                @media only screen and (max-width: $onlyMobile) {
                    font-size: 14px;
                }
            }

            &.parameter-item {
                border: 1px solid #F5F8FA;
                border-radius: 24px;
                background-color: #F5F8FA;
            }

            &.offer-item {
                height: 197px;
                width: 280px;
                border-radius: 24px;
                padding: 24px;
                background-color: $white;
                flex-direction: column;
                justify-content: center;
                box-sizing: border-box;
                text-align: center;
                background-color: #F5F8FA;

                .list-flexed__item--image {
                    width: 50px;
                }

                @media only screen and (max-width: $onlyMobile) {
                    height: 165px;
                    padding: 24px 16px;
                    font-size: $f14;
                    width: 100%;
                }
            }

            &.domain-item {
                height: 136px;
                width: 198px;
                background-color: #F5F8FA;
                box-sizing: border-box;
                padding: 16px;
                flex-direction: column;
                text-align: center;
                border: 1px solid #F5F8FA;
                .list-flexed__item--title{
                    @include get-font($f16, $fw-r, $black, $lh);

                    @media only screen and (max-width: $onlyMobile) {
                        font-size: $f14;
                    }
                }
                .list-flexed__item--image {
                    width: 48px;
                }

                &:hover {
                    background-color: $white;
                }

                @media only screen and (max-width: $onlyMobile) {
                    // width: 170px;
                    width: 100%;
                }
            }
        }

        @media only screen and (max-width: $onlyMobile) {
            justify-content: center;
        }
    }

    .onboarding-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        width: 80%;
        margin: auto;
        gap: 24px;

        @media only screen and (max-width: $onlyMobile) {
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
        }

        .onboarding-item {
            height: 308px;
            width: 100%;
            border-radius: 24px;
            padding: 24px;
            background-color: $white;
            flex-direction: column;
            justify-content: flex-start;
            box-sizing: border-box;
            text-align: center;
            background-color: $white;

            @media only screen and (max-width: $onlyMobile) {
                height: auto;
            }

            .icon-title {
                @include get-font($f18, $fw-sb, #1F2937, $lh);

                @media only screen and (max-width: $onlyMobile) {
                    font-size: $f16;
                }
            }

            .list-flexed__item--image {
                width: 55px;
                height: auto;
            }

            .list-flexed__item--title {
                @include get-font($f16, $fw-r, $black, $lh);

                @media only screen and (max-width: $onlyMobile) {
                    font-size: $f14;
                }
            }
        }
    }

    &__title {
        text-align: center;
        @include get-font($f48, $fw-b, $black, 1.4);

        &.intro-title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
        }

        @media only screen and (max-width: $onlyMobile) {
            font-size: $f22;
            flex-direction: column;

            &.intro-title {
                >span {
                    max-width: 120px;
                    margin: 8px 0;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__content {
        &--title {
            margin: 0;
            text-align: center;
            @include get-font($f32, $fw-sb, $black, $lh);

            &.text-left {
                text-align: left;
            }

            @media only screen and (max-width: $onlyMobile) {
                font-size: $f18;
            }
        }

        &--subtitle {
            text-align: center;
            margin: 16px 0 32px 0;
            @include get-font($f16, $fw-r, $copiedBackground, $lh);

            &.text-left {
                text-align: left;
            }

            &.text-justify {
                text-align: justify;
            }

            &.text-center {
                text-align: center;
            }

            @media only screen and (max-width: $onlyMobile) {
                font-size: $f14;
            }
        }

    }

    &__link {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 12px 24px;
        background-color: $red;
        border-radius: 24px;
        margin: 40px 0;
        text-decoration: none;
        @include get-font($f16, $fw-m, $white, $lh);

        &.centered {
            margin: 40px auto;
        }

        &--icon {
            display: none;
            rotate: 180deg;
        }

        .services__link--icon {
            display: block;
        }

        @media only screen and (max-width: $onlyMobile) {
            width: 100%;
            box-sizing: border-box;
        }
    }

    .with-action {
        display: flex;
        gap: 16px;
        align-items: flex-start;

        .action--container {
            display: flex;
            justify-content: space-between;
            min-width: 112px;
            background-color: $white;
            min-height: 50px;
            box-sizing: border-box;
            padding: 4px;
            border-radius: 24px;

            .action {
                display: block;
                height: 48px;
                width: 48px;
                background-color: #F3F4F6;
                border-radius: 50%;
                cursor: pointer;

                &.prev {
                    img {
                        rotate: -180deg;
                    }
                }

                &:disabled {
                    cursor: no-drop;

                    img {
                        filter: invert(93%) sepia(0%) saturate(180%) hue-rotate(201deg) brightness(90%) contrast(94%);
                    }
                }
            }
        }

        @media only screen and (max-width: $onlyMobile) {
            flex-wrap: wrap;
        }
    }

    .category-list {
        white-space: nowrap;
        overflow-x: auto;
        padding-bottom: 24px;
        margin-bottom: 8px;

        .list-flexed__item {
            padding: 8px 16px;
            border-radius: 24px;
            background-color: #F5F8FA;
            @include get-font($f16, $fw-m, $black, $lh);
            border: 1px solid $imageWrapperBackgroundGray;

            &.active {
                background-color: $black;
                color: $white;
                border: none;
            }
        }

        @media only screen and (max-width: $onlyMobile) {
            justify-content: unset;
        }
    }

    .customer-list {
        &__item {
            width: 132px;
            height: 122px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #F5F8FA;

            img {
                height: 100%;
                width: auto;
                max-width: 100px;
                object-fit: contain;
            }
        }

        @media only screen and (max-width: $onlyMobile) {
            display: grid;
            grid-template-columns: repeat(5, 132px);
            overflow-x: auto;
            justify-content: unset;
        }
    }

    .contact-us {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 36px 32px;
        background-color: #FDE4D06B;
        border-radius: 16px;
        flex-wrap: wrap;

        

        &__info {
            flex: 1 0 45%;

            @media only screen and (max-width: $onlyMobile) {
                flex: 1 1 100%;
            }
        }
        

        &__form {
            flex: 1 0 45%;
            background-color: $white;
            padding: 32px;
            box-sizing: border-box;
            border-radius: 16px;

            @media only screen and (max-width: $onlyMobile) {
                flex: 1 1 100%;
                padding: 32px 16px;
                width: 100%;
            }

            .form__inputs {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;
                gap: 20px;

                &> :nth-child(n+3) {
                    grid-column: 1 / -1;
                }

                .input__container {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    border-radius: 8px;
                    box-sizing: border-box;
                    min-height: 50px;
                    border: 1px solid $imageWrapperBackgroundGray;
                    border-radius: 8px;
                    padding: 8px 16px;

                    &:active,
                    &:focus-within {
                        outline: none;
                        border: 1px solid $mediumBlack;
                    }

                    &.error {
                        border-color: $red;
                    }

                    input,
                    select,
                    textarea {
                        border: none;
                        outline: none;
                        width: 100%;
                        height: 100%;
                        resize: none;

                        &:active,
                        &:focus {
                            border: none;
                            outline: none;
                        }
                    }

                    textarea {
                        height: 90px;
                    }
                }

                @media only screen and (max-width: $onlyMobile) {
                    display: flex;
                    flex-direction: column;
                }
            }

            &--action {
                cursor: pointer;
                margin: 16px 0;
                width: 100%;
                height: 50px;
                border-radius: 8px;
                border: none;
                @include get-font($f14, $fw-m, $white, $lh);

                &:active,
                &:focus {
                    border: none;
                    outline: none;
                    background-color: $black;
                }

                background-color: $black;

                &:disabled {
                    opacity: .4;
                    cursor: no-drop;
                }
            }
        }

        @media only screen and (max-width: $onlyMobile) {
            padding: 36px 16px;
        }
    }
    .contact-us::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 438px;
        background: url('assets/images/services/contact-form.svg') no-repeat;
        background-size: contain;
        z-index: -1;
        opacity: 0.3;
        @media only screen and (max-width: $onlyMobile) {
           height: 350px;
        }
    }

    // project execution // 
    .project--flow-image__wrapper {
        @media only screen and (max-width: $onlyMobile) {
            overflow: hidden;
            overflow-x: scroll;

            img {
                // width:100%;
                height: auto;
            }
        }

        @media only screen and (min-width: $onlyMobile) and (max-width: $tab) {
            overflow: hidden;
            overflow-x: scroll;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    //about us styling 

    .introduction {
        &__content--item {
            display: flex;
            margin-top: 40px;
            gap: 24px;

            &.filled {
                background-color: #F5F8FA;
            }

            &:nth-child(2n) {
                .introduction__content--item__info {
                    order: 2;

                    @media only screen and (max-width: $tab) {
                        order: unset;
                    }
                }

            }

            &__image {
                display: block;
                max-width: 580px;
                flex: 1 0 auto;

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 16px;
                }

                @media only screen and (min-width: $onlyMobile) and (max-width: $tab) {
                    align-self: center;
                }
            }

            @media only screen and (max-width: $tab) {
                flex-direction: column-reverse;
            }
        }
    }

    .ecosystem__content {
        display: flex;
        gap: 8px;
        flex-direction: column;

        .image__container{
            display: block;
            max-width: 60%;
            margin: auto;

            img {
                display: block;
                width: 100%;
            }

            @media only screen and (max-width: $onlyMobile) {
                max-width: 100%;
            }
        }

        
    }

    .team-content{
        margin-bottom: 24px;
    }

    .team-title {
        @include get-font($f32, $fw-sb, $black, $lh);
        margin-bottom: 16px;

        @media only screen and (max-width: $onlyMobile) {
            font-size: $f18;
            text-align: center;
        }
    }

    .team-list {
        overflow-x: auto;
        padding-bottom: 16px;
        align-items: flex-start;

        &__item {
            min-width: 280px;
            max-width: 280px;
            white-space: nowrap;
            box-sizing: border-box;

            @media only screen and (max-width: $onlyMobile) {
                min-width: 150px;
                max-width: 150px;
            }

            img {
                display: block;
                min-height: 250px;
                background-color: $copiedBackground;
                border-radius: 25px;
                width: 100%;

                @media only screen and (max-width: $onlyMobile) {
                    min-height: auto;
                }
            }

            h6 {
                margin: 4px 0;
                @include get-font($f22, $fw-sb, $darkBlack, $lh);
                white-space: break-spaces;

                @media only screen and (max-width: $onlyMobile) {
                    font-size: $f16;
                }
            }

            p {
                min-height: 27px;
                @include get-font($f16, $fw-m, $copiedBackground, $lh);
                white-space: break-spaces;

                @media only screen and (max-width: $onlyMobile) {
                    font-size: $f14;
                }
            }
        }

        @media only screen and (max-width: $onlyMobile) {
            justify-content: center;
            align-items: flex-start;
            gap: 24px;
        }
    }

    .digital-platform {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 24px;
        margin-top: 24px;

        @media only screen and (max-width: $onlyMobile) {
            grid-template-columns: auto;
        }

        .first--container {
            @media only screen and (max-width: $onlyMobile) {
                width: 100%;
            }

            .title {
                @include get-font($f18, $fw-r, $black, $lh);
                border-radius: 16px;
                background-color: #F5F8FA;
                padding: 16px 24px;
                margin-bottom: 16px;
                width: 324px;
                border: 1px solid transparent;

                &:hover {
                    background-color: $white;
                    border: 1px solid #4990E2;
                }

                @media only screen and (max-width: $onlyMobile) {
                    width: auto;
                }
            }
        }

        .image-container {
            img {
                width: 100%;
                height: auto;
            }
        }
        .third-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 16px;

            .item {
                background-color: white;
                @include get-font($f16, $fw-m, $black, $lh);
                border-radius: 20px;
                display: flex;
                min-width: fit-content;
                width: fit-content;
                align-items: center;
                gap: 12px;
                padding: 8px 16px;
                border: 1px solid #F5F8FA;
                box-shadow: 0px -29px 8px 0px rgba(0, 0, 0, 0.00), 0px -19px 8px 0px rgba(0, 0, 0, 0.01), 0px -11px 6px 0px rgba(0, 0, 0, 0.02), 0px -5px 5px 0px rgba(0, 0, 0, 0.04), 0px -1px 3px 0px rgba(0, 0, 0, 0.04);

                &:hover {
                    color: $red;

                    .img {
                        display: none;
                    }

                    .fill-img {
                        display: block
                    }
                }

                .img {
                    width: auto;
                    height: auto;
                    display: block;
                }

                .fill-img {
                    width: auto;
                    height: auto;
                    display: none
                }
            }

        }
    }

    .powered-text {
        @include get-font($f14, $fw-b, $black, $lh);
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 32px;
    }

    .engine-img {
        margin: auto;
        width: 66%;

        @media only screen and (max-width: $onlyMobile) {
            width: 100%;
            overflow: hidden;
            overflow-x: scroll;
        }
    }

    .sticky-container {
        position: sticky;
        top: 80px
    }

    .curve-div::before {
        width: 44px;
        height: 44px;
        border: 1px solid #E5E7EB;
        position: absolute;
        content: "";
        top: 39px;
        bottom: 0;
        right: 0;
        left: -45px;
        border-bottom-right-radius: 70%;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top-width: 0px;
        border-left-width: 0px;

        &.non-active {
            border: unset;
        }
    }

    .curve-div {
        border: 1px solid #E5E7EB;
        width: 160px;
        height: 48px;
        margin-left: 81px;
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
        position: relative;
        box-shadow: 0px 1px 2px 0px #E5E7EB;
        border-bottom-width: 0;
        background-color: white;

        .curve-inner-div {
            width: fit-content;
            height: fit-content;
            padding: 16px;
            // border-radius: 38px;
            border-bottom-color: white;
            background-color: white;
            border-top-left-radius: 36px;
            border-top-right-radius: 36px;
            cursor: pointer;

            .title {
                display: flex;
                padding: 12px 22px;
                justify-content: center;
                align-items: center;
                gap: 12px;
                border-radius: 28px;
                border: 1px solid #E5E7EB;
                background: #F5F8FA;
                @include get-font($f16, $fw-m, #1F2937, $lh);
            }
        }

        &.two {
            margin-left: 241px;
        }

        &.three {
            margin-left: 401px;
        }

        &.four {
            margin-left: 561px;
        }

        &.five {
            margin-left: 721px;
        }

        @media only screen and (max-width: $onlyMobile) {

            &.one,
            &.two,
            &.three,
            &.four,
            &.five {
                margin-left: 81px;
            }
        }
    }

    .curve-div::after {
        width: 44px;
        height: 44px;
        border: 1px solid #E5E7EB;
        position: absolute;
        content: "";
        top: 39px;
        bottom: 0;
        right: 0;
        left: 160px;
        border-top-width: 0;
        border-right-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 70%;
    }


    .outer-div {
        border: 1px solid #F60E3A;
        border-radius: 30px;
        padding: 8px;

        .inner-div {
            padding: 8px 16px;
            min-width: fit-content;
            width: fit-content;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            background-color: #F60E3A;
            border-radius: 20px;
            color: white;
        }
    }

    .outer-div::before {
        width: 10px;
        height: 44px;
        border: 1px solid #E5E7EB;
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: red;
    }

    .service-container {
        // border: 1px solid #E5E7EB;
        border-radius: 40px;
        padding: 24px;
        margin-top: 0px;
        background-color: white;
        margin-bottom: 36px;
        padding-bottom: 20px;
        box-sizing: border-box;
        @include getDefaultScrollbar();

        &.filled {
            background-color: #F5F8FA;
        }

        .training-success__container {
            display: flex;
            gap: 28px;
            align-items: flex-start;
            justify-content: space-between;
            scroll-margin-top: 120px;

            @media only screen and (max-width: $onlyMobile) {
                flex-direction: column;
            }
        }

        .brand-image {
            margin: 16px 0px;

            img {
                width: auto;

                @media only screen and (max-width: $onlyMobile) {
                    width: 100%;
                }
            }
        }

        .training-content {
            &__section-index {
                display: none;
                margin-bottom: 8px;
                width: fit-content;
                border: 1px solid #E5E7EB;
                background-color: #F5F8FA;
                padding: 4px 12px;
                border-radius: 50px;
                @include get-font($f12, $fw-r, #1F2937, $lh);

                @media only screen and (max-width: $tab) {
                    display: block;
                }
            }
            
            .list-title{
                display: inline-block;
                margin-bottom: 24px;

                @media only screen and (max-width: $onlyMobile) {
                    margin-bottom: 0;
                }
            }            
        }

        .title {
            @include get-font($f32, $fw-sb, $black, $lh);

            @media only screen and (max-width: $onlyMobile) {
                font-size: 18px;
            }
        }

        .subtitle {
            margin-bottom: 16px;
            margin-top: 12px;
            @include get-font($f16, $fw-r, #1F2937, $lh);

            @media only screen and (max-width: $onlyMobile) {
                font-size: 14px;
            }
        }

        .image {
            img {
                width: 400px;
                height: auto;
                object-fit: contain;

                @media only screen and (max-width: $onlyMobile) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .flowchart {
            width: 100%;
            margin: 32px auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: auto;

                @media only screen and (max-width: $tab) {
                    width: 100%;
                }
            }
        }

        .text {
            margin-top: 16px;
            display: flex;
            align-items: flex-start;
            gap: 12px;
            @include get-font($f18, $fw-m, $black, $lh);

            .bullet-text {
                min-width: fit-content;
            }

            @media only screen and (max-width: $onlyMobile) {
                font-size: 14px;
            }
        }

        .images-container {
            display: flex;
            gap: 16px;
            margin: 20px 0px;

            @media only screen and (max-width: $onlyMobile) {
                justify-content: space-between;

                >img {
                    max-width: 50px;
                    object-fit: contain;
                }
            }
            &__wrapper{
                display: block;
                max-width: 113px;

                img{
                    width: 100%;
                    height: auto;
                }

                @media only screen and (max-width: $onlyMobile) {
                    max-width: 50px;
                }
            }
        }

        .taste-img {
            border-radius: 16px;
            background: #F3F6F8;
            padding: 16px;

            @media only screen and (max-width: $onlyMobile) {
                padding: 7px;

                img {
                    max-width: 34px;
                    object-fit: contain;
                }
            }

        }

        .outer-circle {
            border-radius: 50%;
            padding: 4px;
            background-color: #FFECF0;
            margin-top: 4px;

            .inner-circle {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $red;
            }
        }

        .heading {
            margin: 24px 0px;
            @include get-font($f20, $fw-sb, #1F2937, $lh);

            @media only screen and (max-width: $onlyMobile) {
                font-size: 14px;
            }
        }

        .rating-image {
            margin: 20px 0px;

            @media only screen and (max-width: $onlyMobile) {
                width: 100%;
            }
        }

        .rating-quality {
            border-radius: 20px;
            background: $white;
            display: flex;
            min-width: 400px;
            padding: 20px;
            align-items: center;
            flex-direction: column;
            gap: 12px;

            @media only screen and (max-width: $onlyMobile) {
                min-width: auto;
                background-color: #F5F8FA;
                padding: 0;
            }

            &__content {
                border-radius: 20px;
                border: 1.292px solid $white;
                background: linear-gradient(270deg, rgba(246, 246, 246, 0.10) 29.68%, $white 51.11%);
                box-shadow: 7.539px 12.923px 54.277px 0px rgba(11, 8, 8, 0.06);
                display: flex;
                padding: 16px;
                gap: 8px;
                align-items: center;
            }

            .start-text {
                text-align: center;
                margin-top: 4px;
                @include get-font($f10, $fw-b, $red, $lh);
            }

            .title {
                @include get-font($f14, $fw-sb, #2C2C2C, $lh);
            }

            .subtitle {
                margin: 0;
                @include get-font($f12, $fw-r, #2C2C2C, $lh);
            }
        }

        .list-item {
            @include get-font($f16, $fw-r, $black, $lh);

            @media only screen and (max-width: $onlyMobile) {
               font-size: 14px;
            }

        }

        .more-text {
            @include get-font($f16, $fw-r, #4990e2, $lh);
            margin: 0;
            padding: 0;
            margin-left: 36px;
            margin-top: 8px;
            cursor: pointer;
        }

    }
}

.services-bigbanner {
    display: block;
    border-radius: 20px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px 0 $superLightBlack;
    margin-top: 24px;
    height: auto;

    @media only screen and (max-width: $onlyMobile) {
        box-shadow: none;
    }

    .slick-dots{
        bottom: 0;
    }

    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
        border-radius: 20px;
        height: 584px;
    }

    @media only screen and (max-width: $tab) {

        .slick-slider,
        .slick-list,
        .slick-track,
        .slick-slide {
            border-radius: 12px;
            height: auto;
        }
    }

    &__slideritem {
        border-radius: 20px;

        img {
            width: 100%;
            border-radius: 20px;
            background: $white;
            max-height: 584px;
            object-fit: cover;
        }
    }

    &__ul {
        margin: 0px 24px 6px 0px;
        padding-top: 6px;
        height: 10px;

        li {
            @media only screen and (max-width: $onlyMobile) {
                width: 4%;
            }
        }
    }


    .slick-active {
        width: 18px;
        top: 0px;
        overflow: hidden;

        .services-bigbanner__customdot {
            width: 18px;
            top: 0px;
            overflow: hidden;
            background-color: $white;
        }

        @media only screen and (max-width: $onlyMobile) {
            width: 4%;

            .feed-bigbanner__customdot {
                width: 100%;
            }
        }
    }

    &__customdot {
        height: 4px;
        width: 18px;
        background-color: $chatSender;
        border-radius: 10px;
        position: relative;

        @media only screen and (max-width: $onlyMobile) {
            width: 100%;
        }
    }
}

.services-partner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    @media only screen and (max-width: $onlyMobile) {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__slideritem {
        &--content {
            border: 1px solid #F5F8FA;
            border-radius: 8px;
            height: 92px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px;
            width: 134px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}