.accountManagement {
  &__back__btn {
    color: gray;
    margin: 2em 0 0 2em;
    background: transparent;
    border: none;
    padding: 0;
    display: flex;
    cursor: pointer;
    img {
      height: 1.2em;
      align-self: center;
    }
    span {
      margin-left: 1em;
      align-self: center;
      color: #747474;
    }
  }
  .settings-container {
    padding-bottom: 0;
    &__heading {
      padding-top: 16px;
    }
  }
  .collaboration-form {
    max-width: none;
    padding: 32px 16px;
    width: unset;
    &__group {
      margin-bottom: 1em;
      &--multiple {
        align-items: unset;
      }
    }
    &__action {
      &__back {
        color: $removeRed;
        font-weight: 600;
        padding: 8px 16px;
      }
      &__btn {
        background: #171717;
        &--disabled {
          background: #d1d1d1;
        }
        // &--text {
        // }
      }
    }
    &__additional-activity {
      border-bottom: none !important;
      &__list {
      }
      &__company_action {
      }
    }
    &__additional-content {
      padding: 16px;
      .collaboration-form__action {
        border-top: unset;
        margin: unset;
        padding: unset;
      }
    }
  }
  &__otp {
    .collaboration-form {
      &__input {
        letter-spacing: 0.5em;
      }
      &__group__label--helper--is-mobile {
        text-align: right;
        opacity: 0;
      }
    }
    .resend-otp-btn {
      &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &__btn {
        align-self: flex-end;
        background: $white;
        border-radius: 8px;
        height: 3.7em;
        padding: 0 1em;
        transition: 300ms all ease-in-out;
        &__disabled {
          border: 1px solid $lightBlue;
          cursor: not-allowed;
          span {
            color: $lightBlue;
          }
        }
        &__active {
          border: 1px solid $blue;
          span {
            color: $blue;
          }
        }
      }
    }
  }
  .activity {
    ul {
      margin: 0;
      li {
        list-style: none;
      }
    }
    .collaboration-form {
      padding: 16px 24px !important;
      // border-bottom: 8px solid rgba(0, 0, 0, 0.05);
    }
    &__list {
      padding: 16px 0 0 0;
      &__card {
        border-top: 8px solid rgba(0, 0, 0, 0.05);
        &__wrapper {
          a {
            text-decoration: none;
          }
        }
        &__passbook {
          &__header {
            padding: 0 24px 8px;
            &__text {
              @include get-font($f16, $fw-sb, $orange, $lh);
            }
            &__subtext {
              @include get-font($f14, $fw-r, $lightestGray, $lh);
            }
          }
          &__description {
            padding: 16px 24px;
            display: flex;
            justify-content: space-between;
            background: $grayBackground;
            &__text {
              @include get-font($f16, $fw-sb, $black, $lh);
              flex: 1;
            }
            &__subtext {
              @include get-font($f16, $fw-sb, $black, $lh);
            }
          }
        }
      }
      &__list {
        &__wrapper {
          a {
            text-decoration: none;
          }
        }
        &__collaborate_private_review_product,
        &__collaborate,
        &__product-review,
        &__polling,
        &__surveys,
        &__quiz {
          &__wrapper {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding-top: 12px;
            padding-bottom: 8px;
            a {
              display: flex;
              justify-content: space-between;
              width: 100%;
              text-decoration: none;
            }
            &:first-child {
              padding-top: unset;
            }
            &:last-child {
              border-bottom: unset;
            }
          }
          &__image__wrapper {
            padding: 0 24px;
            align-self: center;
          }
          &__header {
            flex: 1;
            padding: 0 24px;
            &__text {
              @include get-font($f12, $fw-b, $lightestGray, $lh);
              text-transform: uppercase;
            }
            &__subtext {
              @include get-font($f16, $fw-m, $black, $lh);
            }
          }
        }
        &__polling,
        &__surveys {
          &__header {
            &__text {
              color: $green;
            }
          }
        }
        &__quiz {
          &__header {
            &__text {
              color: $orange;
            }
          }
        }
      }
      &__company_action {
        padding: 16px 0 0 0;
        &__company {
          &__wrapper {
            padding: 12px 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            display: flex;
            &:first-child {
              padding-top: unset;
            }
            &:last-child {
              border-bottom: unset;
            }
          }
          &__content {
            display: flex;
            flex: 1;
          }
          &__header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            padding: 0 24px;
            &__text {
              @include get-font($f16, $fw-b, $black, $lh);
              text-transform: uppercase;
            }
            &__subtext {
              @include get-font($f12, $fw-m, $lightestGray, $lhxxs);
            }
          }
          &__anchor {
            &__wrapper {
              cursor: pointer;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            &__btn {
              @include get-font($f14, $fw-m, $black, $lhxs);
              border-radius: 8px;
              background: $grayBackground;
              cursor: pointer;
              padding: 8px 16px;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .reasons {
    .collaboration-form {
      padding: 20px 24px;
      &__action {
        margin: unset;
      }
    }
    .custom-input__radio {
      &__list {
        &__item {
          &__wrapper {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            &:last-child {
              border-bottom: unset;
            }
          }
        }
      }
    }
  }
  .other-reason {
    .collaboration-form {
      padding: 20px 24px;
      &:first-child {
        padding-bottom: 0;
      }
      &__action {
        margin: unset;
        border-top: unset;
      }
      &__group {
        &__label {
          padding-top: 1em;
          &__main {
            color: $lightBlackNoOpacity;
          }
        }
      }
      &__textarea {
        min-height: 222px;
      }
    }
  }
  .additionnal-activity {
    &__list {
      padding: unset;
    }
    .collaboration-form__additional-activity {
      &__comapny_action {
        .profile-settings__list__item__label__text {
          color: $blue;
        }
      }
    }
  }
}

.accountmanagementPublic {
  .collaboration-form {
    padding: 16px;
  }
}

.custom-input__radio {
  &__list {
    list-style: none;
    margin: unset;
    padding: unset;
    &__item {
      &__wrapper {
        display: flex;
        cursor: pointer;
        padding: 16px 24px;
      }
      &__content {
        align-self: center;
      }
      &__radio-icon {
        padding-right: 16px;
      }
    }
  }
}

@media only screen and (min-width: $onlyMobile) {
  .accountManagement {
    .default-modal__container {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media only screen and (max-width: $onlyMobile) {
  .accountManagement {
    &__back__btn {
      margin-left: 12px;
    }
    .settings-container {
      &__heading {
        padding: 16px 12px;
      }
      .collaboration-form {
        padding: 16px 12px !important;
      }
    }
    .activity {
      &__list {
        &__list {
          &__collaborate_private_review_product,
          &__collaborate,
          &__product-review,
          &__polling,
          &__surveys,
          &__quiz {
            &__header {
              padding: 0 12px;
            }
          }
        }
        &__company_action {
          &__company {
            &__wrapper {
              padding: 12px;
              flex-direction: column;
            }
            &__header {
              padding: 0 8px;
              &__text {
                @include get-font($f14, $fw-b, $black, $lh);
              }
            }
            &__anchor {
              &__wrapper {
                padding-top: 8px;
              }
              &__btn {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &__otp {
      .collaboration-form {
        &__group__label--helper--is-mobile {
          opacity: 1;
        }
        &__group__label--helper--is-desktop {
          opacity: 0;
        }
      }
    }
  }
  .custom-input__radio {
    &__list {
      &__item {
        &__wrapper {
          padding: 16px 12px;
        }
      }
    }
  }
}
