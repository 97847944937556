$golden: #ea9911;
$yellow: #fbb51f;

.post-bar {
  display: flex;
  max-width: 100%;
  width: $centerColumnWidth;
  margin: 0 auto 16px;
  padding: 0 8px;
  &__container {
    margin-bottom: 24px;
  }
  &__btn {
    height: 32px;
    background: $white;
    margin: 0 6px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px 0 8px;
    box-sizing: border-box;
    cursor: pointer;
    @extend %border-box-shadow;
    text-decoration: none;
    &--no-text {
      width: 32px;
      text-align: center;
      padding: 0;
    }
    &__icon {
      margin-right: 6px;
      width: 24px;
    }
    &__text {
      @include get-font($f14, $fw-m, $black, normal);
      letter-spacing: -0.2px;
      &--poll {
        color: $green;
      }
      &--collaborate, &--certificate {
        color: $blue;
      }
      &--certificate {
        font-weight: $fw-b;
        text-transform: uppercase;
      }
      &--quiz {
        color: $orange;
      }
    }
    &--active {
      z-index: 110;
    }
    &.certification{
      position:absolute;
      left: 100%;
    }
    img {
      width: 18px;
      height: 18px;
    }
  }
  &__create-modal {
    position: relative;
    .post-bar__post__arrow {
      &--post {
        margin-left: 20px;
        z-index: 10;
      }
      &--poll {
        z-index: 10;
        margin-left: 330px;
      }
    }
  }
  &__post {
    width: $centerColumnWidth;
    max-width: 100%;
    background: $white;
    @extend %border-box-shadow;
    border-radius: 10px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    &__cancel {
      @extend %appearance-none;
      background: transparent;
      border: 0;
      position: absolute;
      top: 20px;
      right: 12px;
      z-index: 10;
      cursor: pointer;
    }
    &--active {
      z-index: 110;
      position: absolute;
    }
    &__textarea {
      // border: 0;
      min-height: 48px;
      margin-bottom: 16px;
      @include get-font($f16, $fw-r, $black, $lh);
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
      .tag-box__input {
        min-height: 48px;
        -webkit-font-smoothing: antialiased;
        line-height: 1.5;
      }
    }
    &__content {
      position: relative;
      padding: 12px 16px;
      overflow: auto;
      max-height: 60vh;
      @include getDefaultScrollbar();
      .error-text {
        margin-top: 6px;
      }
      .user-create-block__container {
        margin-bottom: 12px;
      }
      &__editable {
        overflow: hidden;
        &__percentage {
          background: $mediumBlack;
          border-radius: 5px;
          position: absolute;
          bottom: 0;
          left: 0;
          margin-bottom: 16px;
          margin-left: 24px;
          padding: 2px 8px;
          @include get-font($f12, $fw-b, $white, $lh);
          letter-spacing: 0.6px;
          text-transform: uppercase;
        }
      }
    }
    &__preview-container {
      &__content {
        position: relative;
      }
      &__close {
        @extend %appearance-none;
        position: absolute;
        top: 0;
        right: 0;
        background: $lightBlack;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -15px;
        margin-right: -10px;
        border: 0;
        cursor: pointer;
      }
    }
    &__action-container {
      display: flex;
      width: calc(100% - 0px);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      box-sizing: border-box;
      box-shadow: 0 -1px 4px 0 $grayBackground;
      background: rgba($white, 0.9);
      box-sizing: border-box;
      flex-wrap: wrap;
      @media only screen and (max-width: 576px) {
        align-items: baseline;
        gap: 4px;
      }
      &__helper {
        @include get-font($f12, $fw-r, $lightBlack, $lh);
      }
      &__actions {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media only screen and (max-width: 576px) {
          margin-bottom: 12px;
          gap: 4px;
        }
      }
      &__action {
        display: flex;
        align-items: center;
        .post-bar__post__action-container__helper {
          margin-right: 12px;
        }
      }
      &__btn {
        box-sizing: border-box;
        background: $white;
        border-radius: 18px;
        height: 32px;
        // opacity: 0.5;
        box-shadow: 0 1px 4px 0 $superLightBlack;
        padding: 0 8px;
        position: relative;
        margin-right: 24px;
        display: block;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 8px;
        }
        &:hover,
        &:active,
        &:focus {
          opacity: 1;
        }
        &__file {
          display: none;
        }
        &__text {
          @include get-font($f14, $fw-m, $golden, $lh);
        }
        &--disabled {
          opacity: 0.3;
          &:hover {
            opacity: 0.3;
            cursor: default;
          }
        }
        @media only screen and (max-width: 576px) {
      margin-right: 0px;
        }
      }
    }
    &__action-btn {
      @extend %appearance-none;
      border: 0;
      box-shadow: 0 1px 2px 0 $superLightBlack;
      background: $yellow;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      cursor: pointer;
      &__text {
        @include get-font($f14, $fw-sb, $white, $lh);
      }
      &__icon {
        margin-right: 6px;

        &__poll {
          margin-right: 6px;
          background: $white;
          width: 12px;
          padding: 3px;
        }
      }
      &--poll {
        background: $green;
      }
      &--disabled {
        opacity: 0.3;
      }
      @media only screen and (max-width: 576px) {
       margin-left: auto;
      }
    }
    &--blank {
      display: flex;
      align-items: center;
      padding: 12px;
      box-sizing: border-box;
      &__helper {
        @include get-font($f14, $fw-sb, $lightestBlack, $lh);
      }
      .post-bar__post__arrow {
        margin-left: 20px;
      }
    }
    &__arrow {
      position: absolute;
      width: 15px;
      height: 15px;
      background: $white;
      top: 0;
      left: 0;
      margin-top: -7.5px;
      transform: rotate(45deg);
      border: 0.5px solid $superLightBlack;
      border-bottom: 0;
      border-right: 0;
      box-shadow: 0 0 0 0 $superLightBlack;
      box-shadow: 0 -2px 4px -2px $superLightBlack,
        -2px 0 4px -2px $superLightBlack;
    }
    @media only screen and (max-width: $onlyMobile) {
      width: 100%;
     }
  }

  &__cropmodal {
    @include getDefaultScrollbar();
    .default-modal__container {
      padding: 14px 20px 0px 20px;
    }

    .default-modal__header {
      margin: 4px -20px 24px -20px;
      padding: 0px 20px 0px 20px;
    }

    .default-modal__header__title {
      font-weight: $fw-sb;
    }

    .default-modal__content {
      margin: 0px -20px 0px -20px;
      padding: 0px;
    }

    &__content {
      &__new {
        position: relative;
        width: 100%;
        height: 400px;
        background-color: $white;
      }

      &__desc {
        @include get-font($f14, $fw-r, $lightestGray, 1.5);
      }

      &__products {
        margin-top: 16px;
        &__title {
          margin-bottom: 12px;
          @include get-font($f16, $fw-sb, $black, 1.5);
        }

        &__item {
          &__title {
            display: flex;
            text-decoration: none;
            background-color: $grayBackground;
            margin: 0px -20px;
            padding: 8px 20px;
            cursor: pointer;

            &__text {
              max-width: 86%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @include get-font($f14, $fw-m, $black, 1.5);
            }
            &__icon {
              margin: 2px 8px 0px auto;
              width: 16px;
            }
          }
          &__content {
            display: flex;
            padding: 20px 0px;
            border-bottom: 1px solid $lightestBlack;

            &:last-child {
              border-bottom: 0px;
            }

            &__batch {
              display: flex;
              flex-direction: column;
              margin-left: 4px;

              &__title {
                @include get-font($f14, $fw-m, $black, 1.5);
              }
              &__date {
                @include get-font($f12, $fw-r, $lightBlack, 1.5);
              }

              &__button {
                margin-left: auto;
              }
            }
          }
        }
      }
    }

    &__footer {
      height: 36px;
      display: flex;
      padding: 12px 16px;
      align-items: center;
      &__file {
        position: relative;

        &__icon {
          width: 24px;
          height: 24px;
        }
        > input {
          position: absolute;
          opacity: 0;
          width: 0px;
        }
      }
      &__rotatebtn {
        margin-left: 24px;
        width: 24px;
        height: 24px;
      }

      &__cropbtn {
        margin-left: auto;
        display: flex;
        align-items: center;
        border-radius: 20px;
        border: 1px;
        background-color: $green;
        padding: 8px 16px;

        &__text {
          margin-left: 8px;
          @include get-font($f14, $fw-m, $white, 1.5);
        }
      }
    }
  }
}

.create-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $lightBlack;
  z-index: 100;
}


@media screen and (max-width:$onlyMobile) {
  .post-bar__cropmodal{
    .default-modal__backdrop{
      .default-modal__container{
        width: 100%;
      }
    }
  }
}