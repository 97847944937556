.pr-manage {
    &__toggle-pills {
        &__list {
            display: flex;
            list-style: none;
            margin: 0;
            // flex-wrap: wrap;
            flex:  1;
            overflow: auto;
            @include getDefaultScrollbar();
            padding: 0;
            width: 100%;
            padding-bottom: 8px;
        }
        &__container {
            padding: 16px 0 0;
            border-top: 1px solid $grayBackground;
            &--multi {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
                gap: 1em;
            }
            &--border-bottom {
                padding-top: 0;
                border-top: 0;
            }
        }
        // height: 36px;
        white-space: nowrap;
        background: $grayBackground;
        @include get-font($f14, $fw-r, $black, 36px);
        padding: 0 12px;
        cursor: pointer;
        box-sizing: border-box;
        @include transitionEase(#{background});
        border-right: 1px solid $lightGrey;
        &:hover, &:active {
            background: $superLightBlack;
        }
        &--active {
            background: $black;
            color: $white;
            font-weight: $fw-sb;
            border-right: unset;
            &:hover, &:active {
                background: $black;
            }
        }
        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-right: unset;
        }
    }
    &__loader-height {
        left: 50%;
        position: absolute;
        top: 30%;
        z-index: 1;
    }
}

@media only screen and (max-width: $onlyMobile) {
    .pr-manage {
        &__toggle-pills {
            &__container {
                &--multi {
                   flex-direction: column;
                }
                &--border-bottom {
                    flex-direction: column;
                    > div {
                        &:first-child {
                            order: 1;
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }
}