// Post btn yellow
$postYellow: #fbb51f;

.default-modal {
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: $lightBlack;
    overflow: hidden;
    overflow-y: auto;
    @include getDefaultScrollbar(); 
    &:has(.helper-message-front__wrapper){
      z-index: 99;
    }
  }
  &--centered {
    @media only screen and (max-width: $onlyMobile) {
      .default-modal__container {
        height: auto;
        min-height: auto;
        border-radius: 10px;
        width: calc(100vw - 32px);
        @include centerItem();
        max-width: 570px;
      }
    }
  }
  &__container {
    padding: 24px;
    box-sizing: border-box;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    border-radius: 10px;
    @extend %border-box-shadow;
    width: 574px;
    margin-bottom: 60px;
    @media only screen and (max-width: $onlyMobile) {
      width: 95vw;
      top: 0;
      left: 0;
      transform: none;
      margin-bottom: 0;
      position: fixed;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden;
      overflow-y: auto;
      height: auto;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    &__title {
      @include get-font($f18, $fw-b, $black, $lh);
      line-height: 1.1
    }
    &__close {
      @extend %appearance-none;
      border: 0;
      background: transparent;
      cursor: pointer;
      padding: 0px;
      img {
        width: 16px;
        display: block;
      }
      &__icon {
        margin-right: 12px;
      }
    }
  }
  &__footer1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--multi {
      justify-content: space-between;
    }
    &--bottom {
      box-shadow: 0 -1px 4px 0 $grayBackground;
      width: calc(100% + 48px);
      margin-left: -24px;
      padding: 12px 24px;
      box-sizing: border-box;
      margin-bottom: -24px;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &--multi {
      justify-content: space-between;
    }
    &--bottom {
      box-shadow: 0 -1px 4px 0 $grayBackground;
      width: calc(100% + 48px);
      margin-left: -24px;
      padding: 12px 24px;
      box-sizing: border-box;
      margin-bottom: -24px;
    }
    &--bottom-no-shadow {
      width: calc(100% + 48px);
      margin-left: -24px;
      padding: 0px 24px ;
      box-sizing: border-box;
    }
    &__action-btn {
      @extend %appearance-none;
      border: 0;
      background: $postYellow;
      height: 32px;
      padding: 6px 12px;
      box-sizing: border-box;
      border: 0;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &--primary {
        background: $blue;
        border-radius: 7px;
      }
      &--green {
        background: $green;
        border-radius: 7px;
      }
      &__icon {
        margin-right: 6px;
      }
      &__text {
        @include get-font($f14, $fw-sb, $white);
      }
      &--disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    &__modaltext {
      color: #747474;
      margin-bottom: 16px !important;
  }
    &__clear-btn {
      cursor: pointer;
      @extend %appearance-none;
      background: transparent;
      border: 0;
      padding-left: 0px;
      &__text {
        @include get-font($f14, $fw-r, $blue, $lh);
        font-weight: 600;
        color: red
      }
      &--remove {
        @include get-font($f14, $fw-r, $mediumRed, $lh);
        margin-left: 12px;
        @include transitionEase(#{color});
        &:hover,
        &:focus,
        &:active {
          color: $red;
        }
      }
      &--no-margin {
        margin-left: 0 !important;
      }
      &--disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
  &__remove-btn,
  &__normal-btn,
  &__rollback-btn,
  &__rollbackfail-btn {
    @extend %appearance-none;
    height: 32px;
    border: 0;
    @include get-font($f14, $fw-sb, $white, $lh);
    padding: 0 12px;
    box-sizing: border-box;
    border-radius: 7px;
    cursor: pointer;
    line-height: 32px;
    @include transitionEase(#{background});
    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  &__remove-btn {
    background: $removeRed;
    &:focus,
    &:hover,
    &:active {
      background: $removeRed;
    }
  }
  &__normal-btn {
    background-color: $mediumBlue;
    &:focus,
    &:hover,
    &:active {
      background: $blue;
    }
  }
  &__rollback-btn {
    background-color: $black;
    &:focus,
    &:hover,
    &:active {
      background: $mediumBlack;
    }
  }
  &__rollbackfail-btn {
    background-color: $black;
    &:focus,
    &:hover,
    &:active {
      background: $mediumBlack;
    }
    margin-left: auto;
  }
}

.full-screen-modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: $white;
  padding: 24px;
  box-sizing: border-box;
  &__close {
    text-align: right;
    &__btn {
      @extend %appearance-none;
      background: transparent;
      border: 0;
      cursor: pointer;
    }
  }
  &.transiton_effect{
    left: -100%;
    transition: left .4s;
    &.active{
      left:0;
    }
  }
}


@media only screen and (max-width: $onlyMobile) {
  .remove-entity-modal{
    .default-modal__backdrop{
      .default-modal__container{
        width: 100%;
      }
    }
  }
}
