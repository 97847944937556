.biz {
  &__dashboard {
    margin: 65px auto 0;
    display: flex;
    max-width: $maxWidthContainer;
    &--loader {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }
  &__tooltip-row {
    display: flex;
  }
  &__tooltip-title {
    display: flex;
    justify-content: space-between;
  }
  &__tooltip {
    background-color: #00191a;
    max-width: 210px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 10px;
    @include get-font($f12, $fw-r, $white, $lh);
    position: absolute;
  }
  &__details {
    flex: 1;
    min-height: calc(100vh - 65px);
    overflow: hidden;
    overflow-y: auto;
    padding: 0 24px;
    &__header {
      padding: 20px 0 10px;
      border-bottom: 1px solid $grayBackground;
      &__title {
        text-decoration: none;
        @include get-font($f12, $fw-sb, $black, $lh);
      }
    }
    &__container {
      margin: 24px 0;
      box-sizing: border-box;
    }
    &__subtitle {
      @include get-font($f14, $fw-m, $black, $lh);
    }
    &__title {
      @include get-font($f22, 900, $black, $lh);
      margin-top: 8px;
      word-break: break-word;
      max-width: 400px;
    }
    &__create-btn {
      @extend %appearance-none;
      height: 45px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $teal;
      @include get-font($f14, $fw-b, $white, $lh);
      letter-spacing: 0.7px;
      border-radius: 10px;
      border: 0;
      text-transform: uppercase;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &__product {
      position: relative;
    }
  }
  &__status-btn {
    @extend %appearance-none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: $grayBackground;
    border: 0;
    margin: 0 auto;
    @include get-font($f14, $fw-r, $black, $lh);
    > img {
      margin-right: 4px;
    }
  }
  &__modal {
    &__done-btn,
    &__edit-btn,
    &__delete-btn {
      height: 32px;
      @extend %appearance-none;
      border: 0;
      display: flex;
      padding: 6px 12px;
      box-sizing: border-box;
      &--disabled {
        opacity: 0.6;
        cursor: not-allowed !important;
      }
      > img {
        margin-right: 4px;
      }
    }
    &__done-btn {
      background-color: $teal;
      @include get-font($f14, $fw-sb, $white);
      border-radius: 7px;
      cursor: pointer;
    }
    &__edit-btn {
      background-color: $grayBackground;
      @include get-font($f14, $fw-r, $black);
      border-radius: 16px;
    }
    &__helper {
      @include get-font($f14, $fw-r, $black, $lh);
    }
    &__delete-btn {
      background-color: $removeRed;
      @include get-font($f14, $fw-sb, $white);
      border-radius: 7px;
      cursor: pointer;
    }

    &__cancel-btn {
      background-color: $white;
      @include get-font($f14, $fw-r, $black);
      border-radius: 7px;
      cursor: pointer;
      border: 0px;
      margin-right: auto;
    }
  }
  &__error {
    // width: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > img {
      margin-bottom: 16px;
    }
    > p {
      @include get-font($f18, $fw-sb, $black, $lh);
    }
  }
  &__breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    > li {
      display: flex;
    }
    &__item {
      @include get-font($f12, $fw-r, $black, $lh);
      margin-right: 4px;
      text-decoration: none;
      &--active {
        font-weight: $fw-sb;
      }
      &__icon {
        display: block;
        margin-right: 4px;
        width: 8px;
        height: auto;
      }
    }
  }
  &__fielderror {
    margin-top: 2px;
    margin-left: 5px;
    @include get-font($f12, $fw-r, $notificationRed, $lh);
  }
}
