.biz {
  &__consis {
    &__review {
      .tasting-form__header-loader__inner {
        background: $teal;
      }
      .tasting-form__footer-container__footer__next,
      .any-other-modal__submit,
      .helper-message-modal__footer__action-btn,
      .profile-completion-btn__btn__submit-btn {
        background: $teal;
        color: $white;
      }
      .tasting-form__footer-container__footer__next__text {
        color: $white;
      }
      .tasting-form__select__option--checked {
        border-color: $teal;
        box-shadow: 0 0 0 1px $teal;
        background-color: $superLightTeal;
      }
      .tasting-form__select__option:hover,
      .aroma-modal__pill__container:hover {
        background-color: $superLightTeal;
      }
      .tasting-form__select__option__intensity {
        border-left-color: $teal;
      }
      .tasting-form__select__image-option__overlay {
        background-color: rgba($teal, 0.2);
      }
      .tasting-form__select__image-option--checked {
        border-color: $teal;
        box-shadow: 0 0 0 1px $teal;
      }
      .aroma-modal__pill__container--checked,
      .textual-intensity__pill--selected {
        border: 1.5px solid $teal;
        background-color: $superLightTeal;
      }
      .tasting-form__nested-options__list__item--checked {
        border-color: $teal;
        box-shadow: 0 0 0 1px $teal;
      }
      .textual-intensity__scale__option--selected {
        background: $teal;
      }
    }
  }
}
