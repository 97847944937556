.quiz {
  &__card {
    background: $lightestOrange;
    display: block;
    padding: 12px 16px 16px 16px;
    text-decoration: none;
    &__header {
      @include get-font($f12, $fw-b, $copiedBackground, $lh);
      text-transform: uppercase;
      margin: 0 0 4px;
      word-break: break-word;
    }
    &__title {
      @include get-font($f16, $fw-m, $black, $lh);
      margin-bottom: 8px;
    }
    &__description {
      @include get-font($f14, $fw-r, $black, $lh);
      margin-bottom: 8px;
    }
    &__action {
      width: 100%;
      height: 42px;
      background: $orange;
      @include get-font($f12, $fw-b, $white, $f18);
      border-radius: 4px;
      border: 0;
      cursor: inherit;
      text-transform: uppercase;
    }
    &__img {
      &__wrapper{
        position: relative;
        .react-player__video{
            video{
              border-radius: unset;
            }
          }  
      }
      width: 100%;
      height: auto;
      max-height: 100%;
      display: block;
      margin: 0 auto 16px;
      left: -16px;
      position: relative;
      width: calc(100% + 32px);
      .react-player__video{
          video{
            border-radius: unset;
          }
        } 
    }
    &__score.overlap{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background: rgba(0, 0, 0, 0.8);
      border-radius: 8px;
      padding: 8px 16px;
      @include get-font($f18,$fw-sb,$white);
      z-index: 1;
    }
  }
}
