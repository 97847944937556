.chat-list-container {
    height: calc(100vh - 150px);
    overflow: hidden;
    overflow-y: auto;
}

.new-chat-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: #FFFFFF;
}

.chat-list {
    list-style: none;
    padding: 0;
    .chat-list-item {
        display: flex;
        // height: 70px;
        width: 100%;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 10px 15px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        &__image-container {
            &__image {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                margin-right: 8px;
                overflow: hidden;
                object-fit: cover;
            }
        }
        &__details {
            width: calc(100% - 58px);
            height: 100%;
            >p {
                display: flex;
                margin-top: 4px;
                @include get-font($f12, $fw-m, $lh, $copiedBackground);
                gap: 4px;
                align-items: center;
                >span{
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                }
            }
            &__name-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__name {
                    font-weight: 600;
                    line-height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 0.95;
                }
                &__time {
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.4);
                    margin-bottom: 0;
                    line-height: 14px;
                    flex-shrink: 0;
                }
            }
        }
    }
    .chat-checkbox-input-box {
        height: 20px;
        width: 20px;
        background: url('assets/images/chat/icons-chat-uncheck@2x.png');
        background-size: contain;
    }
    .chat-checkbox-input {
        position: absolute;
        opacity: 0;
        &:checked + .chat-checkbox-input-box {
            background-image: url('assets/images/chat/icons-chat-check@2x.png');
        }
    }
}
.chat-list-details-container {
    width: calc(100% - 58px);
    height: 100%;
    margin-top: 0;
    &.without-message {
        display: flex;
        align-items: center;
        position: relative;
        .chat-list-meta-container {
            width: 100%;
            margin-bottom: 0;
            max-width: calc(100% - 20px);
        }
    }
    .message-primary-text {
        &.text-bold {
            width: calc(100% - 60px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.group-info-option-container {
    position: absolute;
    right: 10px;
    .group-info-icon {
        width: 17px;
        height: auto;
        padding: 0;
        background-color: $white;
        border: none;
        &:focus,
        &:active{
            outline: none;
        }
        img{
            width: 100%;
            height: auto;
        }
    }
}

.group-info-modal-container {
    .create-group-image-placeholder {
        padding: 15px 0;
        .create-group-label  {
            margin-bottom: 0;
        }
    }
    .link{
        display: flex;
        text-decoration: none;
        color: #4990E2;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
    }
    .create-group-name-input {
        margin-bottom: 15px;
    }
    .chat-modal-content-container{
        padding: 0 15px;
        margin-bottom: 50px
    }
    .group-info-details-container {
        
        .participants-text {
            @include get-font($f14, $fw-sb, $black, $lhr);
            margin-bottom: 10px;
            margin-top: 12px;
        }
        .chat-list-container {
            margin-top: 0;
            height: 225px;
            .chat-list{
                margin: 0;
            }
            .chat-list-item {
                padding: 0;
                padding-bottom: 10px;
                padding-top: 10px;
                align-items: center;
            }
            &.without-admin {
                height: 225px;
            }
            .admin-text {
                @include get-font($f14, $fw-r, $red, $lhs);
                margin-bottom: 0;
                line-height: 17px;
                margin-top: 4px;
            }
        }
    }
    .chat-modal-next-btn-container{
        height: auto;
        padding: 0;
    }
}
.chat-modal-next-btn-container {
    height: 70px;
    border-top : 2px solid $gray;
    display: flex;
    align-items: center;
    justify-content: center;
    &.exit-group-container {
        border: 0;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px 20px;
        .exit-button {
            border: 0;
            background: $white;
            align-items: center;
            outline: 0;
            display: flex;
            .exit-button-icon {
                width: 26px;
                height: auto;
                margin-right: 30px;
            }
            .exit-button-text {
                @include get-font($f16, $fw-sb, $red);
            }
        }
    }
    .chat-modal-next-btn {
        height: 29px;
        padding: 0 10px;
        border-radius: 4px;
        background: $red;
        border: 0;
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
        > span {
            @include get-font($f16, $fw-r, $white, $lhr);
        }
    }
    .next-button { // Remove this after check
        background: $red;
        margin-left: 150px;
        color: $white;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
.chat-hover-options {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
    width: 163px;
    border: 1px solid $superLightBlack;
    box-shadow: 0 0 6px 0px $superLightBlack;
    background-color: $white;
    
    .chat-top-bar-btn {
        min-height: 39px;
        padding-left: 10px;
        background: $white;
        border: 0;
        border-bottom: 1px solid $superLightBlack;
        display: flex;
        align-items: center;
        outline: 0;
        color: $black;
        &:hover, &:active {
            background: $gray;
        }
        > span {
            @include get-font($f16, $fw-r, $black);
        }
    }
}

.create-group-btn-container {
    padding: 20px;
    outline: none;
    .create-group-btn {
        background: $white;
        text-align: center;
        border: 1px solid $red;
        border-radius: 5px;
        width: 100%;
        max-width: 354px;
        box-sizing: border-box;
        height: 39px;
        justify-content: center;
        align-items: center;
        > span {
            @include get-font($f16, $fw-r, $red, $lhr);
        }
    }
}
