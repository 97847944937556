.profile-form-modals {
    .default-modal__container {
        width: 511px;
        @include centerItem();
        .default-modal__header{
            .default-modal__header__title{
                &.capatalize{
                    text-transform: capitalize;
                }
            }
        }
    }
    &__form {
        &__group {
            margin-bottom: 32px;
        }
       
        .login-form__form__input--password{
            &.red {
                border: 1px solid $red !important
            }
        }
        .label{
            @include get-font($f16, $fw-sb, rgba(25, 25, 25, 0.6), $lh24);
            margin-bottom: 8px;
            display: block;
        }
        .info-text{
            @include get-font($f12, $fw-r, rgba(25, 25, 25, 0.4), 16px);
            margin-bottom: 8px;
            display: block;
            text-align: center
        }
        .button{
           padding: 16px;
           border: transparent;
           border-radius: 100px;
           @include get-font($f16, $fw-sb, $white, $lh24);
           background-color: black;
            width: 100%;
            margin-top: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            &.disabled{
                background-color: #747474; 
            }
            .generic-loader--centered{
                line-height: 0;
            }
        }
        &-confirm-list{
            padding: 0px 24px;
            .item{
                @include get-font($f14, $fw-r, rgba(25, 25, 25, 0.6), 16.8px);
                &.red {
                    color: $red
                }
            }
        }
    }
    &__label {
        @include get-font($f14, $fw-m, $black, $lh);
        margin-bottom: 8px;
        display: block;
    }
    &__input {
        @extend %appearance-none;
        @include form-input-border-shadow();
        @include get-font($f14, $fw-r, $black, $lh);
        text-indent: 16px;
        width: 100%;
        height: 36px;
        background: $white;
        border-radius: 10px;
        &:active, &:focus {
            border: 1px solid $mediumBlack;
        }

        &__checkbox{
            @extend %appearance-none;
            margin: 0;

            &--container{
                margin: 8px 0;
                display: flex;
            }
            + label{
                display: flex;
                align-items: center;
                gap: 8px;
                @include get-font($f14,$fw-r,#1e1e1e);

                &:before{
                    content: "";
                    display: block;
                    height: 17px;
                    width: 17px;
                    background: url("assets/images/collaborations/pr/manage/checkbox-off.svg") no-repeat center center;
                    background-size: cover;
                }
            }
            &:checked +label::before{
                background: url("assets/images/collaborations/pr/manage/checkbox-on.svg") no-repeat center center;
                background-size: cover;
            }
            
        }
    }
    &__title {
        @include get-font($f16, $fw-r, $black, $lh);
        margin: 16px 0;
    }
    &__footer {
        height: 56px;
        border-top: 1px solid $grayBackground;
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-bottom: -24px;
        display: flex;
        padding: 0 24px;
        box-sizing: border-box;
        &--right {
            align-items: center;
            justify-content: flex-end;
        }
    }
    &__btn {
        @extend %appearance-none;
        height: 32px;
        border: 0;
        @include get-font($f14, $fw-sb, $black, $lh);
        padding: 0 12px;
        background: $yellow;
        border-radius: 7px;
        cursor: pointer;
        @include transitionEase();
        &:focus, &:active, &:hover {
            background: $darkYellow;
        }
        &--disabled {
            opacity: 0.6;
            &:focus, &:active, &:hover {
                background: $yellow;
            }
        }
    }
    .error-text {
        margin: 4px 0;
    }
}

@media only screen and (max-width: $smallMobile) {
    .profile-form-modals {
        .default-modal__container {
            width: 100%;
        }
    }
  }