.product {
  &__food-shot {
    &__container {
      width: 100%;
      height: 160px;
      background-image: url('assets/images/products/food-shot-bg.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
    }
    &__list {
      margin: 0;
      padding: 0;
      position: relative;
      list-style: none;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: auto;
      display: block;
      width: 100%;
      &__item {
        display: inline-block;
        vertical-align: top;
        width: 96px;
        height: 96px;
        cursor: pointer;
        margin-right: 4px;

        &__image {
          width: 96px;
          height: 96px;
          border-radius: 5px;
          border: 1px solid $grayBackground;
          box-sizing: border-box;

          object-fit: cover;
          position: relative;
        }

        &__box {
          width: 96px;
          height: 96px;
          border-radius: 5px;
          border: 1px solid $grayBackground;
          box-sizing: border-box;

          display: inline-flex;
          align-items: center;
          justify-content: center;
          left: 0;
          margin-right: 4px;
          border-color: transparent;
          p {
            @include get-font($f12, 900, $mediumBlack, $lh);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tab) {
  .product {
    &__food-shot {
      &__container {
        height: 96px;
        margin-top: 16px;
      }
    }
  }
}
