.tasting-report {
  &__range {
    &--average {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      gap: 10px;

      &__value {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        flex: 1;
        padding: 10px 12px;
        @include get-font($f12,$fw-r,rgba(23, 23, 23, 0.6));
        background-color: #f2f2f2;
        border-radius: 100px;
        cursor: pointer;

        b {
          color: $black;
          position: relative;
          z-index: 1;
          font-size: $f14;
          @include  limit-text-to-line(3);
          flex: 1;
        }

        &--action{
            display: flex;
            align-items: center;
            gap: 10px;
            z-index: 1;
            > span {
              min-width: fit-content;
            }
            &.multi{
                justify-content: space-between;
            }
        }

        &:hover{
          .tasting-report__range__list--toggler{
            background-color: rgba(23, 23, 23, 0.2);
          }
        }
      }
      &__percentage {
        @include get-font($f14,$fw-m,$black);
        padding: 10px 0;
        min-width: 52px;
      }
    }

    &__list {
        position: relative;
        width: 80%;
        margin-left: 16px;
        padding:15px 0 0 0;

        &::before{
          content: "";
          position: absolute;
          top: 0;
          left: 25px;
          height: calc(100% - 25px);
          width: 4px;
          background-color: rgba(23, 23, 23, 0.05);
        }

      &--toggler {
        all: unset;
        display: flex;
        height: 20px;
        min-width: 20px;
        background-color: rgba(23, 23, 23, 0.05);
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        line-height: 1;
        cursor: pointer;
        transition: background-color .4s;

        img{
            transition: transform .4s;
        }

        &.expanded{
            img{
                transform: translate(1px,-1px) rotate(180deg);
            }
        }
        
      }
      &--item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        &__value {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          flex: 1;
          padding: 10px 12px;
          @include get-font($f12,$fw-r,rgba(23, 23, 23, 0.6));
          background-color: #f2f2f2;
          border-radius: 100px;
          margin-right: 10px;
          overflow: hidden;
          b {
            @include get-font($f12,$fw-sb,$black);
            position: relative;
            z-index: 1;
            @include  limit-text-to-line(3);
            flex: 1;
          }
          span{
            z-index: 1;
          }
          
        }
        &__percentage {
          padding: 10px 0;
          min-width: 52px;
        }
      }
    }

    & .filled {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      pointer-events: none;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      pointer-events: none;

      &.red {
        background-color: #f5cdd3;
      }
      &.green{
        background-color: #dee4d7;
      }

      &.full{
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
      }
    }
  }
}
