.intensity-modal {
    .default-modal__container {
        width: 328px;
        padding: 0;
        @media screen and (max-width: $onlyMobile) {
            width: 100%;
            max-width: 440px;
            min-height: 300px;
        }
    }
    .textual-intensity {
        position: relative !important;
        border: 0 !important;
        top: 0 !important;
        left: 0 !important;
        transform: none !important;
        &:after {
            display: none;
        }
    }
    .default-modal__header {
        position: absolute;
        z-index: 30;
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 0;
        padding-top: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        z-index: 101;
    }
}