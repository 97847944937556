$productBtn: linear-gradient(79deg, rgba($white, 0.5), rgba($white, 0)), linear-gradient(to bottom, rgba(251, 181, 31, 0.8), rgba(251, 181, 31, 0.8));
.product-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 24px;
    min-width: 24px;
    padding: 0 6px;
    // display: inline-block;
    border-radius: 2px;
    display: inline-flex;
    &--disabled {
        background: $lightBg;
        padding: 0;
        .product-rating__icon {
            margin-right: 0;
        }
    }
    &__icon {
        width: 12px;
        height: auto;
        margin-right: 4px;
    }
    &__text {
        @include get-font($f14, $fw-b, $white, $lh);
        letter-spacing: -0.6px;
    }
}