.product {
    &__reports {
        &__container {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        &__left {
            width: 100%;
            max-width: 328px;
            .product__reports__helper {
                margin-top: 16px;
            }
            .product__reports__list {
                margin-top: 16px;
            }
        }
        &__right {
            width: 328px;
            .product__reviews__sort {
                width: 280px;
                margin: 0 auto 16px;
            }
        }
        &__helper {
            @include get-font($f15, $fw-r, $black, $lh);
        }
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            width: 100%;
            &__item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 12px;
                width: 100%;
                padding: 14px 12px;
                border-radius: 46px;
                box-sizing: border-box;
                position: relative;
                cursor: pointer;
                .product__ratings__default {
                    margin-right: 12px;
                }
                &:hover {
                    .product__reports__list__icon {
                        opacity: 1;
                    }
                }
                &--empty {
                    background: $grayBackground;
                    cursor: default;
                    .product__ratings__from {
                        line-height: 0;
                    }
                    .product__ratings__default {
                        height: 48px;
                        width: 68px;
                    }
                    height: 72px;
                }
            }
            &__title {
                @include get-font($f16, $fw-b, $black, $lh);
                text-transform: capitalize;
            }
            &__sub-title {
                @include get-font($f12, $fw-r, $lightBlack, $lh);
                margin-top: 4px;
            }
            &__icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
                opacity: 0;
                @include transitionEase(#{opacity});
            }
        }
    }
}

@media only screen and (max-width: $tab) {
    .product {
        &__reports {
            &__container {
                flex-direction: column;
                display: flex;
                align-items: center;
            }
            &__helper {
                margin-top: 8px !important;
            }
            &__right {
                margin-top: 24px;
                max-width: 100%;
            }
            &__left{
                max-width: 100%;
            }
        }
    }
}