.feed-ad {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    @extend %border-box-shadow;
    &__image {
        height: auto;
        width: 100%;
        display: block;
    }
    &__overlay {
        background: $grayBackground;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        @include transitionEase(#{opacity});
        &:hover {
            opacity: 1;
        }
    }
}