.switch {
  width: 40px;
  height: 20px;
  border-radius: 100px;
  background: $lightGrey;
  position: relative;
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  &--disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
  &__on {
    background: $lighterBlue;
    &.green {
    background: rgba(0, 161, 70, .4);
    }
  }
  &__input {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
    &:checked + .switch__btn {
      left: calc(100% + 2px);
      background: $blue;
      transform: translateX(-100%);
      &.green {
        background: $green;
      }
    }
  }
  &__btn {
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 26px;
    background: $copiedBackground;
    top: -3px;
    left: -2px;
    box-shadow: 0 2px 4px 0 $lightestBlack;
    transition: all 0.2s ease;
  }
  &__img {
    @include centerItem();
    &--hidden {
      visibility: hidden;
    }
  }
}
