// Font Sizes
$f10: 10px;
$f12: 12px;
$f14: 14px;
$f15: 15px;
$f16: 16px;
$f18: 18px;
$f20: 20px;
$f22: 22px;
$f24: 24px;
$f26: 26px;
$f28: 28px;
$f32: 32px;
$f36: 36px;
$f40: 40px;
$f48: 48px;
//line height
$lhl: 34px;
$lhOnboarding: 21px;
$lhs: 1.4;
$lh: 1.5;
$lhl: 1.8;
$lh22: 22px;
$lh24: 24px;
$lhr: 19px;
$lhs: 17px;
$lhxs: 14px;
$lhxxs: 12px;
// Font weight
$fw-l: 200;
$fw-r: 400;
$fw-m: 500;
$fw-sb: 600;
$fw-b: 700;
$fw-ub: 900;

//colors
$white: #ffffff;
$backgroundGray: #f4f4f4;
$lightGrey: #d1d1d1;
$copiedBackground: #747474;
$imageWrapperBackgroundGray: #ededed;
$lightBg: #e5e5e5;
$black: #171717;
$darkBlack: #000000;
$gray: #f0f0f0;
$focusedGray: #bfbfbf;
$lighterGray: #fafafa;
$fileOverlayGray: #f8f8f8;
$grayFileName: #636b6f;
$lightGray: rgba(0, 0, 0, 0.05);
$darkLightGray: rgba(0, 0, 0, 0.08);
$lightestGray: rgba(23, 23, 23, 0.6);
$chatTime: #a29a9a;
$chatSender: rgba(90, 88, 88, 0.8);
$onboardingBlack: rgba(14, 1, 1, 0.9);
$tastingCardShadow: rgba(0, 0, 0, 0.15);
//gray
$grayerBackground: rgba(23, 23, 23, 0.06);
$grayBackground: rgba(23, 23, 23, 0.05);
$grayText: rgba(23, 23, 23, 0.3);
$lightGrayText: rgba(23, 23, 23, 0.1);
$mediumBlack: rgba($black, 0.8);
$lightBlack: rgba($black, 0.6);
$lightBlackNoOpacity: rgb(83, 83, 83);
$tastingHeadBlack: rgba($black, 0.5);
$lighterBlack: rgba($black, 0.4);
$lightestBlackOverlay: rgba($black, 0);
$lightestBlack: rgba($black, 0.2);
$superLightBlack: rgba(0, 0, 0, 0.1);
$superLightGrey: rgba(0, 0, 0, 0.5);
$greylight: rgba(255, 255, 255, 0.7);

$darkBlue: #33659e;
$darkSkyBlue: rgba(73, 144, 226, 0.8);
$blue: #4990e2;
$aquaBlue: rgba(73, 144, 226, 0.2);
$mediumBlue: rgba($blue, 0.8);
$lightBlue: rgba($blue, 0.6);
$lighterBlue: rgba($blue, 0.4);
$lightestBlue: rgba($blue, 0.2);
$collaborationBlue: #f6f9fd;
$chatTimeBackground: rgba(0, 122, 255, 0.14);

$darkGreen: #1b7542;
$green: #00a146;
$mediumGreen: rgba($green, 0.8);
$lightGreen: rgba($green, 0.6);
$lighterGreen: rgba($green, 0.4);
$lightestGreen: rgba($green, 0.2);
$overallPrefernceGreen: #577b33;
$tastingGreen: rgba(0, 161, 70, 0.4);
$tastingGreenSurvey: rgba(0, 161, 70, 0.2);
$reportGreen: rgba(0, 174, 179, 0.2);
$quizGreen: #66c790;

$orange: #f47816;
$mediumOrange: rgba($orange, 0.8);
$lightOrange: rgba($orange, 0.6);
$lighterOrange: rgba($orange, 0.4);
$lightestOrange: rgba($orange, 0.2);

$red: #f60e3a;
$notificationRed: #d9222a;
$mediumRed: rgba($red, 0.8);
$lightRed: rgba($red, 0.6);
$lighterRed: rgba($red, 0.4);
$lightestRed: rgba($red, 0.2);
$darkestYellow: #d19e0d;
$darkYellow: #eaab23;
$yellow: #efb920;
$mediumYellow: rgba($yellow, 0.8);
$lightYellow: rgba($yellow, 0.6);
$lighterYellow: rgba($yellow, 0.4);
$lightestYellow: rgba($yellow, 0.2);
$tastingYellow: #fcba02;
$tastingOptionYellow: rgba(252, 186, 2, 0.2);

$teal: #00aeb3;
$surveyTeal: #00a188;
$mediumTeal: rgba($teal, 0.8);
$lightTeal: rgba($teal, 0.6);
$lighterTeal: rgba($teal, 0.4);
$lightestTeal: rgba($teal, 0.2);
$superLightTeal: #cceff0;
$workshop:#CFFDF4;

$superLightestBlue: rgba(73, 144, 226, 0.1);
$placeholderBlack: #bdbdbd;

$pink: rgb(246, 14, 58);
$mediumPink: rgba($pink, 0.8);
$lightPink: rgba($pink, 0.6);
$lighterPink: rgba($pink, 0.4);
$lightestPink: rgba($pink, 0.2);
$chatPink: #ffe7e8;
$chatDarkPink: #ec9094;

$noDataText: #668485;

// Root variables
$containerMaxWidth: 1440px;
$centeredBigContentContainer: 1204px;
//Media Variables
$onlyMobile: 819px;
$smallMobile: 576px;
$tab: 1023px;

//Container
$centerColumnWidth: 570px;
$sideColumnWidth: 293px;

$removeRed: #dd2e1f;

// full width container
$maxWidthContainer: 1188px;
// centered container
$centeredContainer: 760px;
// chat Variables
$newMessageHeight: 20px;
// social colors
$fbColor: #385796;
$linkedInColor: #0077b5;

// min height for full view
$minHeightForFullView: 154px;

$paidRibbon: #f0960c;
