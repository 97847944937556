.review-collection {
    &__container {
        padding-top: 42px;
    }
    &__bar {
        position: fixed;
        top: 65px;
        left: 0;
        width: 100%;
        background: $white;
        height: 83px;
        opacity: 0.93;
        box-shadow: 0 1px 14px 0 rgba(127, 3, 3, 0.1);
        border: solid 0.5px $superLightBlack;
        z-index: 10;
        @include transitionEase(#{opacity});
        opacity: 0;
        &--active {
            opacity: 1;
        }
        > div {
            width: 760px;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0 auto;
            height: 100%;
        }
        &__img {
            width: 82.5px;
            height: 55px;
            border-radius: 10px;
            margin-right: 16px;
            background: $grayBackground;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                box-sizing: border-box;
            }
        }
        &__title {
            @include get-font($f16, 900, $black, $lh);
        }
        &__subtitle {
            @include get-font($f12, $fw-b, $black, $lh);
            letter-spacing: 0.6px;
            text-transform: uppercase;
            margin-top: 6px;
        }
    }
    &__header {
        p {
            @include get-font($f12, $fw-b, $black, $lh);
            letter-spacing: 0.6px;
            margin-top: 32px;
        }
        h1 {
            @include get-font($f22, 900, $black, $lh);
            margin-top: 16px;
            word-wrap: break-word;
        }
        h2 {
            @include get-font($f18, $fw-r, $black, $lh);
            margin-top: 12px;
            word-wrap: break-word;
        }
        &__img {
            width: 100%;
            max-width: 760px;
            height: 507px;
            border-radius: 20px;
            background: $grayBackground;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                box-sizing: border-box;
            }
        }
    }
    &__product {
        &__img {
            width: 275px;
            height: 183px;
            object-fit: contain;
            &__container {
                width: 275px;
                height: 183px;
                border-radius: 20px;
                flex-shrink: 0;
                box-sizing: border-box;
                overflow: hidden;
                border: 0.5px solid $superLightBlack;
                position: relative;
            }
        }
        &__details {
            margin-left: 16px;
        }
        ol {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin-top: 32px;
                width: 100%;
                position: relative;
                > a {
                    display: flex;
                    align-items: center;
                    border-radius: 20px;
                    border: 1px solid $superLightBlack;
                    background: $white;
                    padding: 16px;
                    box-sizing: border-box;
                    text-decoration: none;
                    @include transitionEase(#{box-shadow});
                    &:hover, &:focus, &:active {
                        box-shadow: 0 6px 10px 3px $superLightBlack;
                    }
                }
                .product__action__btn {
                    width: 206px;
                    margin-top: 16px;
                }
            }
        }
        &__title {
            @include get-font($f16, $fw-sb, $black, $lh);
        }
        &__brand {
            margin-top: 14px;
            display: flex;
            align-items: center;
            img {
                width: 24px;
                height: 24px;
                border: 0.5px solid $superLightBlack;
                object-fit: contain;
                flex-shrink: 0;
                box-sizing: border-box;
                margin-right: 8px;
            }
            p {
                @include get-font($f14, $fw-r, $black, $lh);
            }
        }
        &__number {
            position: absolute;
            width: 57px;
            height: 57px;
            border-radius: 28.5px;
            background: $grayBackground;
            @include get-font($f22, 900, $black);
            line-height: 57px;
            text-align: center;
            left: -90px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@media only screen and (max-width: $onlyMobile) {
    .review-collection {
        &__container {
            padding-top: 0;
        }
        &__header {
            &__img {
                max-height: 250px;
                border-radius: 0;
                max-width: calc(100% + 32px);
                margin-left: -16px;
                width: calc(100% + 32px);
                > img {
                    display: block;
                    max-width: calc(100% + 32px);
                }
            }
            p {
                margin-top: 16px;
            }
            h1 {
                font-size: $f16;
                margin: 12px 0;
            }
            h2 {
                font-size: $f16;
            }
        }
        &__bar {
            display: none;
        }
        &__product {
            ol {
                li {
                    > a {
                        flex-direction: column;
                        padding: 12px;
                    }
                    .product__action__btn {
                        display: none;
                    }
                    margin-top: 24px;
                }
            }
            &__img {
                &__container {
                    width: 100%;
                    height: 212px;
                    border-radius: 15px;
                }
                width: 100%;
                height: 212px;
            }
            &__details {
                margin-left: 0;
                width: 100%;
                margin-top: 12px;
            }
            &__number {
                display: none;
            }
        }
    }
}