.detail-page {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 64px;
    max-width: $centerColumnWidth;
    padding-top: 32px;
    @media only screen and (max-width: $onlyMobile) {
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
    &--with-height {
        min-height: calc(100vh - 250px);
        @media only screen and (max-width: $onlyMobile) {
            min-height: auto;
        }
    }
    &__feed-link {
        @include get-font($f14, $fw-m, $blue, $lh);
        text-decoration: none;
        margin-bottom: 12px;
        @include transitionEase(#{text-decoration});
        display: block;
        text-align: center;
        &:hover, &:active, &:focus {
            text-decoration: underline;
        }
    }
    &__card {
        background: $white;
        @extend %border-box-shadow;
        border-radius: 10px;
        .feed-card{
            .poll-card{
                .poll-card__details {
                    .react-player__video{
                        margin-top: 0px;
                    } 
                }
            }
        }
}
    .post-comments-list {
        margin-top: 0;
        margin-bottom: 0;
    }
    .feed-card {
        .post-action-bar__action {
            .post-share-popup-up {
                margin-top: -300px;
                border-bottom-right-radius: 0;
            }
        }
    }
}