.collab-cities-modal {
    .default-modal__container {
        width: 518px;
        @media only screen and (max-width: $onlyMobile) {
            width: 100%;
        }
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        width: calc(100% + 48px);
        margin-left: -24px;
        height: calc(100vh - 231px);
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
        cursor: pointer;
        &__item {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 10px 24px;
            box-sizing: border-box;
            &:nth-child(even) {
                background: $grayBackground;
            }
            &__group {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }
    &__header {
        @include get-font($f12, $fw-b, $black, $lh);
        letter-spacing: 0.6px;
    }
    &__input {
        margin-right: 12px;
        cursor: pointer;
    }
    &__label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
    &__title {
        @include get-font($f14, $fw-r, $black, $lh);
    }
    .default-modal__footer {
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-bottom: -24px;
        padding: 12px 24px;
        box-sizing: border-box;
        border-top: 1px solid $grayBackground;
    }
    .see-all-container__search-input {
        max-width: 100%;
        &__container {
            margin-bottom: 16px;
        }
    }
}