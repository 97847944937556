.survey {
    &__big-list {
        padding: 0;
        margin: 0;
        list-style: none;
        .collaboration-item__action__link__text--green {
            color: $black !important;
        }
        &__header {
            text-decoration: none;
            &__helper {
                @include get-font($f12, $fw-b, $lightBlack, $lh);
                letter-spacing: 0.6px;
                margin-bottom: 8px;
            }
            &__status{
                width: fit-content;
                margin-bottom: 8px;
                @include get-font($f14, $fw-b, $white, $lh);
                padding: 4px 12px;
                border-radius: 4px;
                background-color: $copiedBackground;
                &__container{
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;
                }
                &.closed{
                  background-color: $black;
                }
                &.expired{
                  background-color: $red;
                }
              }
        }
        &__item {
            background: $white;
            @include form-input-border-shadow();
            border-radius: 10px;
            margin-top: 16px;
            padding: 24px;
            box-sizing: border-box;
            &--disabled {
                opacity: 0.65;
            }
            @media screen and (max-width: $onlyMobile) {
            &.survey-list{
                padding: 12px;
            }
        }
        }
        &__title {
            @include get-font($f18, $fw-sb, $black, $lh);
            word-break: break-word;
            margin-bottom: 8px;
        }
        &__description {
            @include get-font($f16, $fw-r, $lightBlack, $lh);
            margin-bottom: 16px;
            word-break: break-word;
        }
        &__action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .user-block{
                .user-block__details{
                  align-items: flex-start;
                }
            }
            &__helper {
                @include get-font($f16, $fw-sb, $lighterBlack, $lh);
            }
            &__state {
                @include get-font($f12, $fw-r, $red, $lh);
                letter-spacing: 0.6px;
                flex-shrink: 0;
            }
            &__button{
                &--secondary{
                    background-color: #f2f2f2;
                    border-radius: 8px;
                    padding: 8px 16px;
                    span{
                        @include get-font($f14,$fw-m,$black,$lh);
                        color: $black !important;
                    }
                }
            }
        }
        &__media {
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            gap: 8px;
            @media only screen and (max-width: $onlyMobile) {
            flex-wrap: wrap;
            }
            &__thumb {
                width: 113px;
                height: 64px;
                border: 1px solid $superLightBlack;
                border-radius: 10px;
                object-fit: contain;
                & + .play{
                    height: 24px;
                    width: 21px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    &__small-list {
        width: 100%;
        max-width: 580px;
        background: $white;
        @include form-input-border-shadow();
    }
    &__similar {
        &__container {
            margin-top: 24px;
        }
        &__header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            &__title {
                @include get-font($f18, $fw-b, $black, $lh);
            }
            &__helper {
                @include get-font($f16, $fw-m, $lightBlack, $lh);
                margin-top: 9px;
            }
            &__link {
                > img {
                    transform: rotate(180deg);
                }
            }
        }
    }
}


@media only screen and (max-width: $smallMobile) {
    .survey__big-list__action{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
    }
    .collaboration-item__show-interest-btn--green{
        // width: 100%;
        flex: 1;
    }
    .survey__view__button__showinterest.collaboration-item__show-interest-btn{
        // width: 100%;
        flex: 1;
    }
    .survey__big-list__action__button--secondary{
        // width: 100%;
        flex: 1;
        text-align: center;
    }
    .survey__view__button__submitted.collaboration-item__show-interest-btn{
        // width: 100%;
        flex: 1;
        margin-left: 0px;
    }
  }