.survey {
  &__card {
    background: $lightestGreen;
    display: block;
    padding: 24px 16px 16px 16px;
    text-decoration: none;

    &__wrapper {
      margin: 0 auto 16px;
    left: -16px;
    position: relative;
    width: calc(100% + 32px);
    .react-player__video{
        video{
          border-radius: unset;
        }
      }  
    }
    &__header {
      @include get-font($f12, $fw-b, $green, $lh);
      text-transform: uppercase;
      margin: 0 0 12px;
      word-break: break-word;
    }
    &__title {
      @include get-font($f16, $fw-m, $black, $lh);
      margin-bottom: 8px;
    }
    &__description {
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      margin-bottom: 16px;
    }
    &__action {
      width: 100%;
      height: 38px;
      background: $green;
      @include get-font($f12, $fw-b, $white);
      border-radius: 4px;
      border: 0;
      cursor: inherit;
    }
    &__img {
      width: calc(100% + 32px);
      height: auto;
      position: relative;
      left: -16px;
      border-radius: 0;
      max-height: 100%;
      display: block;
      margin: 0 auto 16px;
      aspect-ratio: 16/9;
    }
  }
}
