.invitation-timer-container {
    border-radius: 8px;
    border: 1px solid $imageWrapperBackgroundGray;
    padding: 2px 8px 2px 8px;
    &.details-view {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      gap: 8px;
      border: none;
      padding: 0;
      margin: 0;
    }
    .title {
      margin-bottom: 8px;
      @include get-font($f12, $fw-r, $copiedBackground, $lh);
      &.details-view {
        margin-bottom: 0;
      }
    }
    .dot-text {
      color: $black;
      &.details-view {
        color: $lightGrey;
      }
    }
    .countdown {
      display: flex;
      gap: 4px;
      margin-bottom: 12px;
    }
    .min_div {
      display: flex;
      gap: 4px;
    }
    .timer-conatiner {
      display: flex;
      gap: 4px;
      &:last-child {
        .dot-text {
          display: none;
        }
      }
    }
    .countdown-label {
      padding: 4px 8px;
      align-items: center;
      justify-content: center;
      display: flex;
      background: $red;
      border-radius: 4px;
      @include get-font($f12, $fw-sb, $white, $lh);
    }
    .time-label {
      margin-top: 4px;
      text-align: center;
      @include get-font($f10, $fw-sb, $copiedBackground, 18px);
    }
  }


   //responsive 
 @media screen and (max-width:$smallMobile) {
  .invitation-timer-container {
    &.details-view {
      margin-top: 16px;
      justify-content: flex-start;
    }
  }
}
