.share-post-modal {
    .default-modal__container {
        // padding-bottom: 0;
    }
    &__content {
        // max-height: 500px;
        overflow: hidden;
        overflow-y: auto;
        @include transitionEase();
        &__helper {
            @include get-font($f16, $fw-r, $black, $lh);
            margin-bottom: 30px;
        }
        .post-bar__post__textarea {
            margin-bottom: 64px;
        }
        .user-create-block {
            margin-bottom: 12px;
        }
        &__shared-post {
            @extend %border-box-shadow;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            .post-details__text, .photo-details__container__slider {
                margin-bottom: 16px;
            }
            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $grayBackground;
                z-index: 11;
            }
        }
    }
    .default-modal__footer {
        padding: 12px 24px;
    }
}

@media only screen and (max-width: $onlyMobile) {
    .share-post-modal {
        .default-modal__footer {
            padding: 16px 0px 0px 0px;
        }
        .default-modal__container{
            width: 100%;
            max-height: 90vh;
            overflow-x: scroll;
            @include centerItem();
        }
        .default-modal__container {
            width: 100%;
            max-height: 92vh;
            overflow-y: scroll;
        }
    }
}