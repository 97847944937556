.review-campus {
    &__container {
        display: flex;
        padding: 32px 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 760px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        > img {
            width: 162px;
            height: auto;
        }
        h2 {
            margin: 0;
            margin-top: 16px;
            @include get-font($f18, 900, $black, $lh);
        }
    }
    &__title {
        @include get-font($f16, $fw-m, $black, $lh);
        margin-top: 8px;
    }
    &__subtitle {
        @include get-font($f14, $fw-r, $black, $lh);
        margin-top: 16px;
        text-align: center;
    }
    &__form {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
            @include get-font($f14, $fw-m, $black, $lh);
        }
    }
    &__input {
        @extend %appearance-none;
        height: 48px;
        border: 0.5px solid $grayBackground;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        box-sizing: border-box;
        width: 490px;
        padding-left: 24px;
        @include get-font($f16, $fw-sb, $black, $lh);
    }
    &__submit-btn {
        @extend %appearance-none;
        height: 48px;
        border: 0.5px solid $grayBackground;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        margin: 0;
        box-sizing: border-box;
        padding: 0;
        width: 158px;
        background: $green;
        text-align: center;
        cursor: pointer;
        @include get-font($f16, $fw-sb, $white, $lh);
        &--disabled {
            opacity: 0.65;
            cursor: not-allowed;
        }
    }
    &__link {
        display: flex;
        margin-top: 16px;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        img {
            width: 20px;
            height: auto;
            margin-right: 8px;
        }
        @include get-font($f14, $fw-m, $black, $lh);
    }
}

@media only screen and (max-width: $tab) {
    .review-campus {
        &__container {
            padding-left: 16px;
            padding-right: 16px;
            box-sizing: border-box;
            h2 {
                margin-top: 12px;
                font-size: $f16;
            }
        }
        &__title {
            font-size: $f14;
        }
        &__input, &__submit-btn {
            width: auto;
            font-size: $f14;
        }
        &__submit-btn {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}