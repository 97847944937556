.pr-reports {
  &__benchmark {
    height: 24px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include get-font($f12, $fw-b, $white, $lh);
    background: $mediumBlack;
    border-radius: 12px;
    margin-left: 12px;
    flex-shrink: 0;
    > img {
      display: block;
      margin-right: 4px;
    }
  }
  &__summary {
    &--links {
      margin-top: 48px;
    }
    &__header {
      &__title {
        @include get-font($f22, $fw-sb, $black, $lh);
      }
      &__helper {
        margin-top: 8px;
        @include get-font($f16, $fw-r, $lightBlack, $lh);
      }
    }
    &__list {
      margin: 32px 0 0;
      padding: 0;
      width: 100%;
      min-width: 100%;
      list-style: none;
      border-radius: 10px;
      overflow: hidden;
      border-collapse: collapse;
      @include form-input-border-shadow();
      border: 0;
      overflow-x: auto;
      display: grid;
      &__item {
        display: flex;
        align-items: stretch;
        box-sizing: border-box;
        &--header {
          background: $grayBackground;
          border-bottom: 1px solid $black;
          .pr-reports__summary__list__box {
            font-weight: $fw-sb;
            display: flex;
            flex-direction: column;
            button {
              @extend %appearance-none;
              background: none;
              border: 0;
              padding: 0;
              @include get-font($f14, $fw-r, $blue, $lh);
              cursor: pointer;
              @include transitionEase(#{color});
              &:hover,
              &:active {
                color: $darkBlue;
              }
            }
          }
        }
        &--overall {
          background: $superLightestBlue;
          border-bottom: 1px solid $black;
          .pr-reports__summary__list__box {
            font-weight: $fw-m;
          }
        }
        &--weights {
          border-top: 1px solid $black;
          border-bottom: 0 !important;
        }
      }
      &__box {
        width: 100%;
        @include get-font($f14, $fw-r, $black, $lh);
        text-align: center;
        border-right: 1px solid $grayBackground;
        padding: 16px;
        text-transform: capitalize;
        &:last-child {
          border-right: 0;
        }
        &--left {
          // text-align: left;
        }

        abbr {
          text-decoration: none;
        }
        &__product {
          word-break: break-word;
          text-align: center;
        }
      }
    }
  }
  &__products {
    &__list {
      margin: 32px 0 0;
      list-style: none;
      padding: 0;
      &__item {
        margin-top: 32px;
      }
      &__color-box {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        border-radius: 5px;
      }
      &__details {
        display: flex;
        flex: 1;
        align-items: flex-start;
        justify-content: space-between;
        &__container {
          display: flex;
          align-items: flex-start;
        }
      }
      &__info {
        &__title {
          @include get-font($f16, $fw-sb, $black, $lh);
        }
        &__helper {
          @include get-font($f14, $fw-r, $lightBlack, $lh);
          margin-top: 4px;
        }
      }
    }
    &__sensogram {
      margin-top: 16px;
      @include get-font($f16, $fw-sb, $lightBlack, $lh);
      margin-left: 36px;
    }
  }
  &__headers {
    &__list {
      margin: 16px 0 0;
      padding: 0 24px;
      overflow: hidden;
      overflow-x: auto;
      list-style: none;
      white-space: nowrap;
      &__item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: top;
        background: $grayBackground;
        cursor: pointer;
        height: 40px;
        padding: 0 16px;
        box-sizing: border-box;
        border-radius: 10px;
        text-transform: capitalize;
        margin-right: 12px;
        @include transitionEase(#{background, color, font-weight});
        @include get-font($f16, $fw-r, $black, $lh);
        &:hover {
          background: $lighterBlack;
        }
        &--active {
          background: $black;
          color: $white;
          font-weight: $fw-b;
          &:hover {
            background: $black;
            color: $white;
          }
        }
      }
    }
  }
  &__report {
    &__container {
      margin-top: 16px;
      padding: 0 24px;
    }
  }
  &__reports {
    &__header {
      display: flex;
      align-items: flex-start;
      &__container {
        padding-bottom: 16px;
        border-bottom: 1px solid $grayBackground;
      }
      &__stats {
        margin-top: 8px;
        display: flex;
        > p {
          @include get-font($f14, $fw-m, $lightBlack, $lh);
          margin-right: 16px;
          position: relative;
          &:after {
            content: "•";
            position: absolute;
            margin-left: 6px;
          }
          &:last-child {
            &:after {
              content: "";
            }
          }
        }
      }
      &__color-box {
        width: 32px;
        height: 32px;
        border-radius: 5px;
        margin-right: 12px;
        flex-shrink: 0;
      }
      &__details {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: flex-end;
      }
      &__title {
        @include get-font($f22, $fw-sb, $black, $lh);
      }
      &__helper {
        margin-top: 6px;
        @include get-font($f16, $fw-r, $lightBlack, $lh);
      }
    }
  }
  &__downloadreports {
    margin-top: 32px;

    &__div {
      display: flex;
      padding: 16px 0;
      border-bottom: 1px solid $lightGray;

      &__infodiv {
        flex-basis: 70%;
        &__title {
          @include get-font($f16, $fw-sb, $black, $lh);
        }
        &__description {
          margin-top: 4px;
          @include get-font($f14, $fw-r, $lightestGray, $lh);
        }
      }
      &__buttondiv {
        margin-left: auto;
      }
    }
  }
  &__graphs {
    &__headerline {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $lightGray;
    }
    &__pills {
      &.pr-manage__filters-pills__container {
        margin-top: 0px;
        margin-bottom: 12px;
      }
    }
    &__accordian {
      &__item {
        margin-bottom: 16px;
      }
      &__title {
        display: flex;
        border-radius: 8px;
        border: solid 1px $lightGrayText;
        background-color: $grayBackground;
        padding: 8px 16px;
        cursor: pointer;

        &__active {
          border-radius: 8px 8px 0px 0px;
        }

        &__text {
          flex-basis: 90%;
          @include get-font($f16, $fw-m, $black, $lh);

          &__multiquestion {
            @include get-font($f14, $fw-b, $blue, $lh);
          }
        }
        &__icon {
          margin: auto 0 auto auto;
          height: 12px;
          width: 16px;
        }
      }
      &__content {
        padding: 24px;
        border: solid 0.5px $superLightBlack;
        border-radius: 0px 0px 8px 8px;
        background-color: #fff;

        &__merged {
          padding-bottom: 24px;
          margin-bottom: 24px;
          border-bottom: 1px dashed $grayText;

          &__hr {
            border: 1px dashed $grayText;
            margin-bottom: 32px;
          }
          &__questiondiv {
            display: flex;
            margin-top: 16px;
            padding: 8px 16px;
            border-radius: 8px;
            border: solid 1px $lightGrayText;
            background-color: $grayBackground;
            @include get-font($f14, $fw-m, $black, $lh);

            &__header {
              margin-left: auto;
              border-radius: 12px;
              background-color: $aquaBlue;
              text-transform: capitalize;
              padding: 2px 12px;
              @include get-font($f14, $fw-r, $black, $lh);
            }
            &:first-child {
              margin-top: 0;
            }
          }
        }

        &__sortdiv {
          display: flex;
          justify-content: flex-end;

          &__text {
            margin-top: 8px;
            @include get-font($f14, $fw-r, $lightestGray, $lh);
          }
          &__dropdown {
            margin-left: 12px;
            min-width: 286px;
            height: 38px;
            padding: 4px 8px;
            border-radius: 4px;
            border: solid 1px $grayText;
            background-color: $grayBackground;
            color: $lightestGray;
          }

          &__productsort {
            margin-left: 12px;
            width: 220px;
          }
          &__sort {
            width: 120px;
            height: 30px;
            margin-left: 12px;

            &__control {
              border-radius: 4px !important;
              max-height: 38px;
              background-color: rgba(23, 23, 23, 0.05) !important;
              min-height: 30px !important;
            }

            &__menu-list {
              background-color: $grayBackground;
            }

            &__indicator-separator {
              background-color: transparent !important;
            }

            &__singlevalue {
              display: flex;

              &__sortdiv {
                margin-top: 3px;
                margin-right: 8px;
                margin-left: 6px;
                width: 14px;

                &__icon path {
                  fill: $blue;
                }
              }
              &__title {
                @include get-font($f14, $fw-m, $blue, $lh);
              }
            }

            &__option {
              display: flex;
              padding: 6px 10px;

              &__sorticon {
                margin-right: 8px;
                margin-left: 6px;
                width: 14px;
              }
              &__textdiv {
                display: flex;
                flex-direction: column;

                &__title {
                  @include get-font($f14, $fw-r, $lightestGray, $lh);
                }
              }

              &:hover {
                border-color: rgb(38, 132, 255);
                background-color: $aquaBlue;
                cursor: pointer;
              }
            }
          }
        }
        &__percentagetoggle {
          margin-top: 86px;
          display: flex;
          align-items: center;
          justify-content: center;

          &__text {
            margin-right: 12px;
            @include get-font($f14, $fw-m, $black, $lh);
          }
        }
        &__chartdiv {
          margin-top: 40px;
        }
        &__charthr {
          margin-top: 24px;
          margin-bottom: 40px;
          border-top: 1px solid $superLightBlack;
        }

        &__intensitygraph {
          display: flex;
          align-items: center;
          justify-content: center;

          &__text {
            margin-right: 12px;
            @include get-font($f14, $fw-m, $black, $lh);
          }
        }
        &__combinationgraph {
          &__hr {
            margin-top: 32px;
            margin-bottom: 32px;
            border: 1px dashed $grayText;
          }
          &__batchtitle {
            margin-top: 24px;
            text-decoration: underline;
            @include get-font($f16, $fw-m, $black, $lh);
          }
          &__toggle {
            &.pr-reports__graphs__accordian__content__percentagetoggle {
              margin-top: 42px;
            }
          }
        }
      }
    }
  }
}

.pr-weight {
  &__modal {
    &__container {
      margin-bottom: 32px;
      .error-text {
        margin-top: 8px;
      }
    }
    &__title {
      @include get-font($f14, $fw-r, $black, $lh);
    }
    &__header {
      @include get-font($f14, $fw-sb, $black, $lh);
      letter-spacing: 0.6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $black;
      padding-bottom: 8px;
      margin-top: 16px;
    }
    &__weights {
      &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid $black;
        padding-bottom: 8px;
        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;
          &--total {
            border-top: 1px solid $black;
            padding: 16px 0 8px;
            .pr-weight__modal__weights__text {
              font-weight: $fw-b;
              &--secondary {
                font-weight: $fw-r;
                margin-right: 16px;
                font-size: $f14;
              }
              &--danger {
                color: $red;
              }
            }
          }
        }
      }
      &__text {
        @include get-font($f16, $fw-r, $black, $lh);
        text-transform: capitalize;
      }
      &__input {
        @extend %appearance-none;
        width: 52px;
        border: 0;
        text-align: center;
        @include get-font($f14, $fw-r, $black);
        padding: 8px;
        background-color: $grayBackground;
        border-radius: 5px;
      }
    }
  }
}
