.biz__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 10px;
  // overflow: hidden;
  margin: 24px auto 32px;
  &__overflow__visible {
    overflow: visible;
  }
  &__head {
    background-color: $superLightTeal;
    &__col {
      @include get-font($f12, $fw-b, $black, $lh);
      letter-spacing: 0.6px;
      text-transform: uppercase;
      padding: 16px 16px 16px 24px;
      &--left-aligned {
        text-align: left;
      }
    }
  }
  &__body {
    background: $white;
    &__col {
      padding: 24px;
      text-align: center;
      &--left-aligned {
        text-align: left;
      }
    }
    &__row {
      border-bottom: 1px solid $grayBackground;
      &:last-child {
        border-bottom: 0;
      }
    }
    &__title {
      @include get-font($f14, $fw-m, $black, $lh);
      &--bold {
        font-weight: $fw-b;
        letter-spacing: 0.7px;
      }
    }
  }
  &__product {
    max-width: 250px;
    display: flex;
    align-items: center;
    text-decoration: none;
    &--disabled {
      opacity: 0.6;
    }
    &__icon {
      width: 66px;
      height: 44px;
      background-color: $grayBackground;
      margin-right: 8px;
      border-radius: 6px;
      flex-shrink: 0;
      overflow: hidden;
      > img {
        width: 66px;
        height: 44px;
        object-fit: contain;
        margin: 0 auto;
      }
    }
    &__info {
      display: flex;
      align-items: center;
    }
  }
  &__leads {
    &__info {
      max-width: 280px;
      text-align: left;
      &__helper {
        margin-top: 4px;
        @include get-font($f12, $fw-r, $black, $lh);
      }
    }
    &__desc {
      width: 280px;
      max-width: 280px;
      @include get-font($f14, $fw-r, $black, $lh);
      text-align: left;
    }
    &__assign {
      width: 200px;
      max-width: 200px;
      text-align: left;
      &__btn {
        @extend %appearance-none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        border-radius: 16px;
        border: 1px solid $teal;
        background-color: transparent;
        box-sizing: border-box;
        @include get-font($f14, $fw-m, $teal);
        cursor: pointer;
      }
      &__text {
        @include get-font($f14, $fw-r, $black, $lh);
      }
    }
    &__contact {
      display: flex;
      justify-content: center;
      > a,
      > div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: $grayBackground;
        border-radius: 16px;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__status {
      > button {
        @extend %appearance-none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 0 12px;
        border-radius: 16px;
        background-color: $grayBackground;
        border: 0;
        margin: 0 auto;
        @include get-font($f14, $fw-m, $black, $lh);
        > img {
          margin-left: 4px;
        }
      }
      > p {
        @include get-font($f12, $fw-r, $lightBlack, $lh);
        margin-top: 12px;
      }
    }
    &__logs {
      @extend %appearance-none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 12px;
      border-radius: 16px;
      background-color: $grayBackground;
      border: 0;
      margin: 0 auto;
      @include get-font($f14, $fw-r, $black, $lh);
      white-space: nowrap;
      > img {
        margin-right: 4px;
      }
    }
  }
  &__user {
    display: flex;
    align-items: center;
    text-decoration: none;
    &--partner {
      display: flex;
      text-decoration: none;
      text-align: left;
      &__helper {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
      }
    }
    &__icon {
      width: 44px;
      height: 44px;
      border-radius: 24px;
      background-color: $grayBackground;
      margin-right: 12px;
      > img {
        width: 44px;
        height: 44px;
        display: block;
        object-fit: cover;
        border-radius: 24px;
      }
    }
    &__title {
      @include get-font($f14, $fw-m, $black, $lh);
    }
  }
}
