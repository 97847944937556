.paidribbon {
  &__rectangle {
    display: inline-block;
    background-color: $paidRibbon;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &__paid {
    @include get-font($f15, $fw-b, $white, $lh);
    padding: 2px 12px 2px 12px;
    position: relative;
  }

  &__glow {
    background-color: $white;
    width: 20%;
    height: 100%;
    z-index: 10;
    bottom: 0;
    top: 0;
    opacity: 1;
    position: absolute;
    animation: flow 0.9s linear infinite;
    -webkit-animation: flow 0.9s linear infinite;
    -moz-animation: flow 1.5s linear infinite;

    transform: skew(-20deg);
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);

    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 1) 100%
    );
    border-left: 1px solid $white;
  }

  @-webkit-keyframes flow {
    0% {
      left: 0px;
      opacity: 0;
    }
    50% {
      left: 30px;
      opacity: 0.3;
    }
    100% {
      left: 50px;
      opacity: 0;
    }
  }
  @keyframes flow {
    0% {
      left: 0px;
      opacity: 0;
    }
    50% {
      left: 30px;
      opacity: 0.3;
    }
    100% {
      left: 50px;
      opacity: 0;
    }
  }
}

.productinfo {
  &__ribbon {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    @media only screen and (max-width: $tab) {
      margin: 12px 0px;

    }
  }
}

.productreview {
  &__ribbon {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    position: relative;
    left: 24px;
    bottom: 4px;
    @media only screen and (max-width: $onlyMobile) {
      left: 12px;
    }
  }
}
.reviewsproduct {
  &__ribbon {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    position: relative;
    right: -16px;
    bottom: 2px;
    height: 0%;
    @media only screen and (max-width: $onlyMobile){
      position: absolute;
      right: 0;
      bottom: unset;
      height: auto;
      width: fit-content;
      top: 20px;
      z-index: 9;
    }
  }
}
.survey__details {
  &__ribbon {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    position: relative;
    left: 24px;
    bottom: 0px;
  }
}

.feedcardribbon {
  &__survey {
    &__ribbon {
      display: flex;
      align-content: flex-end;
      justify-content: flex-end;
      position: relative;
      left: 16px;
      bottom: 6px;
      margin-bottom: -32px;
    }
  }
  &__product {
    &__ribbon {
      position: absolute;
      z-index: 12;
      top: 5px;
      right: 0;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }
}
