.quiz {
  &__container {
    width: 100%;
    margin-left: auto;
    padding: 32px 0;
    margin-right: auto;
  }
  &__seeAll {
    background: $orange !important;
  }
  &__view {
    padding: 32px 0;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    &__button {
      &__showinterest {
        &.collaboration-item__show-interest-btn {
          height: 40px;
          cursor: pointer;
          background: $surveyTeal;
          border: 0px;
          margin-left: 16px;
          @include get-font($f14, $fw-b, $white, $lh);
        }
      }
      &__interested {
        &.collaboration-item__show-interest-btn {
          height: 40px;
          cursor: default;
          background: $white;
          border: 1px solid rgba($surveyTeal, 0.2);
          margin-left: 16px;
          line-height: 0;
          @include get-font($f14, $fw-b, $surveyTeal);
        }

        &__icon {
          vertical-align: text-top;
        }
        &__text {
          margin-left: 8px;
        }
      }
      &__modal-interested {
        &.show-interest-modal__footer__action-btn {
          background: $surveyTeal;
        }
      }
      &__submitted {
        padding: 8px 0;
        width: 135px;
        height: 40px;
        border: none;
        background: #fde4d0;
        border-radius: 8px;
        &__text {
          @include get-font($f14, $fw-b, $orange);
          text-transform: uppercase;
        }

        &.blue{
          background:#DBE9F9;
          .quiz__view__button__submitted__text {
            @include get-font($f14, $fw-b, $blue);
            text-transform: uppercase;
          }
        }
      }
      &__playQuiz {
        padding: 8px 17px;
        height: 40px;
        background: $orange;
        border-radius: 8px;
        @extend %appearance-none;
        @extend %border-box-shadow;
        box-sizing: border-box;
        cursor: pointer;
        text-decoration: none;
        @include get-font($f14, $fw-b, $white);
        flex-shrink: 0;
      }
    }
    &__solidBtn {
      @extend %appearance-none;
      border-radius: 8px;
      height: 40px;
      background: $white;
      padding: 8px 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      @include get-font($f14, $fw-b, $black, $lh);
      letter-spacing: 0.7px;
      cursor: pointer;
      text-decoration: none;
      flex-shrink: 0;
      width: auto;
      img {
        margin-right: 8px;
        width: 16px;
      }
      &--disabled {
        cursor: not-allowed;
        opacity: 0.65;
      }
      &--yellow {
        background: $yellow;
        @include transitionEase(#{background});
        &:hover,
        &:active,
        &:focus {
          background: $darkYellow;
        }
      }
      &--orange {
        background: $orange;
        color: $white;
        @include transitionEase(#{background});
        &:hover,
        &:active,
        &:focus {
          background: $mediumOrange;
        }
      }
      &--blue{
        background: $blue;
        color: $white;
        @include transitionEase(#{background});
        border: none;
        &:hover,
        &:active,
        &:focus {
          background: $mediumBlue;
          border: none;
        }
      }
    }
    &__details {
      max-width: $centeredContainer;
      margin: 0 auto;
      width: 100%;
      background: $white;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 $superLightBlack;
      border: solid 0.5px $superLightBlack;
      &__images {
        width: 100%;
        display: flex;
        @media only screen and (max-width: $onlyMobile) {
          flex-wrap: wrap;
        }
      }
      &__image {
        aspect-ratio: 16/9;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        object-fit: cover;
        width: inherit;
        object-position: top;
        @media only screen and (max-width: $onlyMobile) {
          margin-bottom: 8px;
          width: 100%;
          height: 100%;
        }
        &--noBorder {
          border-bottom-right-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }
      }
      &__viewImage {
        width: 136px;
        height: 136px;
        border-radius: 10px;
        border: solid 1px $superLightBlack;
        margin-right: 8px;
        object-fit: cover;
        object-position: top;
        @media only screen and (max-width: $onlyMobile) {
          margin-bottom: 8px;
          width: 60px;
          height: 60px;
        }
      }
    }
    &__multi__buttons{
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  &__details {
    max-width: $centeredContainer;
    margin: 0 auto;
    width: 100%;
    background: $white;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 $superLightBlack;
    border: solid 0.5px $superLightBlack;
    box-sizing: border-box;
    &__content {
      padding: 32px 24px;
    }
    &--viewPage {
      margin-top: 24px !important;
    }
    .post-comments-list {
      margin-bottom: 0;
    }
    .post-comments-containers {
      max-width: 100%;
    }
    &__submissionCount {
      width: 100%;
      padding: 12px 24px;
      background: #FDE4D0;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 16px;
      left: -24px;
      &--cta {
        &--img {
          margin-left: 8px;
        }
        border-left: 1px solid #F8AE73;
        padding-left: 24px;
      }
      &__text {
        @include get-font($f14, $fw-m, $black);
      }
      &--addSaperator {
        &::after {
          content: " ";
          position: absolute;
          height: 1.5px;
          background: rgba(0, 0, 0, 0.05);
          width: calc(100% + -48px);
          bottom: -16px;
        }
      }
    }
    &__title {
      @include get-font($f22, $fw-sb, $black, $lh);
      word-break: break-word;
      box-sizing: border-box;
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
    &__posted {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: $onlyMobile) {
        gap: 15px;
        flex-direction: column;
        align-items: stretch;
      }
    }
    .collaboration-item__admin__action__container {
      .collaboration__view__action__btn {
        margin-left: 16px;
      }
    }
    &__description {
      margin: 8px 0px;
      @include get-font($f14, $fw-m, $black, $lhOnboarding);
      word-break: break-word;
      white-space: pre-line;
      .linkified {
        font-size: inherit;
        color: $blue;
        text-decoration: none;
      }
      &--playable {
        @include get-font($f16, $fw-r, $black, $f24);
        margin: 8px 0px 0px 0px;
      }
    }
    &__creator {
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      margin-top: 24px;
      padding-bottom: 8px;
      border-bottom: 1px solid $grayBackground;
      a {
        color: inherit;
        font-size: inherit;
        text-decoration: none;
      }
    }
    &__video-container {
      margin-top: 24px;
      border-radius: 10px;
      background: $black;
      overflow: hidden;
    }

    &__admin {
      &__action {
        &__container {
          margin-top: 24px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        &__right {
          display: flex;
          gap: 15px;
          align-items: center;
        }
      }
    }

    &__action {
      display: flex;
      width: 100%;
      gap: 15px;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: $onlyMobile) {
        flex-direction: column;
      }
      &__edit,
      &__setup-roles {
        @extend %border-box-shadow;
        display: inline-block;
        height: 40px;
        box-sizing: border-box;
        padding: 10px 16px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        &__icon {
          margin-right: 12px;
          vertical-align: text-top;
          display: inline-block;

          &.black{
            filter: brightness(0) saturate(100%);
          }
        }
        &__text {
          @include get-font($f14, $fw-b, $black, $lh);
          letter-spacing: 0.7px;
          text-decoration: none;
          display: inline-block;
          vertical-align: top;
        }
        &--no-margin {
          margin-left: 0;
        }

        &.disabled{
          cursor: not-allowed;
          opacity: 0.65;
        }
      }
      &__setup-roles {
        background: $lightestBlue;
        border-color: transparent;
        &__text {
          color: $blue;
        }
      }
      &__edit {
        background: $white;
      }
      &__link {
        text-decoration: none;
        &--manage {
          color: $black;
        }
        &--interest {
          color: $blue;
        }
        &__text {
          &--blue {
            color: $blue !important;
          }
          &--green {
            color: $green !important;
          }
          &--orange {
            color: $orange !important;
          }
          &--black {
            color: $black !important;
          }
          &--strong {
            @include get-font($f18, $fw-sb, $black, $lh);
            margin-right: 4px;
          }
          &--primary {
            @include get-font($f14, $fw-r, $black, $lh);
          }
        }
        &--interested {
          @include get-font($f12, $fw-b, $blue, $lh);
          letter-spacing: 0.6px;
          display: inline-block;
          img {
            margin-right: 8px;
            width: 12px;
            vertical-align: middle;
          }
        }
        &--show-interest {
          height: 36px;
          border: 1.5px solid $blue;
          border-radius: 7px;
          background: $btnBg;
          display: inline-block;
          line-height: 36px;
          padding: 8px 16px;
          box-sizing: border-box;
          @include get-font($f12, $fw-b, $blue, $lh);
          letter-spacing: 0.6px;
        }
        &--response{
          all: unset;
          cursor: pointer;
          padding: 8px 16px;
          @include get-font($f14,$fw-m,$black,$lh);
          display: flex;
          align-items: center;
          gap: 4px;
          background-color: #f2f2f2;
          border-radius: 8px;
        }
      }
    }
  
  }
  
}

@media only screen and (max-width: $smallMobile) {
  .quiz__details__action__link--response{
    justify-content: center;
  }
  .quiz__view__button__submitted{
    // width: 100%;
    flex: 1;
    justify-content: center;
    padding: 8px 16px;
  }
  .quiz__view__solidBtn--orange{
    flex: 1;
    justify-content: center;
  }
}