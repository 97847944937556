.survey__manage {
  &__submissionsListItem {
    padding: 16px;
    position: relative;
    
    & + &::after {
      content: " ";
      position: absolute;
      height: 1px;
      background: #f2f2f2;
      width: 100%;
      top: 0;
    }
   
    &--alignment {
      top: 15vh !important;
      > div:first-child {
        padding: 14px 16px !important;
      }
      > div:last-child {
        min-height: 60vh;
        padding-right: 0px
      }
    }
    &__title {
      @include get-font($f14, $fw-b, $darkBlack, $lhOnboarding)
    }
    &__body {
      @include get-font($f14, $fw-m, $black, $lhOnboarding);
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
  &.collaboration__header__link {
    color: $green;
  }
  &__filters {
    &.pr-manage__filters__header__clear-btn {
      color: $green;
    }
  }
  &__sidebar {
    &.pr-manage__sidebar__content-container {
      padding: 0px;
    }

    &.pr-manage__sidebar__list {
      &:hover,
      &:focus {
        background: $white;
        cursor: default;
        border-radius: 10px;
      }
    }

    &.pr-manage__sidebar__list__item {
      background: $white;
      cursor: default;
      border-radius: 10px;
      &:hover,
      &:focus {
        background: $white;
        cursor: default;
        border-radius: 10px;
      }
    }
  }
  &__content {
    &:has(input:focus) {
      border: 1px solid #747474;
    }
    input {
      &:active, &:focus {
        // border: 1px solid black;
        &::placeholder {
          color: $lightestBlack;
       }
      }
    }
    &.pr-manage__content__title {
      color: $green;
    }

    &.collaboration-manage__search {
      margin-bottom: 24px;
    }

    &.pr-manage__report-btn {
      color: $green;
      background: $lightestGreen;
    }

    &.pr-manage__assign-btn {
      border: 1px solid $lighterGreen;
      color: $green;
      background-color: $lightestGreen;
    }
    &.pr-manage__btn--disabled {
      opacity: 1;
      border: 1px solid $lightGrayText;
      background-color: $lightGrayText;
      @include get-font($f14, $fw-m, $grayText, $lh);
      cursor: default;
      pointer-events: none;
    }

    &__downloadapplicants {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.6;
      cursor: not-allowed !important;

      &__icon {
        width: 16px;
        margin: 6px 0px 0px 4px;

        circle {
          fill: $green;
        }
      }
    }

    &__actionbtn {
      display: flex;
    }
    &__beginsurvey {
      &.pr-manage__assign-btn {
        border: 0px;
        @include get-font($f14, $fw-m, $white, $lh);
        background-color: $green;

        &:disabled {
          opacity: 1;
          background-color: $lightGrayText;
          @include get-font($f14, $fw-m, $grayText, $lh);
          cursor: default;
          pointer-events: none;
        }
      }
    }

    &__overview {
      &.pr-manage__content__header__statsblock {
        display: flex;
        justify-content: space-between;
      }
      &.pr-manage__content__header__stats {
        width: 50%;
        max-width: 50%;
        overflow-y: auto;
      }
    }
    &__beginsurvey {
      &.profile-edit-modal__btn--secondary {
        background: $white !important;
        color: $green;
      }
      &.profile-edit-modal__btn {
        background: $green;
        &:hover {
          background: $green;
          color: $white;
        }
      }
    }
    &__message {
      &.default-modal__footer__action-btn {
        background: $green;
        color: $white;
        &:hover {
          background: $green;
          color: $white;
        }
      }
      &.default-modal__footer__btn--disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &__inviteuser {
      &.pr-manage__invite {
        margin-left: auto;
        flex: 0.95 1;
      }
    }
  }
  &__table {
    &.prproductapplicant__table__row {
      height: auto;
      &:last-child {
        height: 200px;
      }
    }
    &__cell {
      padding-top: 20px !important;
    }
    &__overflowCell {
      overflow-y: auto;
      overflow-x: hidden;
      @include getDefaultScrollbar();
    }
    &__userdetails {
      &__p {
        display: flex;
        flex-direction: column;
      }
      &__invitedtext {
        @include get-font($f12, $fw-sb, $blue, $lh);
      }
    }
    &__usertype {
      text-align: center;
      padding: 8px;
      box-sizing: border-box;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      &__expert {
        @include get-font($f14, $fw-b, $blue, $lh);
      }
      &__consumer {
        @include get-font($f14, $fw-b, $grayText, $lh);
      }
    }
    &__usertastingtype {
      text-align: center;
      padding: 8px;
      box-sizing: border-box;
      &__supertaster {
        @include get-font($f14, $fw-m, $red, $lh);
      }
      &__normal {
        @include get-font($f14, $fw-r, $black, $lh);
      }
    }
    &__sensorytrained {
      text-align: center;
      padding: 8px;
      box-sizing: border-box;
      &__yes {
        @include get-font($f14, $fw-m, $black, $lh);
      }
      &__no {
        @include get-font($f14, $fw-r, $lightestGray, $lh);
      }
    }
    &__gender {
      text-align: center;
      padding: 8px;
      box-sizing: border-box;
      @include get-font($f14, $fw-r, $black, $lh);
    }
    &__status {
      display: flex;
      align-items: center;
      padding: 0px 8px 8px 8px;
      gap: 6px;
      margin-top: 4px;
      position: relative;
      &--flag-img {
        margin-top: 4px;
      }
      &__tobenotified-text {
        margin-left: auto;
        @include get-font($f14, $fw-m, $removeRed, $lh);
      }
      &__notified-text {
        margin-left: auto;
        @include get-font($f14, $fw-m, #e6a51c, $lh);
      }
      &__inProgress-text {
        margin-left: auto;
        @include get-font($f14, $fw-m, $orange, $lh);
      }
      &__completed-text {
        margin-left: auto;
        @include get-font($f14, $fw-m, $green, $lh);
      }

      &__action {
      //  margin-left: auto;

        &__button {
          &.prproductapplicant__table__useraction__button {
            padding: 5px 0px 10px 0px;
          }
        }

        &__message {
          path {
            stroke: $black;
          }
        }
      }
    }
  }
  &__rollback {
    .profile-edit-modal__btn--secondary {
      color: $removeRed;
    }
  }
  &__reject {
    &__text {
      color: $removeRed;
    }
    .profile-edit-modal__btn--secondary {
      color: $removeRed;
    }
  }
  &__shortlist {
    &__text {
      color: $green;
    }
    &__img {
      g > g {
        fill: $green;
      }
      path {
        stroke: $green;
      }
    }

    .profile-edit-modal__btn--secondary {
      color: $removeRed;
    }
    .default-modal__normal-btn {
      background-color: $green;
    }
  }
  &__participation-declined {
    .profile-edit-modal__btn--secondary {
      color: $removeRed;
    }
    .default-modal__remove-btn {
      color: $white;
      background: $black;
      text-decoration: none;
    }
  }
}


.manage-submissions {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
  }
  &__rightpanel {
    padding-right: 16px;
    min-width: fit-content;
    &--buttons {
      padding: 8px 16px;
      height: 37px;
      min-width: 119px;
      @include get-font($f14,$fw-m, $white);
      border: none;
      cursor: pointer;
      background: black;
      &.notselected {
        background: #0000000d;
        color: #747474;
      }
      &:first-child {
        border-radius: 8px 0px 0px 8px;
      }
      &:last-child {
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
  &__header{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      cursor: pointer;
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      padding: 0px;
      &--container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 8px;
        > div:first-child {
          margin-bottom: 0px !important;
         }
         .user-block .user-block__details{
          > div:last-child {
            > a{
              font-size: 16px;
              @include limit-text-to-line(2);
            }
           }
         }
      }
  }
  &__flag {
    &__container {
      padding: 8px 10px;
      background-color: #FFECF0;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      background-color: #FFECF0;
      color: #DD2E1F;
      &.unflag {
        background-color: #CCECDA;
        color: #171717;
      }
    }
    &__text {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
    }
    &-title {
      @include get-font($f14, $fw-m, $white, $lhOnboarding);
      margin-left: 8px;
    }
    &-icon {
      position: relative;
      top: 4px;
    }
    &--unflag-div {
      padding: 8px 16px;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      align-items: end;
        background-color: #00A146;
      &.unflag {
        background-color: #DD2E1F;
      }
    }
  }
  &_is-collapsed {
  
    display: grid;
    grid-template-rows: 0fr;
    transition:  grid-template-rows 500ms;
    margin-bottom: 0px;
    &.active {
      grid-template-rows: 1fr;
      padding-bottom: 16px;
    }
    > div {
      overflow: hidden;
    }
  }
  &_image {
    position: relative;
    top: 3px;
    &--flag {
      left: 8px;
    }
  }

  &__bullet-conatiner {
    display: flex;
    align-items: baseline;
  }
  &__title {
      @include get-font($f14, $fw-b, $darkBlack, $lhOnboarding);
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  &__duration_flag-div {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__duration {
      &-conatiner {
        border-radius: 8px;
        padding: 8px 12px;
        background-color: #CCECDA;
        width: fit-content;
        &--with-collapsed{
          margin-bottom: 0px;
        }
        &--without-collapsed{
          margin-bottom: 16px            
        }
        &--tasting {
          background-color: #FCF1D2
        }
      }
      &-title {
        @include get-font($f14, $fw-m, $black, $lhOnboarding);
      }
    } 
  &__title {
      &-container {
       min-width: 12px;
        height: 12px;
        border-radius: 50%;
        line-height: 0px;
        margin-top: -3px;
        width: auto;
      }
      &-text {  
        padding: 0px 0px 0px 16px;
        @include get-font($f14, $fw-b, $black, $lhOnboarding);
        line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        top: -1px;
      }
    }
  &__timing {
    &_conatiner {
      margin-top: 2px;
      margin-bottom: 3px;
    }
    &_title {
      padding-top: 4px;
      padding-bottom: 4px;
      margin-left: 4.8px;
      margin-top: 0px;
      padding-left: 22px;
      @include get-font($f14, $fw-m, $black, $lhOnboarding);
      > div:first-child {
       margin-bottom: 0px !important;
       margin-top: 12px;
      }
      &.time {
      @include get-font($f12, $fw-r, $copiedBackground, $lhs);
        padding-bottom: 12px;
      }
    }
  }
 
}


@media only screen and (max-width: $onlyMobile) {
  .survey__manage{
    &__submissionsListItem--alignment{
      top: 8vh !important
    }
    &__content {
      &__overview {
        &.pr-manage__content__header__statsblock {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          gap: 16px;
        }
        &.pr-manage__content__header__stats {
          width: 100%;
          max-width: 100%;
          overflow-y: auto;
          .pr-manage__content__header__stats__bloack{
              width: 100%;
              max-width: 100%;
          }
        }
        .pr-manage__invite__wrapper-conatiner{
          width: 100%;
        }
      }
      &__actionbtn{
        width: 100%;
        max-width: 100%;
        overflow-y: scroll;
        padding-bottom: 8px;
        .tooltip{
          min-width: max-content;
          max-width: max-content;
        }
        .survey__manage__content__beginsurvey{
          min-width: fit-content;
        }
        .survey__manage__content{
          min-width: fit-content;
        }
      }
    }
  }
}


@media only screen and (max-width: $smallMobile) {
  .survey__manage{
    &__submissionsListItem--alignment{
      .scrollable-modal__header--paddingLessContentTrue::after{
        top: 102px;
      }
      .scrollable-modal__header--paddingLessContentTrue{
        .scrollable-modal__header{
          align-items: flex-start;
          .manage-submissions{
            &__header{
              align-items: flex-start;
              &--container{
                flex-direction: column;
                align-items: flex-start;
                .manage-submissions__rightpanel{
                  display: flex;
                  justify-content: center;
                  width: 100%;
                  padding-right: 0px;
                }
              }
            }
            &__flag__container{
              width: fit-content;
            }
          }
          &__close{
          margin-top: 12px;
          }
        }
      }
    }
  }
 
}



