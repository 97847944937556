.range-scale{
    &-modal{
        .default-modal__container{
            top: 50%;
            transform: translate(-50%,-50%);
            width: 375px;
            margin: 0;
            padding: 0;
        }
        .modal{
            &__header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 24px 24px 8px 24px;

                &-title{
                    @include get-font($f18,$fw-sb,$black);
                    margin: 0;
                }
                
            }
            &__menu{
                padding: 0px 24px 0px 24px;
                .tasting-form__card,
                .survey__playable{
                    &__rangequestion__option__dropdown{
                        &__option{
                            padding: 0;
                            display: flex;
                            margin: 12px 0;
                            background-color: $white;
                            overflow: hidden;
                            box-sizing: border-box;
                            border-radius:8px;
                            border: 1px solid  $imageWrapperBackgroundGray;
                            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                            &:not(:last-child){
                                border-bottom: none;
                            }
                            &:hover, &:active{
                                outline: 1px solid transparent;
                            }
                            
                        }
                        &__menu-list{
                            width: calc(100% + 8px);
                            max-height: calc(100vh - 250px) !important;
                            overflow-y: scroll;
                        }
                    }
                    
                }
                .survey__playable__rangequestion__option__dropdown__option{
                    &:hover,
                    .option.active{
                        .label,
                        .value{
                            color: $white;
                            background-color: $green;
                        }
                    }
                }
                .tasting-form__card__rangequestion__option__dropdown__option{
                    &:hover,
                    .option.active{
                        .label,
                        .value{
                            color: $black;
                            background-color: $yellow;
                        }
                    }
                }
                
                .option{
                    display: flex;
                    align-items: center;
                    min-height: 45px;
                    width: 100%;
                    .value{
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 40px;
                        background-color:$imageWrapperBackgroundGray ;
                        @include get-font($f14,$fw-m,$black);
                        
                    }
                    .label{
                        padding: 12px;
                        @include get-font($f14,$fw-m,$black);
                        flex: 1;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        box-sizing: border-box;
                    }
                }
            }
            &__action{
                outline: none;
                border: none;
                cursor: pointer;
                background-color: transparent;
                &:focus{
                    outline: none;
                    border: none;
                }
                &:disabled{
                    opacity: .2;
                }
                &.clear{
                    text-transform: uppercase;
                    @include get-font($f14,$fw-b,$removeRed);
                    margin-left: 24px;
                }
            }
            &__footer{
                box-shadow: 0 -1px 4px 0 rgba(23, 23, 23, 0.05);
                padding: 24px 0px 24px 0px;
            }
        }

        
    }
}

@media(max-width:$onlyMobile){
    .range-scale-modal{
        .default-modal__backdrop{
            .default-modal__container{
                @include centerItem();
                width: 100%;
            }
        }
    }
    
  }