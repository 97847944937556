.post-comments-containers {
    display: block;
    width: 100%;
    // overflow: hidden;
    max-width: 568px;
    &__action-container {
        margin: 16px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__action-btn {
        @extend %appearance-none;
        border: 0;
        padding: 0;
        background: transparent;
        cursor: pointer;
        &__text {
            @include get-font($f14, $fw-sb, $blue, $lh);
        }
    }
    &__loader {
        padding: 16px;
        text-align: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background: $backgroundGray;
        &__text {
            @include get-font($f12, $fw-b, $lighterBlack, $lh);
        }
    }
}
.post-comments-list {
    list-style: none;
    padding: 0;
    box-sizing: border-box;
    background: $backgroundGray;
    &__reply {
        @extend %appearance-none;
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0;
        cursor: pointer;
        > img {
            margin-right: 6px;
            display: inline-block;
            vertical-align: middle;
        }
        @include transitionEase(#{color});
        @include get-font($f14, $fw-r, $mediumBlack, $lh);
        &:hover, &:active, &:focus {
            color: $black;
        }
    }
    // margin-bottom: 12px;
    &__item {
        padding: 16px 16px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        border-bottom: 1px solid $grayBackground;
        &:last-child {
            border-bottom: 0;
        }
        &__profile-image-container {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            margin-right: 8px;
            display: flex;
            &__image {
                width: 32px;
                height: 32px;
                border-radius: 32px;
                object-fit: cover;
                overflow: hidden;
                border: 0.5px solid $grayBackground;
                box-sizing: border-box;
            }
        }
    }
    &__details {
        display: block;
        width: calc(100% - 40px);
        &__header {
            &__action-btn {
                width: 32px;
                height: 32px;
                border-radius: 32px;
                background: transparent;
                border: 0;
                @include transitionEase();
                outline: 0;
                cursor: pointer;
                &:hover, &--active {
                    background: $grayBackground;
                }
                &:active {
                    background: $lightestBlack;
                }
                &__text {
                    @include get-font($f14, $fw-m, $lighterBlack, $lh);
                }
            }
            &__container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            text-decoration: none;
            margin-bottom: 5px;
            &__text {
                @include get-font($f14, $fw-m, $black, $lh);
                display: inline-block;
                vertical-align: top;
                &--verified {
                    display: inline-flex;
                    align-items: center;
                }
            }
            &__helper {
                @include get-font($f12, $fw-r, $lightBlack, $lh);
                display: inline-block;
                visibility: top;
                &__divider {
                    margin: 0 4px;
                }
            }
        }
        &__content {
            @include get-font($f14, $fw-r, $black, 1.5);
            margin-bottom: 8px;
            white-space: pre-line;
            word-break: break-word;
        }
        &__action-btn {
            @extend %appearance-none;
            background: transparent;
            border: 0;
            padding: 0;
            &__image {
                width: 12px;
                height: 12px;
                margin-right: 6px;
                vertical-align: text-top;
            }
            &__text {
                @include get-font($f14, $fw-r, $lightBlack, 1.5);
            }
        }
    }
    &__action {
        position: relative;
        &__list {
            position: absolute;
            width: 256px;
            list-style: none;
            margin: 0;
            padding: 0;
            background: $white;
            @extend %appearance-none;
            @extend %border-box-shadow;
            border-radius: 10px;
            border-top-right-radius: 0;
            overflow: hidden;
            margin-left: -224px;
            z-index: 1;
            &--top-left {
                border-radius: 10px;
                top: -60px;
                border-bottom-right-radius: 0;
                left: -230px;
            }
            &--top-right {
                border-radius: 10px;
                top: -60px;
                border-bottom-left-radius: 0;
            }
            &--bottom-left {
                border-radius: 10px;
                border-top-right-radius: 0;
                left: -230px;
            }
            &__item {
                padding: 16px;
                box-sizing: border-box;
                @include transitionEase();
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background: $grayBackground;
                }
                &__icon {
                    margin-right: 8px;
                }
                &__text {
                    @include get-font($f16, $fw-r, $black, $lh);
                    &--red {
                        color: $red;
                    }
                }
            }
        }
    }
}

.post-comments-create-container {
    padding: 12px 16px 12px;
    display: flex;
    border-top: 0.5px solid $superLightBlack;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: space-between;
    align-items: center;
    &__input {
        width: calc(100% - 32px);
        margin-right: 8px;
        min-height: 24px;
        @include get-font($f14, $fw-r, $black, $lh);
        .tag-box__input {
            line-height: 1.5;
            min-height: 24px;
        }
    }
    &__action-btn {
        @extend %circle-action-button;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        &__icon {
            width: 20px;
            flex-shrink: 0;
            height: 20px;
        }
    }
}

@media only screen and (max-width: $smallMobile) {
    .post-comments-list{
        &__action{
            &__list{
                right: 12px;
                margin-left: unset;
                width: max-content;
                padding-right: 12px;
            }
        }
    }
}