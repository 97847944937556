.filter-modal {
    &--centered {
        .default-modal__container {
            width: 520px;
            // top: 50%;
            // transform: translate(-50%, -50%);
            @include centerItem();
            height: 300px;
        }
        .default-modal__content {
            height: 160px !important;
        }
    }
    .default-modal__content {
        height: 412px;
        overflow: hidden;
        overflow-y: auto;
    }
    .filter-list {
        margin-bottom: 28px;
        &__header {
            @include get-font($f12, $fw-b, $black, $lh);
            letter-spacing: 0.6px;
            text-transform: uppercase;
            margin-bottom: 14px;
        }
        &__container {
            margin: 0;
            padding: 0;
            list-style: none;
        }
        &__item {
            &__label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 14px;
                &__input {
                    margin-right: 14px;
                }
                &__text {
                    @include get-font($f14, $fw-r, $black, $lh);
                }
            }
        }
    }
    .default-modal__footer {
        justify-content: space-between;
        padding: 16px 24px;
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-bottom: -24px;
        box-sizing: border-box;
        box-shadow: 0 -1px 4px 0 $grayBackground;
    }
}

@media only screen and (max-width: $onlyMobile) {
    .filter-modal {
        .default-modal__container {
            width: 100%;
            top: 10%;
        }
        &--centered {
            .default-modal__container {
                width: 100%;
                height: auto;
                @include centerItem();
            }
        }
    }
}