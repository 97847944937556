.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 24px;
    pointer-events: auto;
    align-items: center;
    &.right{
        justify-content: flex-end;
    }
    &.left{
        justify-content: flex-start;
    }
    &.loader{
        >li{
            pointer-events: none;
        }
    }

    >li {
        cursor: pointer;
        pointer-events: auto;
        user-select: none;
        display: inline-block;
        border-radius: 4px;
        background-color: white;
        border: 1px solid #EDEDED;
        margin-right: 12px;
        height: fit-content;
        @include get-font($f16, $fw-sb, $copiedBackground);

        >a {
            display: block;
            padding: 4px 12px;
        }
    }

    .disabled {
        color: #d1d1d1;
        background-color: $white;
    }

    .active {
        background-color: #4990E2;
        color: $white !important;
    }
}