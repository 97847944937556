@import 'form';
@import 'list';
@import 'view';
@import 'manage';
@import 'pr/all';
@import 'common';

.collaboration {
    padding: 66px 0;
    @media only screen and (max-width: $onlyMobile) {
        padding-top: 56px;
    }
    &__header {
        margin-bottom: 24px;
        &__text {
            @include get-font($f32, $fw-b, $black);
            margin-bottom: 8px;
            @media only screen and (max-width: $onlyMobile) {
                font-size: $f24;
            }  
        }
        &--multi {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .post-share-popup-up {
                position: absolute;
                margin-left: -210px;
                margin-top: 8px;
                border-top-left-radius: 10px;
                border-top-right-radius: 0;
            }
            .post-share-popup-up1 {
                margin-left: -108px;
                width: 130px;
                margin-top: 0px;
            }
        }
        &--bottom {
            align-items: flex-end;
        }
        &__link {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            @include get-font($f16, $fw-m, $blue, $lh);
            > img {
                margin-left: 6px;
            }
        }
    }
}
