.review-modal {
    .default-modal__container {
        padding: 0;
        border: 0;
        position: relative;
        height: 80vh;
        max-width: 968px;
        width: 100%;
        &--small {
            width: 468px;
            .review-modal__info__container {
                width: 100%;
            }
        }
    }
    .default-modal__content {
        display: flex;
        height: 80vh;
        // overflow: hidden;
    }
    &__image {
        width: 100%;
        max-width: 600px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        overflow: hidden;
        background: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: auto;
            width: auto;
            object-fit: contain;
            max-width: 100%;
            height: 100%;
        }
    }
    &__info {
        &__container {
            width: 368px;
            position: relative;
            overflow: hidden;
            overflow-y: auto;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &__header {
            box-sizing: border-box;
            padding: 32px 16px 16px;
            &--content {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        &__helper {
            margin-top: 16px;
            @include get-font($f14, $fw-r, $lightBlack, $lh);
        }
        &__text {
            margin-top: 16px;
            @include get-font($f14, $fw-r, $black, $lh);
            letter-spacing: 0.25px;
        }
    }
    &__comments {
        &__container {
            background: $grayBackground;
            width: 100%;
            position: relative;
            overflow: hidden;
            overflow-y: auto;
            .post-comments-list {
                margin: 0;
            }
            &--empty {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                min-height: calc(100% - 152px);
                p {
                    @include get-font($f14, $fw-m, $lightBlack, $lh);
                }
            }
        }
        &__input {
            width: calc(100% - 32px);
            min-height: 24px;
            margin: 12px auto;
            // padding: 16px;
            box-sizing: border-box;
            @include get-font($f14, $fw-r, $black, $lh);
            font-family: -apple-system, BlinkMacSystemFont,
                "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
                "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
            .tag-box__input {
                min-height: 24px;
                line-height: 1.5;
            }
            // .tag-box__input {
            //     width: 100%;
            //     box-sizing: border-box;
            //     padding: 16px;
            //     left: 0;
            //     overflow-y: auto !important;
            // }
        }
    }
}

@media only screen and (max-width: $tab) {
    .review-modal {
        .default-modal__container {
            height: 100%;
            &--small {
                max-width: 100%;
            }
        }
        .default-modal__content {
            height: auto;
            overflow: hidden;
            overflow-y: auto;
            flex-direction: column;
        }
        &__image {
            border-radius: 0;
            min-height: 300px;
            img {
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;
            }
        }
        &__info {
            &__container {
                border-radius: 0;
                width: 100%;
                overflow: unset;
            }
        }
    }
}