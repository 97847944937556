.invitation-details {
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  min-height: 108px;
  &__left {
    .subtitle {
      margin-top: 8px;
      @include get-font($f14, $fw-r, $white, $lh);
    }
    .invited-count-div {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 8px;
      .text {
        @include get-font($f12, $fw-m, $white, $lh);
      }
      .arrow-img {
        background: transparent;
        border: none;
        outline: none;
        line-height: 0px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        &.left {
          rotate: 180deg;
        }
        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
    .title {
      @include get-font($f16, $fw-sb, $white, $lh);
    }
  }
  &__right {
    width: 50%;
    .timer-details {
      display: flex;
      @include get-font($f14, $fw-m, $white, $lh);
      gap: 16px;
      margin-bottom: 12px;
      &__item {
        display: flex;
        align-items: center;
        gap: 8px;
        
        width: fit-content;
        max-width: 120px;
        .text{
          display: block;
          text-overflow: ellipsis;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .action {
      display: flex;
      align-items: center;
      gap: 12px;
      flex: 1;
    }
  }
  &-modal {
    .default-modal__container {
      width: 680px;
      padding: 0px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.feed-invitation-details {
  border-radius: 16px;
  padding: 12px;
  background-color: $white;
  margin-bottom: 16px;
  padding-right: 0px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 16px;
    .text {
      @include get-font($f14, $fw-b, $black, $lh);
    }
    .invited-count-div {
      display: flex;
      align-items: center;
      gap: 8px;
      .text {
        @include get-font($f12, $fw-m, $black, $lh);
        .disabled{
          color: $copiedBackground;
        }
      }
      .arrow-img {
        background: transparent;
        border: none;
        outline: none;
        line-height: 0px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        &.left {
          rotate: 180deg;
        }
        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }
  .container {
    border-radius: 13px;
    border: 1px solid $darkBlack;
    margin-top: 16px;
    width: 95%;
    .first {
      background-color: $black;
      padding: 16px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      .invitation-tasting {
        text-decoration: none;
        @include get-font($f16, $fw-m, $white, $lh);
        @include limit-text-to-line(2);
        min-height: 48px;
      }
      .user-block {
        margin-bottom: 0px;
        .user-block__details {
          > a {
            display: none;
          }
          .user-block__info {
            > a {
              color: $copiedBackground;
            }
          }
        }
      }
    }
    .second {
      background-color: $white;
      padding: 16px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      .text {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        @include get-font($f12, $fw-sb, #3e3e3e, $lh);
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .location {
        padding-left: 24px;
        @include get-font($f12, $fw-r, #3e3e3e, $lh);
        @include limit-text-to-line(2);
        min-height: 36px;
      }
      .venue {
        padding: 4px;
        border-radius: 4px;
        background-color: $imageWrapperBackgroundGray;
        display: flex;
        align-items: center;
        gap: 8px;
        text-decoration: none;
        width: fit-content;
        margin-left: 24px;
        margin-top: 8px;
        @include get-font($f14, $fw-m, $copiedBackground, $lh);
        margin-bottom: 20px;
      }
      .action {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 12px;
        flex: 1;
        .invitation-action{
          .dark{
            flex: unset;
          }
        }
      }
    }
  }
  .tooltip {
    width: 208px;
    word-break: break-word;
    opacity: 1 !important;
    text-align: center;
    background-color: $black !important;
  }
}



 //responsive 

 @media screen and (max-width:$onlyMobile) {
  .invitation-details {
    &__right {
      width: 67%;
    }
  }
 }
 @media screen and (max-width:$smallMobile) {
  .invitation-details {
    flex-direction: column;
    align-items: flex-start;
    min-height: 202px;
    &-modal {
      .default-modal__container {
        width: 100%;
        padding: 0px;
        top: 10%;
        transform: unset;
      }
    }
    &__right {
      width: 100%;
      .timer-details {
        margin-top: 16px;
      }
    }
    &__left{
      display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .subtitle{
      margin-top: 0px;
    }
    .invited-count-div{
      margin-top: 0px;
    }
    }
  }
}
