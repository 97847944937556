.feed-card {
    &__title {
        @include get-font($f18, $fw-sb, $black, $lh);
        &--secondary {
            font-size: $f14;
            font-weight: $fw-r;
            margin-top: 4px;
            color: $lightBlackNoOpacity;
        }
    }
    &__card {
        margin-top: 16px;
        background-color: $white;
        @include form-input-border-shadow();
        border-radius: 10px;
        padding-bottom: 16px;
    }
    &__image {
        &__container {
            position: relative;
            display: block;
            overflow: hidden;
        }
        &__icon {
            position: absolute;
            top: 16px;
            left: 16px;
            width: 40px;
            height: 40px;
        }
        &__img {
            width: 100%;
            max-width: 570px;
            height: 309px;
            object-fit: contain;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            display: block;
            margin-top: -1px;
        }
    }
    &__details {
        margin-top: 16px;
        padding: 0 16px;
        box-sizing: border-box;
        &__tagline {
            margin-top: 2px;
            @include get-font($f14, $fw-r, $lightBlackNoOpacity, $lh);
        }
        &__meta {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;;
        }
        &__title {
            @include get-font($f16, $fw-m, $black, $lh);
            text-decoration: none;
            flex: 1;
            margin-right: 6px;
            &--verified {
                display: flex;
                align-items: center;
            }
        }
        &__text {
            margin-top: 16px;
            @include get-font($f14, $fw-r, $black, $lh);
        }
        .post-action-bar__action {
            justify-content: flex-end;
            margin-top: 16px;
            .post-share-popup-up {
                margin-left: -162px;
                border-top-left-radius: 10px;
                border-top-right-radius: 0;
            }
        }
    }
    .follow-btn {
        padding: 2px 6px;
        border-radius: 4px;
        &--big {
            height: 30px;
            padding: 0 12px;
        }
        @include transitionEase(#{background});
        &:hover, &:focus, &:active {
            background: $lightestPink;
        }
    }
    .workshop__card__status{
        margin-right: 0;
    }
}

@media only screen and (max-width: $onlyMobile) {
    .feed-card {
        &__details {
            .post-action-bar__action {
                .post-share-popup-up {
                    right: 8px;
                }
            }
        }
        .user-block{
            &__action{
                &__container{
                    margin-left: auto;
                    right: 0px;
                    margin-right: 18px;
                    &__list{
                        width: max-content;
                        max-width: fit-content;
                        padding-right: 20px;
                    }
                }
            }
        }
        &__image__img{
            width: 100% !important;
        }
    }
}