.profile-view {
  &__loader{
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &__reviewsList {
    &__listItems {
      &--height {
        height: 72vh !important;
        position: relative;
        bottom: 5px;
      }
      &--products {
        &__list {
          margin: 0;
          padding: 0;
          list-style: none;
          &__item {
            padding: 16px;
            box-sizing: border-box;
            display: block;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            background: $white;
            position: relative;
            &::after {
              content: " ";
              position: absolute;
              height: 1px;
              background: #f2f2f2;
              width: calc(100% + 16px);
              left: 0px;
              top: 0;
            }
          }
          &__icon {
            width: 81px;
            height: 54px;
            border-radius: 10px;
            object-fit: cover;
            overflow: hidden;
            border: 1px solid $grayBackground;
            margin-right: 16px;
            background: $grayBackground;
            flex-shrink: 0;
            position: relative;
            > img {
              width: 81px;
              height: 54px;
              object-fit: cover;
              display: block;
            }
          }

          &__details {
            width: 100%;
            &--reviewText {
              @include get-font($f14, $fw-r, $black, $lh);
              letter-spacing: 0.6px;
            }
            &--companyNameText {
              @include get-font($f12, $fw-r, $copiedBackground, $lh);
              letter-spacing: 0.6px;
              margin-bottom: 4px;
            }
          }
          &__title {
            @include get-font($f14, $fw-m, $black, $lh);
            margin-bottom: 8px;
            margin-top: 4px;
            width: 90%;
          }
          &__inline-info {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            img {
              width: 24px;
              height: 24px;
              object-fit: contain;
              margin-right: 8px;
              border: 1px solid $superLightBlack;
              display: block;
              border-radius: 4px;
            }
            p {
              @include get-font($f14, $fw-r, $black, $lh);
            }
          }
          &__review-container {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            .product__ratings__default {
              margin-right: 12px;
            }
          }
        }
        @media only screen and (max-width: $onlyMobile) {
          &__product {
            &__list {
              &__item {
                padding: 8px 16px;
                align-items: center;
              }
              &__icon {
                width: 111px;
                height: 74px;
                img {
                  width: 111px;
                  height: 74px;
                }
              }
              &__title,
              &__inline-info {
                margin-bottom: 8px;
              }
            }
          }
        }
      }

      &--tastings {
        &__list {
          margin: 0;
          padding: 0;
          list-style: none;
          &__item {
            padding: 16px;
            box-sizing: border-box;
            display: block;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            background: $white;
            position: relative;
            &::after {
              content: " ";
              position: absolute;
              height: 1px;
              background: #f2f2f2;
              width: calc(100% + 16px);
              left: 0px;
              top: 0;
            }
          }
          &__icon {
            width: 64px;
            height: 64px;
            border-radius: 10px;
            object-fit: cover;
            overflow: hidden;
            border: 1px solid $grayBackground;
            margin-right: 16px;
            background: $grayBackground;
            flex-shrink: 0;
            position: relative;
            > img {
              width: 64px;
              height: 64px;
              object-fit: cover;
              display: block;
            }
          }

          &__details {
            width: 100%;
            &--reviewText {
              @include get-font($f14, $fw-r, $black, $lh);
              letter-spacing: 0.6px;
            }
            &--companyNameText {
              @include get-font($f12, $fw-r, $copiedBackground, $lh);
              letter-spacing: 0.6px;
              margin: 2px 0;
            }
          }
          &__title {
            @include get-font($f14, $fw-m, $black, $lh);
            margin-bottom: 8px;
            margin-top: 4px;
            width: 90%;
          }
          &--productCountText {
            @include get-font($f12, $fw-b, $copiedBackground, $lhOnboarding);
            margin-top: 4px;
          }
          &__review-container {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            .product__ratings__default {
              margin-right: 12px;
            }
          }
        }
        @media only screen and (max-width: $onlyMobile) {
          &__tastings {
            &__list {
              &__item {
                padding: 8px 16px;
                align-items: center;
              }
              &__icon {
                width: 111px;
                height: 74px;
                img {
                  width: 111px;
                  height: 74px;
                }
              }
              &__title,
              &__inline-info {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
    &__headerComponent {
      display: flex;
      @media screen and (max-width: $centerColumnWidth) {
        display: block;
        margin-right: unset
      }
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &__rightPanel {
        padding-right: 36px;
        @media screen and (max-width: $centerColumnWidth) {
          margin-top: 12px;
        }
        &--buttons {
          padding: 4px 12px;
          height: 32px;
          &--products {
            border-radius: 8px 0px 0px 8px;
          }
          &--tastings {
            border-radius: 0px 8px 8px 0px;
          }
          &--selected {
            background: black;
            @include get-font($f14, $fw-m, $white);
            border: none;
          }
          &--notSelected {
            background: #0000000d;
            @include get-font($f14, $fw-m, $copiedBackground);
            border: none;
          }
        }
      }
    }
    &__noResponse {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      justify-content: center;
      padding: 16px 0px 32px 0px;
      &::after {
        content: " ";
        position: absolute;
        height: 1px;
        background: #f2f2f2;
        width: calc(100% + 16px);
        left: 0px;
        top: 0;
      }
      &--image {
        margin-bottom: 16px;
      }
      &--texts {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 42px;
        &__primary {
          @include get-font($f18, $fw-sb, $black, $f28);
        }
        &__secondary {
          @include get-font($f14, $fw-m, $copiedBackground, $lhOnboarding);
        }
      }
      &--button {
        @extend %appearance-none;
        padding: 12px 24px;
        background-color: $yellow;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include get-font($f14, $fw-b, $black, $lhOnboarding);
        text-decoration: none;
        &__icon {
          padding-left: 10px;
        }
      }
      &--loaderStyle {
        height: 100% !important;
        min-height: 425px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &--with-height {
    min-height: calc(100vh - #{$minHeightForFullView});
    @media screen and (max-width: $onlyMobile) {
      min-height: auto;
    }
  }
  &__background-container {
    position: relative;
  }
  &__background-image {
    height: 400px;
    object-fit: cover;
    width: 100%;
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $lightBlack;
      pointer-events: none;
    }
    &__loader {
      border-radius: 18px;
      height: 24px;
      background: rgba($white, 0.4);
      padding: 2px 16px;
      img {
        width: 48px;
        margin-top: -1px;
      }
    }
    @media screen and (max-width: $onlyMobile) {
      position: relative;
      height: 100px;
    }
    &__container,
    &__placeholder {
      height: 400px;
      width: 100%;
      position: relative;
      @media screen and (max-width: $onlyMobile) {
        position: relative;
        height: 100px;
      }
    }
    &__container {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      @media screen and (max-width: $onlyMobile) {
        position: relative;
        top: auto;
      }
      &:hover,
      &:focus,
      &:active {
        .profile-view__background-image__placeholder__icon__container {
          opacity: 1;
        
        }
      }
    }
    &__placeholder {
      position: absolute;
      top: 0;
      left: 0;
      &__icon {
        width: 40px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &__container {
          width: 100%;
          height: 100%;
          display: block;
          cursor: pointer;
          background: $lightBlack;
          opacity: 0;
          @include transitionEase();
        }
      }
      &__action {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        &__btn {
          @extend %appearance-none;
          background: $mediumBlack;
          border: 0;
          padding: 0;
          margin: 0 16px;
          cursor: pointer;
          width: 64px;
          height: 64px;
          border-radius: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 24px;
          }
        }
      }
    }
    &__input {
      opacity: 0;
      position: absolute;
      left: -10000px;
    }
  }
  &__details {
    width: $centeredContainer;
    border-radius: 10px;
    box-shadow: 0 6px 10px 3px $superLightBlack;
    border: 0.5px solid $superLightBlack;
    background-color: $white;
    margin: 0 auto;
    padding: 25px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 48px;
    margin-top: -85px;
    &--no-margin {
      margin-top: 0;
      box-shadow: none;
      border: 0;
      background: transparent;
    }
    @media screen and (max-width: $onlyMobile) {
      width: 100%;
      border-radius: 0;
      margin-top: 0;
      padding: 16px;
      box-shadow: none;
      border-bottom: 0;
    }
    &__add-button {
      border: 0;
      box-sizing: border-box;
      background: $white;
      margin-right: 0;
      padding: 0;
      cursor: pointer;
      outline: 0;
      &__image {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
    &__edit-button {
      border: 0;
      box-sizing: border-box;
      background: $white;
      margin-left: 16px;
      cursor: pointer;
      outline: 0;
      padding: 0;
      &__image {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }

    &__empty-container {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__image {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      object-fit: cover;
      @media screen and (max-width: $onlyMobile) {
        width: 64px;
        height: 64px;
      }
      &__loader {
        background: rgba($white, 0.4);
        padding: 2px 16px;
        height: 16px;
        box-sizing: border-box;
        border-radius: 8px;
        img {
          margin-top: -4px;
          @media only screen and (max-width: $onlyMobile) {
            margin-bottom: 7px;
          }
        }
      }
      &__container {
        width: 128px;
        height: 128px;
        position: absolute;
        transform: translate(0, -75%);
        border-radius: 50%;
        background: $white;
        border: solid 8px $white;
        &:hover,
        &:focus,
        &:active {
          .profile-view__details__image__placeholder__icon__container {
            opacity: 1;
          }
        }
        @media screen and (max-width: $onlyMobile) {
          width: 64px;
          height: 64px;
        }
      }
      &__placeholder {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        &__icon {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: relative;
          width: 24px;
          height: 24px;
          &__container {
            width: 100%;
            height: 100%;
            display: block;
            cursor: pointer;
            background: $lightBlack;
            opacity: 0;
            @include transitionEase();
            border-radius: inherit;
          }
        }
        &__action {
          top: 50%;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          &__btn {
            @extend %appearance-none;
            background: $mediumBlack;
            border: 0;
            padding: 0;
            margin: 0 8px;
            cursor: pointer;
            width: 32px;
            height: 32px;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 16px;
            }
            &--cancel {
              background: $mediumBlack;
              img {
                width: 12px;
              }
            }
          }
        }
      }
      &__input {
        opacity: 0;
        position: absolute;
        left: -100000px;
      }
    }
    &__badgediv {
      width: 132px;
    }
    &__badge {
      margin: 12px 0;
      height: auto;
      &.logout {
        margin-left: unset;
      }
    }
    &__block {
      &_block-grey-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 17px
      }
      padding-top: 24px;
      border-bottom: 0.5px solid $superLightBlack;
      padding-bottom: 16px;
      &__separator {
        margin: 0 6px;
      }
      &--no-border {
        border: 0 !important;
      }
      &--secondary {
        padding-top: 16px;
      }
      @media screen and (max-width: $onlyMobile) {
        padding-top: 16px;
      }
      &:last-child {
        border-bottom: 0;
      }
      &__header {
        display: flex;
        margin-bottom: 4px;
        margin-top: 12px;
        &__action {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .user-block__action {
            margin-left: 16px;
          }
          &__share {
            margin-right: 16px;
            position: relative;
            @media screen and (max-width: $onlyMobile) {
              margin-right: 6px;
              position: static;
            }
            .post-share-popup-up {
              position: absolute;
              @media screen and (max-width: $onlyMobile) {
                margin-left: -130px;
              }
            }
          }
        }
      }
      &__edit-profile {
        @extend %appearance-none;
        @extend %border-box-shadow;
        cursor: pointer;
        padding: 0 12px;
        top: 32px;
        right: 24px;
        box-sizing: border-box;
        border-radius: 8px;
        background: $white;
        height: 34px;
        outline: none;
        @include transitionEase();
        &--share {
          padding: 0 6px;
          @media screen and (max-width: $onlyMobile) {
            .profile-view__details__edit-profile__image {
              width: 18px;
            }
          }
        }
        &:hover,
        &:focus,
        &:active {
          background: $grayBackground;
        }
        &__image {
          width: 16px;
          height: 16px;
          vertical-align: text-bottom;
          object-fit: contain;
        }
        &__text {
          @include get-font($f12, $fw-b, $black, $lh);
          text-transform: uppercase;
          margin-left: 8px;
          vertical-align: text-bottom;
          &--company {
            @media screen and (max-width: $onlyMobile) {
              display: none;
            }
          }
        }
        &--right-margin {
          margin-right: 16px;
          @media screen and (max-width: $onlyMobile) {
            margin-right: 6px;
            padding: 0 6px;
            min-width: 32px;
          }
        }
      }
      &__follow-btn {
        border-radius: 7px;
        background: $lightestPink;
        padding: 0 16px;
        outline: none;
        border: 0;
        cursor: pointer;
        height: 36px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        @media screen and (max-width: $onlyMobile) {
          padding: 4px 12px 4px 8px;
        }
        &__text {
          @include get-font($f12, $fw-b, $red, $lh);
          text-transform: uppercase;
        }
      }
      &_block-btn {
        border-radius: 7px;
        border: 1px solid #DD2E1F;
        background: $white;
        padding: 15px 13px;
        outline: none;
        &:hover{
          background: $red;
          color: $white;

          img.block-icon,
          span.block-text {
            display: none;
          }

          img.unblock-icon,
          span.unblock-text {
            display: block;
            color: $white;
          }


        }
        cursor: pointer;
        height: 33px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        img.unblock-icon,
        span.unblock-text {
          display: none;
        }
        &__text {
          font-size: 14px;
          color: #DD2E1F;
          font-weight: 500;
          line-height: 21px;
          &:hover{
            color: $white;
          }
        }
      }
      &_unblock-btn {
        border-radius: 7px;
        background: $red;
        padding: 0 16px;
        outline: none;
        border: 0;
        cursor: pointer;
        height: 36px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }
      &_block-text {
        text-align: center;
        color: #171717;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;

    }
    &_block-text1 {
        text-align: center;
        color: #747474;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 50px;
    }
      &__following-btn {
        border-radius: 7px;
        background: $lightGray;
        padding: 0 16px;
        outline: none;
        border: 0;
        cursor: pointer;
        height: 34px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: $onlyMobile) {
          padding: 4px 12px 4px 8px;
        }
        &__text {
          @include get-font($f14, $fw-m, $lightBlack, $lh);
        }
        &__image {
          width: 12px;
          height: 12px;
          margin-right: 8px;
          display: inline-block;
          vertical-align: text-top;
        }
      }
      &__message-btn {
        @extend %appearance-none;
        @extend %border-box-shadow;
        border-radius: 7px;
        outline: none;
        border: 0;
        width: 36px;
        height: 36px;
        background-color: $aquaBlue;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        @media only screen and (max-width: $onlyMobile) {
          height: 34px;
          width: 34px;
          margin-right: 6px;
          &__image {
            width: 14px !important;
          }
        }
        cursor: pointer;
        &__image {
          width: 20px;
          height: auto;
        }
      }
      &__name {
        @include get-font($f22, $fw-sb, $black, $lh);
        word-break: break-word;
        flex: 1;
        @media screen and (max-width: $onlyMobile) {
          font-size: $f18;
        }
        > span {
          display: flex;
          align-items: center;
        }
      }
      &__follow {
        &__block {
          word-break: break-word;
          margin-bottom: 8px;
        }
        &__text {
          @include get-font($f15, $fw-sb, $black, $lh);
          &--link {
            text-decoration: none;
            border-bottom: 1px solid transparent;
            &:hover,
            &:active,
            &:focus {
              border-bottom-color: $black;
            }
          }
          &--secondary {
            margin-left: 5px;
            font-weight: $fw-r;
            color: $lightBlack;
          }
        }
        &__separator {
          margin: 0 6px;
        }
      }
      &__info {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width: $onlyMobile) {
          margin-bottom: 8px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &__text {
          @include get-font($f14, $fw-r, $black, $lh);
          text-decoration: none;
          &--link {
            color: $blue;
            &:hover,
            &:active,
            &:focus {
              text-decoration: underline;
            }
          }
        }
        &__image {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          overflow: hidden;
        }
        &__imageRight {
          width: 16px;
          height: 16px;
          margin-left: 9px;
          overflow: hidden;
        }
        &__btn {
          @extend %appearance-none;
          background: $white;
          border: 0;
          padding: 0;
          @include get-font($f14, $fw-r, $black, $lh);
          border-bottom: 1px solid transparent;
          @include transitionEase();
          &--empty {
            &:hover,
            &:focus,
            &:active {
              border-bottom: 1px solid $blue;
              cursor: pointer;
            }
            color: $blue;
          }
        }
      }
      &__review-container {
        display: flex;
        border: solid 0.5px $superLightBlack;
        align-items: center;
        border-radius: 10px;
        padding: 6px 8px;
        cursor: pointer;
        &__reviews {
          @include get-font($f12, $fw-b, $black, $lh);
        }
      }
      &__badges{
        &__list{
          list-style-type: none;
          padding: 0;
          margin-bottom: 0;
          display: flex;
          gap:16px;

          @media screen and (max-width:881px) {
            flex-wrap: wrap;
          }
          &__item{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 1px solid rgba(23, 23, 23, 0.1);
            cursor: pointer;
            &.more-btn{
              border: none;
              height: auto;
              width: max-content;
              border-radius: unset;
              white-space: nowrap;
            }
          }
          &__icon{
            border-radius: 50%;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          
        }
        
        &__more-btn {
          @extend %appearance-none;
          background: $white;
          border: 0;
          padding: 0;
          height: 48px;
          box-sizing: border-box;
          cursor: pointer;
          @include get-font($f16, $fw-r, $blue) 
        }
      }
      &__about {
        &__title {
          @include get-font($f18, $fw-sb, $black, $lh);
        }
        &__description {
          @include get-font($f14, $fw-r, $black, $lh);
          margin-top: 8px;
        }
      }
      &__specialisation {
        padding-bottom: 0;
        &__more-btn {
          @extend %appearance-none;
          background: $white;
          border: 0;
          padding: 0;
          font-size: $f16;
          color: $blue;
          height: 48px;
          box-sizing: border-box;
          cursor: pointer;
        }
        &__list {
          list-style: none;
          padding: 0;
          margin-bottom: 0;
          &__icon {
            width: 48px;
            height: 48px;
            object-fit: contain;
            margin-right: 12px;
          }
          &__item {
            @include get-font($f14, $fw-m, $black, $lh);
            margin-right: 32px;
            margin-bottom: 32px;
            display: inline-flex;
            align-items: center;
            vertical-align: top;
            @media screen and (max-width: $onlyMobile) {
              margin-right: 16px;
              margin-bottom: 16px;
            }
          }
        }
        &__title {
          @include get-font($f12, $fw-b, $mediumBlack, $lh);
          text-transform: uppercase;
          letter-spacing: 0.6px;
        }
      }
      &__personal {
        &__block {
          display: flex;
          margin-bottom: 12px;
          &--empty {
            margin-bottom: 24px;
          }
        }
        &__title {
          min-width: 60px;
          @include get-font($f14, $fw-r, $lightestGray, $lh);
        }
        &__description {
          margin-left: 8px;
          word-break: break-word;
          white-space: pre-wrap;
          @include get-font($f14, $fw-m, $black, $lh);
          display: flex;
          align-items: center;
        }
      }
      &__professional {
        border-bottom: 0.5px solid $superLightBlack;
        padding-top: 24px;
        padding-bottom: 24px;
        &__inline-text {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 12px;
          .profile-view__details__block__professional__list__item__duration {
            margin-bottom: 0;
          }
        }
        &--individual {
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
        }
        &__edit-container {
          display: flex;
          justify-content: space-between;
        }
        &__title {
          @include get-font($f12, $fw-b, $blue, $lh);
          text-transform: uppercase;
          letter-spacing: 0.6px;
          &--bigger {
            @include get-font($f18, $fw-b, $blue, $lh);
            letter-spacing: normal;
          }
        }
        &__title-container {
          display: flex;
          justify-content: space-between;
          &__see-all {
            @include get-font($f14, $fw-m, $blue, $lh);
            text-decoration: none;
          }
        }
        &__list {
          list-style: none;
          padding: 0;
          margin: 14px 0 0 0;
          &__item {
            margin-bottom: 24px;
            &__title {
              display: flex;
              align-items: center;
              @include get-font($f16, $fw-sb, $black, $lh);
              margin-bottom: 4px;
            }
            &__subtitle {
              @include get-font($f14, $fw-m, $black, $lh);
              margin-bottom: 4px;
              &--secondary {
                font-weight: $fw-r;
              }
            }
            &__duration {
              @include get-font($f14, $fw-r, $black, $lh);
              margin-bottom: 12px;
            }
            &__link {
              @include get-font($f14, $fw-r, $blue, $lh);
              margin-bottom: 12px;
              text-decoration: none;
              display: block;
            }
            &__description {
              white-space: pre-wrap;
              @include get-font($f14, $fw-r, $black, $lh);
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
      &__photos {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 25px);
        margin: 12px 0 0;
        position: relative;
        &__number {
          width: 22px;
          height: 22px;
          background: rgba($white, 0.8);
          border-radius: 5px;
          position: absolute;
          top: 8px;
          right: 8px;
          @include get-font($f12, $fw-r, $lightBlack);
          line-height: 22px;
          text-align: center;
          @media screen and (max-width: $onlyMobile) {
            width: 16px;
            height: 16px;
            line-height: 16px;
            font-size: 12px;
          }
        }
        &__container {
          position: relative;
          cursor: pointer;
          margin: 0 8px 8px 0;
          &:hover,
          &:focus,
          &:active {
            .profile-view__details__block__photos__overlay {
              display: block;
            }
          }
        }
        &__overlay {
          background-color: $grayBackground;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: none;
          border-radius: 10px;
          &--fixed {
            background-color: $lightBlack;
            display: block;
            @include get-font($f40, $fw-sb, $white);
            text-align: center;
            line-height: 172px;
            letter-spacing: 0.6px;
            @media screen and (max-width: $onlyMobile) {
              line-height: 76px;
              font-size: $f18;
            }
          }
        }
        &__single {
          width: 172px;
          height: 172px;
          border-radius: 10px;
          background-size: cover;
          border: 1px solid $grayBackground;
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          @media screen and (max-width: $onlyMobile) {
            width: 76px;
            height: 76px;
          }
        }
      }
    }
  }
  &__empty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      @include get-font($f14, $fw-m, $black, $lh);
      margin-bottom: 8px;
      &--secondary {
        font-size: $f16;
        font-weight: $fw-sb;
      }
      &--blue {
        @include get-font($f12, $fw-b, $blue, $lh);
        letter-spacing: 0.6;
        text-transform: uppercase;
      }
    }
    &__subtitle {
      @include get-font($f14, $fw-r, $lightBlack, $lh);
    }
    &__btn {
      @extend %appearance-none;
      background: transparent;
      border: 0;
      padding: 0;
      cursor: pointer;
      &__image {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__show-more {
    @extend %appearance-none;
    background: $grayBackground;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
    border: 0;
    @include get-font($f14, $fw-sb, $lightBlack, $lh);
    cursor: pointer;
    img {
      width: 16px;
      height: auto;
      margin-left: 8px;
    }
  }
  &__palete {
    padding-bottom: 24px;
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      &__item {
        height: 32px;
        line-height: 32px;
        padding: 0 12px;
        @include get-font($f14, $fw-m, $black);
        margin-right: 12px;
        border: 0.5px solid $superLightBlack;
        border-radius: 16px;
        background: $grayBackground;
        margin-top: 16px;
        flex-shrink: 0;
      }
    }
    &__privary {
      &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__supertastersmall {
    z-index: 1;
    background: $white;
    border-radius: 20px;
    left: -4px;
    box-shadow: 0 1px 2px 0 $tastingCardShadow;
    top: -4px;
    width: 30px;
    height: 30px;

    &__image {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 18px;
    }
    &__photoshots {
      width: 20px;
      height: 20px;

      img {
        top: 2px;
        left: 2px;
        width: 15px;
      }
    }
  }
  &__supertaster {
    width: 132px;
    height: 32px;
    border-radius: 20px;
    background: $white;
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $tastingCardShadow;

    &__img {
      margin-left: 14px;
      width: 15px;
      height: 15px;
    }
    &__text {
      margin-left: 8px;
      @include get-font($f14, $fw-m, $red, $lh);
    }
  }
}

.activity-container {
  width: $centeredContainer;
  margin: 0 auto;
  margin-bottom: 140px;
  &__helper {
    margin-left: 8px;
    @include get-font($f14, $fw-m, $lightBlack, $lh);
    span {
      margin-left: 6px;
    }
  }
  &--flex {
    display: flex;
    align-items: baseline;
  }
  @media screen and (max-width: $onlyMobile) {
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    width: 100%;
  }
  &__feed {
    width: 570px;
    @media screen and (max-width: $onlyMobile) {
      width: 100%;
      box-sizing: border-box;
      margin: 0 auto;
    }
  }
  &__title {
    @include get-font($f18, $fw-b, $black, $lh);
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    width: fit-content
  }
  &__empty {
    @include get-font($f14, $fw-r, $black, $lh);
  }
}

.see-all {
  &__photos {
    &__title-container {
      display: flex;
      align-items: center;
    }
    &__title {
      @include get-font($f18, $fw-b, $black, $lh);
    }
    &__back {
      width: 16px;
      height: 12px;
      margin-right: 8px;
    }
  }
  &__block {
    padding-bottom: 0 !important;
  }
  &__badges {
    &__title-container {
      display: flex;
      align-items: center;
    }
    &__title {
      @include get-font($f18, $fw-b, $black, $lh);
    }
    &__back {
      width: 16px;
      height: 12px;
      margin-right: 8px;
    }
    &__list{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 15px;
      list-style: none;
      padding-left: 0;
      &--item{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 12px;
        border: 1px solid rgba(246, 14, 58, 0.2);
        border-radius: 100px;
        cursor: pointer;

        &__image{
          display: inline-flex;
          height: 32px;
          width: 32px;

          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        &__title{
          @include get-font($f14, $fw-m, $black, $lh);
        }
      }
     
    }
    
  }
}
.profile-edit-details{
  position: absolute;
  left: 97px;
  top: 97px;
  @media only screen and (max-width: $onlyMobile) {
    left: 43px;
    top: 47px;
  }
  cursor: pointer;
  
}
.user-edit__action__container{
  margin-left: 25px;
  @media only screen and (max-width: $onlyMobile) {
    margin-left: 16px;
  }
  position: absolute;
  z-index: 21;
}
.user-edit__action__container__list{
  margin: 0;
    padding: 0;
    list-style: none;
    padding: 16px 0;
    width: 162px;
    @media only screen and (max-width: $onlyMobile) {
      width: 120px;
      padding: 4px 0;
    }
    background: #ffffff;
    border-radius: 10px;
    border-top-right-radius: 0;
    box-shadow: 0px 6px 10px 3px rgba(23, 23, 23, 0.1);
    border-radius: 8px 0px 8px 8px;
}
.user-edit-delete-btn{
  font-size: 16px;
  @media only screen and (max-width: $onlyMobile) {
    font-size: 12px;
  }
    color: $removeRed;
    font-weight: 400;
    line-height: 1.5;
}
.profile-view-edit__details__image__input{
  opacity: 0;
  width: 134px;
  position: absolute;
}
.cover-edit-icon{
  position: absolute;
  right: 30px;
  top: 30px;
  @media only screen and (max-width: $onlyMobile) {
    right: 20px;
    top: 20px;
  }
  
  border-radius: 100px;
  background-color: black;
  padding: 8px 16px;
  @media only screen and (max-width: $onlyMobile) {
    padding: 6px 12px;
  }
  opacity: 0.6 !important;
  cursor: pointer;
}

.edit-cover-pic-toggle{
  position: relative;
}
.edit-cover-pic-toggle-list{
  position: absolute;
  top: 63px;
  right: 60px;
  @media only screen and (max-width: $onlyMobile) {
    z-index: 1;
    top: 43px;
  right: 43px;

  }
}
.user-edit-cover-pic-list{
  position: absolute;
  left: 36px;
}
.profile-view-edit__background-image__input{
  opacity: 0;
  position: absolute;
  left: -24px;
}
.avatar-image{
  cursor: pointer;
}
.add-icon-img{
  height: 24px;
  width: 24px;
  @media only screen and (max-width: $onlyMobile) {
    height: 18px;
    width: 18px;
  }
}
.cover-edit{
  display: flex;
  @media only screen and (max-width: $onlyMobile) {
    height: 14px;
  }
}
.user-edit-delete-btn-disable{

  font-size: 16px;
  @media only screen and (max-width: $onlyMobile) {
    font-size: 12px;
  }
  color: $removeRed;
  font-weight: 400;
  line-height: 1.5;
opacity: 0.5;
}
.delete_icon{
  opacity: .5;
}
.loader-background{
  position: absolute;
  left: 40rem;
  top: 10rem;
}
.loader-avatar{
  position: absolute;
  left: 30px;
  top: 60px;
  @media only screen and (max-width: $onlyMobile) {
    left: 9px;
    top: 23px;
  }
}
.user-block__action__container__list__item__btn__text{
  @media only screen and (max-width: $onlyMobile) {
    font-size: 12px;
  }
}

.user-block__action__container__list__item__btn {
  @media only screen and (max-width: $onlyMobile) {
    padding: 6px 10px;
  }
}