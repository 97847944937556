.earning-redemption{
    padding: 40px 0;
    margin-top: 65px;
    background-color: $white;

    @media screen and (max-width:$onlyMobile) {
       padding: 24px 0;
    }

    .container {
        max-width: $maxWidthContainer;
        margin: 0 auto;
    }

    .section{
        &.filled{
            background-color: #F5F8FA;

            .section__item{
                padding: 60px;

                @media screen and (max-width:$onlyMobile) {
                   padding: 32px 16px;
                }
                
            }

        }
        & + .section{
            margin-top: 80px;

            @media screen and (max-width:$onlyMobile) {
                margin-top: 32px;
             }
        }

        &__item{
            @media screen and (max-width:$onlyMobile) {
                padding: 0 16px;
            }
            &--content{
                margin-top: 24px;
            }
            &--title{
                margin: 0;
                @include get-font($f32,$fw-sb,$black,48px);

                &.text-center{
                    text-align: center;
                }

                @media screen and (max-width:$onlyMobile) {
                    @include get-font($f18,$fw-sb,$black,27px);
                }
            }
            &--sub-title{
                margin: 16px 0;
                @include get-font($f16,$fw-r,#767676,$lhOnboarding);
                &.text-center{
                    text-align: center;
                }
            }
            &--link{
                display: block;
                width: fit-content;
                margin: 0 auto;
                padding: 12px 24px;
                background-color:$black;
                border-radius: 8px;
                text-decoration: none;
                @include get-font($f16,$fw-sb,$white,$lh24);
                &:hover{
                    box-shadow: 0 0 0 0.25rem #7474745e;
                }
            }
        }
    }

    .text-overalp-image{
        &__container{
            position: relative;
            text-align: center;

            &::before{
                content: "";
                position: absolute;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba($color: #000000, $alpha: .6);
                border-radius: 16px;
                z-index: 1;

                @media screen and (max-width:$onlyMobile) {
                    border-radius: 8px;
                }
            }

            .image__container{
                border-radius: 16px;
                overflow: hidden;
                display: flex;
                img{
                    width: 100%;
                    height: auto;
                }
                @media screen and (max-width:$onlyMobile) {
                    border-radius: 8px;
                }
            }
        }

        &__info{
            position: absolute;
            height: 100%;
            width: 90%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            &--title{
                @include get-font($f48,$fw-b,$white,57px);
                margin: 0;

                @include fadeInAnimation();

                &.delay{
                   animation-delay: .5s;
                }

                @media screen and (max-width:$onlyMobile) {
                    font-size: $f18;
                    line-height: 27px;
                }
            }
            &--sub-title{
                width: 85%;
                margin:24px auto 0 auto;
                @include get-font($f16,$fw-r,$white,$lh24);

                @include fadeInAnimation(1s,1s);

                @media screen and (max-width:$onlyMobile) {
                    display: none;
                }
            }
        }

       
    }

    .list-flexed {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .benefits-list{
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 230px);

        @media screen and (max-width:$onlyMobile) {
            grid-template-columns: unset;
            grid-template-rows: unset;
         }


        &__item{
            border-radius: 24px;
            padding: 24px;
            border: 2px solid #F5F8FA;
            text-align: center;

            @media screen and (max-width:$onlyMobile) {
               padding: 16px;
               border-radius: 12px;
            }
            
            .title{
                margin:12px 0 12px 0;
                @include get-font($f18,$fw-b,#1F2937,$lhOnboarding);

                @media screen and (max-width:$onlyMobile) {
                    @include get-font($f14,$fw-sb,#1F2937,$lhOnboarding);
                }
            }
            .subtitle{
                display: flex;
                align-items: flex-start;
                
                @include get-font($f16,$fw-r,$copiedBackground,$lhOnboarding);

                @media screen and (max-width:$onlyMobile) {
                   font-size: $f14;
                }
            }

            &:hover{
                background-color: #F5F8FA;
            }
        }
    }
    .rewards-list{
        display: flex;
        gap: 24px;

        @media screen and (max-width:$onlyMobile) {
           flex-direction: column;
        }

        &__item{
            background-color: #F5F8FA;
            border-radius: 24px;
            padding: 24px;
            text-align: center;

            .title{
                @include get-font($f18,$fw-b,#1F2937,27px);
                margin: 12px 0;
                @media screen and (max-width:$onlyMobile) {
                    @include get-font($f14,$fw-sb,#1F2937,$lhOnboarding);
                }
            }
            .subtitle{
                @include get-font($f16,$fw-r,$copiedBackground,$lh24);
                @media screen and (max-width:$onlyMobile) {
                   font-size: 14px;
                }
            }
        }
    }
    .stepper{
        &__container{
            @media screen and (max-width:$onlyMobile) {
               scroll-margin-top: 60px;
            }
        }
        &__list{
            display: flex;
            gap: 32px;
            position: sticky;
            top: 64px;
            background-color: $white;
            padding: 18px 0 24px 0;
            margin: auto;
            justify-content: center;

            &--item{
                position: relative;
                padding-bottom: 12px;
                width: 80px;
                text-align: left;
                @include get-font($f16,$fw-sb,$black,$lh24);
                text-align: center;

                &::before,
                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    display: block;
                    height:6px;
                    width: 0;
                    background: #F5F8FA;
                    transition: .5s ease-out;
                    border-radius: 40px;
                    margin-top: 12px;
                }
               
                &::after{
                    background: $red;
                }
                &::before,
                &.active::after{
                   width: 100%;
                }

                @media screen and (max-width:$onlyMobile) {
                    font-size: 12px;
                    width: fit-content;
                    &::before,
                    &.active::after{
                        width: 50px;
                    }
                }
            }
            @media screen and (max-width:$onlyMobile) {
                width: 100%;
                gap: 8px;
                justify-content: space-between;
                padding: 8px 0;
                top: 56px;
            }
        }
        &__content{
            grid-column: span 2;
            margin-top: 36px;
            scroll-snap-stop: always;

            &--item{
                scroll-margin-top: 150px;
            }

            @media screen and (max-width:$onlyMobile) {
                margin-top: 24px;
                &--item{
                    scroll-margin-top: 120px;
                }
            }

            .title{
                display: inline-block;
                @include get-font($f18,$fw-sb,$black,27px);
                margin-bottom: 8px;

                @media screen and (max-width:$onlyMobile) {
                    @include get-font($f14,$fw-sb,$black,$lh);
                }
            }
            ul {
                margin:8px 0 8px 0;
                padding-left: 16px;
            }
            li,
            p{
                @include get-font($f16,$fw-r,#767676,$lh24);

                @media screen and (max-width:$onlyMobile) {
                    @include get-font($f14,$fw-r,#767676,$lh);
                }
            }
            p{
                margin-bottom: 24px;
            }
            .image__container{
                display: flex;
                gap: 48px;
                justify-content: center;
                margin:32px 0;

                img{
                    width: 100%;
                    height: auto;
                }

                @media screen and (max-width:$onlyMobile) {
                    flex-direction: column;

                    .image__small{
                        width: 50%;
                        margin: auto;
                    }
                }
            }
            .info{
                padding: 24px;
                border-radius: 24px;

                > b{
                    @include get-font($f16,$fw-b,$black,$lh24);
                    display: block;
                    margin-bottom: 24px;
                }

                li{
                    color: $black;
                }
            }
            .filled{
                background-color: #F5F8FA;
            }
        }
    }
    .faq-list{
        margin:24px 0;

        &__item{
            padding: 16px;
            border-radius: 8px;
            border-bottom: 1px solid rgba(245, 248, 250, 1);

            &:hover{
                background-color: rgba(245, 248, 250, .8);
            }


            &--action{
                display: flex;
                align-items: flex-start;
                width: 100%;
                justify-content: space-between;
                gap: 4px;
                @include get-font($f16,$fw-sb,$black,$lh);

                @media screen and (max-width:$onlyMobile) {
                    font-size: $f14;
                }
            }
            &--info{
                margin-top: 16px;
                @include get-font($f16,$fw-r,$black,$lh);

                @media screen and (max-width:$onlyMobile) {
                    font-size: $f14;
                }

                li{
                    margin-top: 12px;
                }

                .semi-bold-link{
                    color: $black;
                }
            }
            &.active{
                background-color: rgba(245, 248, 250, 1);

                .faq-list__item--action__icon{
                    rotate: 180deg;
                }
            }
        }
    }

    .semi-bold-link{
        color:#767676;
        font-weight: $fw-sb;
        text-decoration: none;
    }
}