@mixin get-font($size, $weight, $color, $lineHeight: null) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
  @if $lineHeight {
    line-height: $lineHeight;
  }
}

@mixin transitionEase($type: null) {
  transition: all 0.3s ease;
  @if ($type) {
    transition-property: $type;
  }
}

@mixin centerItem($pos: null) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @if ($pos) {
    position: $pos;
  } @else {
    position: absolute;
  }
}

%appearance-none {
  appearance: none;
  outline: none;
  -webkit-appearance: none;
  -moz-apperance: none;
}

%border-box-shadow {
  box-shadow: 0 2px 4px 0 $superLightBlack;
  border: solid 0.5px $superLightBlack;
}

@mixin form-input-border-shadow() {
  border: solid 1px $superLightBlack;
  box-shadow: 0 1px 4px 0 $superLightBlack;
}

%circle-action-button {
  @extend %appearance-none;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: 0;
  background: $white;
  cursor: pointer;
  @include transitionEase();
  &:hover,
  &:focus,
  &--active {
    background: $grayBackground;
  }
  &:active {
    background: $lightestBlack;
  }
  &--disabled {
    opacity: 0.5;
  }
  &__text {
    @include get-font($f14, $fw-m, $lighterBlack, $lh);
  }
}

@mixin animateOpacity() {
  animation: opacityAnimation 1s ease-in-out;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin getDefaultScrollbar() {
  scrollbar-width: thin;
  scrollbar-color: #ffffff #ffffff;
  &:active,
  &:focus,
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #ededed;
      transition: 0.5s ease-in-out;
    }
    scrollbar-color: #ededed #ffffff;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 15px;
    border: 3px solid #ffffff;
  }
}

@mixin get-slider-width($breakpoint,$width,$marginRight:null){
  @media only screen and (max-width: $breakpoint) {
    width: $width;
    @if $marginRight {
      margin-right:$marginRight;
    }
  }
}

@mixin limit-text-to-line($lines){
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

@mixin blink($primary-color, $inverted-color) {
  @keyframes blinkAnimation {
    0% {
      background-color: $primary-color;
      color: $inverted-color;
    }
    50% {
      background-color: $inverted-color;
      color: $primary-color;
    }
    100% {
      background-color: $primary-color;
      color: $inverted-color;
    }
  }
  animation: blinkAnimation 2s infinite;
}


@mixin slideLines($color:$white){
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 10px; 
    width: 100%;
    height: 4px;
    background-color: rgba($color: $color, $alpha: .2);
    transform: rotate(55deg); 
    animation: slide-in 3s infinite;
  }
  &::after {
    top: calc(50% + 10px);
    height: 10px; // increasing display area of line
  }

  @keyframes slide-in {
    0% {
      transform: translateX(-55%) rotate(45deg); 
    }
    100% {
      transform: translateX(105%) rotate(45deg); 
    }
  }
};

@mixin fadeInAnimation($duration:1s,$delay:0s){
  opacity: 0; /* Start invisible */
  transform: translateY(30%); /* Start off-screen to the left */
  animation: fadeInLeft $duration $delay ease-out forwards; /* Fade-in and slide-in effect */

  @keyframes fadeInLeft {
      0% {
          opacity: 0;
          transform: translateY(30%); /* Start off-screen to the left */
      }
      100% {
          opacity: 1;
          transform: translateY(0); /* End at the normal position */
      }
  }
}