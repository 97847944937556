@import "form";
@import "view";
@import "card";
@import "playable";
@import "share";
@import "list";
@import "reports";
@import "userSubmissions";

.quiz {
  padding: 66px 0;
  &__header {
    margin-bottom: 24px;
    &__text {
      @include get-font($f32, $fw-b, $black);
      margin-bottom: 8px;
      @media only screen and (max-width: $onlyMobile) {
        font-size: $f24;
      }
    }
    &--multi {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .post-share-popup-up {
        position: absolute;
        margin-left: -210px;
        margin-top: 8px;
        border-top-left-radius: 10px;
        border-top-right-radius: 0;
      }
    }
    &--bottom {
      align-items: flex-end;
    }
    &__link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      @include get-font($f16, $fw-m, $blue, $lh);
      > img {
        margin-left: 6px;
      }
    }
  }
  .user-create-block__select {
    max-height: calc(max(70vh, 500px));
    overflow: auto;
  }
  @media only screen and (max-width: $onlyMobile) {
    padding-top: 56px;
    .user-create-block__select {
      max-height: calc(min(calc(100vh - 240px), 500px));
    }
  }
}
