.biz__table {
  &__consis {
    &-product {
      max-width: 300px;
      display: flex;
      text-decoration: none;
    }

    &-info {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
    &-title {
      @include get-font($f14, $fw-m, $black, $lh);
    }
    &-title-additional {
      @include get-font($f14, $fw-r, $lightestGray, $lh);
      margin-top: 2px;
    }
    &__title__productclose {
      @include get-font($f14, $fw-sb, $removeRed, $lh);
      margin-top: 8px;
      text-transform: uppercase;
    }
    &__batchcode {
      @include get-font($f12, $fw-m, $black, $lh);
      margin-top: 8px;
      text-transform: uppercase;
    }

    &__trio {
      @mixin trio-border {
        border: dotted 2px $teal;
      }
      @mixin trio-div {
        display: flex;
        margin: auto;
        height: 45px;
        border-radius: 100px;
        border: dotted 2px $teal;
        background-color: $superLightTeal;
        align-items: center;
        justify-content: center;
      }
      @mixin trio-div-disabled {
        display: flex;
        margin: auto;
        height: 45px;
        border-radius: 100px;
        border: dotted 2px $focusedGray;
        background-color: $gray;
        align-items: center;
        justify-content: center;
      }

      &code {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        &__label {
          width: 77px;
          height: 21px;
          @include get-font($f14, $fw-r, $black, $lh);
          margin-bottom: 12px;
          text-align: center;
        }
        &__image {
          width: 132px;
          height: 132px;
        }
        &-div {
          @include trio-div;
          width: 166px;

          &-disabled {
            @include trio-div-disabled;
            width: 166px;
          }
        }
        &-text-div {
          @mixin batch-text-div {
            padding: 9px 16px;
            min-width: 58px;
            min-height: 21px;
            @include get-font($f18, $fw-sb, $black, $lh);
          }
          @include batch-text-div;

          &-disabled {
            @include batch-text-div;
            opacity: 0.2;
          }
        }

        &-copy-div {
          margin-left: auto;
          background-color: $teal;
          min-width: 59px;
          width: 59px;
          height: 45px;
          border-radius: 0px 100px 100px 0px;
          cursor: pointer;

          &-disabled {
            margin-left: auto;
            background-color: $superLightBlack;
            width: 59px;
            height: 45px;
            border-radius: 0px 100px 100px 0px;
          }
        }
        &-copy-text {
          width: 35px;
          height: 21px;
          margin: 12px;
          @include get-font($f14, $fw-m, $white, $lh);
        }
      }

      &link {
        &-div {
          @include trio-div;
          width: 301px;

          &-disabled {
            @include trio-div-disabled;
            width: 301px;
          }
        }
        &__downloadbutton {
          width: 91px;
          height: 20px;
          padding: 6px 12px;
          border: 0px;
          border-radius: 18px;
          margin-top: 16px;
          background-color: $teal;
          @include get-font($f14, $fw-m, $white, $lh);
          cursor: pointer;
          text-decoration: none;
        }
        &-text-div {
          @mixin batch-link-text-div {
            padding: 9px 16px;
            min-width: 188px;
            width: 188px;
            max-width: 188px;
            min-height: 21px;
            height: 21px;
            max-height: 21px;
            @include get-font($f18, $fw-sb, $black, $lh);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          @include batch-link-text-div;

          &-disabled {
            @include batch-link-text-div;
            opacity: 0.2;
          }
        }
      }

      &qr {
        &-div {
          @include trio-div;
          width: 115px;
          text-decoration: none;

          &-disabled {
            @include trio-div-disabled;
            width: 115px;
            text-decoration: none;
            pointer-events: none;
          }
        }

        &-text-div {
          @mixin qr-text-div {
            padding: 6px 12px;
            min-width: 91px;
            max-width: 91px;
            min-height: 21px;
            @include get-font($f14, $fw-m, $black, $lh);
          }
          @include qr-text-div;
          &-disabled {
            @include qr-text-div;
            opacity: 0.2;
          }
        }
      }
    }
    &__batches {
      &-div {
        text-align: left;
        @include get-font($f14, $fw-m, $black, $lh);

        &-disabled {
          text-align: left;
          opacity: 0.6;
        }
        &-closed {
          text-align: left;
          @include get-font($f12, $fw-b, $removeRed, $lh);
          margin-bottom: 8px;
          text-transform: uppercase;
        }
        &-title {
          display: flex;

          &__batchname {
            max-width: 200px;
            margin-left: 4px;
          }
        }

        &-date {
          @include get-font($f14, $fw-r, $lightestGray, $lh);
          margin-left: 14px;
        }
        &__info__icon {
          margin-left: 12px;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      &-review-button {
        width: 130px;
        height: 33px;
        margin: 6px 75px 29px 0px;
        padding: 6px 12px;
        border: 0px;
        border-radius: 18px;
        background-color: $teal;
        @include get-font($f14, $fw-m, $white, $lh);
        cursor: pointer;
      }
      &__actionrow {
        display: flex;
        gap: 40px;
        justify-content: flex-end;
        flex-wrap: wrap;

        &__triobutton {
          width: 87px;
          height: 33px;
          padding: 6px 12px;
          border: 0px;
          border-radius: 18px;
          background-color: $darkSkyBlue;
          @include get-font($f14, $fw-m, $black, $lh);
          cursor: pointer;

          &__disabled {
            opacity: 0.4;
            cursor: default;
          }
        }
        &__reviewbutton {
          width: 110px;
          height: 33px;
          padding: 6px 12px;
          border: 0px;
          border-radius: 18px;
          background-color: $teal;
          @include get-font($f14, $fw-m, $white, $lh);
          cursor: pointer;

          &__inprogress {
            background-color: $orange;
          }
          &__completed {
            background-color: $gray;
            color: $grayText;
            cursor: default;
          }

          &__disabled {
            opacity: 0.4;
            cursor: default;
          }
        }
        &__restorebutton {
          width: 77px;
          height: 33px;
          padding: 6px 12px;
          border: 0px;
          border-radius: 18px;
          background-color: $tastingCardShadow;
          @include get-font($f14, $fw-m, $black, $lh);
          cursor: pointer;

          &__disabled {
            opacity: 0.4;
            cursor: default;
          }
        }
      }
    }
    &__archive-batches {
      &-div {
        text-align: right;
        position: relative;
      }
      &-action-button {
        margin-right: 18px;
        margin-top: 4px;
        vertical-align: middle;
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__closedproducts__table {
    &__actionbutton {
      display: flex;
      width: 77px;
      height: 33px;
      margin: 7px 0 4px 67px;
      padding: 3px 6px 3px 6px;
      border-radius: 18px;
      border: 0px;
      box-shadow: 0 2px 4px 0 $superLightBlack;
      background-color: $teal;
      border: 0px;
      cursor: pointer;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      @extend %appearance-none;
      &__container {
        position: relative;
        .post-share-popup-up {
          position: absolute;
          margin-left: -100px;
        }
      }
      &__text {
        @include get-font($f14, $fw-m, $white, $lh);
        text-align: center;
      }
      &__icon {
        margin: 2px 0 3px 4px;
        width: 16px;
        height: 16px;
      }
    }
  }

  &__emptytable__div {
    display: flex;
    height: 300px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__nodatatext {
      @include get-font($f14, $fw-r, $noDataText, $lh);
      width: 343px;
      @media only screen and (max-width: $onlyMobile) {
        width: 100%;
      }
      text-align: center;
    }
  }
}

.biz__consis {
  &__product {
    &__toggle-row {
      display: flex;
      margin-top: 14px;
      margin-bottom: 30px;

      &__toggle-div {
        display: flex;
        &__text {
          @include get-font($f14, $fw-m, $black, $lh);
          margin-right: 16px;
        }
        &__close__product {
          @include get-font($f16, $fw-m, $removeRed, $lh);
          margin-right: 16px;
        }
      }
      &__edit-icon-div {
        margin-left: auto;
        background-color: $superLightBlack;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        border: 0;
        cursor: pointer;
        position: relative;

        &-img {
          width: 20px;
          height: 20px;
          margin: 6px;
          position: relative;
        }
      }
      &__editmenu {
        &-icon {
          width: 16px;
          height: 16px;
          margin: 6px 8px 6px 6px;
          vertical-align: middle;
        }
        &-delete-text {
          @include get-font($f16, $fw-r, $removeRed, $lh);
        }
      }
    }
    &__pro-details-row {
      &__title {
        margin-bottom: 6px;
      }
      &__description {
        @include get-font($f16, $fw-r, $black, $lh);
        margin-top: 8px;
      }
    }
    &__pro-tab-row {
      display: flex;
      margin-top: 24px;

      &-left-column {
        display: flex;
        margin-bottom: 6px;

        &-archive {
          margin-left: 40px;
        }
        &-active {
          @include get-font($f16, $fw-sb, $black, $lh);
          height: 30px;
          border-bottom: 1px solid;
          cursor: pointer;
        }
        &-inactive {
          height: 30px;
          @include get-font($f16, $fw-sb, $lightestGray, $lh);
          cursor: pointer;
        }
      }

      &-button-column {
        display: flex;
        margin-left: auto;

        @mixin common-button {
          height: 45px;
          padding: 0 12px;
          justify-content: center;
          letter-spacing: 0.7px;
          border-radius: 10px;
          border: 0;
          text-transform: uppercase;
          cursor: pointer;
        }

        &-reportbutton {
          @include get-font($f14, $fw-b, $black, $lh);
          @include common-button;
          background-color: $superLightTeal;
          margin-right: 40px;
          height: 25px;
          padding: 12px 12px 12px 12px;
          text-decoration: none;
        }

        &-createbutton {
          @include get-font($f14, $fw-b, $white, $lh);
          @include common-button;
          background-color: $teal;
        }

        &-archive {
          @include get-font($f16, $fw-sb, $lightestGray, $lh);
          margin-left: 40px;
        }
      }
    }
  }
  &__user {
    &-div {
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 $superLightBlack;
      border: solid 0.5px $superLightBlack;
      background-color: $white;
    }
    &__search {
      &-text {
        padding: 24px 0 84px;
        color: red;
      }
    }
  }
}

.biz__consistency {
  &__review__product {
    display: flex;
    margin-top: 40px;
    margin-bottom: 44px;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    &__label {
      margin: auto;
      @include get-font($f14, $fw-sb, $black, $lh);
    }

    &__inputrow {
      display: flex;
      align-content: center;
      justify-content: center;
      margin-top: 12px;

      &__inputdiv {
        width: 154px;
        height: 40px;
        border: solid 0.5px $superLightBlack;
        border-radius: 10px 0px 0px 10px;
        background-color: $white;

        &__input {
          width: 122px;
          height: 24px;
          border: 0px;
          padding: 8px 16px;
          border-radius: 10px 0px 0px 10px;
          @include get-font($f16, $fw-m, $black, $lh);
        }
      }

      &__button {
        width: 154px;
        height: 40px;
        background-color: $teal;
        border: 0px;
        border-radius: 0px 10px 10px 0px;
        cursor: pointer;

        &__text {
          @include get-font($f16, $fw-m, $white, $lh);
        }
        &__icon {
          margin-left: 8px;
          vertical-align: text-bottom;
        }
        &__disabled {
          opacity: 0.4;
          cursor: default;
        }
      }
    }
  }
}
