.edit-a-post-modal {
    >div {
        padding-bottom: 0px !important;
    }

    .scrollable-modal__container {
        padding: 0px;
    }

    .scrollable-modal__header {
        padding: 24px;
    }

    .scrollable-modal__modal-overflow {
        max-height: unset;
    }

    .post-bar__post {
        border: 0;
        box-shadow: none;

        &__content {
            // padding: 0;

            .user-create-block {
                margin-bottom: 12px;
            }
        }
    }

    .poll-create-container {
        padding: 0px 24px 16px 24px;

        .user-create-block {
            margin-bottom: 12px;
        }
    }
}