.suggestions {
    background: transparent;
    max-width: 100%;
    &--profile {
        @media only screen and (max-width: $onlyMobile) {
            white-space: nowrap;
            overflow: hidden;
            overflow-x: scroll;
            height: 204px;
        }
    }
    &__profile {
        width: 184px !important;
        position: relative;
        @media only screen and (max-width: $onlyMobile) {
            width: 140px !important;
            display: inline-block;
            height: 200px;
            vertical-align: top;
        }
        &:last-child {
            margin-right: 0;
        }
        height: 256px;
        @extend %border-box-shadow;
        background: $white;
        margin-right: 8px;
        border-radius: 10px;
        box-sizing: border-box;
        overflow: hidden;
        &__badge {
            position: absolute;
            top: 8px;
            left: 8px;
        }
        &__info {
            max-height: calc(100% - 184px);
            padding: 12px;
            box-sizing: border-box;
            @media only screen and (max-width: $onlyMobile) {
                max-height: calc(100% - 140px);
                padding: 8px;
            }
        }
        &__img {
            width: 184px;
            height: 184px;
            @media only screen and (max-width: $onlyMobile) {
                width: 140px;
                height: 140px;
            }
            object-fit: cover;
            display: block;
        }
        &__name {
            @include get-font($f14, $fw-m, $black, $lh);
            margin-bottom: 4px;
            display: block;
            text-decoration: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &--verified {
                display: flex;
                align-items: center;
                > span {
                    display: block;
                    width: 100%;
                    > span {
                        max-width: calc(100% - 24px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        vertical-align: top;
                    }
                }
            }
        }
    }
}