.pr-inline-status {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-left: 8px;
    &__item {
        // display: inline-block;
        vertical-align: top;
        margin-right: 16px;
        margin-top: 8px;
    }
    &__status {
        width: 18px;
        height: 18px;
        border-radius: 5px;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    &__title {
        @include get-font($f12, $fw-r, $black, $lh);
        vertical-align: top;
    }
}

.collaboration-modals {
    &__btn {
        @extend %appearance-none;
        border: 0;
        background: $yellow;
        display: inline-flex;
        height: 36px;
        min-width: 36px;
        padding: 0 12px;
        @include get-font($f16, $fw-m, $black);
        @include transitionEase(#{background});
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        cursor: pointer;
        &:hover, &:focus, &:active {
            background: $darkestYellow;
        }
        &--gray {
            background: $grayBackground;
            &:hover, &:focus, &:active {
                background: $superLightBlack;
            }
        }
        &__img {
            &--chevron {
                width: 8px;
                height: auto;
            }
            &--right {
                margin-left: 6px;
            }
        }
    }
}

.my-collaborations {
    &__filters {
        &__container {
            display: flex;
            align-items: center;
            .see-all-container__search-input {
                max-width: 343px;
                @media only screen and (max-width: $onlyMobile) {
                &.search-input{
                    max-width: 100% !important;
                }
            }
            }
        }

        &.my-tasting__filters{
            .pill-filters{
                &:hover{
                    background:rgba($color: $yellow, $alpha: .2);
                }
                &--selected{
                    background: $yellow;
                    color: $black;
                }
                
            }
        }
    }
   
}

@media only screen and (max-width: $onlyMobile) {
    .my-collaborations {
        &__filters {
            flex-direction: column;
            align-items: flex-start !important;
            &__container {
                width: 100%
            }
        }
    }
}