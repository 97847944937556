.pr-product-form {
    &__label {
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            position: relative;
            > li {
                display: inline-block;
                vertical-align: top;
                margin-right: 12px;
                input[type="radio"] {
                    position: absolute;
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }
        width: 24px;
        height: 24px;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        color: $white;
        font-weight: $fw-b;
        cursor: pointer;
        opacity: 0.85;
        @include transitionEase(#{opacity});
        &--selected {
            opacity: 1;
        }
    }
}

.pr-assign-tasters {
    .default-modal {
        &__container {
            width: 900px;
            max-height: 70vh;
        }
        &__content {
            height: calc(80vh - 82px);
        }
        &__footer {
            &__content {
                display: flex;
                align-items: center;
                > p {
                    @include get-font($f14, $fw-m, $black, $lh);
                    margin-right: 24px;
                }
            }
        }
    }
    .scrollable-modal{
        &__container{
            width: 900px;
            >div:first-child{
                padding: 24px 16px 0px !important;
            }
            .scrollable-modal__modal-overflow{
                max-height: 50vh;
                min-height: 50vh;
                .pr-manage__unassigned-applicants__list{
                    padding-left: 16px;
                }
            }
            .scrollable-modal__footer{
                .default-modal__footer{
                width: 100%;

                }
                }
        }
    }
}


@media only screen and (max-width: $onlyMobile) {
    .pr-assign-tasters{
         .scrollable-modal__container{
            width: 100%;
         }
    }
  }