.aroma-modal {
    z-index: 26;
    &__input {
        @extend %appearance-none;
        height: 35px;
        width: 100%;
        border-radius: 5px;
        border: 0;
        @include get-font($f16, $fw-r, $black, $lh);
        text-indent: 28px;
        background-color: $grayBackground;
        background-image: url('assets/images/bar/magnifying-glass-browser.svg');
        background-position: 10px;
        background-repeat: no-repeat;
        background-size: 12px;
        border: 1px solid transparent;
        @include transitionEase(#{border-color, background-color, background-image});
        &:focus {
            border-color: $black;
            background-color: $white;
            background-image: url('assets/images/tasting/magnifying.svg');
        }
    }
    .default-modal__container {
        width: 100%;
        max-width: 440px;
        min-height: 300px;
    }
    .default-modal__content {
        min-height: 200px;
    }
    .default-modal__header__title {
        text-transform: capitalize;
    }
    &__header {
        &__left {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__text {
         margin-left: 12px;
        }
    }
    &__helper {
        margin-top: 16px;
        @include get-font($f14, $fw-m, $black, $lh);
    }
    &__breadcrumbs {
        margin: 0;
        padding: 0;
        margin-top: 16px;
        list-style: none;
        &__list {
            display: inline-block;
        }
        &__text {
            @include get-font($f14, $fw-r, $black, $lh);
            display: inline-block;
            text-decoration: underline;
            cursor: pointer;
            text-transform: capitalize;
            &--active {
                text-decoration: none;
                cursor: default;
            }
        }
        &__separator {
            height: 10px;
            width: auto;
            margin: 0 6px;
            display: inline-block;
        }
    }
    &__pill {
        &__helper {
            margin-top: 16px;
            @include get-font($f12, $fw-r, $lightBlack, $lh);
        }
        margin-top: 12px;
        &:first-child {
            margin-top: 0;
        }
        &__list {
            margin: 0;
            padding: 0;
            margin-top: 16px;
            margin-bottom: 10px;
            list-style: none;
            max-height: 60vh;
            overflow: hidden;
            overflow-y: auto;
        }
        &__container {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            border: 1px solid $grayBackground;
            min-height: 36px;
            padding: 0 12px;
            cursor: pointer;
            box-sizing: border-box;
            @include transitionEase(#{background});
            &:hover {
                background: $tastingOptionYellow;
            }
            &--disabled {
                opacity: 0.65;
                cursor: not-allowed;
                &:hover {
                    background: $white;
                }
            }
            &--checked {
                border: 1.5px solid $tastingYellow;
                background-color: $tastingOptionYellow;
                .aroma-modal__pill__text {
                    font-weight: $fw-sb;
                }
            }
            .react-tooltip-lite {
                z-index: 1000000 !important;
                position: fixed;
            }
            .pr-manage__tool-tip {
                margin-left: 0;
            }
            &__parent {
                display: flex;
                align-items: center;
                .aroma-modal__pill__icon--left {
                    margin-left: 16px;
                    width: 20px;
                    display: block;
                    cursor: pointer;
                }
            }
        }
        &__text {
            @include get-font($f14, $fw-r, $black, $lh);
        }
        &__icon {
            width: 16px;
            height: auto;
            &--right {
                margin-right: 6px;
            }
            &--left {
                margin-left: 6px;
            }
        }
    }
    .default-modal__footer {
        border-radius: 10px;
        box-shadow: 0 -1px 4px 0 $grayBackground;
        width: calc(100% + 48px);
        margin-left: -24px;
        padding: 12px 24px 0 0;
        box-sizing: border-box;
        margin-bottom: -12px;
    }
}