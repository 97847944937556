.show-interest-product-modal {

    .post-text {
        margin-top: 25px;
        border-radius: 10px;
        resize: none;
        @include get-font($f16, $fw-r, $black, $lh);
        outline: none;
        min-height: 96px;
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        background: $backgroundGray;
        border: none;
        padding: 16px;
    }

    &__mandatory {
        &__list {
            list-style: none;
            margin: 0;
            padding: 0;
            &__item {
                border-bottom: 1px solid $grayBackground;
                margin-bottom: 16px;
                .profile-edit-modal__form__group {
                    margin-bottom: 24px;
                }
                .form-label {
                    margin-bottom: 16px;
                    display: block;
                }
            }
        }
    }
    .add-address {
        max-height: 300px;
        overflow-y: scroll;
        &--full-height {
            max-height: unset;
            overflow-y: unset;
        }
        &__current-location {
            width: 97%;
            background: $yellow;
            border-radius: 10px;
            height: 37px;
            border: 0;
            outline: none;
            margin-bottom: 13px;
            padding: 9px 0 7px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            &--full {
                width: 100%;
            }
            &__image {
                width: 16px;
                height: 16px;
                object-fit: contain;
            }
            &__text {
                margin-left: 8px;
                @include get-font($f14, $fw-sb, $black, $lh);
            }
        }
        &__form {
            margin-top: 13px;
            &__type {
                &__label {
                    @include get-font($f12, $fw-sb, $lightBlack);
                    margin-bottom: 12px;
                }
                &__container {
                    margin-bottom: 8px;
                    > label {
                        margin-right: 16px;
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        input[type="radio"] {
                            position: absolute;
                            opacity: 0;
                            pointer-events: none;
                        }
                        > img {
                            display: block;
                            margin-right: 6px;
                            width: 20px;
                            height: auto;
                        }
                        > span {
                            @include get-font($f14, $fw-r, $black);
                            bottom: 2px;
                        }
                    }
                }
            }
            &__input {
                width: 97%;
                border-radius: 10px;
                box-sizing: border-box;
                border: 0.5px solid $superLightBlack;
                padding: 8px 16px 8px 16px;
                outline: none;
                height: 37px;
                @include get-font($f14, $fw-r, $black, $lh);
                &:focus, &:active {
                    border: 1.5px solid $mediumBlack;
                }
            }
            &__input-container {
                margin-bottom: 16px;
            }
            &__save {
                @include get-font($f14, $fw-r, $blue, $lh);
                background: none;
                border: none;
                outline: none;
                cursor: pointer;
            }
        }

    }
    &__label {
        display: inline-block;
        margin-left: 8px;
        &__head {
            @include get-font($f16, $fw-sb, $black, $lh);
        }
    }
    &__description{
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-left: 28px;
        &--no-margin {
            margin-left: 0;
        }
    }
    &__choose-cities {
        margin-left: 28px;
        margin-top: 16px;
        &__label {
            display: flex;
            align-items: center;
            > span {
                margin-left: 8px;
            }
            > input {
                display: inline-block;
                margin: 0;
            }
            @include get-font($f14, $fw-r, $black, $lh);
            margin-bottom: 12px;
        }
    }

    &__choose-address {
        margin-left: 28px;
        margin-top: 16px;
        &__option {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-left: 8px;
            max-width: 400px;
            &__head {
                @include get-font($f14, $fw-sb, $black, $lh);
            }
            &__sub-head {
                @include get-font($f14, $fw-r, $lightBlack, $lh);
            }
        }
        &__label {
            display: inline-block;
            margin-bottom: 12px;
        }
        &__description {
            margin-top: 32px;
            &__text {
                @include get-font($f12, $fw-r, $lightBlack, $lh);
            }
        }
    }
    &__add-another {
        &__text {
            cursor: pointer;
            @include get-font($f14, $fw-r, $blue, $lh);
        }
    }
    &__footer {
        padding: 13px 0 12px 0;
        &__action-btn {
            border: 0;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            background: $yellow;
            height: 32px;
            line-height: 32px;
            border-radius: 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 12px;
            cursor: pointer;
            @include get-font($f14, $fw-b, $black, $lh);
            &--disabled {
                background: $lightYellow!important;
                cursor: not-allowed!important;
            }
        }
    }
    &--generic {
        &__addresses {
            margin-top: 16px;
            padding-left: 28px;
            padding-bottom: 16px;
            box-sizing: border-box;
            .add-address {
                list-style: none;
                margin: 0;
                padding: 0;
            }
        }
    }
}

.outlets-modal {
    &__header {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-bottom: 24px;
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 32px;
        &__item {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            > label {
                cursor: pointer;
                display: flex;
                align-items: center;
                > input {
                    position: absolute;
                    opacity: 0;
                    pointer-events: none;
                }
                > img {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                }
                > span {
                    display: inline-block;
                    margin-left: 16px;
                    @include get-font($f16, $fw-r, $black, $lh);
                }
            }
        }
    }
}

.samples-modal {
    .default-modal__header {
        margin-bottom: 0;
    }
    &__helper {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-top: 6px;
    }
    &__info {
        display: flex;
        align-items: center;
        &__color {
            width: 24px;
            height: 24px;
            border-radius: 5px;
            margin-right: 12px;
            display: inline-block;
        }
        &__title {
            @include get-font($f16, $fw-m, $black, $lh);
        }
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        width: calc(100% + 48px);
        margin-left: -24px;
        box-sizing: border-box;
        &__item {
            border-bottom: 1px solid $grayBackground;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    &__btn {
        @extend %appearance-none;
        @extend %border-box-shadow;
        height: 34px;
        padding: 0;
        min-width: 115px;
        box-sizing: border-box;
        background: $yellow;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        text-transform: uppercase;
        cursor: pointer;
        &--secondary {
            background: $white;
            cursor: default;
        }
        img {
            margin-right: 6px;
        }
        @include get-font($f12, $fw-b, $black, $lh);
        letter-spacing: 0.6px;
    }
}



@media only screen and (max-width: $smallMobile) {
    .show-interest-product-modal,.samples-modal {
        .default-modal__container {
            width: 100%;
            top: 8%;
        }
    }

  }