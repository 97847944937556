$loginBlack: #2b292d;
$loginOrange: #ff841f;
$resetYellow: rgb(249, 245, 231);
$centeredWidth: 1140px;
.login {
    &__container {
        width: 100%;
        padding-top: 65px;
        max-width: $containerMaxWidth;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (max-width: $onlyMobile) {
            padding-top: 56px;
        }
        &__reset {
            min-height: 45px;
            padding: 12px 0;
            box-sizing: border-box;
            background-attachment: fixed;
            background: $resetYellow;
            text-align: center;
            @include get-font($f14, $fw-r, $black, $lh);
            width: 100%;
            @media only screen and (max-width: $onlyMobile) {
                padding: 12px 8px;
            }
        }
        &__section {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            box-sizing: border-box;
            &__centered {
                max-width: 1164px;
                margin-left: auto;
                margin-right: auto;
            }
            &--pocket {
                .login__container__section__icon {
                    max-width: 470px;
                    box-shadow: 0 70px 40px 0 rgba($black, 0.4);
                    @media only screen and (max-width: $onlyMobile) {
                        width: 80%;
                        margin: 32px auto 0;
                    }
                }
            }
            &--teal {
                background: $lightestTeal;
            }
            &--yellow {
                background: $lightestYellow;
            }
            &--multi {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 64px 0;
                @media only screen and (max-width: $onlyMobile) {
                    display: block;
                    padding-top: 32px;
                    padding-bottom: 32px;
                }
            }
            @media only screen and (max-width: $tab) {
                padding-left: 16px;
                padding-right: 16px;
            }
            &--main {
                // padding-left: 160px;
                min-height: calc(100vh - 65px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .login__container__section__info {
                    margin-top: 80px;
                    @media only screen and (max-width: $onlyMobile) {
                        margin-top: 0;
                    }
                }
                .login__form__image__container {
                    margin-top: -80px;
                    @media only screen and (max-width: $onlyMobile) {
                        margin-top: 0;
                    }
                }
                @media only screen and (max-width: $tab) {
                    min-height: auto;
                    .login__title {
                        margin-bottom: 16px;
                    }
                    .login__container__section__action {
                        margin-top: 24px;
                    }
                }
                @media only screen and (max-width: $onlyMobile) {
                    display: block;
                }
                .login__container__section__action {
                    margin-top: 32px;
                }
                .login__title {
                    margin-bottom: 24px;
                }
                .login__form__image {
                    max-width: 80%;
                    width: auto;
                    &__container {
                        @include animateOpacity();
                        width: 100%;
                        text-align: right;
                    }
                }
            }
            &__info {
                width: 100%;
                max-width: 510px;
                z-index: 10;
                position: relative;
                @media only screen and (max-width: $onlyMobile) {
                    max-width: 100%;
                }
                &--mobile {
                    @media only screen and (max-width: $onlyMobile) {
                        text-align: center;
                    }
                }
            }
            &__action {
                margin-top: 24px;
                @media only screen and (max-width: $onlyMobile) {
                    margin-top: 16px;
                }
                &__btn {
                    @extend %appearance-none;
                    height: 42px;
                    margin-right: 16px;
                    background: $black;
                    padding: 8px 24px;
                    box-sizing: border-box;
                    text-decoration: none;
                    @include get-font($f18, $fw-sb, $white, $lh);
                    box-shadow: 0 4px 6px 0 $grayBackground, 0 1px 3px 0 $superLightBlack, inset 0 1px 0 0 rgba($white, 0.1);
                    border-radius: 42px;
                    border: solid 0.5px $superLightBlack;
                    display: inline-block;
                    opacity: 0.9;
                    @include transitionEase(#{opacity});
                    &:hover, &:focus, &:active {
                        opacity: 1;
                    }
                    &--secondary {
                        background: $white;
                        color: $black;
                    }
                }
            }
            &--centered {
                padding: 64px 0;
                text-align: center;
                @media only screen and (max-width: $tab) {
                    padding: 32px 0;
                }
                @media only screen and (max-width: $onlyMobile) {
                    padding-left: 16px;
                    padding-right: 16px;
                }
                .login__desc {
                    max-width: 850px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            &--depth {
                .login__icon {
                    margin-top: 32px;
                    @media only screen and (max-width: $onlyMobile) {
                        margin-top: 0;
                    }
                }
            }
            &--customers, &--depth {
                .login__icon {
                    max-width: 1140px;
                    width: auto;
                    margin-left: auto;
                    margin-right: auto;
                    object-fit: contain;
                    @include animateOpacity();
                    @media only screen and (max-width: $tab) {
                        max-width: 95%;
                    }
                    @media only screen and (max-width: $onlyMobile) {
                        max-width: 100%;
                    }
                }
            }
            &--customers {
                .login__icon {
                    @media only screen and (max-width: $onlyMobile) {
                        margin-top: 16px;
                    }
                }
            }
            &__icon {
                width: 100%;
                height: auto;
                @include animateOpacity();
                display: block;
                &__container {
                    width: 620px;
                    @media only screen and (max-width: $onlyMobile) {
                        width: 100%;
                    }
                }
            }
            &__store-links {
                &__container {
                    margin-top: 32px;
                    @media only screen and (max-width: $onlyMobile) {
                        margin-top: 16px;
                    }
                }
                margin-right: 32px;
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: $onlyMobile) {
                    margin-right: 16px;
                }
                &:last-child {
                    margin-right: 0;
                }
                &__icon {
                    width: 200px;
                    height: auto;
                    @media only screen and (max-width: $onlyMobile) {
                        width: 132px;
                    }
                }
            }
        }
    }
    &__title {
        @include get-font($f36, $fw-b, $loginBlack, $lh);
        margin-bottom: 16px;
        @media only screen and (max-width: $onlyMobile) {
            font-size: $f22;
            font-weight: $fw-sb;
            margin-bottom: 8px;
        }
        &--secondary {
            margin-bottom: 12px;
            @media only screen and (max-width: $onlyMobile) {
                margin-bottom: 4px;
            }
        }
    }
    &__desc {
        @include get-font($f20, $fw-r, $black, $lh);
        strong {
            font-weight: $fw-b;
        }
        @media only screen and (max-width: $onlyMobile) {
            font-size: $f16;
            font-weight: $fw-r;
            strong {
                font-weight: $fw-sb;
            }
        }
    }
    &__helper {
        @include get-font($f14, $fw-b, $blue, $lh);
        letter-spacing: 0.7px;
        text-transform: uppercase;
        margin-bottom: 4px;
        &--orange {
            color: $loginOrange;
        }
        @media only screen and (max-width: $onlyMobile) {
            font-size: 12px;
        }
    }
    &__footer {
        width: 100%;
        box-shadow: 0 1px 14px 0 rgba(127, 3, 3, 0.1);
        position: relative;
        background: $white;
        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0;
            max-width: $centeredWidth;
            margin-left: auto;
            margin-right: auto;
            @media only screen and (max-width: $onlyMobile) {
                display: block;
            }
            &__item {
                flex: 1;
                @media only screen and (max-width: $onlyMobile) {
                    text-align: center;
                    margin-bottom: 12px;
                }
                &--right {
                    text-align: right;
                    @media only screen and (max-width: $onlyMobile) {
                        margin-bottom: 0;
                        text-align: center;
                    }
                }
            }
        }
        &__social {
            display: inline-block;
            vertical-align: top;
            margin-right: 16px;
            opacity: 0.9;
            @include transitionEase(#{opacity});
            &:focus, &:hover, &:active {
                opacity: 1;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &__links {
            &__list {
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: center;
                &__item {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 16px;
                    height: 100%;
                    opacity: 0.9;
                    @include transitionEase(#{opacity});
                    &:focus, &:hover, &:active {
                        opacity: 1;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    @media only screen and (max-width: $onlyMobile) {
                        margin-right: 8px;
                    }
                }
            }
        }
        &__link {
            @include get-font($f12, $fw-r, $black, $lh);
            text-decoration: none;
            margin: 0;
        }
        &__helper {
            width: 100%;
            padding: 9px 0;
            text-align: center;
            border-top: 1px solid $grayBackground;
        }
    }
    &__info {
        margin-top: 48px;
        @media only screen and (max-width: $tab) {
            margin-top: 0;
        }
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            @media only screen and (max-width: $tab) {
                padding: 30px 80px;
                box-sizing: border-box;
                white-space: nowrap;
                overflow: hidden;
                overflow-x: auto;
            }
            @media only screen and (max-width: $onlyMobile) {
                padding: 30px 16px;
            }
            &__item {
                display: inline-block;
                vertical-align: top;
                width: 264px;
                height: 406px;
                background: $white;
                margin-right: 24px;
                box-shadow: 0 0 30px 10px $grayBackground;
                @media only screen and (max-width: $tab) {
                    white-space: normal;
                }
                &:last-child {
                    margin-right: 0;
                }
                @media only screen and (max-width: $onlyMobile) {
                    width: 182px;
                    height: 280px;
                    margin-right: 8px;
                }
            }
            &__img {
                height: 206px;
                margin-bottom: 8px;
                @include animateOpacity();
                width: auto;
                @media only screen and (max-width: $onlyMobile) {
                    height: 140px;
                }
            }
            &__info {
                padding: 0 24px;
                box-sizing: border-box;
                @media only screen and (max-width: $onlyMobile) {
                    padding: 0 12px;
                }
            }
            &__title {
                @include get-font($f22, $fw-b, $black, $lh);
                margin-bottom: 8px;
                @media only screen and (max-width: $onlyMobile) {
                    font-size: $f16;
                    font-weight: $fw-sb;
                    margin-bottom: 4px;
                }
            }
            &__desc {
                @include get-font($f16, $fw-r, $black, $lh);
                @media only screen and (max-width: $onlyMobile) {
                    font-size: $f14;
                }
            }
        }
    }
}