.see-all-container {
  max-width: $centeredContainer;
  margin: 0 auto;
  padding: 64px 0;
  .profile__list__title {
    max-width: calc(100% - 110px);
    width: 100%;
  }
  @media only screen and (max-width: $onlyMobile) {
    padding: 32px 16px;
    box-sizing: border-box;
  }
  &__header {
    margin-bottom: 24px;
    &__action {
      &--multi {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  &__title {
    @include get-font($f32, $fw-sb, $black);
    margin-bottom: 8px;
    @media only screen and (max-width: $onlyMobile) {
      font-size: $f24;
    }
  }
  &__helper {
    @include get-font($f16, $fw-r, $lightBlack, $lh);
    margin-bottom: 16px;
    @media only screen and (max-width: $onlyMobile) {
      font-size: $f14;
    }
  }
  &__action {
    &__link {
      @include get-font($f14, $fw-m, $black, $lh);
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      img {
        margin-left: 8px;
        margin-top: 2px;
      }
    }
  }
  &__action-btn {
    @extend %border-box-shadow;
    background: $blue;
    height: 40px;
    border-radius: 7px;
    padding: 8px 16px;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    &__text {
      @include get-font($f16, $fw-m, $white, $lh);
    }
    &--green {
      background: $green;
    }
  }
  &__filter-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    @media screen and (max-width: $onlyMobile) {
      justify-content: flex-start;
    }
  }
  &__filter-clear-btn {
    @extend %appearance-none;
    margin-bottom: 12px;
    background: transparent;
    border: none;
    cursor: pointer;
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    &__text {
      @include get-font($f12, $fw-r, $black, $lh);
    }
  }
  &__search-input {
    @extend %border-box-shadow;
    width: 660px;
    box-sizing: border-box;
    height: 40px;
    background: url("assets/images/collaborations/list/magnifying-glass-browser.svg");
    background-color: $white;
    background-repeat: no-repeat;
    background-position: 16px;
    padding: 10px 16px 10px 40px;
    @include get-font($f14, $fw-r, $black);
    border-radius: 8px;
    outline: 0;
    &--with-clear {
      padding-right: 32px;
    }
    &--full {
      width: 100%;
    }
    @media screen and (max-width: $onlyMobile) {
      width: 100%;
      @include get-font($f12, $fw-r, $black);
      min-width: 100%;
    }
    &__container {
      &:has(input:focus) {
        border: 1px solid #747474;
        border-radius: 8px;
      }
      input {
        &:active, &:focus {
          &::placeholder {
            color: $lightestBlack;
         }
        }
      }
      position: relative;
      &--full {
        width: 100%;
        .see-all-container__search-input {
          width: 100%;
        }
      }

      @media screen and (max-width: $onlyMobile) {
        width: 100%;
      }
    }
    &__clear {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 16px;
      cursor: pointer;
    }
    &:disabled{
      cursor: no-drop;
    }
  }
  &__filter-btn {
    @extend %border-box-shadow;
    @extend %appearance-none;
    flex: 1;
    height: 40px;
    line-height: 40px;
    border-radius: 7px;
    background: $white;
    padding: 0 12px;
    box-sizing: border-box;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:disabled{
      cursor: no-drop;
    }
    &--inline {
      flex: unset;
    }
    img {
      margin-right: 8px;
    }
    &__text {
      @include get-font($f14, $fw-r, $black, $lh);
      @media screen and (max-width: $onlyMobile) {
        @include get-font($f12, $fw-r, $black, $lh);
      }
    }

    @media screen and (max-width: $onlyMobile) {
      @include get-font($f12, $fw-r, $black);
    }
  }
  &__empty-field {
    width: 100%;
    margin-top: 66px;
    text-align: center;
    > img {
      max-width: 100%;
    }
    p {
      margin-top: 24px;
      @include get-font($f16, $fw-r, $lightBlack, $lh);
    }
  }
  .no-list{
    &--container{
    text-align: center;
    }
    &__text {
      @include get-font($f16 , $fw-sb,$copiedBackground , $lh);
      margin: 8px 0px;
      margin-top: -20px; 
    }
    &__image {
      >img{
        width: auto;
        height: 400px;
      }
    }
  }
}
