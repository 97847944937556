.invite{
    &__content{
      width: calc(100% - 16px);
      margin: 24px 16px 8px 16px;
    }
    &__input{
      box-sizing: border-box;
      width: 100%;
      border: 1px solid $lightGrey;
      border-radius: 8px;
      padding: 8px 12px;
      min-height: 40px;

      &:focus{
        outline: 1px solid $black;
      }
    }
    &__error{
      min-height: 20px;
      margin-top: 8px;
      @include get-font($f14,$fw-r,$red);
    }
    &__submit{
      background-color: $blue;
    }

    &__modal{
      .scrollable-modal__header--paddingLessContentTrue:after,
      .scrollable-modal__footer--paddingLessContentTrue:after{
        background: #ededed;
      }
      &.survey{
        .scrollable-modal__backdrop{
          .scrollable-modal__container{
            @include centerItem();
            .scrollable-modal__footer{
              .scrollable-modal__footer__action-btn--secondary{
                color: $red !important;
              }
            }
          }
        }
        .invite__submit{
          background-color: $green;
        }
      }
    }
  }