.profile-edit-modal {
  .error-text {
    margin: 6px 0;
    font-size: 14px;
    line-height: 21px;
  }
  .form-label {
    margin-bottom: 8px;
    display: block;
  }

  &__form {
    &__helper {
      @include get-font($f14, $fw-r, $lightBlack);
      margin-top: 16px;
    }
    &__group {
      position: relative;
      margin-bottom: 32px;
      &.margin{
        margin-bottom: 8px;
      }
      @media only screen and (max-width: $onlyMobile) {
        margin-bottom: 24px;
      }
      .collaboration-form__suggestion__container {
        margin-top: 0;
      }
      &--multi {
        display: flex;
        @media only screen and (max-width: $onlyMobile) {
          display: block;
          > div {
            margin-right: 0;
            margin-bottom: 24px;
            width: 100% !important;
          }
        }
        > div {
          margin-right: 24px;
          width: 50%;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &__privacy {
      display: flex;
      align-items: center;
      &__container {
        .error-text {
          position: absolute;
          margin-top: -8px;
          margin-left: -76px;
        }
        margin-left: 12px;
        &--disabled {
          opacity: 0.65;
          cursor: not-allowed;
        }
      }
      &__label {
        @include get-font($f12, $fw-r, $lightBlack);
        margin-bottom: 8px;
      }
      &__select {
        @include get-font($f12, $fw-r, $lightBlack);
        border: 0;
        background: $white;
        outline: 0;
        margin-bottom: 8px;
      }
    }
    &__phone {
      &__confirm-text {
        margin: 8px 0; 
        position: unset;
        @include get-font($f14, $fw-r, $lightestGray, $lhOnboarding);
      }
      &__otperror {
        margin-bottom: 42px;
      }

      .error-text {
        position: absolute;
      }
      &__otp {
        width: 90px;
        margin-right: 8px;
        font-weight: 500 !important;
        text-align: center !important;
        &::-webkit-inner-spin-button {
          display: none;
        }
      }
      &__btn {
        @extend %appearance-none;
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        text-decoration: underline;
        @include get-font($f14, $fw-r, $black);
        cursor: pointer;
        &:hover,
        &:focus,
        &:active {
          color: $black;
        }
       
        &--disabled {
          color: $lightBlack;
          &:hover,
          &:focus,
          &:active {
            color: $lightBlack;
          }
        }
        &--timer {
          width: 40px;
          text-decoration: none !important;
        }
      }
      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        &.verified-phone{
           margin-right: 14px;
           width: 48%;
           position: relative;
        }
        .update-container{
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
      &__verified {
        @include get-font($f14, $fw-m, $green, $lh);
        > img {
          width: 12px;
          height: auto;
          vertical-align: middle;
          margin-right: 8px;
        }
      }
      &__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-weight: 500 !important;
        width: calc(100% - 72px);
        &::-webkit-inner-spin-button {
          display: none;
        }
      }
      &__codes {
        @extend %appearance-none;
        @include form-input-border-shadow();
        @include get-font($f14, $fw-m, $black, $lh);
        height: 36px;
        width: 72px;
        border-radius: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: $white;
        text-indent: 16px;
        &:focus,
        &:active {
          border: 1px solid $mediumBlack;
          box-shadow: none;
        }
        &.disabled{
          pointer-events: none;
          opacity: 1;
          background-color:$lightGray;
          border: none;
          box-shadow: none;
          border-right: 1px solid rgba(0,0,0,0.2)
        }
      }
    }
    &__email {
      font-weight: 500 !important;
      &__container {
        position: unset;
        margin-bottom: 12px;
        &--margin {
          margin-bottom: 32px;
        }
        .verified-email{
           display: flex;
           align-items: center;
        }
      }
      width: 48%;
      display: flex;
      padding: 0px 10px;
      align-items: center;
      margin-right: 14px;
    }
    &__icon-img {
      width: 16px !important;
      margin-right: 0px !important
    }
    &__date {
      @include form-input-border-shadow();
      border-radius: 10px;
      padding: 12px 16px;
      box-sizing: border-box;
      display: inline-block;
      width: auto;
      &--inline {
        display: inline-block;
        width: auto;
      }
      &--secondary {
        height: 36px;
        padding: 8px 16px;
      }
      &:focus-within{
        border: 1px solid $lightBlack;
        box-shadow: none;
      }
      &--active {
        border: 1px solid $lightBlack;
        box-shadow: none;
      }
      &--subgroup {
        margin-top: 24px;
        .form-label {
          font-weight: normal;
        }
      }
      &__select {
        border: 0;
        background: $white;
        @include get-font($f14, $fw-r, $black, $lh);
        margin-right: 8px;
        outline: none;
        &:last-child {
          margin-right: 0;
        }
        &--secondary {
          margin-right: 0;
        }
      }
    }
  }
  &__footer {
    width: calc(100% + 25px);
    margin-left: -10px;
    margin-bottom: -24px;
    height: 88px;
    border-top: 1.5px solid $gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
  }
  &__btn {
    @extend %appearance-none;
    height: 32px;
    border: 0;
    background: $blue;
    @include get-font($f14, $fw-sb, $white, $lh);
    padding: 0 12px;
    box-sizing: border-box;
    border-radius: 7px;
    cursor: pointer;
    line-height: 32px;
    @include transitionEase(#{background});
    &:focus,
    &:hover,
    &:active {
      background: $red;
    }
    &--gray {
      background: $lightestBlack;
      color: $black;
      &:hover,
      &:focus,
      &:active {
        background: $lighterBlack;
      }
    }
    &--black {
      background: rgba($black, 0.9);
      &:hover,
      &:focus,
      &:active {
        background: $black;
      }
    }
    &--secondary {
      background: $white;
      font-weight: $fw-r;
      color: $blue;
      text-decoration: underline;
      &:focus,
      &:hover,
      &:active {
        background: $white;
        color: $blue;
      }
    }
    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
    &--red {
      color: $removeRed;
      background: transparent;
      &:hover,
      &:focus,
      &:active {
        background: transparent;
      }
      &__text {
        @include get-font($f14, $fw-r, $removeRed, $lhOnboarding);
        margin-top: 8px;
      }
    }
    &--buttonRedFill {
      background: $removeRed;
      color: $white;
      &:hover,
      &:focus,
      &:active {
        background: $removeRed;
      }
    }
    &--buttonBlackFill {
      background: $black;
      color: $white;
      &:hover,
      &:focus,
      &:active {
        background: $black;
      }
    }
    &--green {
      @extend %appearance-none;
      border: 0;
      background: $green !important;
      @include get-font($f16, $fw-sb, $white, $f24);
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      @include transitionEase(#{background});
    }
    &--orange {
      @extend %appearance-none;
      border: 0;
      background: $orange !important;
      @include get-font($f16, $fw-sb, $white, $f24);
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      @include transitionEase(#{background});
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .profile-edit-modal {
    .default-modal__container {
      width: 100%;
    }
    &__form__phone__container{
      &.verified-phone{
        margin-bottom: 4px;
        margin-right: 0px;
        >img{
          left: auto !important; 
          right: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: $centerColumnWidth) {
  .profile-edit-modal {
    &__form__email__container{
      .verified-email{
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        .profile-edit-modal__form__email{
          width: 100%;
        }
      }
    } 
    &__form__phone__container{
      &.verified-phone{
        margin-bottom: 4px;
        margin-right: 0px;
        width: 100%;
        >img{
          left: auto !important; 
          right: 16px;
        }
      }
    }
    &__form__group--multi{
      flex-direction: column;
    }
  }
}
