.workshop {
    &__card {
      background: $workshop;
      display: block;
      padding: 12px 16px 16px 16px;
      text-decoration: none;
      border-radius: 10px 10px 0 0;
      &__status{
        border: 1px solid $red;
        padding: 1px 8px;
        border-radius: 4px;
        @include get-font($f14, $fw-b, $white, $lh);
        @include blink($red,$white);
        margin-right:12px ;
      }
      &__header {
        &--container{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;
        }
        @include get-font($f12, $fw-b, $lightBlack, $lh);
        text-transform: uppercase;
        margin: 0;
        word-break: break-word;
        
      }
      &__title {
        @include get-font($f16, $fw-sb, $black, $lh);
        margin-bottom: 8px;
      }
      &__description {
        @include get-font($f14, $fw-r, $black, $lh);
        margin-bottom: 8px;
      }
      &__action {
        width: 100%;
        height: 42px;
        background: $teal;
        @include get-font($f12, $fw-b, $white, $f18);
        border-radius: 4px;
        border: 0;
        cursor: inherit;
        text-transform: uppercase;
        &.light{
          background: $white;
          color: $teal;
        }
        &.dark{
          background: $black;
          color:$white
        }
        &.primary{
          position: relative;
          overflow: hidden;
          @include slideLines($white);
        }
        .arrow{
          margin-left: 8px;
          vertical-align: middle;
          width: 20px;
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(106%) contrast(104%);
        }
      }
      &__img {
        &__wrapper{
          position: relative;
          .react-player__video{
              video{
                border-radius: unset;
              }
            }  
        }
        width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
        margin: 0 auto 16px;
        left: -16px;
        position: relative;
        width: calc(100% + 32px);
        .react-player__video{
            video{
              border-radius: unset;
            }
          } 
      }
      &__score.overlap{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: rgba(0, 0, 0, 0.8);
        border-radius: 8px;
        padding: 8px 16px;
        @include get-font($f18,$fw-sb,$white);
        z-index: 1;
      }
    }
  }