.suggestions {
    &--product {
        @media only screen and (max-width: $onlyMobile) {
            white-space: nowrap;
            overflow: hidden;
            overflow-x: scroll;
            height: 250px;
        }
    }
    &__product {
        outline: none;
        width: 184px !important;
        @media only screen and (max-width: $onlyMobile) {
            display: inline-block;
            vertical-align: top;
        }
        height: 246px;
        @extend %border-box-shadow;
        margin-right: 8px;
        background: $white;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        &:hover, &:focus, &:active {
            .suggestions__product__overlay {
                opacity: 1;
            }
        }
        &__img {
            width: 184px;
            height: 122px;
            object-fit: cover;
            display: block;
        }
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            @include transitionEase(#{opacity});
            z-index: 10;
            text-align: center;
            padding-top: 40px;
            background: $superLightBlack;
            text-decoration: none;
            box-sizing: border-box;
            &__link {
                height: 45px;
                @include get-font($f14, $fw-b, $black, $lh);
                background: $yellow;
                padding: 12px 24px;
                box-sizing: border-box;
                text-decoration: none;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
            }
        }
        &__container {
            position: relative;
            height: 100%;
        }
        &__info {
            padding: 12px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            height: calc(100% - 122px);
            &__header {
                width: 100%;
            }
        }
        &__title {
            @include get-font($f14, $fw-m, $black, $lh);
            word-break: break-word;
            height: 42px;
            overflow: hidden;
            margin-bottom: 4px;
            width: 100%;
        }
        &__helper {
            @include get-font($f12, $fw-r, $black, $lh);
            margin-bottom: 12px;
            &--company {
                height: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &__meta {
            display: flex;
            align-items: center;
            width: 100%;
            .suggestions__product__helper {
                margin-bottom: 0;
            }
        }
        &__rating {
            height: 24px;
            padding: 4px;
            box-sizing: border-box;
            border-radius: 2px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            &--empty {
                width: 26px;
                background: $superLightBlack;
            }
            &__title {
                margin-left: 4px;
                @include get-font($f14, $fw-b, $white, $lh);
                letter-spacing: -0.64px;
            }
        }
    }
}