.report-modal {
  &__close {
    position: fixed;
    top: 52px;
    right: 52px;
    z-index: 110;
  }
  &--comments {
    .product__reviews__load-more {
      margin-top: 8px;
    }
  }
  .default-modal__container {
    padding: 0;
    border: 0;
    position: relative;
    max-height: 80vh;
    height: auto;
    width: 520px;
    max-width: 100%;
  }
  &__header {
    background: red;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    height: 80px;
    padding-left: 24px;
    box-sizing: border-box;
    .product__ratings__start {
      display: block;
    }
    .product__ratings__default {
      margin-right: 8px;
    }
    &__title {
      @include get-font($f18, $fw-m, $black, $lh);
      text-transform: capitalize;
    }
    &--green {
      background-color: rgba(87, 123, 51, 0.2);
    }
    &--with-back {
      align-items: center;
      img {
        margin-right: 12px;
        cursor: pointer;
      }
      .report-modal__header__title {
        line-height: 1;
      }
    }
    &__sub-title {
      @include get-font($f12, $fw-r, $lightBlack, $lh);
      text-transform: capitalize;
    }
  }
  .default-modal__content {
    height: calc(80vh - 80px);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 14px 32px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
      margin-bottom: 32px;
      .report-modal__list {
        margin: 16px 0 0 16px;
        .report-modal__list__item {
          margin-bottom: 16px;
        }
      }
      .view-all{
        all: unset;
        display: block;
        margin:24px auto auto auto;
        width: fit-content;
        background-color: transparent;
        @include get-font($f12,$fw-b,$blue,$f18);
        cursor: pointer;
      }
    }
    &__title {
      @include get-font($f16, $fw-m, $black, $lh);
    }
    &__helper {
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      margin-top: 4px;
    }
  }
  &__selfie {
    display: flex;
    margin-top: 16px;
    overflow: auto;

    &__item {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      min-width: 84px;
      max-width: 84px;

      &__div {
        position: relative;

        &:hover {
          .report-modal__selfie__item__image {
            opacity: 0.3;
          }
          .report-modal__selfie__item__overlay {
            opacity: 1;
          }
        }
        &.no-image {
          height: 84px;
          margin-bottom: 4px;
          border: 1px solid $imageWrapperBackgroundGray;
          border-radius: 8px;
        }
      }
      &__image {
        width: 84px;
        height: 84px;
        margin: 0 auto 0 auto;
        object-fit: cover;
        border-radius: 8px;
        margin-bottom: 4px;
        cursor: pointer;
      }
      

      &__overlay {
        transition: 0.4s linear;
        opacity: 0;
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        cursor: pointer;

        &__icon {
          background-color: $lightestGray;
          color: white;
          font-size: 16px;
          padding: 30px;
          border-radius: 8px;
        }
      }

      &__user {
        display: flex;
        cursor: pointer;

        &__text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-right: auto;
          @include get-font($f14, $fw-m, $black);
        }
        &__verified {
          margin-left: auto;
        }
      }
    }

    &__viewallblock {
      margin-right: 8px;
      width: 48px;
      min-width: 48px;
      height: 44px;
      padding: 20px 18px;
      border-radius: 8px;
      background-color: $black;
      text-align: center;
      cursor: pointer;
      @include get-font($f14, $fw-sb, $white, $lh);
    }

    &__modal {
      &.photo-modal__img__container {
        flex-direction: column;
        width: 100%;
      }
      &__photo {
        &.photo-modal__img {
          padding-bottom: 0px;
          border-radius: 24px 24px 0 0;
          min-width: 40vw;

          @media screen and (max-width: $onlyMobile) {
            min-width: 96vw;
          }
        }
      }
      &__user {
        display: flex;
        width: calc(100% - 64px);
        min-height: 40px;
        height: 40px;
        background-color: $white;
        padding: 12px 16px;
        border-radius: 0px 0px 8px 8px;

        &__profile {
          display: flex;
          cursor: pointer;

          &__image {
            &.send-message-modal__profile-container__image {
              width: 40px;
              height: 40px;
            }
          }

          &__details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 8px;

            &__date {
              @include get-font($f12, $fw-r, $lightestGray, $lh);
            }
          }
        }
        &__download {
          margin-left: auto;
          height: 24px;
          padding: 8px 16px;
          background: $black;
          border-radius: 10px;
          text-decoration: none;
          @include get-font($f16, $fw-sb, $white, $lh);
        }
      }
    }
  }

  &__viewall {
    margin-right: 8px;
    width: 48px;
    min-width: 48px;
    height: 44px;
    padding: 20px 18px;
    border-radius: 8px;
    background-color: $black;
    text-align: center;
    cursor: pointer;
    @include get-font($f14, $fw-sb, $white, $lh);

    &__modal {
      .default-modal__container {
        width: 652px;
      }
      &__content {
        max-height: 440px;
        overflow: auto;
      }
      &__photos {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        flex-wrap: wrap;
        row-gap: 16px;

        .report-modal__selfie__item {
          width: 112px;
          min-width: 112px;
          margin-right: 8px;
        }
        .report-modal__selfie__item__div {
          &.no-image {
            height: 112px;
            margin-bottom: 5px;
            border: 1px solid $imageWrapperBackgroundGray;
            border-radius: 8px;
          }
        }

        .report-modal__selfie__item__image {
          width: 112px;
          height: 112px;
        }

        .report-modal__selfie__item__overlay__icon {
          padding: 44px;
        }
      }
    }
  }

  &__pill {
    height: 38px;
    background: $grayBackground;
    border-radius: 19px;
    padding: 10px 12px;
    box-sizing: border-box;
    flex: 0.8;
    margin-right: 12px;
    flex-grow: inherit;
    position: relative;
    overflow: hidden;
    &--intensity {
      display: inline-block;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: 16px;
    }
    &:hover,
    &:focus {
      .report-modal__pill__intensity__btn {
        background: $lightestBlack;
        &.tasting {
          background-color: $white;
        }
        &--active {
          background: $lighterBlack;
        }
      }
    }
    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 10;
      position: relative;
    }
    &__title {
      @include get-font($f14, $fw-sb, $black);
      span {
        margin-right: 16px;
        font-size: $f12;
        img {
          margin-right: 4px;
          vertical-align: middle;
        }
      }
      max-width: calc(100% - 124px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__helper {
      @include get-font($f12, $fw-r, $lightBlack);
      position: absolute;
      right: 0;
    }
    &__percent {
      position: absolute;
      height: 100%;
      background: #f5cdd3;
      top: 0;
      left: 0;
      &--green {
        background: #dee4d7;
      }
    }
    &__container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__secondary {
      @include get-font($f14, $fw-m, $black);
      min-width: 58px;
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      text-align: right;
      &--with-benchmarking {
        width: 138px;
        flex-shrink: 0;
      }
      &__text {
        display: inline-block;
        vertical-align: top;
        min-width: 52px;
      }
    }
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      &__item {
        width: 100%;
        margin-top: 16px;
        position: relative;
        &--intensity {
          .pr-reports__benchmark {
            display: inline-flex;
            margin-top: 6px;
            vertical-align: top;
          }
        }
      }
    }
    &__comment {
      &__btn {
        @extend %appearance-none;
        margin-top: 12px;
        background: $lightestYellow;
        border-radius: 5px;
        border: 0;
        @include get-font($f14, $fw-m, $black, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        padding: 0 16px;
        cursor: pointer;
        @include transitionEase(#{background});
        &:hover,
        &:active {
          background: $lightYellow;
        }
      }
      &__icon {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        margin-right: 8px;
        object-fit: cover;
        border: 0;
        display: block;
      }
      &__info {
        display: flex;
        text-decoration: none;
        align-items: center;
        > p {
          @include get-font($f14, $fw-m, $black, $lh);
        }
      }
      > p {
        margin-top: 8px;
        @include get-font($f14, $fw-r, $black, $lh);
      }
    }
    &__intensity {
      &__btn {
        @extend %appearance-none;
        border: 0;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: $grayBackground;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        padding: 0;
        @include transitionEase(#{background});
        cursor: pointer;
        &.tasting {
          background-color: $white;
          img {
            display: block;
            margin-left: -1px;
            height: auto;
            width: 6px;
          }
        }
        &--active {
          background: $lighterBlack;
          img {
            margin-top: -2px;
          }
        }
        img {
          display: block;
          margin-left: -1px;
        }
      }
      &__container {
        padding-top: 12px;
        width: 80%;
        position: relative;
        margin-left: 16px;
        &:after {
          content: '';
          height: 100%;
          width: 4px;
          position: absolute;
          left: 0;
          top: 0;
          margin-left: 16px;
          background: $grayBackground;
          z-index: 1;
        }
        .report-modal__pill {
          flex: 1;
          height: 36px;
          background: rgb(243, 243, 243);
          &__list {
            z-index: 2;
            position: relative;
            &__item {
              &:first-child {
                margin-top: 0;
              }
              margin-top: 12px;
            }
          }
          &__title {
            font-size: $f12;
            max-width: 100%;
          }
        }
      }
    }
  }

  &__movement-div {
    @extend %appearance-none;
    border: 0;
    @extend %border-box-shadow;
    background: $white;
    display: flex;
    padding: 10px 4px;
    align-items: center;

    &__leftbtn {
      cursor: pointer;
      @extend %border-box-shadow;
      margin-left: 3%;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      background: $white;

      img {
        transform: rotate(180deg);
        width: 16px;
        margin: 4px 4px 0 0;
      }
    }
    &__rightbtn {
      @extend %border-box-shadow;
      margin-left: auto;
      margin-right: 3%;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      background: $white;
      cursor: pointer;

      img {
        width: 16px;
        margin: 4px 0px 0 0;
      }
    }
  }

  &__movement-btn {
    @extend %appearance-none;
    border: 0;
    @extend %border-box-shadow;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: $white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &--left {
      left: 0;
      margin-left: -64px;
      img {
        transform: rotate(180deg);

        @media only screen and (max-width: $onlyMobile) {
          width: 16px;
          margin: 4px 4px 0 0;
        }
      }
    }
    &--right {
      right: 0;
      margin-right: -64px;

      img {
        @media only screen and (max-width: $onlyMobile) {
          width: 16px;
          margin: 4px 0px 0 0;
        }
      }
    }

    @media only screen and (max-width: $onlyMobile) {
      top: 16%;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background: rgba(0, 0, 0, 0.06);
    }
  }
  &__any-other {
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding: 12px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $grayBackground;
        gap: 24px;
      }
    }
    &__title {
      @include get-font($f14, $fw-sb, $black, $lh);
    }
    &__helper {
      @include get-font($f12, $fw-r, $lightBlack, $lh);
      min-width: 105px;
      text-align: end;
    }
    &__container {
      height: 100%;
      max-height: 340px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .report-modal {
    .default-modal__container {
      max-height: 100vh;
    }
    .default-modal__content {
      height: calc(100vh - 80px);
      padding: 0px 14px 0px 14px;
      box-shadow: 0 1px 2px 1px #bfbfbf;
    }
    &__close {
      margin-left: auto;
      position: relative;
      top: -10px;
      right: 20px;
      border-radius: 24px;
      width: 24px;
      height: 24px;
      background: $superLightBlack;
      text-align: center;
      line-height: 24px;
      img {
        width: 12px;
      }
    }
    &__movement-btn {
      z-index: 10;
      &--left {
        margin-left: 16px;
      }
      &--right {
        margin-right: 16px;
      }
    }
  }
}
