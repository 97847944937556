.scrollable-modal {
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: $lightBlack;
    overflow: hidden;
    overflow-y: auto;
  }
  &__container {
    padding: 24px;
    box-sizing: border-box;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    border-radius: 10px;
    @extend %border-box-shadow;
    width: 574px;
    margin-bottom: 60px;
    
    @media only screen and (max-width: $onlyMobile) {
      padding: 16px;
      width: 100vw;
      top: 0;
      left: 0;
      transform: none;
      margin-bottom: 0;
      position: fixed;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
      overflow-y: auto;
    }
  }
  &__modal-overflow {
    max-height: 60vh;
    @media screen and (max-width: $onlyMobile) {
      max-height: 72vh;
    }
    overflow: scroll;
    overflow-x: hidden;
    padding-right: 3px;
    scrollbar-width: thin;
    scrollbar-color: #ffffff #ffffff;
    &:active,
    &:focus,
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #d1d1d1;
        transition: 0.5s ease-in-out;
      }
      scrollbar-color: #d1d1d1 #ffffff;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 15px;
      border: 3px solid #ffffff;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      @include get-font($f18, $fw-b, $black, $lh);
    }
    &__note-text {
      margin-top: 8px;
      @include get-font($f14, $fw-m, $copiedBackground, $lhOnboarding);
    }
    &__close {
      @extend %appearance-none;
      border: 0;
      background: transparent;
      cursor: pointer;
      padding: 0;
      img {
        width: 16px;
        display: block;
      }
      &__icon {
        margin-right: 12px;
      }
    }
    &--paddingLessContentFalse {
      &::after {
        content: " ";
        position: absolute;
        height: 0.5px;
        background: #f2f2f2;
        width: 100%;
        left: 0px;
        top: 68px;
      }
    }
    &--paddingLessContentTrue {
      &::after {
        content: " ";
        position: absolute;
        height: 0.5px;
        background: #f2f2f2;
        width: 100%;
        left: 0px;
        top: 68px;
      }
    }
    &--paddingLessContentWithNoteText {
      &::after {
        content: " ";
        position: absolute;
        height: 0.5px;
        background: #f2f2f2;
        width: 100%;
        left: 0px;
        top: 108px;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    width: calc(100% + 25px);
    margin-bottom: -24px;
    height: 72px;
    padding: 0px 24px 0px 0px;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    &--paddingLessContentFalse {
      &::after {
        content: " ";
        position: absolute;
        height: 0.5px;
        background: #f2f2f2;
        width: calc(100% + 24px);
        left: -24px;
        top: 0;
        // box-shadow: 0px -2px 4px rgba(23, 23, 23, 0.1);
      }
    }
    &--paddingLessContentTrue {
      &::after {
        content: " ";
        position: absolute;
        height: 0.5px;
        background: #f2f2f2;
        width: calc(100% + 41px);
        left: -16px;
        top: 0;
        // box-shadow: 0px -2px 4px rgba(23, 23, 23, 0.1);
      }
    }
    &--multi {
      justify-content: space-between;
    }
    &--bottom {
      box-shadow: 0 -1px 4px 0 $grayBackground;
      width: calc(100% + 48px);
      margin-left: -24px;
      padding: 12px 24px;
      box-sizing: border-box;
      margin-bottom: -24px;
    }
    &__action-btn {
      @extend %appearance-none;
      border: 0;
      background: $black;
      height: 40px;
      padding: 0 16px;
      @include get-font($f16, $fw-sb, $white);
      @include transitionEase();
      box-sizing: border-box;
      border: 0;
      border-radius: 10px;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      border-radius: 8px;
      &--primary {
        background: $blue;
      }
      &--secondary {
        padding: 0;
        background: transparent !important;
        color: $copiedBackground !important;
        margin-left: 8px;
      }
      &--green {
        background: $green;
      }
      &--black {
        background: $black;
      }
      &__icon {
        margin-right: 6px;
      }
      &__text {
        @include get-font($f14, $fw-sb, $white);
      }
      &--disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
      .generic-loader{
        &--with-margin{
          margin: 0;
        }
        img {
          width: 24px;
        }
      }
    }
    &__clear-btn {
      cursor: pointer;
      @extend %appearance-none;
      background: transparent;
      border: 0;
      &__text {
        @include get-font($f14, $fw-r, $blue, $lh);
      }
      &--remove {
        @include get-font($f14, $fw-r, $mediumRed, $lh);
        margin-left: 12px;
        @include transitionEase(#{color});
        &:hover,
        &:focus,
        &:active {
          color: $red;
        }
      }
      &--no-margin {
        margin-left: 0 !important;
      }
      &--disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}
