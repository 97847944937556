.review-background {
    background-image: url('assets/images/reviews/bg.png');
    background-color: $white;
}
.review-container {
    margin: 0 auto;
}
.review-page {
    &__scroll {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        overflow-x: auto;
        @include getDefaultScrollbar();
        scrollbar-color: #ededed #ffffff;
        padding-bottom: 4x;
        // padding-left: 15px;
        box-sizing: border-box;
        > * {
            display: inline-block !important;
            vertical-align: top;
        }
    }
    &__list {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        &__item {
            margin-bottom: 64px;
            &:first-child, &--top {
                padding-top: 32px;
            }
            &--bottom {
                padding-bottom: 32px;
            }
            &__container{
                max-width: $centeredBigContentContainer;
                margin: 0 auto;
            }
        }
        &__title-container {
            display: flex;
            justify-content: space-between;
            &--primary {
                display: flex;
                flex-direction: column;
            }
            &--secondary {
                display: flex;
                align-items: center;
                margin-right: 25px;
                &__user {
                    max-width: 400px;
                }
            }
        }
        &__title {
            @include get-font($f18, $fw-ub, $black, $lhl);
            &--red {
                color: $red;
            }
        }
        &__sub-title-container {
            display: flex;
            justify-content: space-between;
            margin-right: 16px;
            &__see-more {
                transform: rotate(180deg);
                &.rotate-remove {
                    transform: none;
                }
            }
        }
        &__sub-title {
            margin-top: 5px;
            @include get-font($f15, $fw-r, $lightBlack, $lhs);
        }
    }
    &__suggestions {
        &__arrow {
            top: 33%;
            &--centered {
                top: 40%;
            }
        }
    }

    &__product {
        margin-top: 24px;
        &--collection {
            margin-top: 16px;
        }
        &__list {
            list-style: none;
            display: flex;
            padding: 0;
            &__item {
                text-decoration: none;
                position: relative;
                display: block;
                width: 285px;
                margin-right: 8px;
                .product__ratings__default {
                    height: 28px;
                    padding: 6px;
                }
                &__image-container {
                    &__image {
                        width: 100%;
                        height: 190px;
                        border-radius: 15px;
                        border: solid 1px $superLightBlack;
                        object-fit: contain;
                        background-color: $lighterGray;
                    }
                    &__video {
                        position: absolute;
                        z-index: 2;
                        top: 44%;
                        left: 44%;
                        width: 12%;
                        margin-right: -50%
                    }
                }
                &__name {
                    @include get-font($f16, $fw-sb, $black, $lh);
                    margin-top: 12px;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                    width: 100%;
                    &--collection {
                        white-space: nowrap;
                    }
                }
                &__subtitle {
                    @include get-font($f14, $fw-r, $lightestGray, $lh);
                    width: 100%;
                    margin: 5px 0 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .product__list__review-container {
                    margin-top: 5px;
                }
            }

        }

    }
    &__profile {
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-top: 24px;
            &__badge {
                position: absolute;
                top: 0;
                left: -4px;
            }
            &__item {
                margin-bottom: 16px;
                box-sizing: border-box;
                object-fit: cover;
                display: block;
                position: relative;
                &__container {
                    width: 150px;
                }
                &__img {
                    display: block;
                    width: 96px;
                    height: 96px;
                    border-radius: 52px;
                    border: 0.5px solid $grayBackground;
                    box-sizing: border-box;
                    flex-shrink: 0;
                    object-fit: cover;
                    margin: 0 auto;
                    position: relative;
                }
                position: relative;
            }
        }
        &__info {
            text-align: center;
            .profile__list__title {
                text-align: center;
                > span {
                    > span {
                        max-width: calc(100% - 24px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        vertical-align: top;
                        white-space: nowrap;
                        display: inline-block;
                    }
                }
            }
            button {
                margin-top: 6px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &__collaboration {
        list-style: none;
        display: flex;
        padding: 0;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        &__item-link{
            text-decoration: none;
            background: $white;
        }
        &--with-margin {
            margin-top: 24px;
        }
        &__item {
            width: 100%;
            max-width: 580px;
            box-shadow: 0 2px 4px 0 $superLightBlack;
            border: solid 0.5px $superLightBlack;
            border-radius: 10px;
            text-decoration: none;
            flex-shrink: 0;
            margin-top: 24px;
            &:nth-child(1), &:nth-child(2) {
                margin-top: 0;
            }
            &--white {
                background: $white;
            }
            &--primary {
                display: flex;
            }
            &--secondary {
                border-top: solid 0.5px $superLightBlack;
                padding: 0 16px 0 16px;
                padding-top: 10px;
                &__user {
                    .collaboration-item__action__user__block {
                        max-width: 70%;
                        word-break: break-word;
                    }
                }
            }
        }
        &__image-container {
            border-right: solid 1px $superLightBlack;
            &__image {
                height: 180px;
                width: 180px;
                object-fit: contain;
                border-radius: 10px 0 0 0;
                display: block;
                flex-shrink: 0;
            }
            &__video {
                height: 180px;
                width: 180px;
                object-fit: contain;
                border-radius: 10px 0 0 0;
                display: block;
                flex-shrink: 0;
            }
            &__playIcon {
                position: absolute;
                z-index: 2;
                width: 17%;
                top: 41%;
                left: 43%;
                margin-right: -50%;
              }

              &__playIcon-carousel {
                width: 12%;
                top: 37%;
                left: 45%;
              }  
        }
        &__details {
            padding: 16px 16px 0 16px;
            &__type {
                margin-bottom: 12px;
                @include get-font($f16, $fw-r, $black, $lh);
            }
            &__category {
                margin-bottom: 12px;
                @include get-font($f12, $fw-r, $black, $lh);
            }
            &__name {
                @include get-font($f12, $fw-b, $lightestGray, $lh);
                text-transform: uppercase;
                &--yellow {
                    color: $yellow;
                }
                &--blue {
                    color: $blue;
                }
            }
            &__title {
                margin-top: 4px;
                @include get-font($f18, $fw-b, $black, $lh);
                overflow: hidden;
                word-wrap: break-word;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // max-width: 370px;
                // width: 100%;
            }
            &__description {
                @include get-font($f16, $fw-r, $lightestGray, $lh);
                margin-top: 12px;
                word-break: break-word;
                height: 50px;
                overflow: hidden;
                margin-bottom: 4px;
                width: 100%;
                text-overflow: ellipsis;
            }
        }
    }
    &__centered-collection {
        margin-bottom: 64px;
        &__container {
            padding: 30px 0;
            max-width: $centeredBigContentContainer;
            margin: 0 auto;
        }
        &__title {
            @include get-font($f18, $fw-ub, $black, $lh);
            text-align: center;
            margin-right: 15px;
            max-width: 285px;
            margin-top: 5px;
            margin: 0 auto;
        }
        &__sub-title{
            margin-top: 5px;
            text-align: center;
            @include get-font($f16, $fw-m, $lightestGray, $lh);
            margin-bottom: 24px;
        }
        &__list {
            margin-top: 24px;
            &__image {
                width: 178px;
                height: 178px;
                border-radius: 40px;
                border: solid 1px $superLightBlack;
                object-fit: cover;
                object-position: top;
                background: $white;
                margin: 0 auto;
            }
            &__item {
                display: block;
                text-decoration: none;
                @media only screen and (max-width: $onlyMobile) {
                    margin-right: 15px;
                }
            }
            &__title {
                margin-top: 5px;
                @include get-font($f16, $fw-sb, $black, $lh);
                text-align: center;
                word-break: break-word;
            }
        }
    }
    &__chips-collection {
        margin-bottom: 64px;
        &--top{
            padding: 32px 0 0 0;
        }
        &__container {
            max-width: $centeredBigContentContainer;
            margin: 0 auto;
        }
        &__list {
            margin-top: 24px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            &__item {
                box-shadow: 0 2px 8px 0 $superLightBlack;
                border: 0.8px solid $grayBackground;
                padding: 12px 24px;
                border-radius: 10px;
                margin: 0 16px 16px 0;
                text-decoration: none;
                @include get-font($f16, $fw-r, $black, $lh);
                text-decoration: none;
            }
        }
    }
}

@media only screen and (max-width: $tab) {
    .review-page {
        &__scroll {
            padding-left: 15px;
        }
        &__collaboration {
            flex-direction: column;
            &__image-container {
                &__image {
                    width: 64px;
                    height: 64px;
                    border-right: 0;
                    border-radius: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    position: relative;
                }
                &__video {
                    width: 64px;
                    height: 64px;
                    border-right: 0;
                    border-radius: 10px;
                    top: 50%;
                    position: relative;
                }
            }
            &__details {
                padding: 0 16px 0 12px;
                &__title {
                    font-size: $f14;
                }
            }
            &__item {
                border: 0;
                box-shadow: none;
                margin-top: 0;
                &--primary {
                    padding: 16px 0 16px 16px;
                    border-bottom: 1px solid $grayBackground;
                }
                &__user {
                    @include get-font($f12, $fw-r, $lightBlack, $lh);
                    margin-top: 8px;
                }
                &-link {
                    display: block;
                }
            }
        }
        &__list {
            &__item {
                margin-bottom: 32px;
            }
            &__title-container {
                margin-left: 16px;
            }
        }
        &__product {
            margin: 16px 0 16px 0;
            &__list {
                &__item {
                    width: 200px;
                    margin-right: 12px;
                    &__image-container {
                        &__image {
                            height: 130px;
                        }
                    }
                }
            }
            &__subtitle {
                max-width: 200px;
                overflow: hidden;
                word-break: break-word;
                text-overflow: ellipsis;
                display: block;
            }
        }
        &__profile {
            margin: 16px 0 16px 0;
            &__list {
                margin-top: 16px;
                &__item {
                    &__container {
                        margin: 0 8px;
                    }
                }
            }
        }
        &__centered-collection {
            &__list {
                &__item {
                    margin-right: 15px;
                }
            }
        }
        &__chips-collection {
            &__list {
                &__item {
                    box-shadow: none;
                }
            }
        }
    }
}