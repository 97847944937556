.chat-container {
    display: flex;
    justify-content: flex-start;
    height: calc(100vh - 64.5px);
    margin-top: 45px;
    //height: 100vh;
    width: 1050px;
    background: $white;
    margin: auto;
    overflow: hidden;
    padding-top: 65px;
    &--mobile {
        margin-top: 45px;
        padding: 65px 25px 0 25px;
        @media only screen and (max-width: $onlyMobile) {
            margin: 0 auto;
            &__primary {
                @include get-font($f18, $fw-sb, $black, $lh);
                text-align: center;
            }
            &__secondary {
                @include get-font($f14, $fw-r, $lightBlack, $lh);
                margin-top: 4px;
                text-align: center;
            }
        }
    }
    &__left {
        width: 374px;
        position: relative;
        border-left: 1px solid $superLightBlack;
        border-right: 1px solid $superLightBlack;
        &__search {
            height: 54px;
            width: 374px;
            background-color: #F0F0F0;
            &__container {
                height: 54px;
                align-items: center;
                width: 100%;
                padding: 10px 15px;
                display: flex;
                align-items: center;
                align-items: center;
                padding: 10px;
                box-sizing: border-box;
                border-radius: 5px;
                &__input-container {
                    width: 288px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    border-radius: 5px;
                    margin-right: 18px;
                    .chat-search-icon{
                        opacity: .5;
                    }
                    &--full-width {
                        width: 100%;
                        margin-right: 0!important;
                    }
                    &__input {
                        width: 100%;
                        border: 0px;
                        height: 100%;
                        border-radius: 5px;
                        font-size: 14px;
                        outline: 0;
                        &::placeholder {
                            color: $lightestBlack;
                        }
                    }
                }
                &__new-chat {
                    &__image {
                        height: 35px;
                        width: 35px;
                    }
                }
            }
        }
        &__empty-chat {
            margin-top: 110px;
            padding: 0 60px;
            text-align: center;
            &__header {
                @include get-font($f16, $fw-sb, $lighterBlack, $lhr);
                margin-bottom: 10px;
            }
            &__helper {
                @include get-font($f14, $fw-sb, $lighterBlack, $lhs);
                margin-bottom: 0;
            }
            &__no-result {
                @include get-font($f16, $fw-sb, $lighterBlack, $lhr);
            }
        }
    }
    &__right {
        width: calc(100% - 374px);
        max-width: 674px;
        border-right: 1px solid $superLightBlack;
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        height: 100%;
        &__container {
            padding: 15px 40px 68px;
            margin-bottom: 20px;
            margin-top: 58px;
        }
        .chat-top-bar {
            min-height: 58px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 12px 20px;
            border-bottom: 1px solid $superLightBlack;
            position: fixed;
            z-index: 11;
            width: 100%;
            max-width: 674px;
            background: $white;
            .chat-title{
                @include get-font($f16,$fw-r,$black,$lh24)
            }
            &.empty-top-bar {
                display: flex;
                justify-content: flex-end;
            }
            &__icon {
                height: 23px;
                width: 23px;
                cursor: pointer;
                padding: 0;
                border: none;
                background-color: $white;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            .link{
                display: flex;
                text-decoration: none;
                @include get-font($f14, $fw-r,$blue , $lhOnboarding)
            }
            .chat-top-bar {
                &__options {
                    display: flex;
                    flex-direction: column;
                    border-radius: 6px;
                    overflow: hidden;
                    width: 163px;
                    border: 1px solid $superLightBlack;
                    box-shadow: 0 0 6px 0px $superLightBlack;
                    margin: 8px 8px 0 0 ;
                    &__button {
                        font-size: 16px;
                        min-height: 39px;
                        padding-left: 10px;
                        background: $white;
                        border: 0;
                        border-bottom: 1px solid $superLightBlack;
                        display: flex;
                        outline: 0;
                        color: $black;
                        cursor: pointer;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
        .centered {
            .header-message {
                text-align: center;
                margin: 20px 0;
                .time-header-chat-text {
                    border-radius: 8.5px;
                    background: $chatTimeBackground;
                    @include get-font($f12, $fw-r, $lightBlack, $lhxxs);
                    display: inline-block;
                    padding: 3px 15px;
                }
                .header-chat-text {
                    @include get-font($f14, $fw-r, $lightBlack, $lhs);
                    margin-bottom: 0;
                    display: inline-block;
                    border-radius: 16px;
                    background: $chatPink;
                    padding: 8px 10px;
                }
            }
        }
        .chat-bubble {
            display: flex;
            flex-direction: column;
            &__left {
                text-align: left;
                &__container {
                    background-color: $gray;
                    border: 1px solid $gray;
                }
            }

            &__right {
                text-align: right;
                &__container {
                    background-color: $white;
                    border: 1px solid $gray;
                }
            }
            .chat-user-link {
                text-decoration: none;
                @include get-font($f14, $fw-sb, $black, $lhs);
                margin-bottom: 4px;
                display: inline-block;
            }
            &__container {
                text-align: left;
                max-width: 482px;
                min-width: 75px;
                border-radius: 10px;
                display: inline-block;
                margin: 3px 0;
                border-radius: 10px;
                position: relative;
                padding: 10px;
            }

            &__text {
                @include get-font($f16, $fw-r, $black, $lhr);
                margin-bottom: 0;
                white-space: pre-line;
                word-wrap: break-word;
                a {
                    color: #337ab7;
                    text-decoration: none !important;
                }
            }

            .chat-attachment {
                &__container {
                    display: flex;
                    position: relative;
                    &__icon {
                        &__image {
                            width: 33px;
                            height: 33px;
                            margin-right: 6px;
                        }
                    }
                    &__details {
                        &--name {
                            margin-bottom: 2px;
                            @include get-font($f16, $fw-r, $black, $lhr);
                            white-space: pre;
                            overflow: hidden;
                            max-width: 420px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        &--size {
                            @include get-font($f12, $fw-r, $lightBlack, $lhr);
                        }
                    }
                    &__download-icon-container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        justify-content: flex-end;
                        display: none;
                        &__image {
                            width: 40px;
                            height: 40px;

                        }
                    }
                    &:hover {
                        .chat-attachment__container__download-icon-container {
                            display: flex;
                        }
                    }
                }
            }
            .chat-preview {
                height: 73px;
                overflow: hidden;
                display: flex;
                border-radius: 6px;
                background: $chatPink;
                margin-bottom: 2px;
                text-decoration: none;
                &__image-container {
                    width: 73px;
                    height: 73px;
                    margin-right: 7px;
                    &__image {
                        width: 73px;
                        height: 73px;
                        object-fit: contain;
                        border: 0;
                    }
                }
                &__details-container {
                    padding: 10px 0;
                    overflow: hidden;
                    &__title {
                        @include get-font($f14, $fw-sb, $black, $lhs);
                        margin-bottom: 2px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &__description {
                        height: 30px;
                        @include get-font($f14, $fw-r, $lightBlack, $lhxs);
                        overflow: hidden;
                        margin-bottom: 0;
                    }
                }
            }
            .chat-image {
                max-width: 100%;
                width: auto;
                height: auto;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 10px;
                overflow: hidden;
                margin-bottom: 8px;
            }
            .chat-message-status {
                text-align: right;
                &__text {
                    @include get-font($f12, $fw-r, $chatTime, $lhxxs);
                    margin-bottom: 0;
                    display: inline-block;
                }
                &__icon {
                    width: 10px;
                    height: auto;
                    margin-left: 4px;
                }
            }
        }
        .chat-input-bar {
            position: fixed;
            bottom: 0;
            width: 100%;
            //max-width: 676px;
            &__container {
                width: 100%;
                max-width: 673px;
                background: #FFFFFF;
                min-height: 58px;
                display: flex;
                padding: 0 30px;
                box-sizing: border-box;
                border-top: 2px solid $superLightBlack;
                flex-direction: column;
                z-index: 11;
                .link-preview {
                    margin: 0 auto;
                    &__link {
                        text-decoration: none!important;
                    }
                    &__container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    &__details {
                        max-width: 400px;
                        margin-left: 40px;
                        &__container {
                            display: flex;
                            background-color: $fileOverlayGray;
                            border-radius: 6px;
                            &__image-container {
                                display: flex;
                                align-items: center;
                                &__image {
                                    width: 70px;
                                    height: 70px;
                                    object-fit: contain;
                                }
                            }
                            &__description {
                                margin-left: 15px;
                                &__title {
                                    @include get-font($f14, $fw-sb, $mediumBlack, $lhs);
                                    margin: 8px 0 3px 0;
                                }
                                &__subtitle {
                                    @include get-font($f14, $fw-l, $lightBlack, $lhs);
                                    max-height: 34px;
                                    overflow: hidden;
                                    margin: 0 0 3px 0;
                                }
                            }
                        }
                    }
                    &__close {
                        border: none;
                        outline: none;
                        padding: 10px 10px 10px 10px;
                        &__image {
                            height: 15px;
                            width: 15px;
                        }
                    }
                }
            }
            &__disabled {
                display: flex;
                align-items: center;
                justify-content: center;
                &__text {
                    @include get-font($f16, $fw-r, $lightBlack, $lhr);
                    margin-bottom: 0;
                    text-align: center;
                    color: $black;
                    font-weight: 500;
                }
            }
            &__textarea-container{
                //max-width: 676px;
                display: flex;
                width: 100%;
                margin-top: 8px;
            }
            &__textarea {
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 20px;
                padding: 10px 18px;
                margin-right: 25px;
                @include get-font($f16, $fw-r, $black, $lhr);
                width: 100%;
                outline: none;
                box-sizing: border-box;
                &::placeholder {
                    @include get-font($f16, $fw-r, $lighterBlack, $lhr);
                }
            }
            &__file-attachment {
                border: 0;
                padding: 10px;
                box-sizing: border-box;
                background: $white;
                margin-right: 10px;
                opacity: 0.6;
                cursor: pointer;
                outline: 0;
                &__icon {
                    width: 22px;
                    cursor: pointer;
                }
            }
            &__image-attachment {
                border: 0;
                padding: 10px;
                box-sizing: border-box;
                background: $white;
                margin-right: 10px;
                opacity: 0.6;
                cursor: pointer;
                outline: 0;
                &__icon{
                    width: 22px;
                    cursor: pointer;
                }
            }
            &__send-message {
                border: 0;
                box-sizing: border-box;
                background: $white;
                outline: 0;
                &__icon {
                    width: 40px;
                    height: auto;
                }
            }
        }

        .empty-chat-container {
            height: calc(100% - 58px);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &__image{
                width: 189px;
                margin-bottom: 17px;
            }
            &__text {
                @include get-font($f16, $fw-r, $lighterBlack, $lhr);
                margin-bottom: 0;
            }
        }
        .overlay-container {
            position: fixed;
            bottom: 0;
            width: 674px;
            //background: $fileOverlayGray;
            display: flex;

            &__text {

            }
            &__close {
                width: 20px;
                height: auto;
            }
            &__enlarged-image-container {
                height: calc(100% - 150px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            &__form {
                width: 100%;
                &--image {
                    width: 100%;
                    justify-content: space-between;
                    //height: calc(100vh - 58px);
                    height: calc(100vh - 120px);
                    justify-content: space-between;
                    display: flex;
                    flex-direction: column;
                }
            }

            &__text-container {
                border: 1px solid $superLightBlack;
                @include get-font($f14, $fw-r, $black, $lhs);
                padding: 10px 30px;
                box-sizing: border-box;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $white;
                &--image {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 10px 30px;
                    @include get-font($f14, $fw-r, $black, $lhs);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: $fileOverlayGray;
                }
            }
            &__image-container {
                height: calc(100% - 40px);
                //height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: $white;
                &__image {
                    height: 290px;
                    width: 290px;
                    border-radius: 10px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    object-fit: cover;
                }
                &__caption {
                    border: 1px solid $superLightBlack;
                    border-radius: 20px;
                    padding: 10px 18px;
                    @include get-font($f16, $fw-r, $black, $lhr);
                    width: 427px;
                    margin: 0px auto;
                    display: flex;
                    box-sizing: border-box;
                    outline: none;
                }
            }
            &__preview-container {
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $fileOverlayGray;
            }
            &__preview {
                width: 500px;
                overflow-x: scroll;
                height: 100%;
                align-items: center;
                display: flex;
                &__files-container {
                    display: flex;
                }
                &__file-container {
                    height: 75px;
                    width: 160px;
                    margin-left: 20px;
                    position: relative;
                    cursor: pointer;
                    text-align: center;
                    border-radius: 10px;
                    background-color: $superLightBlack;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &--image{
                        background: none!important;
                    }
                }
                &__overlay {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    &__close-container {
                        background-color: $mediumBlack;
                        border-radius: 50%;
                        height: 36px;
                        width: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &__icon {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
                &__file {
                    display: flex;
                    align-items: center;
                    position: relative;
                    margin-left: 20px;
                    &__icon {
                        width: 55px;
                        height: 55px;
                        transition: .2s ease;
                    }
                    &--image {
                        width: 75px;
                        height: 75px;
                        object-fit: cover;
                    }
                    &--type {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 86px;
                        height: 17px;
                        white-space: pre;
                        @include get-font($f14, $fw-r, $grayFileName, $lhs);
                    }
                }
            }
            &__actions {
                display: flex;
                margin-left: 20px;
                margin-right: 20px;
                &__add {
                    display: flex;
                    margin: 0px;
                    color: $mediumBlack;
                    font-size: 21px;
                    background: $superLightBlack;
                    height: 75px;
                    width: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    cursor: pointer;
                    &__icon {
                        &__image {
                            height: 35px;
                            width: 35px;
                            cursor: pointer;
                        }
                    }

                    &__input {
                        display: none;
                    }
                }
                &__send {
                    border: 0;
                    margin-left: 36px;
                    box-sizing: border-box;
                    background: none;
                    &__icon {
                        height: 50px;
                        width: 50px;
                    }
                }
            }
        }
    }
    &__reply {
        margin-bottom: 6px;
        background-color: $chatPink;
        box-sizing: border-box;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        max-height: 74px;
        &:after {
            content: ' ';
            width: 4px;
            height: 100%;
            background: $chatDarkPink;
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0px;
            border-radius: 10px 0 0 10px;
        }
        &__sender {
            @include get-font($f14, $fw-sb, $chatSender, $lhs);
            margin-bottom: 2px;
        }
        &__file-name {
            max-width: 390px;
            @include get-font($f14, $fw-r, $lighterBlack, $lhs);
        }
        &__message {
            @include get-font($f14, $fw-r, $lightBlack, $lhs);
            margin-bottom: 0;
            max-height: 34px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__image {
            display: flex;
            justify-content: space-between;
            &__details {
                padding: 10px;
            }
            &__icon {
                height: 57px;
                width: 57px;
                border-radius: 0 10px 10px 0;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
        &__attachment {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            &__icon-container {
                display: flex;
                justify-content: space-between;
                &__icon {
                    width: 33px;
                    height: 33px;
                    margin-left: 20px;
                }
            }
        }
        &__message-container {
            padding: 10px;
        }
    }
}

.create-group-modal {
    background : $white;
    width: 372px;
    height: auto;
    max-height: 635px;
    border-radius: 6px;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    outline: 0;
    .create-group-container {
        white-space: nowwrap;
        height: 50px;
        background: $red;
        color: $white;
        font-weight: bold;
        padding-top: 15px;
        padding-left: 10px;
        .close-modal {
                height: 20px;
                width: 20px;
                margin-left: 200px;
        }
    }
    .chat-modal-next-btn-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        height: 70px;
        border-top : 2px solid $gray;
        display: flex;
        align-items: center;
        justify-content: center;
        &.exit-group-container {
            border: 0;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px 20px;
            left: 0;
            .exit-button {
                border: 0;
                background: $white;
                align-items: center;
                outline: 0;
                cursor: pointer;
                .exit-button-icon {
                    width: 26px;
                    height: auto;
                    margin-right: 30px;
                }
                .exit-button-text {
                    @include get-font($f16, $fw-sb, $red);
                    //@include get-font(16px, 600, $red);
                }
            }
        }
        .chat-modal-next-btn {
            height: 44px;
            padding: 0 10px;
            border-radius: 4px;
            background: $black;
            border: 0;
            width: 100%;
            margin: 0 16px;
            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
            > span {
                @include get-font($f16, $fw-r, $white, $lhr);
            }
        }
        .next-button {
            background: $red;
            margin-left: 150px;
            color: $white;
            border-radius: 5px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

.chat-modal-header {
    height: 50px;
    background: $notificationRed;
    padding: 20px 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    box-sizing: border-box;
    .chat-modal-header-text {
        @include get-font($f16, $fw-sb, $white, $lhr);
        margin-bottom: 0;
    }
    .chat-modal-close-icon {
        width: 18px;
        height: auto;
    }
    
}

.chat-modal-list {
    height: 440px;
    padding-top: 16px;
    >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        cursor: pointer;
        &:hover{
            background-color: #f2f2f2;
        }
        >div {
            >p:first-child {
                @include get-font($f16, $fw-m, $lh24, $black)
            }
            >p:last-child{
                @include get-font($f14, $fw-r, $lhOnboarding, $copiedBackground)
            }
        }
    }
}

.chat-modal-content-container {
    height: 425px;
    overflow-y: auto;
    @include getDefaultScrollbar();
    margin-bottom: 70px;

    &.empty-modal-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .chat-list-container {
        margin-top: 0;
        height: unset;
    }
}

.create-group-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .group-info-name {
        @include get-font($f16, $fw-sb, $black, $lhr);
        margin-bottom: 8px;
        text-align: center;
    }
    .create-group-image-placeholder {
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .create-group-label {
            height: 100px;
            width: 100px;
            background-image: url('assets/images/chat/icons-chat-group-photo-placeholder@2x.png');
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            overflow: hidden;
            cursor: pointer;
            &.with-file {
                background-image: none;
            }
            .create-group-icon {
                width: 42px;
                height: auto;
            }
            .create-group-selected-item {
                width: 160px;
                height: 160px;
                border-radius: 160px;
                object-fit: cover;
            }
            &.group-info-label{
                background-image: none;
                >img {
                    width: 100px;
                    height: 100px;
                }
            }
        }
        .add-group-icon-text {
            @include get-font($f16, $fw-sb, $lightBlack);
            line-height: 20px;
            margin-bottom: 0;
        }
    }
    .create-group-name-input {
        border: 0;
        height: auto;
        width: 100%;
        max-width: 315px;
        border-bottom: 1px solid $lightBlack;
        @include get-font($f16, $fw-sb, $black);
        margin-bottom: 40px;
        border-radius: 0;
        outline: none;
        padding: 0.3em 0.2em 0.3em 0.3em;
        &::placeholder {
           color: $lightestBlack;
        }
    }

    #group-image-input {
        position: absolute;
        opacity: 0;
    }
}
.chat-modal__search{
    padding: 10px;
    background-color: $white;
    &-content{
        align-items: center;
        display: flex;
        background-color: $imageWrapperBackgroundGray;
        padding: 0 10px;
        border-radius: 8px;
    }
    &-input{
        padding: 10px 5px;
        flex: 1;
        border: none;
        outline: none;
        background-color: $imageWrapperBackgroundGray;
        &:focus,
        &:active{
            outline: none;
            border: none;
        }
    }
}
.chat-search-icon {
    margin-right: 7px;
}
.new-message-icon-container {
    position: fixed;
    bottom: 86px;
    margin-left: 600px;
    display: flex;
    align-items: center;
    &__number {
        display: inline-block;
        width: $newMessageHeight;
        height: $newMessageHeight;
        border-radius: $newMessageHeight;
        line-height: $newMessageHeight;
        @include get-font(12px, 400, $white);
        text-align: center;
        background: $red;
        position: absolute;
        z-index: 10;
    }
    &__icon {
        width: 40px;
        height: auto;
        margin-left: 6px;
    }
}
.empty-new-chat-left-list-container {
    margin-top: 60px;
    padding: 0 20px;
    text-align: center;
    &__header {
        @include get-font($f16, $fw-sb, $lighterBlack);
        margin-bottom: 10px;
    }
    &__text {
        @include get-font($f14, $fw-sb, $lighterBlack);
    }
}
.empty-modal{
    &-list{
        text-align: center;
        padding: 0 47px;

        &-header{
            @include get-font($f16, $fw-sb, $tastingHeadBlack);
            margin-bottom: 10px;
        }
        &-text{
            @include get-font($f14, $fw-sb, $tastingHeadBlack);
        }
        &-icon{
            width: 100%;
            height: auto;
        }
    }
}