@import 'create.scss';
@import 'list.scss';
@import 'details.scss';
@import 'invitationItem.scss';
@import 'details.scss';
@import 'invitationDetails.scss';
@import 'timer.scss';
.schedule{
    &-background{
        background-color: $orange;
        color: $white;
        border-color: $orange;
    }
    &__add-to-calendar-wrapper{
        width: 100%;
        &.details-view{
            width: unset;
        }
    }
    &__action {
        @include get-font($f14,$fw-sb,$white,$lh);
        background-color: $orange;
        padding: 8px 16px;
        border: 1px solid $orange;
        border-radius: 8px;
        height:37px;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        &.full-width {
             gap: 8px;
             flex: 1;
             text-align: center;
             justify-content: center;
             height: auto;
             padding: 8px 16px 8px 16px;
        }

        &.secondary{
            color:$orange;
            background-color: $white;
            border-color: $orange;

            &.modal-actions{
                border: none;
                color: $copiedBackground;
                &.padding-left {
                    padding-left: 4px;
                }
            }

            &.slider-action{
                &.dark{
                background-color: $imageWrapperBackgroundGray;
                border: 1px solid $imageWrapperBackgroundGray;
                color: $copiedBackground;
                }
            }
        }
        &.danger{
            background-color: $removeRed;
            border-color: $removeRed;

            &.secondary{
                color: $removeRed;
                background-color: $white;
                border-color: $removeRed;
            }
            &.slider-action{
                background-color: #ffecf0;
                color: $removeRed;
                border: transparent;
            }
            &.black{
                background-color: $black;
                border-color: $black;
            }
        }
        &.light {
            background-color: $white;
            color: $orange;
            border: 1px solid $orange;
        }
        &__group{
            display: flex;
            align-items: center;
            gap: 16px;
            min-width: max-content;
            overflow-x: auto;
        }
        &.no-background{
            background-color: transparent;
            color: $white;
            border: 1px solid $white;
            img {
                filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(2deg) brightness(103%) contrast(104%);
            }
        }
        &.disabled{
            background-color: $imageWrapperBackgroundGray !important;
            color: $lightGrey !important;
            border-color: $imageWrapperBackgroundGray !important;

            img{
                filter:brightness(0) saturate(100%) invert(89%) sepia(1%) saturate(0%) hue-rotate(156deg) brightness(94%) contrast(95%)
            }
        }
    }

    &__delete{
        .default-modal__container{
            top: 50%;
            transform: translate(-50%,-50%);
            padding-bottom: 16px
        }
        .default-modal__header__subtitle{
            @include get-font($f16,$fw-r,$black);
            margin-top: 8px;
        }
        .form-textarea{
            height: 125px;
        }
        footer{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            position: relative;
            padding-top: 16px;

            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: calc(100% + 48px);
                height: 1px;
                background-color: $imageWrapperBackgroundGray;
            }
        }
    }
    &__remove-invitee{
        .default-modal__container{
            top: 50%;
            transform: translate(-50%,-50%);
            padding-bottom: 16px
        }
        .default-modal__header__title{
            @include get-font($f18,$fw-sb,$black, $lh);
        }
        .default-modal__content{
            .text{
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: $black;
                .count {
                    font-weight: 600;
                }
            }
        }
        footer{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            position: relative;
            padding-top: 16px;

            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: calc(100% + 48px);
                height: 1px;
                background-color: $imageWrapperBackgroundGray;
            }
        }
    }
    &__timeline{
        border-radius: 8px;
        border: 1px solid #D1D1D180;
        box-shadow: 0px 2px 4px 0px #1717171A;
        &.__react_component_tooltip{
            max-height: 200px;
            overflow-y: auto;
            &.place-left:before{
                border-left: 6px solid #D1D1D180;
            }
        }
    }
    &__status{
        &.danger{
            @include get-font($f14,$fw-m,$removeRed,$lh);
            display: block;
            background-color: #F9DBD9;
            border-radius: 90px;
            padding: 4px 12px;
            width: fit-content;
            min-width: 48px;
        }
    }
}


  //responsive 
  @media screen and (max-width:$onlyMobile) {
    .schedule{
        &__add-to-calendar-wrapper{
            width: 100%;
            &.details-view{
                width: unset;
                flex:  1 1;
            }
        }
        &__delete{
            .default-modal__container{
                top: 30%;
                padding-bottom: 16px;
                width: auto;
                transform: unset;
            }
          
        }
        &__remove-invitee{
            .default-modal__container{
                top: 30%;
                padding-bottom: 16px;
                width: auto;
                transform: unset;
            }
        }
    }
  }