.pr-manage {
  &__age-proof {
    .review-page__suggestions__arrow {
      top: 50%;
    }
    .slick-dots {
      bottom: 0;
      > li {
        > button {
          &:before {
            font-size: 10px;
          }
        }
      }
    }
    &__action {
      text-align: center;
      margin-top: 25px;
      &__btn {
        @extend %appearance-none;
        padding: 0 12px;
        border: 0;
        border-radius: 7px;
        height: 32px;
        @include get-font($f14, $fw-sb, $black, $lh);
        background: $yellow;
        &--secondary {
          background: $grayBackground;
        }
        cursor: pointer;
        > img {
          display: inline-block;
          width: 12px;
          margin-right: 6px;
        }
        margin-right: 32px;
        &--disabled {
          opacity: 0.65;
          cursor: not-allowed;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__sidebar {
     width: 256px;
    flex-shrink: 0;
    &__content-container {
      background: $white;
      @include form-input-border-shadow();
      padding: 12px 0 12px;
      box-sizing: border-box;
      margin-bottom: 16px;
      border-radius: 10px;
      flex-shrink: 0;
    }
    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      .add-demograhy-btn{
        padding: 12px 16px;
        border-radius: 8px;
        outline: none;
        border: transparent;
        background-color: #DBE9F9;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: #4990E2 ;
        margin: 12px 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      &__item {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        @include get-font($f14, $fw-m, $black, $lh);
        @include transitionEase(#{background});
        word-break: break-word;
        text-decoration: none;
        &--report {
          align-items: flex-start;
          .pr-manage__sidebar__list__item__helper {
            @include get-font($f12, $fw-r, $lightBlack, $lh);
          }
        }
        &--inactive {
          pointer-events: none;
        }
        &--active {
          font-weight: $fw-sb;
          background: $grayBackground;
        }
        &:hover,
        &:focus {
          background: $grayBackground;
        }
        &--secondary {
          font-size: $f12;
          font-weight: $fw-sb;
          color: $lightBlack;
          cursor: default;
          &:hover,
          &:focus {
            background: $white;
          }
        }
        cursor: pointer;
        &__img {
          margin-right: 8px;
        }
      }
      &__box {
        width: 24px;
        height: 24px;
        border-radius: 5px;
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;
        flex-shrink: 0;
        &--helper {
          background: $lightestBlue;
          box-sizing: border-box;
          text-align: center;
          line-height: 24px;
        }
        &--applicants {
          background: $mediumBlack;
          @include get-font($f12, $fw-b, $white, 24px);
          text-align: center;
          min-width: 24px;
          width: auto;
          padding-left: 6px;
          padding-right: 6px;
          box-sizing: border-box;
        }
      }
    }
  }
  &__content {
    flex: 1;
    background: $white;
    @include form-input-border-shadow();
    border-radius: 10px;
    margin-left: 16px;
    padding: 24px 24px 76px;
    box-sizing: border-box;
    &--max-width {
      min-width: calc(100% - 270px);
    }
    &--padding{
      padding: 24px 24px 24px;
    }
    &__public {
      margin-left: 12%;
      width: 80%;
    }
    width: 100%;
    overflow: hidden;
    &__title {
      &__container {
        display: flex;
        align-items: center;
        text-decoration: none;
        width: fit-content;
        margin-bottom: 24px;
        > img {
          margin-right: 8px;
        }
        &:hover {
          text-decoration: underline;
          color: $blue;
        }
      }
      &__container--business {
        display: flex;
        align-items: center;
        text-decoration: none;
        width: fit-content;
        margin-bottom: 24px;
        > img {
          margin-right: 8px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      &__containerbutton {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-bottom: 24px;
        border: 0px;
        background: none;
        cursor: pointer;
        padding: 0px;
        > img {
          margin-right: 8px;
        }
      }
      text-decoration: none;
      @include get-font($f14, $fw-r, $blue, $lh);
    }
    &__header {
      padding-bottom: 16px;
      &__container {
        display: flex;
        align-items: center;
        &--with-action {
          justify-content: space-between;
          align-items: flex-end;
        }
      }
      &__productoverview {
        margin-top: 8px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1em;
        flex-wrap: wrap;
      }
      &__box {
        width: 36px;
        height: 36px;
        border-radius: 5px;
        margin-right: 12px;
      }
      &__content {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;
        .menu-option{
          font-size: 16px;
          color: #171717;
          font-weight: 700;
          line-height: 1.5;
        }
        .menu-option-div{
          background: transparent;
          border: none;
          outline: none;
          width: 32px;
          height: 32px;
          border-radius: 32px;
          background: #fff;
          cursor: pointer;
          rotate: 90deg;
          @include transitionEase(#{background});
        }
        .action__container{
          position: absolute;
          z-index: 21;
          margin-right: 12px;
          right: 0px;
          .demography-btn{
            background: transparent;
            border: none;
            outline: none;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #171717;
            padding: 0px;
            margin-top: 12px;
            cursor: pointer;
          }
          .edit-btn{
            height: 0px;
            padding: 0px !important;
            display: unset;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #171717;
            text-transform: none;
            box-shadow: none;
          }
          .list{
            margin: 0;
            list-style: none;
            padding: 8px 16px 8px 16px;
            width: 213px;
            background: #fff;
            border-radius: 10px;
            border-top-right-radius: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
            border: .5px solid rgba(0, 0, 0, .1);
            margin-top: 8px
          }
        }
      }
      &__title {
        @include get-font($f22, $fw-sb, $black, $lh);
      }
      &__statsblock {
        margin-top: 24px;
        margin-bottom: 24px;
        width: 100%;
      }
      &__stats {
        display: flex;

        @media screen and (max-width: $onlyMobile) {
          overflow-y: scroll;
          padding-bottom: 8px;
        }

        &__block {
          border-right: 1px solid $lightGrey;
          padding-left: 20px;
          padding-right: 20px;
          min-width: 96px;
          max-width: 126px;
          text-align: center;

          &:first-child {
            padding-left: 0px;
          }
          &:last-child {
            border-right: none;
          }

          @media screen and (max-width: $onlyMobile) {
            padding-left: 12px;
            padding-right: 12px;
          }

          &__title {
            @include get-font($f12, $fw-sb, $lightestGray, $lh);
            @media screen and (max-width: $onlyMobile) {
              @include get-font($f12, $fw-sb, $lightestGray, $lh);
            }
          }
          &__stat {
            margin-top: 4px;
            @include get-font($f18, $fw-b, $black, $lh);
            @media screen and (max-width: $onlyMobile) {
              @include get-font($f16, $fw-b, $black, $lh);
            }
          }
        }
      }
      &__helper {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-top: 8px;
        &--secondary {
          font-size: $f12;
          font-weight: $fw-b;
          letter-spacing: 0.6px;
          text-transform: uppercase;
          margin-top: 0;
        }
      }
    }
    .protocol{
      .dropdown-button{
        font-size: 36px;
        rotate: unset;
        line-height: 0;
      }
      .submenu--list{
        width: fit-content;
        .text-danger{
          color: $removeRed;
        }
        .black-icon{
          filter:brightness(0) saturate(100%);
        }
      }
      &__modal{
        .default-modal__container{
          @include centerItem();
        }
        &--content{
          padding-bottom: 8px;
        }
        &--footer{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 24px;

          .button-secondary{
            color: $blue;
            font-size: 14px;
            padding:8px 0;
          }
          .button-danger{
            @include get-font($f14,$fw-sb,$white);
            border-radius: 4px;
            padding:8px 12px;
            background-color: $removeRed;
          }
          &::before{
            content: "";
            display: block;
            height: 1px;
            width:calc(100% + 48px);
            background-color: $backgroundGray;
            position: absolute;
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    @media only screen and (max-width: $onlyMobile) {
      margin-left: 0px;
      width: 99%;
    }
  }
  &--button-div{
    display: flex;
    align-items: center;
    gap: 12px;
    .tooltip {
      position: relative;
      .__react_component_tooltip.show{
        width: max-content;
        position: absolute;
        left: 0px !important;
        top: -32px !important;
        margin-left: 24px;
      }
      .__react_component_tooltip.place-top:after{
          left: 4% !important;
      }
    }
  }
  &__outer--demography{
    padding: 12px;
    background-color: $white;
    border-radius: 8px;
    margin-bottom: 16px ;
    cursor: pointer;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    .pr-manage--chat-count-div{
      background-color: #CCECDA;
      padding: 8px;
      @include get-font($f14, $fw-m, $green, $lhOnboarding);
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;
      justify-content: center;
      margin-bottom: 0px;
      .demography-image {
      filter: brightness(0) saturate(100%) invert(35%) sepia(86%) saturate(2831%) hue-rotate(130deg) brightness(96%) contrast(101%);
    }
  }
  }
  &--chat{
    &-btn {
      background-color: $black !important;
      color: $white !important;
      border-color:$white !important;
    }
    &-count-div {
      background-color: white;
      border-radius: 8px;
      padding: 12px 16px;
      margin-bottom: 16px ;
      cursor: pointer;
      @include get-font($f14, $fw-m, $black, $lhOnboarding);
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      .__react_component_tooltip.show{
        width: max-content;
        position: absolute;
        left: 4px !important;
        top: -34px !important;
        margin-left: 24px;
      }
      .__react_component_tooltip.place-top:after{
          left: 8% !important;
      }
    }
    &-modal {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .chat-list{
        max-height: 400px;
        overflow: auto;
        overflow-x: hidden;
        @include getDefaultScrollbar();
        padding-right: 16px;
        width: 100%;
        .title {
          @include get-font($f12, $fw-b, $copiedBackground, 18px);
          text-transform: uppercase;
        }
        &--item {
          border-radius: 8px;
          background-color: #f2f2f2;
          padding: 12px 16px;
          margin: 8px 0px 12px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;
          gap: 12px;
          .left{
            display: flex;
            gap: 12px;
            .img{
              width: auto;
              height: 40px;
              aspect-ratio: 1 / 1;
              border-radius: 50%;
            }
            .chat-div{
              display: flex;
              flex-direction: column;
            }
            .chat-name{
              @include get-font($f14, $fw-m, $black, $lhOnboarding);
            }
            .participant-count{
              @include get-font($f12, $fw-m, $copiedBackground, 18px);

            }
          }
        }
      }
      &.width {
        width: 380px !important;
      }
      .send-message_modal {
        .message {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 24px 0px 8px 0px;
          .text{
            @include get-font($f14, $fw-m, $lhOnboarding, $copiedBackground);
          }
          .length{
            @include get-font($f14, $fw-r, $lhOnboarding, $copiedBackground);
          }
        }
        .user-edit__action__container{
          top: 22px;
        }
        .profile-view__details__image__container{
          position: unset;
          transform: none;
          width: 100px;
          height: 100px;
          display: flex;
          margin: auto;
          position: relative;
          .useravatar{
            >img {
              border: 1px solid #D1D1D1;
            }
          }
        }
        .profile-view__details__image{
          width: 100px;
          height: 100px;
        }
        .profile-view__details__image__placeholder{
          position: absolute;
          top: 66px;
          left: 39px;
          bottom: 0;
          right: 0;
          border-radius: 50%;
          .profile-edit-details{
            position: unset;
            cursor: pointer;
            .user-edit__action__container__list{
              padding: 0px;
              border-radius: 0px 8px 8px 8px;
            }
          }
        }
      
        >p:first-child {
          @include get-font($f14, $fw-m, $copiedBackground, $lhOnboarding);
        }
        .group-name {
          margin: 24px 0px;
          padding-bottom: 8px;
          border-bottom: 1px solid #D1D1D1;
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
          @include get-font($f16, $fw-m, $copiedBackground, $lhOnboarding);
          .input {
            border: none;
            outline: none;
            margin: 0px;
            padding: 0;
            flex: 1;
            margin-top: 2px;
            &::placeholder {
              @include get-font($f16, $fw-m, $lightGrey, $lhOnboarding);
          }
          }
        }
        .images {
          display: flex;
          margin: 20px auto;
          max-width: fit-content;
          margin-top: 34px;
          .round-image{
          border-radius: 50%;
          &:not(:first-child){
            margin-left: -17px;
            border: 2px solid white;
          }
        }
        .round-div{
          background-color: #4990E2;
          width: 41px;
          height: 41px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include get-font($f14, $fw-r, $white, $lhOnboarding);
          border: 2px solid white;
          position: relative;
          margin-left: -17px;
         }
        }
        .create-group{
          padding: 12px 16px;
          border-radius: 8px;
          outline: none;
          border: transparent;
          cursor: pointer;
          background-color: #EFB920;
          width: 100%;
          margin-top: 16px;
          @include get-font($f14, $fw-m, $black, $lhOnboarding);
          &.disabled {
            background-color: #D1D1D1;
            color: white;
            cursor: not-allowed;
            pointer-events: none;
          }
          &.survey{
            background-color: #171717;
            color: $white;
          }
        }
        .post-text{
          border-radius: 10px;
          resize: none;
          outline: none;
          height: 220px;
          box-sizing: border-box;
          width: 100%;
          background: #f4f4f4;
          border: none;
          padding: 16px;
          margin-bottom: 16px;
          @include get-font($f16, $fw-r, $black, $lh);
          @include getDefaultScrollbar(); 
        }
        .profile-list{
          padding: 16px;
          border-radius: 8px;
          background-color: white;
          border: 1px solid #EDEDED;
          margin-top: 16px;
          p {
          @include get-font($f14, $fw-m, $black, $lhOnboarding)
          }
        }
        .checkbox-container {
          display: flex;
          align-items: center;
          gap: 8px;
          > input {
            width: 16px;
            height: 16px;
            margin: 0px;
            border-color: #747474;
            border-radius: 4px;
          }
          >span {
            @include get-font($f14, $fw-r, $black, $lhOnboarding);
          }
        }
        .footer {
          padding: 13px 0 0px 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        .btn {
          background: #EFB920;
          padding: 8px 16px;
          border-radius: 8px;
          outline: none;
          border: transparent;
          @include get-font($f14, $fw-sb, $black, $lhOnboarding);
          &.disabled {
            background-color: #FCF1D2;
            color: #d1d1d1;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
        }
      }
    }
  }
  &__begin-modal,
  &__applicants-modal {
    &__text {
      @include get-font($f14, $fw-r, $black, $lh);
      margin: 16px 0;
    }
  }
  &__filters {
    padding: 24px 16px;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > p {
        @include get-font($f12, $fw-sb, $mediumBlack, $lh);
      }
      &__action {
        display: flex;
        align-items: center;
      }
      &__apply-btn {
        @extend %appearance-none;
        height: 30px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 5px;
        background: $lightestBlack;
        @include get-font($f14, $fw-m, $black);
        @include transitionEase(#{background});
        cursor: pointer;
        &:hover,
        &:active {
          background: $lighterBlack;
        }
        &--disabled {
          opacity: 0.65;
          cursor: not-allowed;
          &:hover,
          &:active {
            background: inherit;
          }
        }
      }
      &__clear-btn {
        @extend %appearance-none;
        margin-right: 12px;
        background: transparent;
        padding: 0;
        border: 0;
        @include get-font($f12, $fw-r, $removeRed);
        cursor: pointer;
      }
    }
    &__group {
      margin-top: 24px;
      &__header {
        text-transform: capitalize;
        @include get-font($f16, $fw-m, $black, $lh);
      }
      &__items {
        > div {
          display: flex;
          align-items: center;
          margin-top: 12px;
          cursor: pointer;
          > img {
            flex-shrink: 0;
            width: 18px;
            height: 18px;
            box-sizing: border-box;
            object-fit: contain;
            margin-right: 8px;
          }
          > p {
            @include get-font($f14, $fw-r, $black, $lh);
          }
        }
      }
      &--question {
        &--modal {
          &__container {
            padding: 0;
            .default-modal {
              &__header {
                align-items: flex-start;
                gap: 10px;
                padding: 16px;
                margin-bottom: 0;
                box-shadow: 0px 2px 4px rgba(23, 23, 23, 0.1);
              }
            }
            .custom-header {
              &__wrapper {
                display: flex;
                align-items: flex-start;
                gap: 12px;
              }
              .title {
                margin: 0;
                @include get-font($f18, $fw-b, $black, 27px);
              }
              p {
                @include get-font($f14, $fw-r, $copiedBackground, 21px);
              }
              &__close--btn {
                all: unset;
                cursor: pointer;

                img{
                  filter: brightness(0) saturate(100%);
                }
              }
            }
          }
        }

        &-action {
          all: unset;
          cursor: pointer;
          margin-top: 12px;
          @include get-font($f14, $fw-sb, $blue, 21px);

          &.filled {
            background-color: $black;
            padding: 8px 12px;
            color: $white;
            line-height: 21px;
            border-radius: 8px;
          }
        }

        &__content {
          .section--list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            max-height: 60vh;
            overflow-y: auto;
            @include getDefaultScrollbar();

            &__item {
              background-color: #f2f2f2;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 12px 24px 12px 16px;
              border-bottom: 1px solid rgba($color: $lightGrey, $alpha: 0.2);
              cursor: pointer;
              gap: 10px;

              .item {
                &-index {
                  @include get-font($f12, $fw-sb, $black);

                  .dot{
                    display: inline-block;
                    height: 3px;
                    width: 3px;
                    border-radius: 100%;
                    background-color: $black;
                    margin: 0 2px;
                  }
                }
                &-title {
                  @include get-font($f16, $fw-m, $black);
                }
              }
              &--action {
                all: unset;
                cursor: pointer;

                img{
                  filter: brightness(0) saturate(100%) invert(44%) sepia(0%) saturate(642%) hue-rotate(163deg) brightness(102%) contrast(87%);
                }
              }
            }
          }
          .question--list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            max-height: 60vh;
            overflow-y: auto;
            @include getDefaultScrollbar();

            &__title {
              text-transform: uppercase;
              @include get-font($f14, $fw-b, $copiedBackground);
              padding: 12px 24px 12px 16px;
            }

            &__item {
              display: flex;
              gap: 12px;
              padding: 12px 24px 12px 16px;
              border-bottom: 1px solid $imageWrapperBackgroundGray;
              &--left {
                cursor: pointer;
                margin-top: 4px;
                img{
                  height: 16px;
                  width: 16px;
                }
              }
              &--right {
                .title {
                  @include get-font($f16, $fw-m, $black);
                  .read-more-link{
                    display: unset;
                  }
                }
                .type {
                  @include get-font($f14, $fw-r, $black);
                  opacity: 0.6;
                  margin-top: 5px;
                }
              }
            }
          }
          .selected-questions--list {
            list-style-type: none;
            padding: 0;
            margin: 0;

            &__item {
              padding: 16px 24px 16px 16px;
              border-bottom: 1px solid #EDEDED;
              &--top {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 5px;
                .info {
                  .title {
                    @include get-font($f16, $fw-m, $black);
                    .read-more-link{
                      display: unset;
                    }
                  }
                  .type {
                    @include get-font($f14, $fw-r, $black);
                    opacity: 0.6;
                    margin-top: 5px;
                  }
                }
                .action {
                  all: unset;
                  cursor: pointer;
                  color: $removeRed;
                  height: 16px;
                  width: 16px;
                }
              }
            }
            .question--list__item {
              padding: 16px 24px 0 0;
              border: none;

              &--left {
                cursor: pointer;
                margin-top: 0;
              }
              &--right {
                .title {
                  @include get-font($f14, $fw-r, $black);
                }
              }
            }
          }
          .no-questions {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 0;
            text-align: center;
            img{
              margin-bottom: 25px;
            }
            &__text {
              text-align: center;
              @include get-font($f16, $fw-m, $copiedBackground, 24px);
            }
          }
          .add-more-questions {
            &__button {
              all: unset;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              padding: 16px;
              box-sizing: border-box;
              background-color: #CCECDA;
              cursor: pointer;

              span{
                @include get-font($f16,$fw-m,$green)
              }
            }
          }
        }
        &__footer {
          padding: 24px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 0px 4px rgba(23, 23, 23, 0.1);

          button {
            all: unset;
            padding: 8px 16px;
            cursor: pointer;
            border-radius: 8px;

            &.primary {
              background-color: $yellow;
              @include get-font($f14, $fw-m, $black, 21px);
            }
            &.danger {
              @include get-font($f14, $fw-m, $removeRed, 21px);
              padding: 0;
            }
            &.disabled {
              opacity: 0.2;
              pointer-events: none;
            }
          }
        }
      }
    }
    &__profile {
      &__group {
        margin-top: 24px;
        > p {
          @include get-font($f16, $fw-m, $black, $lh);
          margin-bottom: 8px;
        }
      }
      &__input {
        &__input {
          @include get-font($f14, $fw-r, $black, $lh);
        }
        &__placeholder {
          @include get-font($f14, $fw-r, $lighterBlack, $lh);
        }
        &__control {
          background: $grayBackground !important;
          border: 0 !important;
          border-radius: 10px !important;
          &--is-focused {
            box-shadow: none !important;
          }
        }
        &__dropdown-indicator {
          display: none !important;
        }
      }
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 8px;
        }
        &__info {
          display: flex;
          align-items: center;
          > p {
            @include get-font($f14, $fw-r, $black, $lh);
          }
        }
        &__icon {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          object-fit: cover;
          margin-right: 8px;
          flex-shrink: 0;
        }
        &__cancel {
          @extend %appearance-none;
          border: 0;
          padding: 0;
          background: $white;
          cursor: pointer;
          > img {
            width: 10px;
            height: auto;
            margin-left: 6px;
          }
        }
      }
    }
  }
  &__filters-pills {
    &__container {
      display: flex;
      align-items: flex-start;
      margin-top: 16px;
    }
    &__header {
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      flex-shrink: 0;
    }
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      &--yellow {
        > li {
          background: $lightestYellow !important;
        }
      }
      > li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        padding: 0 8px;
        background: $lightestYellow;
        margin: 0 0 8px 8px;
        border-radius: 5px;
        > p {
          text-transform: capitalize;
          @include get-font($f12, $fw-r, $black, $lh);
        }
        > img {
          width: 10px;
          height: 10px;
          object-fit: contain;
          margin-left: 6px;
          cursor: pointer;
        }
      }
    }
  }
  &__tool-tip {
    margin-left: 12px;
    &__hover {
      @include get-font($f14, $fw-r, $white, $lh);
      background: $mediumBlack;
      border-radius: 8px !important;
      padding: 16px !important;
      width: auto !important;
      max-width: 200px !important;
    }
    &__icon {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
    .react-tooltip-lite {
      background: $mediumBlack;
      @include get-font($f14, $fw-m, $white);
      border-radius: 4px;
      text-align: center;
      word-wrap: pre-line;
    }
    .react-tooltip-lite-arrow {
      background: transparent;
      border-top-color: $mediumBlack !important;
      border-bottom-color: $mediumBlack !important;
    }
  }
  &__invite {
    flex: 0.65;
    &__wrapper-conatiner {
      width: 330px;
      z-index: 5;
    }
    &__control {
      border: 0 !important;
      border-bottom: 1px solid $lightGrey !important;
      &--is-focused {
        box-shadow: none !important;
        border: 0 !important;
        border-bottom: 1px solid $superLightBlack !important;
      }
    }
    &__placeholder,
    &__input {
      @include get-font($f14, $fw-r, $lighterBlack, $lh);
    }
    &__input {
      color: $black;
    }
    &__indicator {
      display: none !important;
    }
  }
  &__empty {
    padding: 48px 0;
    text-align: center;
    > p {
      @include get-font($f14, $fw-sb, $lightBlack, $lh);
      margin-top: 24px;
    }
  }
  &__document-verify {
    &__image {
      max-width: 100%;
      width: auto;
      &__container {
        border-radius: 10px;
        border: 1px solid $grayBackground;
        .review-page__suggestions__arrow {
          top: 50%;
        }
      }
    }
    &__action {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__btn {
        @extend %appearance-none;
        height: 32px;
        border: 0;
        border-radius: 7px;
        background: $yellow;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        @include get-font($f14, $fw-sb, $black);
        cursor: pointer;
        @include transitionEase(#{background});
        &:hover,
        &:active,
        &:focus {
          background: $darkestYellow;
        }
        > img {
          margin-right: 4px;
          width: 12px;
          height: auto;
        }
        &--cancel {
          background: $grayBackground;
          &:hover,
          &:active,
          &:focus {
            background: $superLightBlack;
          }
        }
        margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__protocol{
    .collaboration__view__details__description{
      @include form-input-border-shadow();
      border-radius: 8px;
      padding: 16px;
    }
  }
}


@media only screen and (max-width: $onlyMobile) {
  .mobile-filter{
    display: none !important;
    &.active{
      display: block !important;
      &.show{
        display: block !important;
      }
    }
  }
  .pr-manage__outer--demography{
    margin-right: 8px;
  }

  .web-filter{
    display: none;
  }

  .pr-manage__sidebar{
    width: 100%;
    &__content-container{
      margin-right: 8px;
    }
  }

  .pr-manage__filters{
    padding: 24px  !important;
  }
  .pr-manage__invite__wrapper-conatiner{
    width: auto;
  }
  .pr-manage{
    &__protocol{
      .collaboration-form{
        padding: 0px !important;
      }
    }
    &--button-div{
      width: 100%;
      overflow-y: scroll;
      .tooltip, .pr-manage__btn {
        max-width: fit-content;
        min-width: fit-content
      }
    }
  }
}

@media only screen and (max-width: $smallMobile) {
  
  .pr-manage__content__header__content 
  {
    .action__container
    {
      .list{
        width: max-content;
      }
    }
  }
}

@media(max-width:819px){
  .survey__reports__section{
    margin-left: 0px !important;
  }
}
.mobile-filter{
  display: none;

  
}

.filterbtn{
  height: 30px;
  padding: 0 12px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: 0;
  border-radius: 5px;
  background: rgba(23, 23, 23, 0.2);
  font-size: 14px;
  color: #171717;
  font-weight: 500;
  cursor: pointer;
  margin-right: 20px;
}