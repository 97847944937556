.biz {
  &__modal {
    &__lead {
      .default-modal__header {
        margin-bottom: 12px;
      }
      .default-modal__footer {
        margin-top: 8px;
      }
      &__subtitle {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-bottom: 6px;
      }
      &__title {
        @include get-font($f18, $fw-b, $black, $lh);
      }
      &__helper {
        @include get-font($f12, $fw-b, $lightBlack, $lh);
        letter-spacing: 0.6px;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
      &__text {
        @include get-font($f14, $fw-r, $black, $lh);
        &--inline-text {
          display: flex;
          align-items: center;
          .biz__status-btn {
            margin: 0 0 0 16px;
          }
          > img {
            margin-left: 16px;
          }
        }
        &__container {
          margin-top: 24px;
        }
      }
    }
    &__status {
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 16px;
        &__item {
          padding: 12px 0;
          cursor: pointer;
          position: relative;
          @include transitionEase(#{background-color});
          &:hover {
            &:before {
              position: absolute;
              content: '';
              width: calc(100% + 48px);
              height: 100%;
              margin-left: -24px;
              background-color: $grayBackground;
              top: 0;
              left: 0;
              z-index: -1;
            }
          }
          &--active {
            &:hover {
              background-color: transparent;
            }
            &:after {
              position: absolute;
              content: '';
              width: calc(100% + 48px);
              height: 100%;
              margin-left: -24px;
              background-color: $grayBackground;
              top: 0;
              left: 0;
              z-index: -1;
            }
          }
          &--selected {
            .biz__status-btn {
              opacity: 0.4;
            }
            &:after {
              background-color: transparent;
            }
          }
          .form-input {
            background-color: $white;
            margin-top: 12px;
            position: relative;
            z-index: 1;
          }
        }
      }
      &__btn {
        &__container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .biz__status-btn {
            margin: 0;
          }
        }
      }
    }
    &__log {
      &__form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;
      }
      &__btn {
        @extend %appearance-none;
        @include get-font($f14, $fw-m, $black);
        height: 36px;
        background-color: $grayBackground;
        margin-left: 12px;
        padding: 8px 12px;
        border: 0;
        border-radius: 10px;
        @include transitionEase(#{background-color});
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          background-color: $lightestBlack;
        }
        &--disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
      &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        &__item {
          padding: 12px 0;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: $grayBackground;
            width: calc(100% + 48px);
            margin-left: -24px;
          }
        }
        &__helper {
          @include get-font($f12, $fw-m, $lightBlack, 1.75);
        }
        &__title {
          @include get-font($f14, $fw-sb, $black, $lh);
          margin-top: 2px;
          text-decoration: none;
        }
        &__secondary {
          @include get-font($f14, $fw-r, $black, $lh);
          margin-top: 2px;
        }
      }
    }
    &__create-product {
      @mixin __common {
        display: flex;
        cursor: pointer;
        @include get-font($f14, $fw-r, $black, $lh);
      }
      &__button {
        @include __common;
      }
      &__button__disabled {
        @include __common;
        cursor: auto;
      }
      &__questionnaire {
        &-list {
          overflow-y: auto;
          max-height: 490px;
        }

        &-div {
          @include __common;
          margin-bottom: 16px;

          &:active {
            border: solid 1px rgba(0, 0, 0, 0.1);
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
          }

          &-selected {
            @include __common;
            margin-bottom: 16px;
            color: $white;
            background-color: $teal;
            border: none !important;
          }
        }
      }

      @mixin __common-button {
        width: 16px;
        margin-top: 3px;
        margin-left: auto;
        margin-right: 16px;
      }

      &__button-arrow {
        @include __common-button;
      }
      &__button-tick {
        @include __common-button;
      }
      &__second-title {
        label {
          @include get-font($f18, $fw-b, $black, $lh);
        }
      }

      &__search-box {
        width: 100% !important;
        margin-top: 14px;
        margin-bottom: 24px;
      }

      &__search-clear {
        position: absolute;
        right: 40px;
        top: 138px;
        transform: translateY(-50%);
        cursor: pointer;
      }
      &__button-back {
        cursor: pointer;
        img {
          @include __common-button;
          margin-top: 0px !important;
          margin-right: 14px !important;
          vertical-align: middle;
        }
        span {
          @include get-font($f14, $fw-m, $black, $lh);
        }
      }

      &__avatar {
        &-selected {
          span {
            @include get-font($f12, $fw-b, $lightestGray);
            text-transform: uppercase;
          }
        }
        &-field-selected {
          display: flex;
          margin-top: 37px;
          &-text {
            margin-left: auto;
            margin-right: 50px;
          }
        }

        &-list {
          margin-top: 16px;
          overflow-y: auto;
          max-height: 490px;
        }

        @mixin checkbox-div {
          @include __common;
          line-height: 1.5;
          border-bottom: solid 1px $superLightBlack;
          outline: none;
          width: 100%;
          box-sizing: border-box;
        }
        &-div {
          @include checkbox-div;
          padding: 24px 16px;
        }

        &-field-div {
          @include checkbox-div;
          padding: 24px 0px !important;
        }

        &-name {
          @include get-font($f14, $fw-m, $black);
          margin-top: 10px;
        }
        &-field-name {
          margin-top: 0px !important;
        }
      }
      &__assignee-checkbox {
        margin-right: 12px;
      }
      &__mandatory-field-checkbox {
        margin-left: auto;
        margin-right: 80px;
      }
    }
    &__createbatch {
      &__input {
        width: 210px !important;
      }
      &__field__div {
        height: 36px !important;
      }
    }
    &__batch__edit__description {
      @include get-font($f14, $fw-r, $black, $lh);
      margin-bottom: 24px;
    }
    &__batch__delete {
      &__title {
        @include get-font($f18, $fw-sb, $removeRed, $lh);
      }
    }

    &__batchdata {
      margin-top: -12px;
      &__infotext {
        @include get-font($f14, $fw-r, $lightestGray, $lh);
      }
      &__table {
        margin-top: 24px;
        margin-bottom: 32px;

        &__padding__bottom {
          padding-bottom: 19px;
        }
        &__padding__left {
          padding-left: 12px;
        }
        &__fieldtitle {
          @include get-font($f14, $fw-m, $lightestGray, $lh);
        }
        &__fieldvalue {
          @include get-font($f16, $fw-m, $black, $lh);
        }
      }
    }

    &__triodata {
      &__modalwidth {
        .default-modal__container {
          width: 400px;
        }
      }
      &__triocode {
        margin-top: 24px;
      }
      &__triolink {
        margin-top: 32px;
      }
      &__trioqr {
        margin-top: 32px;
        margin-bottom: 40px;
      }
    }
  }
}
