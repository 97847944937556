.login-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 24;
  &_forgot-form{
    .type-div{
      background: #4990E2;
      padding: 4px;
      border-radius: 22px;
      display: flex;
      width: 134px;
      cursor: pointer;
      margin-bottom: 12px;
      .selected-text{
        padding: 0px 6px 0px 6px;
        border-radius: 15px;
        background: white;
        text-align: center;
        @include get-font($f14, $fw-m,$black, $lh24);
        width: 43%;
        &.not-selected{
          text-align: center;
          background: transparent;
          color: white;
        }
      }
     
    }
    .login-form__container{
      width: 511px;
      padding: 24px !important;
      border-radius: 12px;
      .login-form__title{
        @include get-font($f18, $fw-sb,$black, $lh);
      }
    }
   
      .login-form__form__input--email{
        &::placeholder {
          color: $lightGrey;
      }
      }
    
    .label-text{
      margin-bottom: 12px;
      @include get-font($f16, $fw-r,$copiedBackground, $lh24);

    }
    .info-text{
      margin: 16px 0px;
      @include get-font($f14, $fw-r,rgba(25, 25, 25, 0.6), $lhOnboarding);
      text-align: center;
    }
    .login-form__action__btn{
      margin-top: 24px;
      font-size: 16px !important;
      &.disabled{
        background-color: #747474;
      }
    }
    .loginphone__phone{
      &::-webkit-inner-spin-button{
        display: none;
      }
    }
    .login-form__title{
      text-align: left;
    }
    .forgot-otp-form{
      .login-form__container{
        div{
          padding: 0px !important;
        }
        .login-form__otp-form--confirm_title{
          @include get-font($f16, $fw-r,$copiedBackground, $lh24);
          padding: 0px;
          text-align: left;
          margin-bottom: 16px;
        }
        .login-form__otp-form--continue_button{
          margin-top: 20px;
        }
        .login-form__otp-form--time{
          margin-top: 19px;
        }
        .login-form__form__group{
          .error-text{
            text-align: center;
            position: unset
          }
        }
      }
    }
    .forgot-password-form{
      .label{
        @include get-font($f16, $fw-sb, rgba(25, 25, 25, 0.6), $lh24);
        margin-bottom: 8px;
        display: block;
    }
    .info-text{
        @include get-font($f12, $fw-r, rgba(25, 25, 25, 0.4), 16px);
        margin-bottom: 8px;
        display: block;
        text-align: center
    }
    .button{
       padding: 16px;
       border: transparent;
       border-radius: 100px;
       @include get-font($f16, $fw-sb, $white, $lh24);
       background-color: black;
       cursor: pointer;
        width: 100%;
        margin-top: 24px;
        &.disabled{
          background-color: #747474; 
      }
    }
    .confirm-list{
      padding: 0px 24px;
      .item{
          @include get-font($f14, $fw-r, rgba(25, 25, 25, 0.6), 16.8px);
          &.red {
              color: $red
          }
      }
  }
  .login-form__form__input--password{
    &.red{
      border-color: $red;
    }
  }
  .login-form__container{
    .hr{
      color: #EDEDED;
      margin-top: 24px;
    }
    .login-form__header{
      margin-bottom: 12px
    }
    .error-text{
      // text-align: center;
      position: unset;
      margin: 0px;
      margin-top: 10px;
      margin-bottom: 10px
    }
  }
 
    }
    .success-form{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .success-gif-text1{
        margin-bottom: 24px;
        @include get-font($f18, $fw-sb,#001223, $lh);
      }
      .success-gif-text2{
        text-align: center;
        @include get-font($f12, $fw-r,rgba(25, 25, 25, 0.4), 16px);
        width: 50%;
        margin: auto;
      }
    }
  }
  &__otp-form {
    &--confirm_title {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: rgba(23,23,23,0.6);
      text-align: center;
      padding: 0px 12px;
      .black{
        color: rgba(23,23,23,1);
      }
    }
    &--time {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: rgba(23,23,23,1);
      margin-top: 32px;
      text-align: center
    }
    &--resend_button {
      border: none;
      background-color: white;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(73, 144, 226, 1);
      cursor: pointer;
    }
    &--continue_button {
      width: 100%;
      border: none;
      padding: 14px;
      border-radius: 26px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-top: 32px;
      cursor: pointer;
      color: white;
      background-color: $black;
      &.disabled{
        background-color: rgba(23,23,23,0.3);
      }
    }
    &--update_email {
      text-align: center;
      color: black;
      text-decoration: none;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      cursor: pointer;
      border-bottom: 1px solid black;
    }
    &--update_email_div {
      text-align: center;
      margin-top: 28px;
    }
  }
  &--without-background {
    .login-form__backdrop {
      background: none;
    }
  }
  &__backdrop {
    background: rgba($white, 0.95);
    width: 100%;
    height: 100%;
    @media only screen and (max-width: $onlyMobile) {
      background: transparent;
    }
  }
  &__container {
    width: 510px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0 3px 14px 0 rgba($black, 0.14);
    background-color: $white;
    padding: 48px 80px;
    box-sizing: border-box;
    @media only screen and (max-height: 570px) {
      max-height: 100vh;
      overflow: hidden;
      overflow-y: auto;
    }
    @media only screen and (max-width: $onlyMobile) {
      width: 100%;
      height: 100%;
      padding: 48px 24px 24px;
      transform: none;
      top: 0;
      left: 0;
      background-color: rgba($white, 0.975);
    }
    .hr{
      color: #EDEDED;
      margin-top: 24px;
    }
  }
  &__title {
    text-align: center;
    @include get-font($f22, $fw-sb, $black, $lh);
    margin-bottom: 24px;
    @media only screen and (max-width: $onlyMobile) {
      font-size: $f18;
    }
  }
  &__action {
    &__btn {
      @extend %appearance-none;
      height: 52px;
      border-radius: 26px;
      box-shadow: 0 2px 4px 0 $superLightBlack;
      margin: 0;
      margin-bottom: 16px;
      padding: 0;
      width: 100%;
      display: block;
      box-sizing: border-box;
      border: 0;
      border: 1px solid $grayBackground;
      background-color: $white;
      background-repeat: no-repeat;
      background-position: 24px;
      @include get-font($f18, $fw-sb, $black, $lh);
      cursor: pointer;
      &--facebook {
        background-image: url('assets/images/login/left-icon-24-px.svg');
        background-color: $fbColor;
        color: $white !important;
      }
      &--linkedin {
        background-image: url('assets/images/login/left-icon-24-px-copy.svg');
        background-color: $linkedInColor;
        color: $white !important;
      }
      &--google {
        background-image: url('assets/images/login/google.svg');
      }
      &--email {
        background-image: url('assets/images/login/email-envelope.svg');
      }
      &--default {
        background-color: $black;
        color: $white;
        margin-bottom: 16px !important;

        &.live{
          background-color: $teal;

          &:disabled{
            background-color: $superLightTeal;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &--disabled {
        opacity: 0.6;
        cursor: wait;
        pointer-events: none;
        &:hover,
        &:focus,
        &:active {
          opacity: 0.6;
        }
      }
      &:disabled {
        opacity: 0.6;
        cursor: wait;
        pointer-events: none;
        &:hover,
        &:focus,
        &:active {
          opacity: 0.6;
        }
      }
      @media only screen and (max-width: $onlyMobile) {
        font-size: $f16;
        margin-bottom: 12px;
      }
    }
  }
  &__divider {
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &__line {
      height: 1px;
      width: 100%;
      background: $superLightBlack;
    }
    &__text {
      @include get-font($f16, $fw-r, $lightBlack, $lh);
      margin: 0 16px;
    }
  }
  &__link {
    text-align: center;
    @include get-font($f16, $fw-r, $black, $lh);
    text-decoration: none;
    > a,
    button {
      margin-left: 6px;
    }
    &--btn {
      @extend %appearance-none;
      border: 0;
      background: transparent;
      padding: 0;
      cursor: pointer;
      margin: 0;
    }
    &--blue {
      color: $blue;
      @include transitionEase(#{color});
      font-weight: $fw-sb;
      &:hover,
      &:active,
      &:focus {
        color: $darkBlue;
      }
    }
    &--full-width {
      display: block;
      margin-bottom: 12px;
    }
    margin-bottom: 24px;
  }
  &__helper-link {
    text-align: center;
    @include get-font($f14, $fw-r, $lightBlack, $lh);
    text-decoration: none;
    &--blue {
      color: $blue;
      @include transitionEase(#{color});
      &:hover,
      &:active,
      &:focus {
        color: $darkBlue;
      }
    }
  }
  &__header {
    margin-bottom: 24px;
    position: relative;
    &__icon {
      position: absolute;
      left: 0;
      top:50%;
      transform: translateY(-50%);
    }
    .login-form__title {
      margin-bottom: 0;
      text-align: center;
      width: 100%;
    }
  }
  &__form {
    &__input {
      @extend %appearance-none;
      height: 52px;
      border-radius: 26px;
      border: 1px solid $superLightBlack;
      width: 100%;
      box-sizing: border-box;
      padding: 16px 16px 16px 50px;
      @include get-font($f16, $fw-m, $black);
      background-repeat: no-repeat;
      background-position: 16px;
      background-size: 22px;
      background-color: $white;
      &--name {
        background-image: url('assets/images/login/name.svg');
      }
      &--email {
        background-image: url('assets/images/login/email.svg');
      }
      &--password {
        background-image: url('assets/images/login/password.svg');
        padding-right: 48px;
          &.red {
           border-color: $red
        }
      }
      &:focus {
        opacity: 1;
        border: 1px solid $black;
        &.red {
          border-color: $red
       }
      }
    }
    &__input1 {
      @extend %appearance-none;
      height: 52px;
      border-radius: 26px;
      border: 1px solid $superLightBlack;
      width: 100%;
      box-sizing: border-box;
      padding: 16px;
      @include get-font($f16, $fw-m, $black);
      background-repeat: no-repeat;
      background-position: 16px;
      background-size: 22px;
      background-color: $white;
      text-align: center;
      color: black;
      &:focus {
        opacity: 1;
        border: 1px solid $black;
      }
      &::placeholder {
        opacity: 0.4;
        text-align: center;
      }
      &::-webkit-inner-spin-button {
        display: none;
      }
      &.red{
        border-color: $red;
      }
    }
    &__helper-btn {
      position: absolute;
      right: 0;
      border: 0;
      background: transparent;
      @extend %appearance-none;
      top: 16px;
      right: 16px;
    }
    &__group {
      margin-bottom: 30px;
      position: relative;
      .error-text {
        position: absolute;
        margin-top: 6px;
      }
      &.otp-screen{
        .error-text{
          text-align: center;
        position: unset;
        margin: 0px;
        margin-top: 10px
        }  
      }
    }
    .error-text{
      position: unset;
       margin-bottom: 0px 
    }
    .hr{
      color: #EDEDED;
      margin-top: 24px;
    }
  }
  &__cancel {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 72px;
    margin-top: 52px;
    text-decoration: none;
    display: block;
    @extend %appearance-none;
    border: 0;
    background: transparent;
    cursor: pointer;
    @media only screen and (max-width: $onlyMobile) {
      margin-right: 24px;
      margin-top: 24px;
      z-index: 10;
      img {
        width: 16px;
      }
    }
  }  
}

.loginphone {
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    margin-bottom: 4px;
    border-radius: 26px;

    &__noerror {
      border: solid 1px $superLightBlack;
      &:active,
      &:focus,
      &:focus-within,
      &:target,
      &:hover,
      &:visited,
      &:focus-visible {
        border: solid 1px rgba(0, 0, 0, 0.8);
      }
    }

    &__error {
      border: solid 1px $removeRed;
    }
  }
  &__errors {
    text-align: center;
    margin-bottom: 8px;
    min-height: 18px;
  }

  &__countrycode {
    border: 0px;
    border-radius: 26px;
    height: 52px;
    width: 72px;
    background: $white;
    text-indent: 16px;
    border-right: 0;
    @include get-font($f16, $fw-m, $black, $lh);

    &:active,
    &:focus,
    &:visited,
    &:focus-visible {
      border: 0px;
      outline: 0px;
    }
  }
  &__partition {
    border: 1px solid $superLightBlack;
    height: 30px;
    margin: 10px 4px;
  }
  &__phone {
    border: 0px;
    border-radius: 0px 26px 26px 0px;
    outline: none;
    width: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: $white;
    height: 52px;
    @include get-font($f16, $fw-m, $black, $lh);

    &::-webkit-inner-spin-button{
      display: none;
    }
  }
}

.otp-form {
  &__header {
    margin-bottom: 8px;
    text-align: center;
    position: relative;

    &__title {
      text-align: center;
      @include get-font($f22, $fw-sb, $black, $lh);
      margin-bottom: 0;
      width: 100%;
      @media only screen and (max-width: $onlyMobile) {
        font-size: $f18;
      }
    }
  }
  &__subtitle {
    text-align: center;
    @include get-font($f14, $fw-r, $lightestGray, $lh);
    width: 100%;
    margin-bottom: 16px;
  }
  &__updatecontact {
    @include get-font($f14, $fw-m, $black, $lh);
    text-align: center;
    margin-top: 32px;
    text-decoration: underline;
  }
  &__otp {
    height: 52px;
    border-radius: 26px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 16px 16px 16px;
    @include get-font($f16, $fw-m, $black, $lh);
    text-align: center;
    border: 0px;
    outline: 0px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    -moz-appearance: textfield;
  }
  &__errors {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 4px;
    min-height: 18px;
  }
  &__resend {
    &__countdown {
      text-align: center;
      width: 100%;
      margin-bottom: 26px;
      min-height: 24px;
      @include get-font($f16, $fw-r, $black, $lh);
    }

    &__button-div {
      text-align: center;
      width: 100%;
      margin-bottom: 40px;
      &__text {
        @include get-font($f14, $fw-r, $black, $lh);
      }
      &__button {
        margin-left: 8px;
        background: none;
        border: none;
        cursor: pointer;
        @include get-font($f16, $fw-r, $blue, $lh);
        &:disabled {
          @include get-font($f16, $fw-r, $grayText, $lh);
          opacity: 0.65;
          cursor: not-allowed !important;
        }
      }
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .login-form{
    &_forgot-form{
      .login-form{
        &__backdrop{
          .login-form__container{
            width: 100%;
          }
        }
      }
    }
    &__form{
      &__group{
          &.otp-screen{
             padding: 12px !important;
          }
      }
    }
  }
}


