.skeleton {
  @keyframes loading {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
  }
  &.loading-animation {
    animation: loading 1.5s infinite;
  }

  &__cover-background {
    height: 400px;
    background-color: $grayBackground;
  }
  &__content {
    background-color: white;
    width: 760px;
    border-radius: 10px;
    box-shadow: 0 6px 10px 3px rgba(0, 0, 0, 0.1);
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    height: 364px;
    padding: 25px;
    position: relative;
    margin: -85px auto 48px;
    .avtar {
      width: 128px;
      height: 128px;
      position: absolute;
      transform: translateY(-75%);
      border-radius: 50%;
      background: #ededed;
      border: 8px solid #fff;
    }
    .loader-strip {
      border-radius: 8px;
      margin-bottom: 20px;
      height: 20px;
      background-color: $grayBackground;
      margin-top: 32px;
    }
  }

  &__explore-loader {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .search {
      height: 54px;
      background-color: $grayBackground;
      border-radius: 32px;
      box-sizing: border-box;
    }
    .skeleton-list{
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        .item {
          display: flex;
          flex-direction: column;
          flex: 1;
          gap: 20px;
          div{
            border-radius: 12px;
            background-color: $grayBackground;
          }
      }
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .skeleton {
    &__content {
      width: auto;
    }
    &__explore-loader {
      .skeleton-list{
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
@media only screen and (max-width: $smallMobile) {
  .skeleton {
    &__explore-loader {
      .skeleton-list{
        grid-template-columns: 1;
      }
    }
  }
}
