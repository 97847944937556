.flag-content {
    &__modal {
        .default-modal__header {
            margin-bottom: 16px;
        }
        .default-modal__container {
            max-width: 442px;
        }
        .biz__modal__done-btn {
            background: $black;
        }
        &--centered {
            .default-modal__container {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &__title {
        @include get-font($f14, $fw-r, $black, $lh);
        margin-left: 1px;
        &--secondary {
            color: $lightBlack;
        }
    }
    &__list {
        margin: 24px 0 0;
        padding: 0;
        list-style: none;
        padding: 0 0px;
        &__item {
            display: flex;
            margin-top: 14px;
            cursor: pointer;
        }
        &__img {
            width: 20px;
            height: 20px;
        }
        &__title {
            margin-left: 10px;
            padding-bottom: 12px;
            flex: 1;
            border-bottom: 1px solid $grayBackground;
        }
    }
    &__textarea {
        width: 100%;
        box-sizing: border-box;
        resize: none;
        @extend %appearance-none;
        border-radius: 10px;
        background: $grayBackground;
        height: 72px;
        margin-top: 24px;
        border: 0;
        padding: 12px 16px;
        @include get-font($f16, $fw-r, $black, $lh);
    }
    &__helper {
        margin: 16px 1px;
        @include get-font($f14, $fw-r, $black, $lh);
        > a {
            color: $blue;
            text-decoration: none;
        }
    }
}

@media only screen and (max-width: $onlyMobile) {
    .flag-content {
        &__list {
            margin: 16px 0 0;
            padding: 0 16px;
        }
        &__modal {
            .default-modal__container {
                top: 8%;
                left: 0;
                transform: none;
                max-width: 100% !important;
            }
            &--centered {
                .default-modal__container {
                    top: 0;
                    left: 0;
                    transform: none;
                }
            }
        }
    }
}