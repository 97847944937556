$imageWidth: 170px;
$editableImageWidth: 160px;
.create-image {
    width: $imageWidth;
    height: $imageWidth;
    border-radius: 5px;
    margin: 4px;
    position: relative;
    &__container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;
        &--disabled {
            opacity: 0.4;
            overflow: hidden;
            .create-image {
                width: $editableImageWidth;
                height: $editableImageWidth;
                &__image {
                    width: $editableImageWidth;
                    height: $editableImageWidth;
                }
            }
        }
    }
    &__image {
        width: $imageWidth;
        height: $imageWidth;
        border-radius: 5px;
        object-fit: contain;
        border: solid 1px $superLightBlack;
        &--placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $grayBackground;
            @include get-font($f12, $fw-sb, $lightBlack, $lh);
        }
    }
    &__image-remove {
        @extend %appearance-none;
        width: 24px;
        height: 24px;
        border-radius: 14px;
        background-color: $lightBlack;
        border: 0;
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &__icon {
            width: 12px;
        }
    }
}