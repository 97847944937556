.certification {
  &__big-list {
    padding: 0;
    margin: 0;
    list-style: none;

    .collaboration-item__action__link__text--green {
      color: $black !important;
    }

    &__header {
      text-decoration: none;

      &__helper {
        @include get-font($f12, $fw-b, $lightBlack, $lh);
        letter-spacing: 0.6px;
        margin-bottom: 8px;
      }

      &__status {
        width: fit-content;
        margin-bottom: 8px;
        @include get-font($f14, $fw-b, $white, $lh);
        padding: 4px 12px;
        border-radius: 4px;
        background-color: $copiedBackground;

        &__container {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
        }

        &.closed {
          background-color: $black;
        }

        &.expired {
          background-color: $red;
        }
      }
    }

    &__item {
      background: $white;
      @include form-input-border-shadow();
      border-radius: 10px;
      margin-top: 24px;
      padding: 24px;
      box-sizing: border-box;

      &--disabled {
        opacity: 0.65;
      }

      @media screen and (max-width: $onlyMobile) {
        &.quiz-list {
          padding: 12px;
        }
      }


      .certification_info {
        margin-top: 16px;

        &__container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          flex-wrap: wrap;
          gap: 4px;

          .container-item {
            color: #747474;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 8px;
            min-width: fit-content;

            >img {
              height: auto;
              width: auto;
            }

            span {
              &:last-child {
                .symbol-text {

                  display: none
                }
              }
            }
          }
        }
      }
    }

    &__title {
      @include get-font($f18, $fw-sb, $black, $lh);
      word-break: break-word;
      margin-bottom: 8px;
    }

    &__description {
      @include get-font($f16, $fw-r, $lightBlack, $lh);
      margin-bottom: 16px;
      word-break: break-word;
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .user-block {
        .user-block__details {
          align-items: center;
        }
      }

      &__helper {
        @include get-font($f16, $fw-sb, $lighterBlack, $lh);
      }

      &__state {
        @include get-font($f12, $fw-r, $red, $lh);
        letter-spacing: 0.6px;
        flex-shrink: 0;
      }

      .test-taker {
        border-radius: 8px;
        background: #F2F2F2;
        color: #171717;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        padding: 8px 16px;
        min-width: fit-content;
      }
    }

    &__media {
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      &__thumb {
        width: 64px;
        height: 64px;
        border: 1px solid $superLightBlack;
        border-radius: 10px;
        margin-right: 8px;
        object-fit: cover;
        object-position: center;
      }

      &__playIcon {
        width: 26%;
        top: 34%;
        left: 34%;
      }
    }
  }

  &__small-list {
    width: 100%;
    max-width: 580px;
    background: $white;
    @include form-input-border-shadow();
  }

  &__similar {
    &__container {
      margin-top: 24px;
    }

    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &__title {
        @include get-font($f18, $fw-b, $black, $lh);
      }

      &__helper {
        @include get-font($f16, $fw-m, $lightBlack, $lh);
        margin-top: 9px;
      }

      &__link {
        >img {
          transform: rotate(180deg);
        }
      }
    }
  }
}


@media only screen and (max-width: $smallMobile) {
  .quiz__big-list__action {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }
}