.quiz {
  &__flexbox {
    &--displayFlex {
      display: flex;
    }
    &--flexDirectionRow {
      flex-direction: row;
    }
    &--flexDirectionColumn {
      flex-direction: column;
    }
    &--justifyContentCenter {
      justify-content: center;
    }
    &--justifyContentSpaceBetween {
      justify-content: space-between;
    }
    &--alignItemsCenter {
      align-items: center;
    }
    &--alignItemsFlexStart {
      align-items: flex-start;
    }
    &--alignItemsFlexEnd {
      align-items: flex-end;
    }
    &--alignItemsStretch {
      align-items: stretch;
    }
    &--alignItemsBaseline {
      align-items: baseline;
    }
    &--alignContentCenter {
      align-content: center;
    }
    &--alignContentFlexStart {
      align-content: flex-start;
    }
    &--alignContentFlexEnd {
      align-content: flex-end;
    }
    &--alignContentStretch {
      align-content: stretch;
    }
    &--alignContentSpaceBetween {
      align-content: space-between;
    }
    &--alignContentSpaceAround {
      align-content: space-around;
    }
    &--alignContentSpaceEvenly {
      align-content: space-evenly;
    }
    &--flexWrap {
      flex-wrap: wrap;
    }
    &--flexWrapReverse {
      flex-wrap: wrap-reverse;
    }
    &--flexWrapNoWrap {
      flex-wrap: nowrap;
    }
    &--flexGrow {
      flex-grow: 1;
    }
    &--flexShrink {
      flex-shrink: 1;
    }
    &--flexBasis {
      flex-basis: 0;
    }
    &--flex {
      flex: 1;
    }
    &--flexAuto {
      flex: auto;
    }
    &--flexInitial {
      flex: initial;
    }
    &--flexNone {
      flex: none;
    }
    &--spaceBetween {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--centerItems {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__modalPaddingAdjust {
    padding: 24px 10px 14px 24px !important;
  }
  &__closeQuizBtn {
    border-radius: 8px;
    color: $white;
    background: $removeRed;
    width: 77px;
    height: 40px;
  }
  &__form {
    max-width: $centeredContainer;
    margin: 0 auto;
    width: 100%;
    .react-player__video{
      margin-top: unset;
      }
    &__textarea-title {
      width: 100%;
      padding: 12px 16px;
      border-radius: 10px;
      border: solid 0.5px rgba(0, 0, 0, 0.1);
      background-color: $white;
      &::placeholder {
        opacity: 1;
        @include get-font($f16, $fw-r, $lightGrey, $f24);
        color: #757575;
      }
      resize: none !important;
      @include get-font($f16, $fw-r, $black, $lh);
      @extend %border-box-shadow;
      @include getDefaultScrollbar();
      outline: none;
      box-sizing: border-box;
      max-height: 168px;
      &::-webkit-scrollbar {
          width: 0;
      }
      &:focus, &:active {
          outline: 1px solid $mediumBlack;
      @include getDefaultScrollbar();
      }
  }
    &__label {
      cursor: pointer;
      text-align: center;
      @media only screen and (max-width: $onlyMobile) {
        text-align: initial;
      }
      > input {
        position: absolute;
        opacity: 0;
        width: 0;
      }
      > p {
        text-align: center;
      }
      &__text {
        @include get-font($f14, $fw-r, $lighterBlack, $lh);
        margin-top: 6px;
      }
      &__helper {
        @include get-font($f12, $fw-r, $lighterBlack, $lh);
      }
    }
    &__copied {
      @include get-font($f12, $fw-r, $white, $lhxs);
      background-color: $copiedBackground;
      padding: 6px 12px;
      margin-right: 10px;
      border-radius: 4px;
      height: fit-content;
      animation: hideAnimation 0s ease-in 5s;
      animation-fill-mode: forwards;
    }
    @keyframes hideAnimation {
      to {
        visibility: hidden;
        width: 0;
        height: 0;
      }
    }
    &__details {
      background: $white;
      padding: 24px;
      box-sizing: border-box;
      @include form-input-border-shadow();
      margin-top: 24px;
      border: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
   
      .collaboration-form__input {
        box-shadow: none;
      } 
      &__title_subtext {
        margin-top: 10px;
      }
      &__red-astrick {
        color: $removeRed;
      }
      &__detailText {
        @include get-font($f14, $fw-m, $copiedBackground, $lhOnboarding);
        // margin-bottom:10px;
      }
      &__action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        border-top: 1px solid #ededed;
        padding-top: 16px;
        &__btn {
          border: 0;
          height: 40px;
          padding: 0 16px;
          @include get-font($f16, $fw-sb, $white);
          @include transitionEase();
          cursor: pointer;
          border-radius: 10px;
          outline: none;
          text-decoration: none;
          &--primary {
            background: $orange;
            &:hover {
              background: $mediumOrange;
            }
          }
          &--removeOption {
            font-size: 14px !important;
            font-weight: 500 !important;
            padding: 0;
            background: transparent;
            color: $removeRed;
            &:hover {
              color: $red;
            }
          }
          &--secondary {
            padding: 0;
            background: transparent;
            color: $removeRed;
            // margin-left: 8px;
            text-decoration: none;
            font-weight: 500;
            border: none;
            &:hover {
              color: $red;
            }
          }
          &--disabled {
            cursor: not-allowed;
            opacity: 0.65;
          }
        }
      }
      
    }
    &__edit-btn {
      @extend %appearance-none;
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0;
      cursor: pointer;
      margin-left: 16px;
      @media screen and (max-width: $onlyMobile) {
       margin-left: 0px !important;
      }
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
    &__add-question {
      width: 100%;
      margin-top: 40px;
      height: 56px;
      line-height: 56px;
      border-radius: 8px;
      border: 1px solid $black;
      background: $black;
      text-align: center;
      @extend %appearance-none;
      @include get-font($f18, $fw-sb, $orange);
      cursor: pointer;
      img {
        margin-right: 16px;
        display: inline-block;
        vertical-align: text-bottom;
      }
      &--inline {
        > span {
          background: rgb(241, 241, 241);
          position: relative;
          z-index: 1;
          padding: 0 8px;
          img {
            width: 20px;
            height: auto;
            margin-right: 8px;
          }
        }
        @include transitionEase();
        display: none;
        font-size: $f16;
        font-weight: $fw-m;
        height: auto;
        background: transparent;
        border-radius: unset;
        border: 0;
        padding: 0;
        margin-top: 24px;
        position: relative;
        &:after {
          content: "";
          width: 80%;
          height: 1px;
          background: $orange;
          @include centerItem();
        }
      }
    }
    &__addImageForQuestion {
      &__circularGalleryImage {
        @include centerItem();
      }
      &__wrapperLabel {
        padding-left: 11px;
        display: flex;
        align-items: center;
      }
      &__wrapper {
        border: 1px solid #d1d1d1;
        border-radius: 0px 0px 8px 8px;
        display: flex;
        width: calc(100% - 2px);
        max-height: 52px;
        padding: 10px 0px;
        cursor: pointer;
      }
      &__removeImage {
        position: absolute;
        right: 2%;
        top: 29%;
        margin-top: 8px;
        width: 20px !important;
        cursor: pointer;
      }
      &__inner {
        width: fit-content;
        margin: 10px;
      }
      &__text {
        &__wrapper {
          align-items: center;
          display: flex;
          flex-direction: row;
          span {
            margin-left: 0.5em;
          }
        }
      }
      &__imageGuidelines {
        justify-content: center;
        @include get-font($f14, $fw-m, $copiedBackground, $lhOnboarding);
        margin-left: 10px;
        @media only screen and (max-width: $onlyMobile) {
          margin-left: 24px;
        }
      }
      &__wrapper:hover {
        &__text {
          &__wrapper {
            background: #e0e0e0;
          }
        }
      }
    }
    &__edit-btn {
      @extend %appearance-none;
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0;
      cursor: pointer;
      margin-left: 16px;
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
    &__choices {
      margin-top: 16px;
      &__empty {
        min-width: 24px;
      }
      &__remove {
        @extend %appearance-none;
        border: 0;
        padding: 0;
        background-color: $white;
        cursor: pointer;
        margin-left: 8px;
      }
      &__add-more {
        @extend %appearance-none;
        border: 0;
        padding: 0;
        background: $white;
        @include get-font($f16, $fw-m, $blue);
        margin-top: 20px;
        cursor: pointer;
      }
      &__container {
        margin-top: 0px;
        border-top: none;
      }
      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      &__title {
        @include get-font($f14, $fw-m, $black);
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--secondary {
          font-weight: $fw-r;
          color: $lightBlack;
        }
      }
      &__input {
        border: 0;
        @include get-font($f14, $fw-r, $black);
        flex: 1;
        &::placeholder {
          opacity: 1;
          @include get-font($f16, $fw-r, $lightGrey, $f24);
        }
        outline: none;
        &__container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
        }
        &__box {
          border: solid 1px $superLightBlack;
          width: 100%;
          border-radius: 10px;
          padding: 12px 16px;
          box-sizing: border-box;
          display: flex;
        }
        &__img {
          position: relative;
          cursor: pointer;
          > img {
            width: 20px;
            height: auto;
          }
          > input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
          }
        }
      }
      .error-text {
        position: relative;
      }
      &__image {
        &__container {
          width: 156px;
          height: 120px;
          border: 1px solid $superLightBlack;
          background: $grayBackground;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        width: 156px;
        height: 120px;
        object-fit: contain;
        &__remove {
          @extend %appearance-none;
          width: 24px;
          height: 24px;
          padding: 6px;
          border-radius: 14px;
          background-color: $lightBlack;
          border: 0;
          z-index: 1;
          position: absolute;
          top: 8px;
          right: 8px;
          cursor: pointer;
          > img {
            width: 12px;
          }
        }
      }
    }
    &__imageChoices {
      &__option {
        max-width: 245px;
        position: relative;
      }
      &__lengthCount {
        @include get-font($f14, $fw-r, $lightBlack);
        background: $white;
        border-radius: 4px;
        position: absolute;
        right: 0;
        margin-right: 15px;
        padding: 4px 8px;
      }
      &__listContainer {
        max-width: 570px;
        display: flex;
        justify-content: space-between;
        padding: 0 !important;
        flex-wrap: wrap;
        list-style: none;
        @media only screen and (max-width: $onlyMobile) {
          padding: revert !important;
        }
      }
      margin-top: 16px;
      &__empty {
        min-width: 24px;
      }
      &__emptyImage {
        width: 250px !important;
        height: 200px !important;
        background: $imageWrapperBackgroundGray;
        border-radius: 10px 10px 0px 0px;
        &__img {
          top: 50%;
          position: relative;
          left: 50%;
        }
      }
      &__remove {
        @extend %appearance-none;
        border: 0;
        padding: 0;
        background-color: $white;
        cursor: pointer;
        margin-left: 8px;
      }
      &__add-more {
        @extend %appearance-none;
        border: 0;
        padding: 0;
        background: $white;
        @include get-font($f16, $fw-m, $blue);
        margin-top: 24px;
        cursor: pointer;
      }
      &__container {
        margin-top: 0px;
        border-top: none;
      }
      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      &__title {
        @include get-font($f14, $fw-m, $black);
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--secondary {
          font-weight: $fw-r;
          color: $lightBlack;
        }
      }
      &__input {
        border: 0;
        @include get-font($f14, $fw-r, $black);
        resize: none !important;
        flex: 1;
        &::placeholder {
          opacity: 1;
          @include get-font($f16, $fw-r, $lightGrey, $f24);
        }
        outline: none;
        &__container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &__box {
          @include form-input-border-shadow();
          width: 250px;
          border-radius: 0px 0px 10px 10px;
          padding: 12px 16px;
          box-sizing: border-box;
          display: flex;
        }
        &__img {
          position: relative;
          cursor: pointer;
          > img {
            width: 20px;
            height: auto;
          }
          > input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
          }
        }
      }
      .error-text {
        position: relative;
      }
      &__image {
        width: 250px;
        height: 200px;
        object-fit: contain;
        &__container {
          width: 156px;
          height: 120px;
          border: 1px solid $superLightBlack;
          background: $grayBackground;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &__remove {
          @extend %appearance-none;
          width: 24px;
          height: 24px;
          padding: 6px;
          border-radius: 14px;
          background-color: $lightBlack;
          border: 0;
          position: absolute;
          left: 40%;
          z-index: 1;
          cursor: pointer;
          > img {
            width: 12px;
          }
        }
      }
    }
    &__save {
      &__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 32px;
        margin-top: 32px;
        border-top: 1px solid $superLightBlack;
      }
      &__date-picker {
        outline: none;
        @media only screen and (max-width: $onlyMobile) {
          width: 100% !important;
        }
        @media(max-width:360px){
          font-size: 14px;
        }
        &__container {
          display: flex;
          align-items: center;
          padding: 16px;
          background: $white;
          border: 1px solid $superLightBlack;
          border-radius: 8px;
          width: 160px;
        }
        &__icon {
          margin-right: 16px;
        }
        background: transparent;
        border: 0;
        width: 100%;
        @include get-font($f16, $fw-sb, $black, $lh);
      }
      &__btn {
        @extend %appearance-none;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        background: $orange;
        padding: 16px;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background: $orange;
        }
        &__details {
          text-align: right;
        }
        &__title {
          @include get-font($f16, $fw-sb, $white, $lh);
          @media(max-width:360px){
            font-size: 14px;
          }
        }
        > img {
          margin-left: 16px;
          height: 24px;
          width: auto;
        }

        //certifications color
        &.blue{
          background: $blue;
        }
      }
      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        > li {
          height: 60px;
          border: 1px solid $orange;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px;
          box-sizing: border-box;
          border-radius: 10px;
          margin-top: 16px;
          cursor: pointer;
          &:hover {
            background: $grayBackground;
          }
          > div {
            display: flex;
            align-items: center;
            > img {
              margin-right: 40px;
            }
            > p {
              @include get-font($f18, $fw-b, $black, $lh);
            }
          }

          //certifications styling
          &.blue{
            border-color: $blue;
          }
        }
      }
      &__type {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 32px;

        &__li {
          height: 60px;
          border: 0.5px solid $superLightBlack;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px;
          box-sizing: border-box;
          border-radius: 10px;
          margin-top: 16px;
          cursor: pointer;
          &:hover {
            background: $grayBackground;
          }
          &__selected {
            border: 0.5px solid $green;
          }

          &__div {
            display: flex;
            > img {
              margin-right: 12px;
            }
            &__text {
              &__title {
                @include get-font($f16, $fw-m, $black, $lh);
              }
              &__subtitle {
                @include get-font($f14, $fw-r, $lightestGray, $lh);
              }
            }
          }
        }
        &__publish-btn {
          @extend %appearance-none;
          height: 32px;
          border: 0;
          @include get-font($f14, $fw-sb, $white);
          padding: 0 12px;
          box-sizing: border-box;
          border-radius: 7px;
          cursor: pointer;
          @include transitionEase(#{background});
          margin-left: auto;
          background: $green;
          &:focus,
          &:hover,
          &:active {
            background: $mediumGreen;
          }
          &--disabled {
            opacity: 0.6;
            pointer-events: none;
          }
        }
      }
    }
    &__group {
      margin-bottom: 24px;
      position: relative;
      @media only screen and (max-width: $onlyMobile) {
        margin-bottom: 32px;
      }
      &__label {
        display: block;
        @include get-font($f14, $fw-m, $black, $lh);
        margin-bottom: 4px;
        &--multi {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &__red-astrick {
          color: $removeRed;
        }
        &--helper {
          @include get-font($f14, $fw-r, $copiedBackground, $lhOnboarding);
        }
        &--choiceHelperText {
          @include get-font($f14, $fw-m, $copiedBackground, $lhOnboarding);
        }
        &--wordCount {
          @include get-font($f14, $fw-r, $copiedBackground, $lhOnboarding);
        }
        &--with-subtitle {
          margin-bottom: 2px !important;
        }
        &--secondary {
          @include get-font($f12, $fw-b, $black, $lh);
          letter-spacing: 0.6px;
        }
        &--error {
          @include get-font($f12, $fw-r, $red);
          margin-bottom: 12px;
        }
        &--red {
          color: $red !important;
        }
        &__span {
          margin-left: 8px;
          @include get-font($f12, $fw-r, $lightBlack);
        }
      }
      &__helper {
        @include get-font($f14, $fw-r, $mediumBlack, $lh);
        margin-bottom: 12px;
      }
      &__multi {
        flex: 1;
        margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
  
      .react-player__video{
        margin-top: unset;
      }
    }
    &__modalCustomPadding {
      padding: 0px 16px 0px 24px !important;
    }
    &__ProfileEditFormGroup {
      position: relative;
      margin-bottom: 32px;
      @media only screen and (max-width: $onlyMobile) {
        margin-bottom: 24px;
      }
      .collaboration-form__suggestion__container {
        margin-top: 0;
      }
      &--multi {
        display: flex;
        @media only screen and (max-width: $onlyMobile) {
          display: block;
          > div {
            margin-right: 0;
            margin-bottom: 24px;
            width: 100% !important;
          }
        }
        > div {
          margin-right: 24px;
          width: 50%;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &--after {
      &::after {
        content: " ";
        position: absolute;
        height: 1px;
        background: #ededed;
        width: calc(100% + 42px);
        left: -14px;
        top: 0;
      }
    }
    &__defaultButtonColor {
      background: $orange;
      &:hover {
        background: $orange;
      }
      &--disabled {
        background: $mediumOrange;
        opacity: 0.65;
        &:hover {
          background: $mediumOrange;
          cursor: not-allowed;
        }
      }
    }
    &__tooltipStyle {
      visibility: hidden !important;
      display: none !important;
    }
    &__defaultBorder {
      padding-top: 16px;
      margin-right: 0px !important;
    }
    &__toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 12px;
      margin-top: 32px;
      &__title {
        @include get-font($f14, $fw-m, $orange);
      }
      &__description {
        @include get-font($f14, $fw-m, $black, $lhOnboarding);
        margin-top: 8px;
        margin-right: 0.8em;
      }
      &__quizDescription {
        @include get-font($f14, $fw-r, $copiedBackground, $lhOnboarding);
        margin-top: 8px;
        margin-right: 0.8em;
      }
      &__darkerDescription {
        @include get-font($f14, $fw-m, $darkBlack);
        margin-top: 0.1em;
        margin-right: 23px;
      }
      .switch__on {
        background: $lighterGreen;
      }
      .switch__input:checked + .switch__btn {
        background: $green;
      }
      // doing this for certifications buttons styling
      &.blue{
        .quiz__form__toggle__title{
          &.blue{
            color: $blue;
          }
        }
        .switch__on {
          background: $lightBlue;
        }
        .switch__input:checked + .switch__btn {
          background: $blue;
        }
      }
      &__container {
        .collaboration-form__image-container {
          margin-top: 16px;
        }
        .error-text {
          position: relative;
          margin-top: 0;
        }
      }
    }
    &__quizTextarea {
      height: 200px;
      border-radius: 8px !important;
      &::placeholder {
        opacity: 1;
        @include get-font($f16, $fw-r, $lightGrey, $f24);
      }
      @include getDefaultScrollbar();
    }
    &__input {
      @include get-font($f16, $fw-r, $black, $lh);
      outline: none;
      @include getDefaultScrollbar();
      @extend %border-box-shadow;
      resize: none !important;
      width: 100%;
      padding: 12px 16px;
      border-radius: 10px;
      // border: solid 0.5px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      background-color: $white;
      &:focus,
      &:active {
        border: 1px solid $mediumBlack;
      }
      &::placeholder {
        opacity: 1;
        @include get-font($f16, $fw-r, $lightGrey, $f24);
        color: #757575 !important;
      }
    }
    &__input-container {
      width: 100%;
      background: $white;
      @extend %border-box-shadow;
      border-radius: 10px;
      padding: 12px 16px;
      &--media {
        padding: 32px 24px;
        box-sizing: border-box;
      }
      &__media-group {
        margin-bottom: 48px;
        &:last-child {
          margin-bottom: 0;
        }
        .collaboration-form__group__label {
          display: flex;
          align-items: center;
          &__icon {
            width: 20px;
            height: auto;
            margin-right: 12px;
          }
        }
        &__input {
          width: 100%;
          padding: 12px 16px;
          box-sizing: border-box;
          background: $backgroundGray;
          border-radius: 10px;
          border: 1px solid transparent;
          @include get-font($f16, $fw-r, $black, $lh);
          outline: none;
          @media only screen and (max-width: $onlyMobile) {
            padding: 10px 8px;
            font-size: $f14;
          }
          &:focus {
            border: 1px solid $mediumBlack;
          }
          &--error {
            border-color: $removeRed;
          }
        }
        &__document {
          &__container {
            display: flex;
            align-items: center;
            @media only screen and (max-width: $onlyMobile) {
              display: block;
            }
          }
          &__remove {
            vertical-align: top;
            margin-top: 18px;
            margin-left: 18px;
            height: 12px;
            width: auto;
          }
          &__btn {
            height: 48px;
            display: inline-block;
            padding: 0 24px;
            background: $backgroundGray;
            position: relative;
            border-radius: 10px;
            line-height: 48px;
            @include get-font($f16, $fw-sb, $black);
            margin-right: 12px;
            cursor: pointer;
            &__text {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              color: $black;
              text-decoration: none;
              white-space: nowrap;
              &:hover {
                text-decoration: underline;
              }
            }
            &__input {
              position: absolute;
              opacity: 0;
              display: none;
            }
          }
          &__helper {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
            @media only screen and (max-width: $onlyMobile) {
              font-size: $f12;
              margin-top: 4px;
            }
          }
        }
      }
    }

    &__textarea {
      resize: none !important;
      @include get-font($f16, $fw-r, $black, $f24);
      @include getDefaultScrollbar();
      outline: none;
      border: solid 1px rgba(0, 0, 0, 0.1);
      margin: 0px 0px -5px 0px !important;
      height: 110px;
      max-height: 136px;
      box-sizing: border-box;
      box-shadow: none !important;
      width: 100%;
      background: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      padding: 12px 16px;
      
      &::placeholder {
        opacity: 1;
        @include get-font($f16, $fw-r, $lightGrey, $f24);
      }
      &__removeTopRightRadius {
        border-top-right-radius: 0px !important;
      }
      &__removeRightRadius {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
      &__textarea1 {
        &::placeholder {
          opacity: 1;
          color: black;
        }
      }
    }

    &__imageloader{
      height: 14px;
      img{
          height: 100%;
      }
    }
    &__addQuestionImage{
      display: block !important;
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .quiz {
    &__form {
      &__details {
        padding: 16px 12px;
      }
    }
  }
}
