.user-create-block {
  display: flex;
  align-items: center;
  &__container {
    position: relative;
    display: inline-block;
  }
  &__select {
    background: $white;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
    left: 0;
    border-radius: 10px;
    @extend %border-box-shadow;
    cursor: pointer;
    overflow: hidden;
    width: 300px;
    .user-create-block {
      // margin-bottom: 8px;
      padding: 8px;
      &:hover {
        background: $grayBackground;
      }
    }
  }
  &__avatar {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border: solid 1px $grayBackground;
    border-radius: 36px;
    background: $grayBackground;
    margin-right: 8px;
    overflow: hidden;
    display: block;
    box-sizing: border-box;
    flex-shrink: 0;
    &--square {
      border-radius: 5px;
    }
  }
  &__name {
    @include get-font($f14, $fw-m, $black, $lh);
    @include limit-text-to-line(1);
    &--verified {
      display: inline-flex;
      align-items: center;
    }
    &--copy-verified {
      // display: inline-flex;
      align-items: center;
    }
  }
  &__chevron {
    width: 14px;
    margin-left: 6px;
    // transform: rotate(90deg);
  }
}

.useravatar {
  position: relative;
  &__icon {
    position: absolute;
    background: $white;
    border-radius: 20px;
    padding: 1px;
    left: -4px;
    box-shadow: 0 1px 2px 0 $tastingCardShadow;
    border: solid 0.5px $tastingCardShadow;
    top: -4px;
    width: 16px;
    &__small {
      width: 10px;
    }
  }
}
