.profile {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
      background: $white;
      display: flex;
      align-items: center;
      @extend %border-box-shadow;
      margin-bottom: 16px;
      border-radius: 10px;
      padding: 24px 16px;
      box-sizing: border-box;
      object-fit: cover;

      .useravatar__icon {
        left: 4px;
        top: 4px;
      }
      &__image {
        display: inline-block;
        width: 96px;
        height: 96px;
        border-radius: 52px;
        margin-right: 16px;
        border: 0.5px solid $grayBackground;
        box-sizing: border-box;
        flex-shrink: 0;
        object-fit: cover;
      }
      &__info {
        flex: 1;
      }
      &__company-img {
        border-radius: 10px !important;
      }
      position: relative;
    }
    a {
      text-decoration: none;
    }
    p {
      word-wrap: break-word;
    }
    &__title {
      @include get-font($f16, $fw-sb, $black, $lh);
    }
    &__badge {
      vertical-align: top;
      margin-left: 6px;
    }
    &__subtitle {
      @include get-font($f14, $fw-m, $black, $lh);
      margin-top: 8px;
      &--badge {
        display: flex;
        align-items: center;
      }
    }
    &__desc {
      @include get-font($f14, $fw-r, $mediumBlack, $lh);
      margin-top: 12px;
    }
    &__btn {
      &__container {
        position: absolute;
        top: 24px;
        right: 16px;
      }
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .profile {
    &__list {
      &__item {
        padding: 12px 16px;
        > img {
          width: 48px;
          height: 48px;
          border-radius: 48px;
          margin-right: 12px;
        }
        &__company-img {
          border-radius: 10px !important;
        }
      }
      &__subtitle,
      &__desc {
        margin-top: 6px;
      }
      &__btn {
        &__container {
          top: 12px;
        }
      }
    }
  }
}
