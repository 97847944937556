.remove-company-modal {
    &__content {
        margin-bottom: 16px;
        &__text {
            @include get-font($f14, $fw-m, $black, $lh);
            &--light {
                font-weight: $fw-r;
            }
            &--danger {
                margin-top: 4px;
                color: $removeRed;
                font-weight: $fw-sb;
            }
        }
    }
    input {
        width: 100%;
        box-sizing: border-box;
        height: 36px;
        padding: 8px 16px;
        @include form-input-border-shadow();
        outline: none;
        @include get-font($f14, $fw-r, $black, $lh);
        &:focus, &:active {
            border: 1px solid $mediumBlack;
            box-shadow: none;
        }
        border-radius: 10px;
        margin-bottom: 30px;
    }
    .default-modal__footer {
        width: calc(100% + 48px);
        margin-bottom: -24px;
        margin-left: -24px;
        padding: 16px 24px;
        box-sizing: border-box;
        border-top: 1px solid $grayBackground;
    }
}