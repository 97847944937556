.suggestions {
    .slick-track {
        margin: 0;
    }
    &__info {
        margin-bottom: 18px;
        &__title {
            @include get-font($f18, $fw-sb, $black, $lh);
            margin-bottom: 4px;
        }
        &__subtitle {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
        }
        &--multi {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__link {
            @include get-font($f14, $fw-b, $blue, $lh);
            letter-spacing: 0.7px;
            text-decoration: none;
        }
    }
    &__arrow {
        @extend %appearance-none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        background: $white;
        @extend %border-box-shadow;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &--prev {
            margin-left: -12px;
        }
        &--next {
            right: 0;
            margin-right: -12px;
        }
        &__icon {
            width: 16px;
            height: auto;
            &--prev {
                transform: rotate(180deg);
            }
        }
        &--disabled {
            display: none;
            pointer-events: none;
            opacity: 0;
        }
    }
    &__follow-btn {
        @extend %appearance-none;
        background: $white;
        cursor: pointer;
        @include transitionEase(#{background});
        &:hover, &:focus, &:active {
            background: rgba(246, 14, 58, 0.1);
        }
        border: 0;
        border-radius: 3px;
        height: 21px;
        @include get-font($f12, $fw-b, $removeRed, $lh);
        padding: 2px 4px 1px;
        box-sizing: border-box;
        letter-spacing: 0.6px;
        &--disabled {
            opacity: 0.65;
        }
    }
    &__following {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        // background: $grayBackground;
        // padding: 0 8px;
        box-sizing: border-box;
        border-radius: 4px;
        height: 21px;
        img {
            width: 12px;
            margin-right: 6px;
        }
        @include get-font($f14, $fw-r, $mediumBlack, $lh);
    }
}