.pr-manage--demography {
  top: 50%;
  transform: translate(-50%, -50%);
  width: 761px;
  @media only screen and (max-width: $onlyMobile) {
    width: 100%;
    transform: translateY(-50%);
  }
  .scrollable-modal__footer {
    .cancel-btn {
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      @include get-font($f16, $fw-sb, $removeRed, $lh24);
    }
    .submit-btn {
      outline: none;
      border: none;
      background-color: $blue;
      @include get-font($f16, $fw-sb, $white, $lh24);
      padding: 8px 16px 8px 16px;
      border-radius: 8px;
      display: flex;
      gap: 8px;
      cursor: pointer;
      &.green {
        background-color: $green;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }
      .submit-loader{
        >img {
          width: 24px;
          filter: brightness(0) saturate(100%) invert(95%) sepia(23%) saturate(2%) hue-rotate(326deg) brightness(111%) contrast(100%);;
        }
      }
    }
  }
}
.demography {
  &--modal {
    padding: 16px;
    padding-top: 8px;
  }
  .disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  &__product-select-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_product-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .product-count-list {
      margin: 0;
      padding: 0;
      list-style: none;
      .item {
        display: inline-block;
        width: 45px;
        height: 45px;
        background: $white;
        @include get-font($f14, $fw-sb, $black, $lh);
        line-height: 45px;
        text-align: center;
        border: 0.5px solid $superLightBlack;
        cursor: pointer;
        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &.active {
          background: $blue;
          color: $white;
        }
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
    .toggle-conatiner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .toggle-with-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.vertical-line {
          border-right: 1px solid #d1d1d1;
          padding-right: 28px;
          margin-right: 24px;
        }
        .target-demography-text {
          text-align: left;
          @include get-font($f14, $fw-m, $black, $lhOnboarding);
          margin-right: 16px;
        }
      }
      .disabled {
        opacity: 0.7;
        pointer-events: none;
      }
      .city-label {
        gap: 8px;
        display: flex;
        align-items: center;
        input[type="checkbox"] {
          width: 16px;
          height: 16px;
        }
        .target-demography-text {
          text-align: left;
          @include get-font($f14, $fw-m, $black, $lhOnboarding);
          margin-right: 16px;
        }
        .disabled {
          opacity: 0.7;
          pointer-events: none;
        }
      }
    }
  }
  &__text-copy-conatiner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .targate-text {
      @include get-font($f12, $fw-b, $black, $lh);
      text-transform: uppercase;
    }
    .copy-button {
      border-radius: 8px;
      padding: 12px;
      background-color: #4990e2;
      min-width: 200px;
      outline: none;
      border: transparent;
      @include get-font($f14, $fw-m, $white, $lhOnboarding);
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }
  }
  .select-city-text {
    @include get-font($f14, $fw-m, $black, $lhOnboarding);
  }
  .demography_data-table {
    display: flex;
    justify-content: flex-end;
    position: relative;
    gap: 16px;
    align-items: center;
    &__container{
      border: 1px solid #d1d1d1;
      border-radius: 8px;
      padding: 16px;
      margin: 20px 0 16px 0;

      .toggle-conatiner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .toggle-with-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .target-demography-text {
            text-align: left;
            @include get-font($f14, $fw-m, $black, $lhOnboarding);
            margin-right: 16px;
          }
        }
        .disabled {
          opacity: 0.7;
          pointer-events: none;
        }
        .city-label {
          gap: 8px;
          display: flex;
          align-items: center;
          input[type="checkbox"] {
            width: 16px;
            height: 16px;
          }
        }
      }
      .copy-city-btn {
        background-color: #171717;
        gap: 8px;
        border-radius: 8px;
        padding: 12px;
        @include get-font($f14, $fw-m, $white, $lhOnboarding);
        display: flex;
        align-items: center;
        cursor: pointer;
        align-self: flex-end;
        &.disabled {
          pointer-events: none;
          opacity: 0.7;
        }
      }
    }
    &__action{
      &--container{
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 16px;
      }
    }
    &.isCity {
      justify-content: flex-end;
    }
    &.notCity {
      margin-top: 0px;
    }
    &.city-wise {
      display: unset;
    }
    .content {
      display: flex;
      gap: 16px;
    }
    .column {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .city-tooltip-container {
      position: relative;
      display: flex;
      align-self: flex-end;
      .city-tooltip {
        padding: 8px 12px 8px 12px;
        position: absolute;
        width: fit-content;
        border-radius: 8px;
        top: -36px !important;
        left: -90px !important;
        text-align: center;
        @include get-font($f14, $fw-r, #eeeeee, $lhOnboarding);
      }
      .__react_component_tooltip.place-top:after {
        margin-left: 45px !important;
      }
    }
   
    .text {
      text-align: center;
      @include get-font($f16, $fw-r, $darkBlack, $lh24);
      text-transform: capitalize;
      &.key-text {
        color: #747474;
        padding: 12px 0;
      }
    }
    .input {
      padding: 12px 16px 12px 16px;
      border-radius: 8px;
      border: 1px solid #d1d1d1;
      width: 43px;
      height: 20px;
      text-align: center;
      @include get-font($f16, $fw-r, $lightGrey, $lh24);
      &::-webkit-inner-spin-button {
        display: none;
      }
      &:focus,
      &:active {
        border: 1px solid #d1d1d1;
        outline: none;
      }
      &.male {
        color: #4990e2;
      }
      &.female {
        color: #f84d7b;
      }
      &.other {
        color: $black;
      }
    }
  }
  &_city-view {
    margin-top: 12px;
    &.disabled {
      pointer-events: none;
    }
    .city-name {
      @include get-font($f14, $fw-b, $black, $lhOnboarding);
      display: flex;
      &.label {
        &.disabled {
          opacity: 0.7;
          pointer-events: none;
        }
        gap: 8px;
        input[type="checkbox"] {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.demography-summary {
  &--table {
    top: 50%;
    transform: translate(-50%, -50%);
    width: 670px;
    .default-modal__header {
      margin-bottom: 8px;
      .default-modal__header__title {
        @include get-font($f18, $fw-sb, $darkBlack, $lh);
      }
    }
    .color-coding-div {
      padding-bottom: 12px;
      &.bottom-shadow{
        clip-path: inset(0px 0px -10px 0px);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 7px 0px;
      }
      .list {
        padding-left: 28px;
        margin-top: 8px;
        margin-bottom: 0px;
        .text {
          @include get-font($f14, $fw-r, $white, $lh);
          margin-bottom: 2px;
          &::marker {
            color: $copiedBackground;
          }
        }
      }

      .label-text {
        @include get-font($f14, $fw-r, $copiedBackground, $lhOnboarding);
        margin-right: 12px;
      }
    }
    .city-list {
      display: flex;
      white-space: nowrap;
      overflow: auto;
      @include getDefaultScrollbar();
      &.bottom-shadow{
        clip-path: inset(0px 0px -10px 0px);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 7px 0px;
      }
      &.rightLeft-shadow{
        clip-path: inset(0px -10px 0px -10px);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 7px 0px;
      }
      .div {
        padding: 8px 16px 8px 16px;
        border-radius: 8px;
        border: 1px solid #d1d1d1;
        @include get-font($f16, $fw-r, $black, $lh24);
        background-color: white;
        margin-right: 16px;
        cursor: pointer;
      }
    }
    .table-data {
      display: flex;
      margin-bottom: 16px;
      margin-top: 8px;
      .column {
        flex: 1;
        border-right: 1px solid $imageWrapperBackgroundGray;
        min-width: fit-content;
        &:first-child {
          .row {
            border-bottom-left-radius: 8px;
            border-left: 1px solid $imageWrapperBackgroundGray;
            .cell {
              &:last-child {
                border-bottom-left-radius: 8px;
              }
            }
          }
          .header {
            border-top-left-radius: 8px;
          }
        }
        &:last-child {
          border-right: none;
          .header {
            border-top-right-radius: 8px;
          }
          .row {
            border-right: 1px solid $imageWrapperBackgroundGray;
            border-bottom-right-radius: 8px;
            .cell {
              &:last-child {
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }

      .header {
        background-color: #f2f2f2;
        padding: 8px 16px 8px 16px;
        text-align: center;
        @include get-font($f16, $fw-sb, $black, $lh24);
        .info-text {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          > img {
            filter: brightness(0) saturate(100%) invert(44%) sepia(11%)
              saturate(0%) hue-rotate(245deg) brightness(99%) contrast(89%);
          }
          .info-text-tooltip {
            background-color: $black;
            opacity: 1;
            @include get-font($f14, $fw-m, $white, $lh);
          }
        }
      }
      .row {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        .cell {
          padding: 8px 16px 8px 16px;
          border-bottom-color: $imageWrapperBackgroundGray;
          border-bottom: 1px solid $imageWrapperBackgroundGray;
          .text{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
          }
        }
      }
    }
    .table-container {
      overflow: auto;
      overflow-x: hidden;
      @include getDefaultScrollbar();
      width: 100%;
    }
    .missing-demography-table {
      margin-top: 16px;
      .title-text {
        @include get-font($f14, $fw-b, $copiedBackground, $lh);
        text-transform: uppercase;
      }
      .table-data {
        margin-top: 8px;
        .column {
          flex: 1;
          &:first-child {
            .header {
              min-width: 112px;
            }
          }
        }
      }
    }
  }

  &--content {
    padding-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    &.survey{
      border: none;
      .target-demography-text{
        color: $copiedBackground;
      }
    }
    .text {
      text-align: center;
      border-radius: 8px;
      padding: 8px 16px 8px 16px;
      width: fit-content;
      cursor: pointer;
      display: flex;
      align-items: center;
      @include get-font($f14, $fw-m, $black, $lhOnboarding);
      gap: 8px;
      &.survey{
        border: 1px solid $black;
      }
      >img{
        max-width: 16px;
      }
    }
    .target-demography-text {
      @include get-font($f14, $fw-b, $blue, $lhOnboarding);
    }
    .demography-pill-div {
      margin-top: 16px;
      white-space: nowrap;
      overflow: auto;
      @include getDefaultScrollbar();
      display: flex;
      gap: 8px;
      padding-bottom: 8px;
      align-items: flex-end;
      .dnm-conatiner{
        display: flex;
        flex-direction: column;
      }
      .dnm-div {
        border: 1px solid $lightGrey;
        @include get-font($f12, $fw-b, $red, $lhOnboarding);
        width: fit-content;
        display: flex;
        align-self: flex-end;
        padding: 4px 12px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: 0px;
      }
    }
  }
}

// web mobile responsive css
@media only screen and (max-width: $onlyMobile) {
  .demography {
    &__product-select-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }
    &_product-container {
      .toggle-conatiner {
        .toggle-with-text {
          &.vertical-line {
            border: none;
            margin-right: 0px;
          }
        }
        display: flex;
        gap: 16px;
        align-items: flex-start;
        flex-wrap: wrap;
      }
      display: flex;
      gap: 16px;
      align-items: center;
      flex-wrap: wrap;
    }
    &__text-copy-conatiner {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }
    .demography_data-table {
      width: auto;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      justify-content: flex-start !important;
      padding-bottom: 8px;
    }
  }
  .demography-summary {
    &--table {
      width: 100%;
      transform: translateY(-50%);
      .color-coding-div {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
      }
      .table-data {
        white-space: nowrap;
        overflow: hidden;
        overflow-x: scroll;
      }
    }
  }
}
